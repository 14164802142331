import { isRouteErrorResponse, useRouteError, Link } from 'react-router-dom';

const DEFAULT_ERROR = {
  title: 'Unexpected Error',
  description: ` Sorry, something unexpected happened and 
  we are unable to process your request at the moment
  `
};

const KNOWN_ERRORS = {
  404: 'The page you are looking for could not be found.',
  500: 'Oops, something went wrong.'
};

type TKnownErrorKeys = keyof typeof KNOWN_ERRORS;

const ErrorBoundary = () => {
  const error = useRouteError() as any;

  const data = (() => {
    if (isRouteErrorResponse(error)) {
      return {
        title: error.status,
        description:
          KNOWN_ERRORS?.[error.status as TKnownErrorKeys] || error.data
      };
    }

    return DEFAULT_ERROR;
  })();

  return (
    <div className='tw-flex tw-min-h-screen tw-w-full tw-flex-col tw-items-center tw-justify-center tw-bg-background tw-px-4 sm:tw-px-6'>
      <div className='tw-mx-auto tw-max-w-md tw-text-center'>
        <h1 className='tw-text-8xl tw-font-bold tw-tracking-tighter tw-text-primary-black'>
          {data.title}
        </h1>
        <p className='tw-mt-4 tw-text-lg tw-text-muted-foreground'>
          {data.description}
        </p>
        <Link
          to='/'
          className='tw-no-underline tw-mt-8 tw-inline-flex tw-h-10 tw-items-center tw-justify-center tw-rounded-md tw-bg-primary tw-px-6 tw-text-sm tw-font-medium tw-text-primary-foreground tw-shadow tw-transition-colors tw-hover:tw-bg-primary/90 hover:tw-text-primary-foreground tw-focus-visible:tw-outline-none tw-focus-visible:tw-ring-1 tw-focus-visible:tw-ring-ring'>
          Go back home
        </Link>
      </div>
    </div>
  );
};

export default ErrorBoundary;
