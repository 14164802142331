import { atom } from 'recoil';

export interface IBrand {
  name: string;
  is_default: boolean;
  id: string;
  team_id: number;
  users: number[];
  create_at: string;
  updated_at: string;
}

const userBrands = atom<IBrand[]>({
  key: 'userBrands',
  default: []
});
const activeBrand = atom<IBrand | null>({
  key: 'activeBrand',
  default: null
});

export { userBrands, activeBrand };
