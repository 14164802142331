import {
  Outlet,
  useParams,
  useRouteLoaderData,
  useSearchParams
} from 'react-router-dom';
import { ROUTER_PATHS_ID } from 'src/lib/constants';
import ProfileCard from 'src/User/components/ProfileCard';
import AnalyticsNavbar from 'src/User/components/AnalyticsNavbar';
import { PromiseType } from 'src/lib/models';
import analyticsLayoutLoader from '../../../layout/loaders/analyticsLayoutLoader';
import formatCellValueToAmount from 'src/User/features/Viz/components/Table/utils/formatCellValue';
import addParamsToPathname from 'src/lib/utils/addParamsToPathname';
import { DATE_SEARCH_PARAMS } from 'src/configs';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { type TNamespace } from 'src/i18n';

const navLinks = [
  {
    href: 'overview',
    i18nKey: 'overview'
  },
  {
    href: 'clicks-on-page',
    i18nKey: 'clicks-on-page'
  },
  {
    href: 'audience',
    i18nKey: 'audience'
  },
  // {
  //   href: 'stories',
  //   i18nKey: 'stories',
  // },
  {
    href: 'posts',
    i18nKey: 'posts'
  }
];

const FacebookPageAnalyticsLayout = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { socialAccounts } = useRouteLoaderData(
    ROUTER_PATHS_ID.ANALYTICS_LAYOUT
  ) as PromiseType<ReturnType<typeof analyticsLayoutLoader>>;
  const { t } = useTranslation<TNamespace>();

  const facebookPages = socialAccounts?.FACEBOOK_PAGE;
  const activeFacebookPageInfo = facebookPages?.find(
    ({ facebook_page_id }) => facebook_page_id == id
  );
  const duration = {
    start: searchParams.get(DATE_SEARCH_PARAMS.start) ?? undefined,
    stop: searchParams.get(DATE_SEARCH_PARAMS.stop) ?? undefined
  };

  return (
    <section>
      <header>
        <div className='shadow tw-rounded-b-xl tw-mb-5 tw-overflow-hidden'>
          <ProfileCard
            username={activeFacebookPageInfo?.name ?? ''}
            bio={activeFacebookPageInfo?.about ?? ''}
            created_at={activeFacebookPageInfo?.created_at ?? null}
            updated_at={activeFacebookPageInfo?.updated_at ?? null}
            profilePhotoUrl={activeFacebookPageInfo?.profile_pic ?? ''}
            profileMetrics={[
              {
                label: capitalize(t('fans', { ns: 'analytics' }) ?? ''),
                value: formatCellValueToAmount(
                  activeFacebookPageInfo?.fan_count
                )
              }
            ]}
          />
        </div>
        <AnalyticsNavbar
          navLinks={navLinks.map(({ href, i18nKey }) => ({
            title: t(i18nKey) ?? '',
            href: addParamsToPathname(href, duration)
          }))}
        />
      </header>
      <section className='d-flex flex-column tw-mt-6 tw-gap-3 py-0 w-100'>
        <Outlet />
      </section>
    </section>
  );
};

export default FacebookPageAnalyticsLayout;
