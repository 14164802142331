import PromptBox, { usePromptBoxFetcher } from './PromptBox';
import SuggestionTile from './SuggestionTile';
import { Sparkles } from 'lucide-react';
import LoadingConversation from './LoadingConversation';
import { TNamespace } from 'src/i18n';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import classNames from 'classnames';

interface NewSomateAIConversationProps {
  actionPath: string;
  suggestions: { suggestion: string; icon: React.ReactNode }[];
  header?: {
    title: string;
    description: string;
    icon?: ReactNode;
  };
  suggestionsLayout?: '2x2' | '1x4';
}

const NewConversationView = (props: NewSomateAIConversationProps) => {
  const { actionPath, suggestions, header, suggestionsLayout = '1x4' } = props;

  const { t } = useTranslation<TNamespace>();
  const fetcher = usePromptBoxFetcher();

  return (
    <section className='tw-flex tw-flex-col tw-justify-center tw-h-full md:tw-max-w-xl lg:tw-max-w-2xl xl:tw-max-w-4xl tw-mx-auto'>
      <div className='tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-5 tw-w-full tw-flex-1'>
        <header>
          {header ? (
            <div className='tw-flex tw-flex-col tw-justify-center tw-items-center'>
              {header.icon ? (
                <div className='tw-rounded-full tw-p-3 tw-w-fit tw-h-fit tw-bg-slate-50 tw-border tw-mb-2'>
                  {header.icon}
                </div>
              ) : null}
              <h1 className='tw-text-3xl tw-font-bold tw-text-center tw-mb-4'>
                {header.title}
              </h1>
              <p className='tw-text-center tw-text-lg tw-max-w-2xl tw-text-slate-500'>
                {header.description}
              </p>
            </div>
          ) : (
            <div className='tw-flex tw-items-center tw-gap-2'>
              <span className='!tw-font-bold tw-text-3xl'>
                {t('somate-ai')}
              </span>
              <Sparkles className='tw-w-10 tw-h-10' />
            </div>
          )}
        </header>
        {fetcher.state == 'idle' ? (
          <div
            className={classNames('tw-grid tw-gap-6 tw-w-11/12', {
              'tw-grid-cols-2 tw-grid-rows-2': suggestionsLayout == '2x2',
              'tw-grid-cols-4 tw-grid-rows-1': suggestionsLayout == '1x4'
            })}>
            {suggestions.map(({ suggestion, icon }, index) => (
              <SuggestionTile
                key={index}
                suggestion={suggestion}
                icon={icon}
                onClick={() => {
                  fetcher.submit(
                    { prompt: suggestion },
                    { method: 'POST', action: actionPath }
                  );
                }}
              />
            ))}
          </div>
        ) : null}
        {fetcher.state == 'submitting' ? (
          <LoadingConversation
            title='Creating Chat'
            steps={[
              {
                label: 'Processing prompt and generating response',
                isActive: true
              }
            ]}
          />
        ) : null}
      </div>
      <PromptBox
        action={actionPath}
        methond='POST'
      />
    </section>
  );
};

export default NewConversationView;
