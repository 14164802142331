import { buttonVariants } from 'src/components/ui/button';
import { TColumDef } from 'src/User/features/Viz/components/types';
import formatDate from '../utils/formatDate';
import { ICellRendererParams } from 'ag-grid-community';
import { Download } from 'lucide-react';
import { truncate } from 'lodash';

export const REPORT_LANGUAGE_OPTIONS = [
  {
    value: 'en',
    label: 'English'
  },
  {
    value: 'pl',
    label: 'Polish'
  }
];

export const REPORT_HISTORY_TABLE_COLS: TColumDef = [
  {
    field: 'id',
    headerName: 'Report Id',
    minWidth: 270,
    autoHeight: true,
    sortable: true,
    flex: 0.8,
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    },
    valueFormatter: ({ value }) => truncate(value, { length: 35 })
  },
  {
    field: 'template_name',
    headerName: 'Template Name',
    minWidth: 270,
    autoHeight: true,
    sortable: true,
    flex: 1,
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    },
    valueFormatter: ({ value }) => truncate(value, { length: 35 })
  },
  {
    field: 'report_type',
    headerName: 'Report Type',
    minWidth: 150,
    autoHeight: true,
    sortable: true,
    flex: 0.4,
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  {
    field: 'duration',
    headerName: 'Report Dates',
    minWidth: 250,
    autoHeight: true,
    sortable: true,
    flex: 0.6,
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    minWidth: 170,
    sortable: true,
    autoHeight: true,
    flex: 0.85,
    valueFormatter: (props) =>
      typeof props.value === 'string' ? formatDate(props.value) : 'N/A',
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  {
    field: 'report_path',
    headerName: 'Report Link',
    minWidth: 200,
    autoHeight: true,
    sortable: true,
    flex: 0.7,
    cellRenderer: (props: ICellRendererParams) => (
      <a
        href={props.value}
        target='_blank'
        rel='noreferrer noopener'
        className={buttonVariants({
          variant: 'secondary',
          size: 'default',
          className:
            'tw-group tw-flex tw-gap-2 tw-items-center tw-no-underline tw-text-inherit tw-border tw-font-medium tw-rounded-xl tw-my-2 tw-px-2 tw-w-fit hover:tw-text-primary hover:tw-border-primary tw-transition-all'
        })}>
        Download
        <Download className='tw-text-slate-500 tw-w-4 tw-h-4 group-hover:tw-text-inherit' />
      </a>
    )
  }
];
