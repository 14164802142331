import classNames from 'classnames';
import { Nav, NavLink, NavItem, TabContent, TabPane, Input } from 'reactstrap';
import { useState } from 'react';
import { capitalize, startCase } from 'lodash';
import previewImg from 'src/Assets/images/reporting-page-preview2.png';
import styles from './pages.module.css';
import RightArrowCoiled from 'src/components/icons/right-arrow-coiled';
import { ITemplateSectionProps } from '../../models';

export default function Pages(props: ITemplateSectionProps) {
  const { templateData, dispatcher } = props;

  const [activeTab, setActiveTab] = useState(0);
  const activeAccounts = templateData.socialAccounts.filter(
    ({ status }) => status == 'add-to-template'
  );

  return (
    <>
      <Nav tabs>
        {activeAccounts.map(({ accountName }, index) => (
          <NavItem
            key={index}
            className='bg-transparent'>
            <NavLink
              tag={'button'}
              type='button'
              className={classNames(
                { active: activeTab == index },
                { 'bg-transparent': activeTab !== index }
              )}
              onClick={() => {
                setActiveTab(index);
              }}>
              {accountName}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent
        activeTab={activeTab}
        className='bg-white mt-0 p-3 rounded-bottom'>
        {activeAccounts.length > 0 ? (
          activeAccounts.map(({ pages, accountId }, index) => (
            <TabPane
              key={index}
              tabId={index}>
              <div className='d-flex gap-4 flex-wrap justify-content-start'>
                {Object.entries(pages).map(([pageName, isActive]) => {
                  const pageLabel = capitalize(startCase(pageName));

                  return (
                    <div key={pageName}>
                      <p className='d-flex align-itmes-center justify-content-between'>
                        <span>{pageLabel}</span>
                        <Input
                          type='switch'
                          role='switch'
                          checked={isActive}
                          id={pageLabel}
                          value={pageLabel}
                          onChange={() =>
                            dispatcher({
                              type: 'UPDATE_ACTIVE_PAGES',
                              payload: {
                                accountId,
                                page: {
                                  name: pageName,
                                  isActive: !isActive
                                }
                              }
                            })
                          }
                        />
                      </p>
                      <div className={styles['page-img-container']}>
                        <img
                          className={styles['page-img']}
                          src={previewImg}
                          alt={`${pageLabel} template page preview`}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </TabPane>
          ))
        ) : (
          <div
            className={classNames(
              'd-flex align-items-start tw-justify-end gap-2 tw-w-full tw-text-lg',
              styles['select-acct-text']
            )}>
            <span>Select a social media account to get started</span>{' '}
            <RightArrowCoiled
              className={classNames(
                'tw-h-14 tw-w-14 tw-rotate-90',
                styles['right-arrow']
              )}
            />
          </div>
        )}
      </TabContent>
    </>
  );
}
