import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import {
  getReportTemplatesByBrand,
  getReportsByBrand
} from 'src/User/services/reporting';
import { USER_CONFIG } from 'src/configs';

const rootRouteLoader = async () => {
  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);

  const { data: response } = await getReportsByBrand(brandId);
  const { data: reportsHistory } = response;

  const { data: reportTemplatesResponse } = await getReportTemplatesByBrand(
    brandId
  );
  const { data: reportTemplates } = reportTemplatesResponse;

  return { reportsHistory, reportTemplates };
};

export default rootRouteLoader;
