import { TColumDef } from 'src/User/features/Viz/components/types';
import PostPreviewCellRenderer from '../../PostPreviewCellRenderer';
import i18n, { TNamespace } from 'src/i18n';
import { startCase } from 'lodash';

export const POST_ANALYTICS_TABLE_COLS: TColumDef = [
  {
    field: 'media_preview_details',
    headerName: startCase(
      i18n.t('media_preview_details', {
        ns: 'analytics' satisfies TNamespace
      }) ?? ''
    ),
    minWidth: 400,
    cellRenderer: PostPreviewCellRenderer,
    sortable: true,
    autoHeight: true,
    flex: 1
  },
  {
    field: 'reach',
    headerName: startCase(
      i18n.t('reach', {
        ns: 'analytics' satisfies TNamespace
      }) ?? ''
    ),
    minWidth: 150,
    sortable: true,
    autoHeight: true,
    flex: 0.3,
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  {
    field: 'impressions',
    headerName: startCase(i18n.t('impressions') ?? ''),
    minWidth: 150,
    sortable: true,
    autoHeight: true,
    flex: 0.5,
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  {
    field: 'saved',
    headerName: startCase(
      i18n.t('saved', {
        ns: 'analytics' satisfies TNamespace
      }) ?? ''
    ),
    minWidth: 100,
    sortable: true,
    autoHeight: true,
    flex: 0.3,
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  {
    field: 'total_interactions',
    headerName: startCase(
      i18n.t('total_interactions', {
        ns: 'analytics' satisfies TNamespace
      }) ?? ''
    ),
    minWidth: 180,
    sortable: true,
    autoHeight: true,
    flex: 0.5,
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  {
    field: 'likes',
    headerName: startCase(
      i18n.t('likes', { ns: 'analytics' satisfies TNamespace }) ?? ''
    ),
    minWidth: 100,
    sortable: true,
    autoHeight: true,
    flex: 0.3,
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  {
    field: 'comments',
    headerName: startCase(
      i18n.t('comments', { ns: 'analytics' satisfies TNamespace }) ?? ''
    ),
    minWidth: 150,
    sortable: true,
    autoHeight: true,
    flex: 0.3,
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  {
    field: 'shares',
    headerName: startCase(
      i18n.t('shares', { ns: 'analytics' satisfies TNamespace }) ?? ''
    ),
    minWidth: 100,
    sortable: true,
    autoHeight: true,
    flex: 0.3,
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    }
  }
];
