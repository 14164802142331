import mediaTypeAdAccountLoader from './loader/mediaTypeAdAccountLoader';
import facebookAdCreativeLoader from './views/MetaAdsAnalytics/loaders/facebookAdCreativeLoader';
import facebookAdSummaryLoader from './views/MetaAdsAnalytics/loaders/facebookAdSummaryLoader';
import getAdAnalyticsAccountLoader from './views/AdAnalyticsIndexRoute/loaders/getAdAnalyticsAccountLoader';

export { default as MetaAdsAnalyticsLayout } from './views/MetaAdsAnalytics/layout';
export { default as MetaAdsAnalyticsErrorBoundary } from './views/MetaAdsAnalytics/layout/MetaAdsAnalyticsErrorBoundary';
export { default as MetaAdsCreativesAnalytics } from './views/MetaAdsAnalytics/views/AdCreatives';
export { default as MetaAdsOverviewAnalytics } from './views/MetaAdsAnalytics/views/AdOverview';
export { default as RootErrorBoundary } from './layout/RootErrorBoundary';
export { default as AdAnalyticsIndexRoute } from './views/AdAnalyticsIndexRoute';
export * as MetaAdsCampaignAnalytics from './views/MetaAdsAnalytics/views/AdCampaign';
export const loaders = {
  mediaTypeAdAccountLoader,
  getAdAnalyticsAccountLoader,
  fb: {
    facebookAdCreativeLoader,
    facebookAdSummaryLoader
  }
};
