import { useState } from 'react';
import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useBrand from './useBrand';
import { IFormDialogProps } from 'src/User/components/FormDialog/types';

interface IPopupContent {
  operation: 'modify_brand' | 'create_brand' | 'drop_brand';
  dispatcherText: string;
  title: string;
  brand: IBrand | null;
}

export default function usePopup() {
  const [popupvisibility, setPopupVisibility] = useState(false);
  const [popupContent, setPopupContent] = useState<IPopupContent>();
  const brandHelpers = useBrand();

  const onPopupFormSubmit = async ({ brand_name }: { brand_name: string }) => {
    const onSubmitSuccess = () => {
      togglePopupVisibility();
    };

    if (popupContent?.brand) {
      if (popupContent?.operation == 'modify_brand') {
        const brandData = {
          ...popupContent.brand,
          name: brand_name
        };

        brandHelpers.updateBrand(brandData, onSubmitSuccess);
      }

      if (popupContent?.operation == 'drop_brand') {
        const brandId = popupContent.brand.id;
        brandHelpers.deleteBrand(brandId, onSubmitSuccess);
      }
    }

    if (popupContent?.operation == 'create_brand') {
      brandHelpers.createBrand(brand_name, onSubmitSuccess);
    }
  };

  const {
    values,
    isSubmitting,
    errors,
    handleChange,
    handleBlur,
    handleSubmit
  } = useFormik({
    initialValues: {
      brand_name: popupContent?.brand?.name ?? ''
    },
    validationSchema: Yup.object({
      brand_name: Yup.string()
        .min(1, 'Must be at least one character')
        .required('Required')
    }),
    onSubmit: onPopupFormSubmit
  });

  const updateBrandInitiator = (brand: IBrand) => {
    values.brand_name = brand.name;
    setPopupContent({
      operation: 'modify_brand',
      dispatcherText: 'Update',
      brand: brand,
      title: 'Update your brand'
    });
    togglePopupVisibility();
  };

  const deleteBrandInitiator = (brand: IBrand) => {
    values.brand_name = brand.name;
    setPopupContent({
      operation: 'drop_brand',
      title: 'Delete your brand',
      brand: brand,
      dispatcherText: 'Delete'
    });
    togglePopupVisibility();
  };

  const createBrandInitiator = () => {
    values.brand_name = '';
    setPopupContent({
      operation: 'create_brand',
      brand: null,
      dispatcherText: 'create',
      title: 'Create a new brand'
    });
    togglePopupVisibility();
  };

  const togglePopupVisibility = () => {
    setPopupVisibility((isVisible) => !isVisible);
  };

  return {
    visibility: popupvisibility,
    content: {
      ...popupContent,
      form: {
        isSubmitting,
        onSubmit: handleSubmit,
        inputEltValue: values.brand_name,
        onInputChange: handleChange,
        onInputBlur: handleBlur,
        inputEltId: 'brand_name',
        inputEltLabel: 'Brand Name',
        errors
      } satisfies IFormDialogProps['form']
    },
    updateBrandInitiator,
    deleteBrandInitiator,
    createBrandInitiator,
    togglePopupVisibility,
    change: handleChange,
    value: values.brand_name
  };
}
