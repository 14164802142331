//** React Imports
import { Dispatch } from 'react';

//** Utils imports
import { startCase } from 'lodash';
// import { truncate } from 'lodash';
import { cn } from 'src/lib/utils/shadcn-utils';

//** Component imports
import { AdCreativeAction } from '../store/metricReducer';
import PostCellRenderer from '../components/Table/PostCellRenderer';
import HeaderComponent from '../components/Table/TableHeaderComponent';
import {
  GradientCellRenderer,
  getGradientCellRange
} from 'src/User/features/Viz/components/Table/components/GradientCellRenderer';

// ** Type Imports
import { ITableData } from '../type';
import { ColumnDef } from '@tanstack/react-table';

// ** Constants
import { CHART_METRIC_KEYS, TABLE_COLS_HEADER_NAME_MAPPER } from '../constants';

// ** Icons
import { ChevronRight, ChevronDown } from 'lucide-react';

const getMaximumValue = (data: ITableData[], field: string) => {
  return Math.max(
    ...data.map((item) => Number(item?.[field as keyof ITableData]) || 0)
  );
};

interface Props {
  tableData: ITableData[];
  state: {
    activeMetrics: string[];
    activeAdCreativeIds: string[];
  };
  dispatch: Dispatch<AdCreativeAction>;
}

type TTableField = keyof typeof TABLE_COLS_HEADER_NAME_MAPPER;

const tableDataFields: { field: TTableField; minWidth: number }[] = [
  { field: 'thumbnail_url', minWidth: 350 },
  { field: 'optimization_goal', minWidth: 250 },
  { field: 'cpc', minWidth: 110 },
  { field: 'cpm', minWidth: 110 },
  { field: 'cpp', minWidth: 110 },
  { field: 'ctr', minWidth: 110 },
  { field: 'reach', minWidth: 125 },
  { field: 'spend', minWidth: 150 },
  // { field: 'cost_per_action', minWidth: 190 },
  // { field: 'purchase_roas', minWidth: 190 },
  // { field: 'purchase', minWidth: 160 },
  { field: 'clicks', minWidth: 160 },
  { field: 'video_p25_watched', minWidth: 220 },
  { field: 'video_p50_watched', minWidth: 220 },
  { field: 'video_p75_watched', minWidth: 220 },
  { field: 'video_p95_watched', minWidth: 220 },
  { field: 'video_p100_watched', minWidth: 220 }
];

const createTableColsDefs = ({ tableData, dispatch, state }: Props) => {
  return tableDataFields.map(
    ({ field, minWidth }): ColumnDef<ITableData, string | number> => {
      const headerName = TABLE_COLS_HEADER_NAME_MAPPER?.[field];

      return field == 'thumbnail_url'
        ? {
            accessorKey: 'thumbnail_url',
            enablePinning: true,
            header: headerName,
            cell: ({ row }) => {
              const CellIcon = row.getIsExpanded() ? ChevronDown : ChevronRight;
              const isRootRow = row.depth == 0;

              return (
                <div
                  style={{ paddingLeft: `${row.depth * 3}rem` }}
                  className={cn('tw-flex-grow tw-text-base', {
                    '!tw-pl-4': isRootRow
                  })}>
                  <div className='tw-flex tw-gap-2 tw-items-center tw-justify-start'>
                    {row.subRows.length ? (
                      <CellIcon
                        className='tw-cursor-pointer tw-text-gray-500 tw-w-6 tw-h-6 hover:tw-bg-gray-200 tw-rounded'
                        onClick={() => row.toggleExpanded()}
                      />
                    ) : null}
                    {isRootRow ? (
                      <PostCellRenderer
                        data={row.original}
                        activeAdCreativeIds={state.activeAdCreativeIds}
                        toggleAdCreative={(adCreativeId: string) =>
                          dispatch({
                            type: 'TOGGLE_ACTIVE_AD_CREATIVE_ID',
                            payload: adCreativeId
                          })
                        }
                      />
                    ) : (
                      // TODO: Need to account for internalization
                      startCase(row.original.name)
                    )}
                  </div>
                </div>
              );
            },
            sortingFn: (row1, row2) => {
              const collator = new Intl.Collator(undefined);

              return collator.compare(
                row1?.original?.name ?? '',
                row2?.original?.name ?? ''
              );
            },
            sortUndefined: 'last',
            sortDescFirst: false,
            minSize: minWidth
          }
        : {
            accessorKey: field,
            header: () => {
              if (CHART_METRIC_KEYS.includes(field)) {
                return (
                  <div className='tw-flex-grow'>
                    <HeaderComponent
                      displayName={headerName}
                      metricKey={field}
                      activeMetrics={state.activeMetrics}
                      toggleMetric={(metric: string) =>
                        dispatch({
                          type: 'TOGGLE_ACTIVE_METRIC',
                          payload: metric
                        })
                      }
                    />
                  </div>
                );
              }

              return headerName;
            },
            minSize: minWidth,
            cell: ({ getValue, row }) => {
              const isRootRow = row.depth == 0;

              if (field == 'optimization_goal' && !isRootRow) return null;

              const getRange = () => {
                const ascMetrics = ['cpc', 'cpm', 'cpp', 'ctr'];
                const order = ascMetrics.includes(field) ? 'asc' : 'desc';

                return getGradientCellRange(tableData, field, order);
              };

              return (
                <div className='tw-flex-grow'>
                  <GradientCellRenderer
                    value={getValue() as any}
                    color={'green'}
                    variant='gradient'
                    range={getRange()}
                    gradientMax={getMaximumValue(tableData, field)}
                    className='tw-flex-grow tw-min-h-16 tw-flex tw-items-center tw-text-base'
                  />
                </div>
              );
            },
            sortingFn: (row1, row2, columnId) => {
              const collator = new Intl.Collator(undefined);

              return collator.compare(
                row1.getValue(columnId),
                row2.getValue(columnId)
              );
            },
            sortUndefined: 'last',
            sortDescFirst: false
            // headerTooltip: truncate(headerName, {
            //   length: 20,
            //   omission: '...'
            // }),
          };
    }
  );
};

export default createTableColsDefs;
