import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styles from './authWrapper.module.css';
import classNames from 'classnames';
import { isEmpty, first } from 'lodash';
import displayAxiosError from 'src/lib/utils/displayAxiosError';
import {
  IBrand,
  activeBrand as activeBrandAtom,
  userBrands
} from 'src/User/recoil/Atoms/UserBrandAtom';
import { getBrands } from 'src/User/services/brand';
import { USER_CONFIG } from 'src/configs';

export default function BrandWrapper() {
  const [activeBrand, setActiveBrand] = useRecoilState(activeBrandAtom);
  const [brands, setBrands] = useRecoilState(userBrands);
  const navigate = useNavigate();

  const hasAnActiveBrand = activeBrand && brands?.length > 0;

  useEffect(
    function getBrandsDataOnMount() {
      let ignoreResponse = false;

      const getBrandsData = async () => {
        try {
          const { data: brandsData } = await getBrands();
          const shouldCreateBrand = isEmpty(brandsData) && !ignoreResponse;

          if (shouldCreateBrand) return goToCreateBrandPage();

          const localStoreBrand = localStorage.getItem(
            USER_CONFIG.storageUserBrand
          );
          const activeBrand: IBrand = localStoreBrand
            ? JSON.parse(localStoreBrand)
            : () => {
                const brand = first(brandsData)!;

                localStorage.setItem(
                  USER_CONFIG.storageUserBrand,
                  JSON.stringify(brand)
                );

                return brand;
              };

          setBrands(brandsData);
          setActiveBrand(activeBrand);
        } catch (error: any) {
          displayAxiosError(error, {
            title: 'No brand found',
            fallbackMsg: 'Please create a new brand to continue',
            toast: true,
            duration: 2000
          });
          goToCreateBrandPage();
        }
      };

      const goToCreateBrandPage = () => {
        navigate('/create_brand');
      };

      getBrandsData();

      return () => {
        ignoreResponse = true;
      };
    },
    [setActiveBrand, setBrands, navigate]
  );

  if (hasAnActiveBrand) {
    return <Outlet />;
  }

  return (
    <div
      className={classNames(
        'd-flex flex-column justify-content-center align-items-center',
        styles.container
      )}>
      <p className={styles.text}>Loading your analytics...</p>
      <Spinner color='secondary'>Loading...</Spinner>
    </div>
  );
}
