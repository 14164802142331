import {
  ResponsiveContainer,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from 'recharts';
import type {
  CustomBarLabelProps,
  CustomTooltipProps,
  CustomXAxisTickProps,
  ImageSupportChartProps,
  RechartsXAxisTick
} from './types';
import truncate from 'lodash/truncate';
import { isEmpty } from 'lodash';
import { CHART_COLORS } from 'src/User/features/Viz/components/Chart/constants/colors';
import { IChartData } from '../../type';
import getTabelColLabel from '../../utils/getColLabel';
import AvatarWithFallback from 'src/User/components/AvatarWithFallback';
import { useTranslation } from 'react-i18next';
import { TNamespace } from 'src/i18n';

const getMaxYAxisDomain = (data: IChartData[], activeFilters: string[]) => {
  const max = Math.max(
    ...data.map((record) =>
      activeFilters.reduce((acc, filter) => {
        return Math.max(acc, Number(record?.[filter as keyof IChartData]) || 0);
      }, 0)
    )
  );

  return Math.ceil(max);
};

export const ImageSupportChart = (props: ImageSupportChartProps) => {
  const { data, chartFilters: activeFilters } = props;

  const { t } = useTranslation<TNamespace>();

  const chartBarRadius: [number, number, number, number] = [4, 4, 0, 0];

  if (isEmpty(data))
    return (
      <div className='tw-h-28 tw-flex tw-justify-center tw-items-center'>
        <p className='tw-text-slate-500'>
          {t('meta.adcreative.chart.noDataToVisualize', {
            ns: 'adAnalytics'
          })}
        </p>
      </div>
    );

  return (
    <ResponsiveContainer
      width={'100%'}
      height={'100%'}
      minHeight={500}>
      <BarChart
        data={data}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
        <Legend
          verticalAlign='top'
          height={50}
          iconType='square'
          formatter={(value) => getTabelColLabel(value)}
        />
        <CartesianGrid
          vertical={false}
          strokeDasharray='5 5'
        />
        <XAxis
          dataKey='name'
          height={100}
          tickLine={false}
          tickCount={data.length}
          tickFormatter={(value) => truncate(value, { length: 10 })}
          tick={(props) => {
            const tickProps = props as RechartsXAxisTick;

            return (
              <CustomTickComponent
                tickProps={tickProps}
                record={data.find(
                  ({ name }) => name == tickProps.payload.value
                )}
              />
            );
          }}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          domain={[0, getMaxYAxisDomain(data, activeFilters)]}
        />
        {activeFilters.map((filter, index) => (
          <Bar
            key={index}
            dataKey={filter}
            fill={CHART_COLORS[index]}
            radius={chartBarRadius}
            label={(props) => {
              return (
                <CustomBarLabel
                  labelProps={props}
                  dataKey={filter}
                />
              );
            }}
          />
        ))}
        <Tooltip
          cursor={{ fillOpacity: 0.25 }}
          content={<CustomTooltip />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

const CustomBarLabel = (props: CustomBarLabelProps) => {
  const { labelProps } = props;
  const { x, y, value } = labelProps;
  const label = truncate(value || '', { length: 12 });

  return (
    <g transform={`translate(${x},${y + -20})`}>
      <switch>
        <foreignObject className='tw-w-28 tw-h-28'>
          <p className='tw-text-sm tw-font-semibold mb-0'>{label}</p>
        </foreignObject>
        <text
          fill='#666'
          textAnchor='middle'
          dy={-6}
          fontSize={'0.75rem'}
          fontWeight={500}>
          {label}
        </text>
      </switch>
    </g>
  );
};

const CustomTickComponent = ({ record, tickProps }: CustomXAxisTickProps) => {
  const { y, payload } = tickProps;
  const clipId = `clip-${payload.value}`;
  const label = truncate(payload.value, { length: 12 });
  const Tag = record?.permalink ? 'a' : 'div';

  const { t } = useTranslation<TNamespace>();

  return (
    <g transform={`translate(${payload.tickCoord},${y})`}>
      <switch>
        <foreignObject className='tw-w-28 tw-h-28 tw-max-h-32'>
          <Tag
            href={record?.permalink ?? ''}
            target='_blank'
            rel='noreferrer'
            className='tw-no-underline tw-text-inherit hover:tw-text-primary'>
            <div className='tw-flex tw-flex-col tw-justify-center gap-1'>
              <div className='tw-w-20 tw-h-20 tw-mx-auto'>
                <AvatarWithFallback
                  className='tw-w-full tw-h-full !tw-rounded'
                  imgClassName='tw-shadow-md tw-object-cover tw-object-center'
                  src={
                    typeof record?.thumbnail_url == 'string'
                      ? record?.thumbnail_url
                      : ''
                  }
                  alt={`${record?.name} ${t(
                    'meta.adcreative.chart.adCreativePreview',
                    {
                      ns: 'adAnalytics'
                    }
                  )}`}
                />
              </div>
              <p
                className='text-base mb-0'
                title={payload.value}>
                {label}
              </p>
            </div>
          </Tag>
        </foreignObject>

        {/* //** Fallback for browser which don't support foreignObject */}
        <g>
          <defs>
            <clipPath id={clipId}>
              <rect
                className='tw-w-20 tw-h-20'
                rx={15}
              />
            </clipPath>
          </defs>
          <Tag
            href={record?.permalink ?? ''}
            target='_blank'
            rel='noreferrer'
            className='tw-no-underline tw-text-inherit hover:tw-text-primary'>
            <image
              className='tw-w-20 tw-h-20'
              x={0}
              y={0}
              href={
                typeof record?.thumbnail_url == 'string'
                  ? record?.thumbnail_url
                  : ''
              }
              style={{ clipPath: `url(#${clipId})` }}
            />
            <text
              x={0}
              y={60}
              dy={12}
              fill='#666'>
              {label}
            </text>
          </Tag>
        </g>
      </switch>
    </g>
  );
};

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  if (active)
    return (
      <div className='tw-bg-white tw-border tw-border-gray-200 tw-rounded-md tw-shadow'>
        <p className='tw-text-sm tw-text-black tw-border-b tw-bg-gray-100 tw-p-2 tw-m-0'>
          {truncate(label, {
            length: 30
          })}
        </p>
        <div className='tw-p-3 tw-flex tw-flex-col tw-gap-4 tw-text-sm'>
          {payload?.map(({ dataKey, value, color }, index) => (
            <p
              key={index}
              className='tw-m-0 tw-flex tw-items-center tw-gap-3'>
              <span
                className='tw-rounded-full tw-w-3 tw-h-3 tw-block'
                style={{ backgroundColor: color }}></span>
              <span>
                {' '}
                <span>{getTabelColLabel(String(dataKey))}: </span>
                <span className='tw-font-semibold tw-mr-2 '>{value}</span>
              </span>
            </p>
          ))}
        </div>
      </div>
    );

  return null;
};

export default ImageSupportChart;
