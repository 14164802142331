import classNames from 'classnames';
import styles from './index.module.css';
import { truncate } from 'lodash';

type TProps = {
  value?: number | string;
  changeCount?: number | string;
};

export default function NumberCellRenderer(props: TProps) {
  const { value, changeCount } = props ?? {};
  const changeDirection = Number(changeCount);

  return (
    <div
      className={classNames(
        'd-flex justify-content-start align-items-center gap-3 py-3',
        styles['table-cell']
      )}>
      <p className={styles['cell-value']}>
        {truncate(String(value), { length: 25 }) ?? 'N/A'}
      </p>
      <div className='d-flex gap-3'>
        <p
          className={classNames('mb-0', {
            'd-none': !changeCount,
            [styles['success-badge']]: Math.sign(changeDirection ?? 1) == 1,
            [styles['danger-badge']]: Math.sign(changeDirection ?? -1) == -1
          })}>
          <span>{changeCount ?? 'N/A'}</span>
        </p>
      </div>
    </div>
  );
}
