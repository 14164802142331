import { useCallback, useState } from 'react';
import { TChartMetrics } from '../types';

export interface IActiveChartSeries {
  [chartId: string]: TChartMetrics[];
}

const useChartSeries = () => {
  const [activeChartSeries, setActiveChartSeries] =
    useState<IActiveChartSeries>();

  const toggleChartSeries = (chartId: string, seriesName: TChartMetrics) => {
    const currentChartActiveSeries = activeChartSeries?.[chartId];
    const updatedChartSeries = currentChartActiveSeries?.includes(seriesName)
      ? currentChartActiveSeries?.filter(
          (activeMetricKey) => activeMetricKey !== seriesName
        )
      : [...(currentChartActiveSeries ?? []), seriesName];

    setActiveChartSeries((prev) => ({
      ...prev,
      [chartId]: updatedChartSeries
    }));
  };

  const initializeActiveChartSeries = useCallback(
    (chartId: string, seriesNames: TChartMetrics[]) => {
      setActiveChartSeries((prev) => ({
        ...prev,
        [chartId]: seriesNames
      }));
    },
    []
  );

  return {
    activeChartSeries,
    toggleChartSeries,
    initializeActiveChartSeries
  };
};

export default useChartSeries;
