// * Do not delete commented code, we will need it in future
// import { useRouteLoaderData } from 'react-router-dom';
// import { ROUTER_PATHS_ID } from 'src/lib/constants';
// import facebookPageAnalyticsLoader from '../../loaders/facebookPageAnalyticsLoader';
// import { PromiseType } from 'src/lib/models';
// import { pick } from 'lodash';
// import {
//   getChartOptions,
//   getChartFilterOptions,
//   getSeriesData
// } from './utils/chart-utils';
// import { Chart } from 'src/User/features/Viz/components/Chart';

import { PostAnalyticsTableView } from '../../../InstagramAnalytics/views/Posts/components/PostAnalyticsTable';
import { createTableColDefs } from './utils/table-utils';
import { IFacebookPageMediaInsight } from './types';
import { Suspense } from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import AnalyticsErrorRenderer from 'src/User/components/Error/AnalyticsErrorRenderer';
import { TableLoading } from 'src/User/features/Viz/components/Table';
import { useTranslation } from 'react-i18next';
import { type TNamespace } from 'src/i18n';
import createTableData from 'src/User/features/Analytics/utils/createTableData';

const PostsContainer = () => {
  const { facebookPageLifetimeMediaInsight } = useLoaderData() as any;
  const { t } = useTranslation<TNamespace>();

  return (
    <Suspense
      fallback={
        <div className='tw-bg-white tw-rounded-xl tw-flex tw-justify-center'>
          <TableLoading />
        </div>
      }>
      <Await
        errorElement={
          <AnalyticsErrorRenderer
            title={t('error.title', {
              ns: 'analytics',
              accountType: 'facebook page',
              feature: 'posts'
            })}
            fallbackErrorMsg={t('error.description', {
              ns: 'analytics'
            })}
            hideConnectAccount
          />
        }
        resolve={facebookPageLifetimeMediaInsight}>
        {(data) => <Posts data={data} />}
      </Await>
    </Suspense>
  );
};

interface IPostsProps {
  data: IFacebookPageMediaInsight[];
}

const Posts = ({ data }: IPostsProps) => {
  // * Do not delete commented code, we will need it in future
  // const insightOverview = useRouteLoaderData(
  //   ROUTER_PATHS_ID.FB_PAGE_ANALYTICS_LAYOUT
  // ) as PromiseType<ReturnType<typeof facebookPageAnalyticsLoader>>;

  // const postInteractionsByDay = insightOverview?.interactions;
  // const postInsightOverviewByMetric = pick(insightOverview, [
  //   'average_reach_per_post',
  //   'interactions_total',
  //   'engagement_rate'
  // ]);

  // const postInsightOverviewByDay = mergeDialyPostInsights(
  //   postInsightOverviewByMetric
  // );

  // const overviewSeriesData = getSeriesData(postInsightOverviewByDay);
  // const interactionsSeriesData = getSeriesData(postInteractionsByDay);

  const { t } = useTranslation<TNamespace>();

  return (
    <div className='tw-flex tw-flex-col tw-gap-6'>
      {/* // * Do not delete commented code, we will need it in future */}
      {/* <div className='tw-rounded-xl tw-shadow-lg p-3 bg-white'>
        <Chart
          header={{
            title: 'Overview',
            filtersOptions: getChartFilterOptions(overviewSeriesData)
          }}
          data={{
            options: getChartOptions(
              postInsightOverviewByDay?.map(({ date }) => date)
            ),
            series: overviewSeriesData
          }}
          type='bar'
        />
      </div>
      <div className='tw-rounded-xl tw-shadow-lg p-3 bg-white'>
        <Chart
          header={{
            title: 'Interactions',
            filtersOptions: getChartFilterOptions(interactionsSeriesData)
          }}
          data={{
            options: getChartOptions(
              postInteractionsByDay?.map(({ date }) => date)
            ),
            series: interactionsSeriesData
          }}
          type='bar'
        />
      </div> */}

      <PostAnalyticsTableView
        cumulativeData={{}}
        tableTitle={t('list-of-posts', { ns: 'analytics' }) ?? ''}
        tableData={createTableData(data)}
        tableColumnsDefs={createTableColDefs([
          'media_preview_details',
          'created_time',
          'reactions',
          'likes',
          'comments',
          'shares',
          'clicks',
          'impressions'
        ])}
      />
    </div>
  );
};

export default PostsContainer;
