export const ANALYTICS_OAUTH_SOCIAL_ACCOUNT_PROVIDERS = [
  {
    name: 'Facebook Page',
    icon: (
      <div>
        <i className='bi bi-flag-fill modal-icon'></i>
      </div>
    ),
    href: '/socials/facebook-page/integrations/select-media-type/facebook-page',
    disabled: false
  },
  {
    name: 'Instagram Business',
    icon: (
      <div>
        <i className='bi bi-instagram modal-icon'></i>
      </div>
    ),
    href: '/socials/ig-business/integrations/select-media-type/ig-business',
    disabled: false
  },
  {
    name: 'Twitter',
    href: '#',
    icon: <i className='bi bi-twitter modal-icon'></i>,
    disabled: true
  },
  {
    name: 'LinkedIn Page',
    href: '#',
    icon: <i className='bi bi-linkedin modal-icon'></i>,
    disabled: true
  },
  {
    name: 'Tiktok',
    href: '#',
    icon: <i className='bi bi-tiktok modal-icon'></i>,
    disabled: true
  }
];
