import { TData } from '../../../../types';
import getAllValuesForTableField from '../../../utils/getAllValuesForTableField';

const getGradientCellRange = (
  data: TData[],
  field: string,
  order: 'asc' | 'desc'
) => {
  const range = getAllValuesForTableField(data, field, order);
  return range.splice(0, 5);
};

export default getGradientCellRange;
