import { ActionFunctionArgs, redirect } from 'react-router-dom';
import { USER_CONFIG } from 'src/configs';
import { ROUTER_PATHS } from 'src/lib/constants';
import displayAxiosError from 'src/lib/utils/displayAxiosError';
import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { startConversation } from 'src/User/services/somate-ai';

export async function startConversationAction({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const prompt = formData.get('prompt');

  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);

  if (typeof prompt == 'string') {
    try {
      const { data: conservation } = await startConversation({
        brandId,
        prompt: prompt
      });

      return redirect(
        `${ROUTER_PATHS.SOMATE_AI_LAYOUT}/${conservation.data.conversation_id}`
      );
    } catch (error) {
      displayAxiosError(error, {
        title: 'Unable to start conversation',
        fallbackMsg:
          'An unexpected error occurred while starting new conversation',
        toast: true,
        duration: 7000
      });

      return null;
    }
  }

  return null;
}
