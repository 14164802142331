import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import PatternRight from "../../../Assets/images/pattern-right.png";
import PatternStars from "../../../Assets/images/pattern-stars.png";
import SOMateLogo from "../../../Assets/images/somate-logo.svg";

const SucFailPay = () => {
    const [searchParams] = useSearchParams();
    const handleVerify = async () => {
            Swal.fire({
                title: "Error",
                text: "Payment Failed",
                icon: "error",
                confirmButtonText: "OK",
            }).then(function () {
                window.location.href = "/flow-plan";
            });
    };

    useEffect(() => {
        handleVerify();
    }, []);

    return (
        <>
            
            <div style={{ outline: "none" }} className="authentication-form-container">
                <div style={{ opacity: "0" }} className="logo_wrapper">
                    <img className="somate_logo" src={SOMateLogo} alt="SOMateLogo" />
                </div>
                <img
                    className="pattern_right"
                    src={PatternRight}
                    alt="pattern_right"
                    style={{ zIndex: "1" }}
                />
            </div>
            <img
                className="pattern_stars"
                src={PatternStars}
                alt="pattern_stars"
            />
        </>
    );
};

export default SucFailPay;
