import { useRouteError } from 'react-router-dom';
import ErrorRenderer from '../../../../components/Error/AnalyticsErrorRenderer';
import { useTranslation } from 'react-i18next';
import { TNamespace } from 'src/i18n';

const InstagramBusinessErrorBoundary = () => {
  const { t } = useTranslation<TNamespace>();
  const error = useRouteError() as { message?: string };
  console.log({ error });

  return (
    <ErrorRenderer
      title={t('error.title', {
        ns: 'analytics',
        accountType: 'Instagram Business',
        feature: 'account'
      })}
      fallbackErrorMsg={t('error.description', {
        ns: 'analytics'
      })}
    />
  );
};

export default InstagramBusinessErrorBoundary;
