import i18n, { TNamespace } from 'src/i18n';

export const DEFAULT_PRE_SELECTED_FILTERS_LIST: (keyof typeof TABLE_COLS_HEADER_NAME_MAPPER)[] =
  ['cpm', 'cpp', 'ctr'];

export const NUMBER_FIELDS = [
  'cpc',
  'cpm',
  'cpp',
  'ctr',
  'reach',
  'spend',
  'clicks',
  'frequency',
  'impressions',
  'website_ctr',
  'inline_post_engagement',
  'cost_per_inline_link_click',
  'cost_per_inline_post_engagement',
  'full_view_reach',
  'full_view_impressions'
];

export const TABLE_COLS_HEADER_NAME_MAPPER = {
  thumbnail_url: i18n.t('meta.adcreative.common.adCreative', {
    ns: 'adAnalytics' satisfies TNamespace
  }),
  cpc: i18n.t('meta.adcreative.common.cpc', {
    ns: 'adAnalytics' satisfies TNamespace
  }),
  cpm: i18n.t('meta.adcreative.common.cpm', {
    ns: 'adAnalytics' satisfies TNamespace
  }),
  cpp: i18n.t('meta.adcreative.common.cpp', {
    ns: 'adAnalytics' satisfies TNamespace
  }),
  ctr: i18n.t('meta.adcreative.common.ctr', {
    ns: 'adAnalytics' satisfies TNamespace
  }),
  optimization_goal: i18n.t('meta.adcreative.common.optimizationGoal', {
    ns: 'adAnalytics' satisfies TNamespace
  }),
  reach: i18n.t('meta.adcreative.common.reach', {
    ns: 'adAnalytics' satisfies TNamespace
  }),
  spend: i18n.t('meta.adcreative.common.spend', {
    ns: 'adAnalytics' satisfies TNamespace
  }),
  clicks: i18n.t('meta.adcreative.common.clicks', {
    ns: 'adAnalytics' satisfies TNamespace
  }),
  video_p25_watched: i18n.t('meta.adcreative.common.videoP25Watched', {
    ns: 'adAnalytics' satisfies TNamespace
  }),
  video_p50_watched: i18n.t('meta.adcreative.common.videoP50Watched', {
    ns: 'adAnalytics' satisfies TNamespace
  }),
  video_p75_watched: i18n.t('meta.adcreative.common.videoP75Watched', {
    ns: 'adAnalytics' satisfies TNamespace
  }),
  video_p95_watched: i18n.t('meta.adcreative.common.videoP95Watched', {
    ns: 'adAnalytics' satisfies TNamespace
  }),
  video_p100_watched: i18n.t('meta.adcreative.common.videoP100Watched', {
    ns: 'adAnalytics' satisfies TNamespace
  }),
  cost_per_action: i18n.t('meta.adcreative.common.costPerAction', {
    ns: 'adAnalytics' satisfies TNamespace
  })
  // purchase: i18n.t('meta.adcreative.common.purchase', {
  //   ns: 'adAnalytics' satisfies TNamespace
  // }),
  // purchase_roas: i18n.t('meta.adcreative.common.purchaseRoas', {
  //   ns: 'adAnalytics' satisfies TNamespace
  // })
};

export const CHART_METRIC_KEYS = Object.keys(
  TABLE_COLS_HEADER_NAME_MAPPER
).filter(
  (key) =>
    key !== 'optimization_goal' &&
    key !== 'thumbnail_url' &&
    key !== 'purchase' &&
    key !== 'purchase_roas' &&
    key !== 'cost_per_action'
);
