import classNames from 'classnames';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from 'src/components/ui/select';

interface ICustomSelectProps {
  defaultValue: string;
  options: { label: string; value: string }[];
  value?: string;
  onChange?: (value: string) => void;
  trigger?: {
    className?: string;
  };
}

const CustomSelect = (props: ICustomSelectProps) => {
  const { options, value, defaultValue, trigger, onChange } = props;

  return (
    <Select
      defaultValue={defaultValue}
      value={value}
      onValueChange={onChange}>
      <SelectTrigger className={classNames('', trigger?.className)}>
        <SelectValue
          id='select-trigger'
          defaultValue={defaultValue}
          placeholder='Select...'
        />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {options.map(({ label, value }) => (
            <SelectItem
              key={value}
              value={value}
              className='tw-text-base tw-font-normal tw-capitalize'>
              {label}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default CustomSelect;
