import { defer, redirect } from 'react-router-dom';
import { USER_CONFIG } from 'src/configs';
import { ROUTER_PATHS } from 'src/lib/constants';
import { getUserPlan } from 'src/User/services/planServices';

export const subscriptionLoader = async () => {
  const token = localStorage.getItem(USER_CONFIG.storageTokenKeyName);

  if (!token) return redirect(ROUTER_PATHS.LOGIN);

  const userSubscriptionPromise = getUserPlan();

  return defer({
    userSubscriptionPlan: userSubscriptionPromise.then(({ data }) => data)
  });
};
