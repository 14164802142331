import { META_OAUTH_CONFIG } from '../../constants';

export default function MetaAdsOauthModal() {
  return <p>Monitor how your money is being spent on meta ads suit</p>;
}

MetaAdsOauthModal.headerTitle = 'Connect Meta Ads';

MetaAdsOauthModal.headerIcon = (
  <div>
    <img
      className='tw-w-9 tw-h-9'
      src='/icon/meta-icon.svg'
      alt='meta icon'
    />
  </div>
);

MetaAdsOauthModal.oauthReqUrl =
  `https://www.facebook.com/dialog/oauth?` +
  `client_id=${META_OAUTH_CONFIG.clientId}&` +
  `redirect_uri=${META_OAUTH_CONFIG.redirectUri}&` +
  `response_type=${META_OAUTH_CONFIG.responseType}&` +
  `scope=${META_OAUTH_CONFIG.scope}&` +
  `state=${META_OAUTH_CONFIG.state}`;
