import { Maybe } from 'src/lib/models';

const formatCellValueToAmount = (
  value: Maybe<string | number>,
  currency?: string
) => {
  const valueAsNumber = parseInt(`${value}`);

  const intlInstance =
    typeof currency == 'string'
      ? new Intl.NumberFormat('pl-PL', {
          style: 'currency',
          currency: currency
        })
      : new Intl.NumberFormat();

  return Number.isNaN(valueAsNumber)
    ? valueAsString(value)
    : intlInstance.format(valueAsNumber);
};

const valueAsString = (value: Maybe<string | number>) =>
  !value ? '' : String(value);

export default formatCellValueToAmount;
