import { ROUTER_PATHS_ID } from 'src/lib/constants';
import { InstagramAnalyticsLoaderData } from '../models';
import { useRouteLoaderData } from 'react-router-dom';
import AuditCard from 'src/User/features/CompetitionAnalysis/views/Instagram/components/AuditCard';
import createAuditData from './utils/createAuditData';

const Audit = () => {
  const { insightsSummary } = useRouteLoaderData(
    ROUTER_PATHS_ID.IG_BUSINESS_ANALYTICS_LAYOUT
  ) as InstagramAnalyticsLoaderData;
  const auditData = createAuditData({ data: insightsSummary });

  return (
    <div className='d-flex flex-column gap-4'>
      {auditData.map((props, index) => {
        return (
          <AuditCard
            key={index}
            {...props}
          />
        );
      })}
    </div>
  );
};

export default Audit;
