import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import './EventCalendarMonth.css';
import FacebookCircle from '../../../Assets/images/facebook-circle.svg';
import InstaCircle from '../../../Assets/images/insta-circle.svg';
import TwiterCircle from '../../../Assets/images/twiter-circle.svg';
import {
  getWeekdays,
  getShortMonthName,
  getDaysInWeeksInMonth
} from './CalFunction/getDaysInWeeksInMonth';
import AddAccountModal from '../../components/AddAccountModal';
import { Modal, ModalBody } from 'reactstrap';
const EventCalendarMonth = () => {
  const navigate = useNavigate();

  const clickedDate = ({ target }) => {
    const { day, month, year } = target.dataset;

    if (day != undefined) {
      alert(day + ' ' + month + ' ' + year);
    }
  };
  const today = new Date();

  const initialState = {
    year: today.getFullYear(),
    month: today.getMonth(),
    day: today.getDate()
  };

  const [selectedDate, setDate] = useState({
    ...initialState
  });
  const [selectedOption, setSelectedOption] = useState('Month');
  const [selecteStatus, setSelectStatus] = useState('Active');
  const previousMonth = () => {
    setDate(({ year, month }) => ({
      year: month > 0 ? year : year - 1,
      month: month > 0 ? month - 1 : 11,
      day: month === today.getMonth() ? today.getDate() : 1
    }));
  };

  const nextMonth = () => {
    setDate(({ year, month }) => ({
      year: month === 11 ? year + 1 : year,
      month: month === 11 ? 0 : month + 1,
      day: month === today.getMonth() ? today.getDate() : 1
    }));
  };
  const selectDay = ({ target }) => {
    alert('select Day called');
    const { day, month, year } = target.dataset;

    setDate({
      year: Number(year),
      month: Number(month),
      day: Number(day)
    });
  };

  const setToday = () => {
    selectDay.call(null, {
      target: {
        dataset: {
          ...initialState
        }
      }
    });
  };
  const [modal, setModal] = useState(false);
  const toggle2 = () => setModal(!modal);

  return (
    <>
      <div className='calendar-month'>
        <div className='calendar-header'>
          <div className='cal-schedule'>
            <label>Schedule :</label>
            <select
              onChange={(e) => {
                setSelectedOption(e.target.value);
                navigate('/calendar/week');
              }}
              value={selectedOption}>
              <option value='Month'>Month</option>
              <option value='Week'>Week</option>
            </select>
          </div>
          <div className='month-year-navigator'>
            <div className='month-year-selector'>
              <span
                onClick={previousMonth}
                className='month-prev'>
                <i className='bi bi-chevron-left'></i>
              </span>
              <div className='current_month_year'>
                {`${getShortMonthName(selectedDate.month)} ${
                  selectedDate.year
                }`}
              </div>
              <span
                onClick={nextMonth}
                className='month-next'>
                <i className='bi bi-chevron-right'></i>
              </span>
            </div>
            <div
              onClick={() => {}}
              className='month-year-selector-direct'>
              <i className='bi bi-calendar2-event'></i>
            </div>
          </div>
          <div className='status-filter'>
            <select
              onChange={(e) => {
                alert(e.target.value + ' selected');
                setSelectStatus(e.target.value);
              }}
              value={selecteStatus}>
              <option value='Active'>Active</option>
              <option value='Deactive'>Deactive</option>
            </select>
          </div>
        </div>
        <div className='calendar-cells-wrappper'>
          <div className='calendar-day-cell'>
            {getWeekdays().map((d, i) => {
              return (
                <div
                  key={`days_${i}`}
                  className='calendar-day'>
                  {d}
                </div>
              );
            })}
          </div>
          <div className='calendar-date-cell'>
            {getDaysInWeeksInMonth(selectedDate.year, selectedDate.month).map(
              (d, i) => {
                return (
                  <div
                    data-day={d}
                    data-month={selectedDate.month}
                    data-year={selectedDate.year}
                    onClick={clickedDate}
                    key={`day_${i}`}
                    className='calendar-date'>
                    <span
                      className='date-no'
                      data-day={d}
                      data-month={selectedDate.month}
                      data-year={selectedDate.year}>
                      {d}
                      {!d == undefined || d == 4 || d == 9 || d == 20 ? (
                        <div className='event-plateform-list'>
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              selectDay(e);
                            }}
                            data-day={d}
                            data-month={selectedDate.month}
                            data-year={selectedDate.year}
                            className='event-plateform-list-itm'>
                            <span>
                              <img
                                src={FacebookCircle}
                                alt='Facebook'
                              />
                            </span>
                            9:30 am
                          </div>
                          <div className='event-plateform-list-itm'>
                            <span>
                              <img
                                src={TwiterCircle}
                                alt='Twiter'
                              />
                            </span>
                            11:30 am
                          </div>
                          <div className='event-plateform-list-itm'>
                            <span>
                              <img
                                src={InstaCircle}
                                alt='Twiter'
                              />
                            </span>
                            12:30 pm
                          </div>
                        </div>
                      ) : null}
                    </span>
                    <button
                      type='button'
                      className='btn btn-primary btn-icon btn-new-event'
                      onClick={() => {
                        setModal(!modal);
                      }}>
                      <i className='bi bi-plus'></i>
                    </button>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>

      {/* <div className="modal fade" id="new_event" tabIndex="-1" aria-labelledby="new_eventLabel" aria-hidden="true"> */}
      <Modal
        size='lg'
        isOpen={modal}
        toggle={toggle2}>
        <ModalBody>
          <AddAccountModal
            modal={modal}
            setModal={setModal}
          />
        </ModalBody>
      </Modal>
      {/* </div> */}
    </>
  );
};

export default EventCalendarMonth;
