import { IInstagramBusinessDailyInsight } from '../types';

/**
 *
 * @param data List of daily insights sorted by date
 * @param metric A daily metric you wish to compute it's daily change count
 * @returns List of daily insights with the metric count difference between the current day and the previous day
 */
const getInsightDataWithMetricCountDifference = (
  data: IInstagramBusinessDailyInsight[],
  metric: Exclude<keyof IInstagramBusinessDailyInsight, 'date'>
) => {
  return data.map((dailyInsight, index) => {
    const previousDayMediaCount =
      index === 0 ? dailyInsight[metric] : data[index - 1][metric];
    const count = Number(dailyInsight[metric]) - Number(previousDayMediaCount);

    return {
      ...dailyInsight,
      [metric]: count
    };
  });
};

export default getInsightDataWithMetricCountDifference;
