import { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CircleCheck } from 'lucide-react';
import {
  ROUTER_PATHS,
  SUBSCRIPTION_SUCCESS_REDIRECT_TIMEOUT
} from 'src/lib/constants';
import FullScreenFeedback from 'src/User/components/FullScreenFeedback';
import { Button } from 'src/components/ui/button';

const SubscriptionSuccess = () => {
  const navigate = useNavigate();

  useEffect(
    function redirectToDashboardDueToValidSubscriptionStatus() {
      setTimeout(function initiateRedirectionAfterXSeconds() {
        navigate(ROUTER_PATHS.DASHBOARD);
      }, SUBSCRIPTION_SUCCESS_REDIRECT_TIMEOUT);
    },
    [navigate]
  );

  return (
    <FullScreenFeedback
      icon={
        <CircleCheck className='tw-mx-auto tw-h-12 tw-w-12 tw-text-green-500' />
      }
      title='You have an active subscription'
      message={`
          You're all set! Your subscription is currently active and you
          can continue using our services. You will be automatically
          redirected to your dashboard shortly.
       `}>
      <div className='tw-mt-6'>
        <Button asChild>
          <Link
            to={ROUTER_PATHS.DASHBOARD}
            className='tw-no-underline tw-text-primary-foreground tw-transition-colors !hover:tw-bg-primary/80 hover:tw-text-primary-foreground'>
            Go to Dashboard
          </Link>
        </Button>
      </div>
    </FullScreenFeedback>
  );
};

export default SubscriptionSuccess;
