// ** React Imports
import { useMemo, useReducer } from 'react';

// ** React Router Imports
import { useLoaderData } from 'react-router-dom';

// ** Util Imports
import createTableData from './utils/createTableData';
import getChartData from './utils/getChartData';
import classNames from 'classnames';
import metricReducer from './store/metricReducer';
import createTableColsDefs from './utils/createTableColsDef';
import getTabelColLabel from './utils/getColLabel';

// ** Component Imports
import { Card, CardHeader } from 'src/components/ui/card';
import Chart from './components/Chart';
import DataGrid from './components/Table/DataGrid/DataGrid';

// ** Type Imports & Constants
import { IAdCreative } from '../../types';
import { DEFAULT_PRE_SELECTED_FILTERS_LIST } from './constants';
import { TNamespace } from 'src/i18n';

// ** Schadcn UI Component Imports
import { Button } from 'src/components/ui/button';
import * as C from 'src/components/ui/command';
import * as P from 'src/components/ui/popover';
import { Check } from 'lucide-react';

// ** Constants
import { CHART_METRIC_KEYS } from './constants';

// ** Custom Hooks
import { useTranslation } from 'react-i18next';

const MetaAdsCreatives = () => {
  const data = useLoaderData() as IAdCreative[];
  const { t } = useTranslation<TNamespace>();

  const tableData = useMemo(() => createTableData(data), [data]);
  const adCreativeIds = tableData.map(({ key }) => key);

  const [state, dispatch] = useReducer<typeof metricReducer>(metricReducer, {
    activeAdCreativeIds: adCreativeIds.slice(0, 4),
    activeMetrics: DEFAULT_PRE_SELECTED_FILTERS_LIST
  });

  const tableColsDefs = useMemo(
    () =>
      createTableColsDefs({
        tableData,
        dispatch,
        state
      }),
    [tableData, state]
  );

  return (
    <div className='tw-flex tw-flex-col tw-gap-6'>
      <div className=''>
        <Card className='tw-w-full tw-overflow-hidden'>
          <CardHeader className='tw-border-b !tw-py-4 !tw-px-6'>
            <div className='tw-flex tw-mb-0 tw-p-0'>
              {state.activeMetrics.map((metric, index) => (
                <div
                  key={index}
                  className='tw-flex tw-gap-2 tw-mr-2 tw-text-sm tw-border tw-rounded tw-px-5 tw-py-2 tw-bg-white tw-shadow-sm'>
                  <span>{getTabelColLabel(metric, 22)}</span>
                  <button
                    type='button'
                    onClick={() =>
                      dispatch({
                        type: 'REMOVE_ACTIVE_METRIC',
                        payload: metric
                      })
                    }>
                    <i className='bi bi-x-lg'></i>
                  </button>
                </div>
              ))}
              <P.Popover>
                <P.PopoverTrigger asChild>
                  <Button
                    variant='outline'
                    role='combobox'
                    // aria-expanded={open}
                    className='tw-justify-between'>
                    {t('meta.adcreative.chart.addMetric', {
                      ns: 'adAnalytics'
                    })}
                  </Button>
                </P.PopoverTrigger>
                <P.PopoverContent className='tw-p-0'>
                  <C.Command>
                    <C.CommandList>
                      <C.CommandInput
                        placeholder='Search framework...'
                        className='tw-h-9 focus:tw-outline-none'
                      />
                      <C.CommandEmpty>
                        {t('meta.adcreative.chart.searchMetric', {
                          ns: 'adAnalytics'
                        })}
                      </C.CommandEmpty>
                      <C.CommandGroup>
                        {CHART_METRIC_KEYS.map((metric) => {
                          const metricLabel = getTabelColLabel(metric, 25);

                          return (
                            <C.CommandItem
                              key={metric}
                              value={metric}
                              onSelect={(currentValue) => {
                                if (
                                  state.activeMetrics.includes(currentValue)
                                ) {
                                  dispatch({
                                    type: 'REMOVE_ACTIVE_METRIC',
                                    payload: currentValue
                                  });

                                  return;
                                }

                                dispatch({
                                  type: 'ADD_ACTIVE_METRIC',
                                  payload: currentValue
                                });
                              }}>
                              {metricLabel}
                              <Check
                                className={classNames(
                                  'tw-ml-auto tw-h-4 tw-w-4',
                                  {
                                    'tw-opacity-100':
                                      state.activeMetrics.includes(metric),
                                    'tw-opacity-0':
                                      !state.activeMetrics.includes(metric)
                                  }
                                )}
                              />
                            </C.CommandItem>
                          );
                        })}
                      </C.CommandGroup>
                    </C.CommandList>
                  </C.Command>
                </P.PopoverContent>
              </P.Popover>
            </div>
          </CardHeader>
          <CardHeader className='tw-pt-4'>
            <Chart
              data={getChartData(tableData, state.activeAdCreativeIds)}
              chartFilters={state.activeMetrics}
            />
          </CardHeader>
        </Card>
      </div>
      <div
        className='tw-mb-3'
        id='table'>
        <DataGrid
          isLoading={false}
          data={tableData}
          columns={tableColsDefs}
        />
      </div>
    </div>
  );
};

export default MetaAdsCreatives;
