import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import PatternRight from "../../Assets/images/pattern-right.png";
import PatternStars from "../../Assets/images/pattern-stars.png";
import SOMateLogo from "../../Assets/images/somate-logo.svg";
import { getUserSubscription } from "../services/account";
import { Spinner } from 'reactstrap';

const PaySuccess = () => {
  var intOut, count = 0;
  // const [searchParams] = useSearchParams();
  const [isSubmitting, setIsSubmitting] = useState(true)
  // const [arr, setArr] = useState([])
  const navigate = useNavigate();

  // const handleVerify = async () => {
  //   const response = await getUserSubscription();
  //   console.warn("user subscription details ", response);
  //   if (await response?.data?.length >= 1) {
  //     clearTimeout(intOut)
  //     setIsSubmitting(false);
  //     localStorage.setItem("user_current_subscription", response?.data?.length)
  //     Swal.fire({
  //       title: "Success",
  //       text: "Payment done successfully!!",
  //       icon: "success",
  //       confirmButtonText: "OK",
  //     }).then(function () {
  //       navigate("/add-account")
  //     });
  //   }
  //   else {
  //     clearTimeout(intOut)
  //     setIsSubmitting(false);
  //     Swal.fire({
  //       title: "Error",
  //       text: "Error in payment",
  //       icon: "error",
  //       confirmButtonText: "OK",
  //     }).then(function () {
  //       navigate("/flow-plan")
  //     });
  //   }
  // };

  const anotherFun = async () => {

    const response = await getUserSubscription();
    // console.warn("user subscription details ",response);
    if (await response?.data?.length >= 1) {
      console.warn("in")
      // setArr([...await response?.data])
      count = count + 1;
      clearTimeout(intOut)
      setIsSubmitting(false);
      localStorage.setItem("user_current_subscription", response?.data?.length)
      Swal.fire({
        title: "Success",
        text: "Payment done successfully!!",
        icon: "success",
        confirmButtonText: "OK",
      }).then(function () {
        navigate("/add-account")
      });
    }
    else {
      count = count + 1;
      if (count >= 5) {
        clearTimeout(intOut)
        setIsSubmitting(false);
        Swal.fire({
          title: "Error",
          text: "Error in payment",
          icon: "error",
          confirmButtonText: "OK",
        }).then(function () {
          navigate("/flow-plan")
        });
      }
      console.warn("error")
    }
  }

  useEffect(() => {
    // handleVerify();
    intOut = setInterval(anotherFun, 2000)
  }, []);

  return (
    <>
      <div style={{ outline: "none" }} className="authentication-form-container">
        <div style={{ opacity: "0" }} className="logo_wrapper">
          <img className="somate_logo" src={SOMateLogo} alt="SOMateLogo" />
        </div>
        <img
          className="pattern_right"
          src={PatternRight}
          alt="pattern_right"
          style={{ zIndex: "1" }}
        />
      </div>
      <h6 style={{ color: "brown", height: "30vh", width: "70vw", display: "flex", alignItems: "center", justifyContent: "center" }}>
        {isSubmitting && <Spinner color="dark">
          Loading...
        </Spinner>}</h6>
      <img
        className="pattern_stars"
        src={PatternStars}
        alt="pattern_stars"
      />
    </>
  );
};

export default PaySuccess;
