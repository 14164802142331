import { format } from 'date-fns';
import { startCase } from 'lodash';
import { ReactNode } from 'react';
import { Chart, ChartLoading } from 'src/User/features/Viz/components/Chart';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle
} from 'src/components/ui/card';
import { TNamespace } from 'src/i18n';
import { useTranslation } from 'react-i18next';

type TDate = string;

interface CampaignEngagementProps {
  spendingByDate: Record<TDate, number>;
  impresssionsByDate: Record<TDate, number>;
  clicksByDate: Record<TDate, number>;
  reachByDate: Record<TDate, number>;
  frequencyByDate: Record<TDate, number>;
}

const CampaginEngagement = (props: CampaignEngagementProps) => {
  const {
    spendingByDate,
    clicksByDate,
    frequencyByDate,
    impresssionsByDate,
    reachByDate
  } = props;

  return (
    <div className='tw-flex tw-flex-col tw-gap-6'>
      <div className='tw-flex tw-flex-col tw-gap-6'>
        <SpendingByDate data={spendingByDate} />
        <ImpressionsAndClicksByDate
          data={{ impressions: impresssionsByDate, clicks: clicksByDate }}
        />
      </div>
      <ReachAndFrequencyByDate
        data={{ reach: reachByDate, frequency: frequencyByDate }}
      />
    </div>
  );
};

CampaginEngagement.Container = ({ children }: { children: ReactNode }) => (
  <div className='tw-flex tw-flex-col tw-gap-6'>{children}</div>
);

CampaginEngagement.CardContainer = ({
  children,
  title
}: {
  children: ReactNode;
  title: string;
}) => (
  <Card className='tw-w-full'>
    <CardHeader>
      <CardTitle>{title}</CardTitle>
    </CardHeader>
    <CardContent className='tw-pl-2'>{children}</CardContent>
  </Card>
);

const Loading = () => {
  const labels = useCampaignEngagementLabels();

  return (
    <CampaginEngagement.Container>
      <div className='tw-flex tw-flex-col tw-gap-6'>
        <CampaginEngagement.CardContainer title={labels.amountSpent.title}>
          <CampaginEngagement.ChartLoading />
        </CampaginEngagement.CardContainer>
        <CampaginEngagement.CardContainer
          title={labels.impressionsAndClicks.title}>
          <CampaginEngagement.ChartLoading />
        </CampaginEngagement.CardContainer>
      </div>
      <CampaginEngagement.CardContainer title={labels.reachAndFrequency.title}>
        <CampaginEngagement.ChartLoading />
      </CampaginEngagement.CardContainer>
    </CampaginEngagement.Container>
  );
};
CampaginEngagement.Loading = Loading;

CampaginEngagement.ChartLoading = () => (
  <div className='tw-flex tw-justify-center tw-w-full'>
    <ChartLoading />
  </div>
);

const Error = () => {
  const labels = useCampaignEngagementLabels();

  return (
    <CampaginEngagement.Container>
      <div className='tw-flex tw-flex-col tw-gap-6'>
        <CampaginEngagement.CardContainer title={labels.amountSpent.title}>
          <p className='!tw-my-0 tw-pl-4'>{labels.amountSpent.error}</p>
        </CampaginEngagement.CardContainer>
        <CampaginEngagement.CardContainer
          title={labels.impressionsAndClicks.title}>
          <p className='!tw-my-0 tw-pl-4'>
            {labels.impressionsAndClicks.error}
          </p>
        </CampaginEngagement.CardContainer>
      </div>
      <CampaginEngagement.CardContainer title={labels.reachAndFrequency.title}>
        <p className='!tw-my-0 tw-pl-4'>{labels.reachAndFrequency.error}</p>
      </CampaginEngagement.CardContainer>
    </CampaginEngagement.Container>
  );
};
CampaginEngagement.Error = Error;

interface SpendingByDateProps {
  data: Record<TDate, number>;
}

const SpendingByDate = (props: SpendingByDateProps) => {
  const chartSeriesData = Object.entries(props.data).map(([date, value]) => ({
    x: date,
    y: value
  }));

  const labels = useCampaignEngagementLabels();
  const { t } = useTranslation<TNamespace>();

  return (
    <CampaginEngagement.CardContainer title={labels.amountSpent.title}>
      <Chart
        type='line'
        height={400}
        data={{
          options: {
            xaxis: {
              type: 'datetime',
              labels: {
                formatter: (value) => format(new Date(value), 'dd/MM/yyyy')
              }
            },
            legend: {
              show: true,
              position: 'bottom'
            }
          },
          series: [
            {
              name:
                t('meta.adcreative.common.spend', {
                  ns: 'adAnalytics'
                }) ?? 'Spend',
              data: chartSeriesData
            }
          ]
        }}
      />
    </CampaginEngagement.CardContainer>
  );
};

interface ImpressionsAndClicksByDateProps {
  data: {
    impressions: Record<TDate, number>;
    clicks: Record<TDate, number>;
  };
}

const ImpressionsAndClicksByDate = (props: ImpressionsAndClicksByDateProps) => {
  const { impressions, clicks } = props.data;

  const { t } = useTranslation<TNamespace>();
  const labels = useCampaignEngagementLabels();

  const impressionLabel = startCase(t('impressions') ?? 'Impressions');
  const clickLabel =
    t('meta.adcreative.common.clicks', {
      ns: 'adAnalytics'
    }) ?? 'Clicks';

  return (
    <CampaginEngagement.CardContainer title={labels.impressionsAndClicks.title}>
      <Chart
        type='line'
        height={400}
        data={{
          options: {
            xaxis: {
              type: 'datetime',
              labels: {
                formatter: (value) => format(new Date(value), 'dd/MM/yyyy')
              }
            },
            yaxis: [
              {
                title: {
                  text: impressionLabel
                }
              },
              {
                opposite: true,
                title: {
                  text: clickLabel
                }
              }
            ],
            legend: {
              show: true,
              position: 'bottom'
            }
          },
          series: [
            {
              name: impressionLabel,
              data: Object.entries(impressions).map(([date, value]) => ({
                x: date,
                y: value
              }))
            },
            {
              name: clickLabel,
              data: Object.entries(clicks).map(([date, value]) => ({
                x: date,
                y: value
              }))
            }
          ]
        }}
      />
    </CampaginEngagement.CardContainer>
  );
};

interface ReachAndFrequencyByDateProps {
  data: {
    reach: Record<TDate, number>;
    frequency: Record<TDate, number>;
  };
}

const ReachAndFrequencyByDate = (props: ReachAndFrequencyByDateProps) => {
  const { reach, frequency } = props.data;

  const { t } = useTranslation<TNamespace>();
  const labels = useCampaignEngagementLabels();

  const reachLabel = startCase(
    t('meta.adcreative.common.reach', {
      ns: 'adAnalytics' satisfies TNamespace
    }) ?? 'Reach'
  );
  const frequencyLabel =
    t('meta.summary.common.frequency', {
      ns: 'adAnalytics' satisfies TNamespace
    }) ?? 'Frequency';

  return (
    <CampaginEngagement.CardContainer title={labels.reachAndFrequency.title}>
      <Chart
        type='bar'
        height={400}
        data={{
          options: {
            xaxis: {
              type: 'datetime',
              labels: {
                formatter: (value) => format(new Date(value), 'dd/MM/yyyy')
              }
            },
            yaxis: [
              {
                title: {
                  text: reachLabel
                }
              },
              {
                opposite: true,
                title: {
                  text: frequencyLabel
                }
              }
            ],
            legend: {
              show: true,
              position: 'bottom'
            }
          },
          series: [
            {
              name: reachLabel,
              data: Object.entries(reach).map(([date, value]) => ({
                x: date,
                y: value
              }))
            },
            {
              name: frequencyLabel,
              data: Object.entries(frequency).map(([date, value]) => ({
                x: date,
                y: value
              }))
            }
          ]
        }}
      />
    </CampaginEngagement.CardContainer>
  );
};

const useCampaignEngagementLabels = () => {
  const { t } = useTranslation<TNamespace>();

  return {
    amountSpent: {
      title: t('meta.summary.campaignEngagement.amountSpent', {
        ns: 'adAnalytics'
      }),
      error: t('meta.summary.campaignEngagement.error.404.amountSpent', {
        ns: 'adAnalytics'
      })
    },
    impressionsAndClicks: {
      title: t('meta.summary.campaignEngagement.impressionsAndClicks', {
        ns: 'adAnalytics'
      }),
      error: t(
        'meta.summary.campaignEngagement.error.404.impressionsAndClicks',
        {
          ns: 'adAnalytics'
        }
      )
    },
    reachAndFrequency: {
      title: t('meta.summary.campaignEngagement.reachAndFrequency', {
        ns: 'adAnalytics'
      }),
      error: t('meta.summary.campaignEngagement.error.404.reachAndFrequency', {
        ns: 'adAnalytics'
      })
    }
  };
};

export default CampaginEngagement;
