export const SUBSCRIPTION_PLANS = [
  {
    title: 'Creator',
    duration: 'Month',
    description: 'Best for single User',
    featureList: ['4 socials', 'Posting Plan', 'Analytics', 'Social inbox'],
    priceId: process.env.REACT_APP_MONTH_1_PRICE_ID
  },
  {
    title: 'Brand',
    price: '$25',
    duration: 'Month',
    description: 'Best for Small brands',
    featureList: [
      '15 Socials',
      '10 Workspaces',
      'AI Tools',
      'Collaboration Tools'
    ],
    priceId: process.env.REACT_APP_MONTH_2_PRICE_ID
  },
  {
    title: 'Business',
    price: '$95',
    duration: 'Month',
    description: 'Best for Organizations',
    featureList: [
      '150 Socials',
      '80 Workspaces',
      'Unilimited Posts',
      'Social inbox'
    ],
    priceId: process.env.REACT_APP_YEAR_3_PRICE_ID
  },
  {
    title: 'Agency',
    price: '$295',
    duration: 'Month',
    description: 'Best for Agencies with multiple client',
    featureList: [
      'Unlimited socials',
      'Unlimited Workspaces',
      'AI Image & Video Tools',
      'Social inbox'
    ],
    priceId: process.env.REACT_APP_MONTH_4_PRICE_ID
  },

  // year plans
  {
    title: 'Creator',
    price: '$180',
    duration: 'Year',
    description: 'Best for single User',
    featureList: ['4 socials', 'Posting Plan', 'Analytics', 'Social inbox'],
    priceId: process.env.REACT_APP_YEAR_1_PRICE_ID
  },
  {
    title: 'Brand',
    price: '$300',
    duration: 'Year',
    description: 'Best for Small brands',
    featureList: [
      '15 Socials',
      '10 Workspaces',
      'AI Tools',
      'Collaboration Tools'
    ],
    priceId: process.env.REACT_APP_YEAR_2_PRICE_ID
  },
  {
    title: 'Business',
    price: '$1140',
    duration: 'Year',
    description: 'Best for Organizations',
    featureList: [
      '150 Socials',
      '80 Workspaces',
      'Unilimited Posts',
      'Social inbox'
    ],
    priceId: process.env.REACT_APP_YEAR_3_PRICE_ID
  },
  {
    title: 'Agency',
    price: '$3540',
    duration: 'Year',
    description: 'Best for Agencies with multiple client',
    featureList: [
      'Unlimited socials',
      'Unlimited Workspaces',
      'AI Image & Video Tools',
      'Social inbox'
    ],
    priceId: process.env.REACT_APP_YEAR_4_PRICE_ID
  }
];

export const SUBSCRIPTIONS_METADATA = {
  creator: {
    title: 'Creator',
    description: 'Best for single User'
  },
  business: {
    title: 'Business',
    description: 'Best for Organizations'
  },
  agency: {
    title: 'Agency',
    description: 'Best for Agencies with multiple client'
  }
};
