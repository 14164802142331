import { useTranslation } from 'react-i18next';
import { TNamespace } from 'src/i18n';
import AnalyticsErrorRenderer from 'src/User/components/Error/AnalyticsErrorRenderer';

const RootErrorBoundary = () => {
  const { t } = useTranslation<TNamespace>();

  return (
    <AnalyticsErrorRenderer
      title={t('layout.error.404')}
      fallbackErrorMsg={t('layout.error.description')}
    />
  );
};

export default RootErrorBoundary;
