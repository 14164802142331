import { IReportTemplate, ITemplateState } from '../../../types';
import formatSocialAccountsDict from '../../../../SocialAccounts/pages/AllSocialAccounts/utils/getTableData';
import getTemplatePagesByAccounType from './getTemplatePagesByAccounType';

import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { intersectionBy, reduce, xorBy } from 'lodash';
import { EMPTY_REPORT_TEMPLATE_STATE_WITHOUT_BRAND } from '../contants';

interface IProps {
  brand: IBrand;
  socialAccounts: ReturnType<typeof formatSocialAccountsDict>;
  activeReportTemplate?: IReportTemplate;
}

const convertReportTemplateToTemplateState = (
  props: IProps
): ITemplateState => {
  const { brand, socialAccounts, activeReportTemplate } = props;

  const activeReportTemplatesSocialAccounts =
    activeReportTemplate?.social_media_sections?.map(
      ({ account_id, account_type, pages }) => ({
        accountId: account_id,
        accountType: account_type,
        name:
          socialAccounts.find(({ accountId }) => accountId == account_id)
            ?.name ?? 'N/A',
        pages
      })
    );

  const socialAccountsInActiveTemplate = intersectionBy(
    activeReportTemplatesSocialAccounts,
    socialAccounts,
    'accountId'
  );
  const otherSocialAccounts = xorBy(
    activeReportTemplatesSocialAccounts,
    socialAccounts.map((item) => ({ ...item, pages: [] })),
    'accountId'
  );

  return {
    brand,

    templateName: activeReportTemplate?.name ?? 'N/A',
    socialAccounts: [
      ...socialAccountsInActiveTemplate?.map((accountInfo) => ({
        accountType: accountInfo.accountType,
        accountName: accountInfo.name,
        accountId: accountInfo.accountId,
        status: 'add-to-template' as const,
        pages: (() => {
          const availablePages = getTemplatePagesByAccounType(
            accountInfo.accountType
          );

          return reduce(
            availablePages,
            (acc, _, pageKey) => {
              const isActive = accountInfo.pages.includes(pageKey);
              (acc as Record<string, boolean>)[pageKey as keyof typeof acc] =
                isActive;

              return acc;
            },
            availablePages
          );
        })()
      })),
      ...otherSocialAccounts.map(({ accountId, accountType, name }) => ({
        status: 'do-not-add-to-template' as const,
        accountId,
        accountType: accountType,
        accountName: name,
        pages: getTemplatePagesByAccounType(accountType)
      }))
    ],
    coverTitle: activeReportTemplate?.cover_title ?? 'N/A',
    colors:
      activeReportTemplate?.colors?.reduce(
        (acc, color, index) => {
          acc[index + 1] = color;
          return acc;
        },
        {} as Record<number, string>
      ) ?? EMPTY_REPORT_TEMPLATE_STATE_WITHOUT_BRAND.colors,
    backgroundImage:
      activeReportTemplate?.background ??
      EMPTY_REPORT_TEMPLATE_STATE_WITHOUT_BRAND.backgroundImage,
    coverBackgroundImage:
      activeReportTemplate?.cover_image ??
      EMPTY_REPORT_TEMPLATE_STATE_WITHOUT_BRAND.coverBackgroundImage,
    logo:
      activeReportTemplate?.logo ??
      EMPTY_REPORT_TEMPLATE_STATE_WITHOUT_BRAND.logo
  };
};

const getEmptyDraftTemplateData = ({
  brand,
  socialAccounts
}: {
  brand: IBrand;
  socialAccounts: ReturnType<typeof formatSocialAccountsDict>;
}): ITemplateState => {
  return {
    ...EMPTY_REPORT_TEMPLATE_STATE_WITHOUT_BRAND,
    brand,
    socialAccounts: socialAccounts.map(({ accountId, accountType, name }) => ({
      status: 'do-not-add-to-template',
      accountId,
      accountType: accountType,
      accountName: name,
      pages: getTemplatePagesByAccounType(accountType)
    }))
  };
};

export { getEmptyDraftTemplateData, convertReportTemplateToTemplateState };
