import { LogIn } from 'lucide-react';
import { ROUTER_PATHS } from 'src/lib/constants';
import FullScreenFeedback from 'src/User/components/FullScreenFeedback';

const RestrictedUserSubcriptionUI = () => {
  return (
    <FullScreenFeedback
      icon={<LogIn className='tw-h-12 tw-w-12 tw-text-primary' />}
      title='Restricted Subscription Plan'
      message={`
        Your current subscription plan has limited features and functionality.
        To unlock full access, please consider upgrading your plan.`}>
      <FullScreenFeedback.Button
        href={ROUTER_PATHS.LOGIN}
        label='Connect with different user'
      />
    </FullScreenFeedback>
  );
};

export default RestrictedUserSubcriptionUI;
