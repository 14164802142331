export const HISTORY_DATA = [
  {
    date: 'Aug 31 2023',
    followers_count: {
      value: 24171,
      change_count: 40
    },
    following_count: {
      value: 376,
      change_count: 0
    },
    media_count: {
      value: 837,
      change_count: 0
    },
    engagement_rate: {
      value: 1.01,
      change_count: 0
    }
  },
  {
    date: 'Sep 1 2023',
    followers_count: {
      value: 24167,
      change_count: -4
    },
    following_count: {
      value: 376,
      change_count: 0
    },
    media_count: {
      value: 837,
      change_count: 0
    },
    engagement_rate: {
      value: 1.01,
      change_count: 0
    }
  },
  {
    date: 'Sep 2 2023',
    followers_count: {
      value: 24163,
      change_count: -4
    },
    following_count: {
      value: 376,
      change_count: 0
    },
    media_count: {
      value: 837,
      change_count: 0
    },
    engagement_rate: {
      value: 1.01,
      change_count: 0
    }
  }
];
