import Api from 'src/Api';
import {
  IGetFacebookPageAudienceInsightResponse,
  IGetFacebookPageClicksInsightResponse,
  IGetFacebookPageInsightOverview,
  IGetFacebookPageInsightOverviewResponse,
  IGetFacebookPageLifetimeMediaInsightResponse
} from './models';

export const getFacebookPageOverviewAnalytics = async (
  props: IGetFacebookPageInsightOverview
) => {
  const { brandId, facebookPageId, date } = props;
  const startDateQuery = date?.start ? `&start_date=${date.start}` : '';
  const endDateQuery = date?.stop ? `&end_date=${date.stop}` : '';
  const url =
    `/facebook-page/overview/?brand_id=${brandId}` +
    `&facebook_page_id=${facebookPageId}${startDateQuery}${endDateQuery}`;

  return await Api.get<IGetFacebookPageInsightOverviewResponse>(url);
};

export const getFacebookPageClicksAnalytics = async (
  props: IGetFacebookPageInsightOverview
) => {
  const { brandId, facebookPageId, date } = props;
  const startDateQuery = date?.start ? `&start_date=${date.start}` : '';
  const endDateQuery = date?.stop ? `&end_date=${date.stop}` : '';
  const url =
    `/facebook-page/clicks/?brand_id=${brandId}` +
    `&facebook_page_id=${facebookPageId}${startDateQuery}${endDateQuery}`;

  return await Api.get<IGetFacebookPageClicksInsightResponse>(url);
};

export const getFacebookPageAudienceAnalytics = async (
  props: IGetFacebookPageInsightOverview
) => {
  const { brandId, facebookPageId, date } = props;
  const startDateQuery = date?.start ? `&start_date=${date.start}` : '';
  const endDateQuery = date?.stop ? `&end_date=${date.stop}` : '';
  const url =
    `/facebook-page/audience/?brand_id=${brandId}` +
    `&facebook_page_id=${facebookPageId}${startDateQuery}${endDateQuery}`;

  return await Api.get<IGetFacebookPageAudienceInsightResponse>(url);
};

export const getFacebookPageLifetimeMediaAnalytics = async (
  props: Pick<IGetFacebookPageInsightOverview, 'brandId' | 'facebookPageId'>
) => {
  const { brandId, facebookPageId } = props;
  const url = `/facebook-page/posts/?brand_id=${brandId}&facebook_page_id=${facebookPageId}`;

  return await Api.get<IGetFacebookPageLifetimeMediaInsightResponse>(url);
};
