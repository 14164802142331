import { ICONS_MAP } from 'src/User/features/CompetitionAnalysis/views/Instagram/views/Overview/constants';
import { facebookPageDailyInsightTitleKeyMapper } from '../../../constants';
import { IFacebookPageInsightOverviewSummary } from '../types';

type TData = IFacebookPageInsightOverviewSummary;

export const getSummaryData = (data: TData) => {
  return [
    {
      title: data.followers_count,
      description: facebookPageDailyInsightTitleKeyMapper.followers,
      icon: ICONS_MAP['followers']
    },
    {
      title: data.page_actions_total_post_reactions,
      description:
        facebookPageDailyInsightTitleKeyMapper.page_actions_post_reactions_like_total,
      icon: ICONS_MAP['averageLikes']
    },
    {
      title: data.impressions,
      description: facebookPageDailyInsightTitleKeyMapper.page_impressions,
      icon: ICONS_MAP['engagementRate']
    },
    {
      title: data.views,
      description: facebookPageDailyInsightTitleKeyMapper.page_views_total,
      icon: ICONS_MAP['pageVisits']
    },
    {
      title: data.fan_count,
      description: facebookPageDailyInsightTitleKeyMapper.fans,
      icon: ICONS_MAP['followers']
    },
    {
      title: data.fans_acquired,
      description: facebookPageDailyInsightTitleKeyMapper.fans_acquired,
      icon: ICONS_MAP['followersRatio']
    },
    {
      title: data.fans_lost,
      description: facebookPageDailyInsightTitleKeyMapper.fans_lost,
      icon: ICONS_MAP['weeklyFollowers']
    }
  ];
};
