import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { register } from 'src/User/services/auth';
import { USER_CONFIG } from 'src/configs';
import { useNavigate } from 'react-router-dom';
import { PASSWORD_REGEX } from 'src/configs';

const initialVisibilityState = {
  mainPassword: false,
  confirmPassword: false
};

const initialFormData = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  password_confirm: '',
  language: 'EN'
};

export default function useGetRegistrationData() {
  const navigate = useNavigate();
  const [visibility, setPasswordVisibility] = useState(initialVisibilityState);

  const togglePasswordVisibility = (
    type: keyof typeof initialVisibilityState
  ) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [type]: !prevState[type]
    }));
  };

  const onSubmit = async (values: typeof initialFormData) => {
    localStorage.setItem(USER_CONFIG.storageUserSubscriptionKey, 'null');

    try {
      await register(values);

      Swal.fire({
        icon: 'success',
        title: 'Account created, verification email sent!',
        text: 'Head to provided email to verify account',
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        toast: true,
        position: 'top-right'
      });
      navigate('/login');
    } catch (error: any) {
      const { response } = error;
      const { data } = response;
      const errorMessages = Object.values(data).flatMap(
        (msg) => msg as string | string[]
      );

      Swal.fire({
        title: 'Error',
        html: errorMessages.join('<br>'),
        icon: 'error',
        showConfirmButton: false,
        showCloseButton: true,
        toast: true,
        position: 'top-right'
      });
    }
  };

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema: Yup.object({
      first_name: Yup.string()
        .trim()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .required('Required'),
      last_name: Yup.string()
        .trim()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
      password: Yup.string()
        .matches(PASSWORD_REGEX, 'Password must contain at least 8 charactes')
        .required('Required'),
      password_confirm: Yup.string()
        .required('Required')
        .oneOf([Yup.ref('password')], 'Passwords must match')
    }),
    onSubmit
  });

  return {
    togglePasswordVisibility,
    passwordVisible: visibility,
    formik
  };
}
