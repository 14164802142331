import { Spinner } from 'reactstrap';
import { IFormDialogProps } from './types';
import UnControlledDialog from '../UnControlledDialog';
import { Label } from 'src/components/ui/label';
import { Input } from 'src/components/ui/input';

export default function FormDialog({
  buttonText,
  popupTitle,
  isOpen,
  onClose,
  form
}: IFormDialogProps) {
  return (
    <UnControlledDialog
      title={popupTitle}
      open={isOpen}
      onCancel={onClose}
      submitButtonLabel={
        <div className='tw-flex tw-gap-2 tw-items-center'>
          {buttonText}
          {form.isSubmitting ? (
            <Spinner
              color='light'
              size={'sm'}
              className=''>
              Loading...
            </Spinner>
          ) : null}
        </div>
      }
      onConfirm={form.onSubmit}
      onOpenChange={onClose}>
      <div className='tw-grid tw-w-full tw-items-center tw-gap-2'>
        <Label htmlFor={form.inputEltId}>{form.inputEltLabel}</Label>
        <Input
          type='text'
          value={form.inputEltValue}
          onChange={form.onInputChange}
          onBlur={form.onInputBlur}
          id={form.inputEltId}
          name={form.inputEltId}
          autoComplete='off'
        />
      </div>
    </UnControlledDialog>
  );
}
