import { TSocialAccountKind } from 'src/lib/models/socialAccounts';
import MetaIcon from 'src/components/icons/meta';
import ColorfulInstagramIcon from 'src/components/icons/colorful-instagram';
import ColorfulFacebook from 'src/components/icons/colorful-facebook';

type TValidSocialAccountKind = Extract<
  TSocialAccountKind,
  'META_ADS' | 'IG_BUSINESS' | 'FACEBOOK_PAGE'
>;

export const SOCIAL_ACCOUNT_ICONS = {
  META_ADS: MetaIcon,
  IG_BUSINESS: ColorfulInstagramIcon,
  FACEBOOK_PAGE: ColorfulFacebook
} satisfies Record<TValidSocialAccountKind, any>;
