import React, { ReactNode } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styles from '../../views/instagram/index.module.css';
import classnames from 'classnames';

interface IntegrationModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  oauthReqUrl: string;
  header: {
    title: string;
    icon?: ReactNode | string;
  };
  children: ReactNode;
}

export default function IntegrationModal(props: IntegrationModalProps) {
  const { isOpen, toggleModal, oauthReqUrl, children, header } = props;

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        centered>
        <div className={styles.modalContentWrapper}>
          <ModalHeader
            toggle={toggleModal}
            close={<div></div>}
            className={styles.modalHeader}>
            <div className={styles.headerContent}>
              {typeof header?.icon == 'string' ? (
                <i
                  className={classnames(
                    header?.icon,
                    styles.headerContentIcon
                  )}></i>
              ) : (
                header?.icon ?? null
              )}
              <p className={styles.headerContentTitle}>{header.title}</p>
            </div>
          </ModalHeader>
          <ModalBody>{children}</ModalBody>
          <ModalFooter className={styles.modalFooter}>
            <a
              className={classnames('btn btn-primary', styles.connectBtn)}
              href={oauthReqUrl}>
              Connect
            </a>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
}
