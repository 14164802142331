import { AtomEffect, atom } from 'recoil';
import { USER_CONFIG } from 'src/configs';
import { IUserInfo } from 'src/lib/models/userInfo';

const localStorageEffect: AtomEffect<IUserInfo> = ({ setSelf, onSet }) => {
  const savedValue = localStorage.getItem(USER_CONFIG.storageUserInfoKeyName);
  if (savedValue) {
    setSelf(JSON.parse(savedValue));
  }

  onSet((newValue, _, isReset) => {
    isReset
      ? localStorage.removeItem(USER_CONFIG.storageUserInfoKeyName)
      : localStorage.setItem(
          USER_CONFIG.storageUserInfoKeyName,
          JSON.stringify(newValue)
        );
  });
};

const userInfoState = atom<IUserInfo>({
  key: 'userInfoState',
  default: undefined,
  effects: [localStorageEffect]
});

export default userInfoState;
