import { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { DATE_SEARCH_PARAMS } from 'src/configs';
import displayAxiosError from 'src/lib/utils/displayAxiosError';
import { useBrand } from 'src/User/layout/Sidebar/components/BrandSelect';
import { metaAdsAnalyticsAtom } from 'src/User/recoil/Atoms/MetaAdsAnalyticsAtom';

export const CAMPAIGN_INSIGHTS_SEARCH_PARAMS = {
  campaigns: 'campaigns',
  objectives: 'objectives',
  adsets: 'adsets'
} as const;

interface IReturn<T> {
  loading: boolean;
  response?: T | undefined;
}

interface IFetcherProps {
  adAccountId: string;
  brandId: string;
  campaignIds?: string;
  campaignObjectives?: string;
  campaignAdsets?: string;
  date: { start: string; stop: string };
}

interface IProps<T> {
  fetcher: (props: IFetcherProps) => Promise<{ data: T }>;
}

//! This hook is temporary and will be removed once we use react-query for data fetching
const useGetData = <T>(props: IProps<T>): IReturn<T> => {
  const { fetcher } = props;

  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const { adAccountId } = useParams();
  const { pathname } = useLocation();
  const { getCurrentBrand } = useBrand();
  const [metaAdsAnalyticsCache, setMetaAdsAnalyticsCache] =
    useRecoilState(metaAdsAnalyticsAtom);

  const { id: brandId } = getCurrentBrand();
  const startDate = searchParams.get(DATE_SEARCH_PARAMS.start);
  const endDate = searchParams.get(DATE_SEARCH_PARAMS.stop);
  const campaignIds = searchParams.get(
    CAMPAIGN_INSIGHTS_SEARCH_PARAMS.campaigns
  );
  const campaignObjectives = searchParams.get(
    CAMPAIGN_INSIGHTS_SEARCH_PARAMS.objectives
  );
  const campaignAdsets = searchParams.get(
    CAMPAIGN_INSIGHTS_SEARCH_PARAMS.adsets
  );

  const cacheKey =
    `${pathname}` +
    `_${brandId}_${campaignObjectives}_` +
    `${campaignIds}_${campaignAdsets}_${startDate}_${endDate}`;

  useEffect(() => {
    const persistedInsightData = metaAdsAnalyticsCache.adCampaign?.[cacheKey];
    const shouldFetchData =
      !persistedInsightData && adAccountId && startDate && endDate;

    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: adAnalyticsCampaignInsightsResponse } = await fetcher({
          adAccountId: adAccountId as string,
          brandId,
          campaignIds: campaignIds ?? '',
          campaignObjectives: campaignObjectives ?? '',
          campaignAdsets: campaignAdsets ?? '',
          date: {
            start: startDate as string,
            stop: endDate as string
          }
        });

        setMetaAdsAnalyticsCache((prevValue) => ({
          ...prevValue,
          adCampaign: {
            ...prevValue.adCampaign,
            [cacheKey]: adAnalyticsCampaignInsightsResponse
          }
        }));
      } catch (error) {
        displayAxiosError(error, {
          title: 'Failed to fetch ad campaign insights',
          fallbackMsg: '',
          toast: true
        });
      } finally {
        setLoading(false);
      }
    };

    if (shouldFetchData) {
      fetchData();
    }
  }, [
    adAccountId,
    brandId,
    cacheKey,
    campaignIds,
    campaignObjectives,
    campaignAdsets,
    endDate,
    metaAdsAnalyticsCache.adCampaign,
    fetcher,
    setMetaAdsAnalyticsCache,
    startDate
  ]);

  return {
    loading,
    response: metaAdsAnalyticsCache.adCampaign?.[cacheKey]
  };
};

export default useGetData;
