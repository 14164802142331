import { truncate } from 'lodash';
import styles from './index.module.css';
import clx from 'classnames';
import { ReactNode } from 'react';
import AvatarWithFallback from 'src/User/components/AvatarWithFallback';
import classNames from 'classnames';

export interface IAccountCellRendererProps {
  avatar?: string;
  username?: string | number;
  name?: string;
  borderRadius?: 'rounded-full';
  title?: string;
  numberOfAds?: number;
  imageUrl?: string;
  style?: {
    spacing: 'dense' | 'loose';
  };
  avatarClassName?: string;
  children?: ReactNode;
  charLimit?: number;
}

export default function AccountCellRenderer(props: IAccountCellRendererProps) {
  const {
    avatar,
    username,
    name,
    borderRadius,
    style = {
      spacing: 'dense'
    },
    avatarClassName,
    children,
    charLimit = 25
  } = props ?? {};

  return (
    <div
      className={clx('d-flex gap-3 align-items-center', {
        'py-3': style.spacing == 'loose'
      })}>
      <div>
        <div className={styles['account-info-img-container']}>
          <AvatarWithFallback
            className={classNames(
              `tw-w-16 tw-h-16 tw-object-cover tw-object-center`,
              { [styles[borderRadius as string]]: borderRadius },
              avatarClassName
            )}
            src={avatar ?? ''}
            alt={`${name}'s avatar`}
          />
        </div>
      </div>
      <div className='tw-leading-tight'>
        <p className='d-flex flex-column mb-0'>
          <span className={styles['account-info-title']}>
            {truncate(name, { length: charLimit })}
          </span>
          <span className={styles['account-info-subtitle']}>{username}</span>
        </p>
        {children}
      </div>
    </div>
  );
}
