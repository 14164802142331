import classNames from 'classnames';
import { omit, startCase } from 'lodash';
import { ArrowUpRightFromSquareIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TNamespace } from 'src/i18n';

interface TableExternalLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  label?: string;
}

const TableExternalLink = (props: TableExternalLinkProps) => {
  const { t } = useTranslation<TNamespace>();

  const {
    href,
    children,
    className,
    label = startCase(t('view-post') ?? '')
  } = props;

  const Tag = !href ? 'a' : Link;
  const linkProps = {
    to: href ?? '',
    href: href ?? ''
  };

  return (
    <Tag
      {...linkProps}
      className={classNames(
        'tw-flex tw-items-center tw-gap-1 tw-text-sm tw-text-slate-600 tw-w-fit tw-border-b tw-border-primary-black tw-no-underline hover:tw-text-primary hover:tw-border-primary',
        className
      )}
      target='_blank'
      rel='noreferrer'
      {...omit(props, ['href', 'children', 'className'])}>
      <span>{label}</span>{' '}
      <ArrowUpRightFromSquareIcon className='tw-w-4 tw-h-3' />
      {children}
    </Tag>
  );
};

export default TableExternalLink;
