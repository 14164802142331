import rootRouteLoader from './loaders/rootRouteLoader';
import rootRouteAction from './actions/rootRouteAction';
import reportTemplateAction from './actions/reportTemplateAction';
import getReportTemplateByIdLoader from './loaders/getReportTemplateLoader';

export { default as TemplatePage } from './views/template';
export { default as ReportPage } from './views/reports';
export { default as ReportPageErrorElement } from './views/reports/Error/RootRouteErrorBoundary';

export const loaders = {
  rootRouteLoader,
  getReportTemplateByIdLoader
};

export const actions = {
  rootRouteAction,
  reportTemplateAction
};
