import { format, subMonths } from 'date-fns';

export const USER_CONFIG = {
  storageUserInfoKeyName: 'userInfo',
  storageTokenKeyName: 'token',
  storageUserSubscriptionKey: 'user_current_subscription',
  storageUserBrand: 'user_brand'
};

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

export const DEFAULT_DATE_DURATION = {
  start: format(subMonths(new Date(), 1), DEFAULT_DATE_FORMAT),
  stop: format(new Date(), DEFAULT_DATE_FORMAT)
};

export enum DATE_SEARCH_PARAMS {
  start = 'start',
  stop = 'stop'
}

export const PASSWORD_REGEX = /^.{8,}$/;
