import { ISubscription } from 'src/User/services/planServices';
import { capitalize, isEmpty } from 'lodash';
import { TPlanNames } from 'src/User/recoil/Atoms/UserSubscriptionAtom';
import { Maybe } from 'src/lib/models';

type TReturn = {
  key: TPlanNames;
  title: string;
  stripeProductId: string;
  stripePriceId: string;
  monthlyPrice: string;
  yearlyPrice: string;
  description: string;
  featureList: string[];
}[];

export default function getSubscriptionData(data: ISubscription[]) {
  if (isEmpty(data)) return [];

  function formatSubscriptionData() {
    return data.reduce((acc, curr) => {
      const { price, currency, plan_name: planName } = curr;

      const formattedPlanData = {
        key: planName,
        title: capitalize(planName),
        description: '',
        stripeProductId: curr.stripe_product_id,
        stripePriceId: curr.stripe_price_id,
        featureList: getFeaturesList(curr),
        monthlyPrice: `${price} ${currency}`,
        yearlyPrice: getYearlyPricing(price, currency)
      };

      acc.push(formattedPlanData);

      return acc;
    }, [] as TReturn);
  }

  function getYearlyPricing(price: number, currency: string) {
    return `${price * 12} ${currency}`;
  }

  return formatSubscriptionData();
}

export function getFeaturesList<
  T extends {
    max_brands: number;
    max_users: number;
    max_socials: number;
    max_ai: number;
    free_trial_period?: number;
  }
>(plan?: Maybe<T>) {
  const { free_trial_period, max_brands, max_users, max_socials, max_ai } =
    plan ?? {};

  if (!plan) return [];

  const baseFeatures = [
    `Up to ${max_brands} brands`,
    `Up to ${max_users} users`,
    `Up to ${max_socials} social accounts`
  ];

  if (max_ai) baseFeatures.push(`${max_ai} SoMate AI credits`);

  if (free_trial_period)
    baseFeatures.unshift(`${free_trial_period} day(s) free trial`);

  return baseFeatures;
}
