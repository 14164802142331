import { useMemo } from 'react';
import useChartSeries, {
  IActiveChartSeries
} from '../../../hooks/useChartSeries';
import useGetData from '../../../hooks/useGetData';
import {
  CampaignInsightByAd,
  IAdsTableRow,
  TActiveChartSeriesInitializer
} from '../../../types';
import createChartSeriesData from '../../../utils/createChartSeriesData';
import { getMetaAdCampaignInsightsByAds } from 'src/User/services/meta-ads';

const useGetAdsInsights = () => {
  const { response, loading } = useGetData({
    fetcher: getMetaAdCampaignInsightsByAds
  });
  const { activeChartSeries, initializeActiveChartSeries, toggleChartSeries } =
    useChartSeries();

  const { data } = response ?? {};
  const aggregatedAdsTableData = useMemo(
    () => getAggregatedAdsTableData(data),
    [data]
  );
  const adsCharts = useMemo(
    () =>
      getAllChartData({
        data,
        initializeActiveChartSeries,
        activeSeriesByChart: activeChartSeries
      }),
    [data, activeChartSeries, initializeActiveChartSeries]
  );

  return {
    aggregatedAdsTableData,
    adsCharts,
    loading,
    toggleChartSeries
  };
};

interface IGetAllChartData {
  data?: CampaignInsightByAd[];
  activeSeriesByChart?: IActiveChartSeries;
  initializeActiveChartSeries: TActiveChartSeriesInitializer;
}

const getAllChartData = (props: IGetAllChartData) => {
  const { data, activeSeriesByChart, initializeActiveChartSeries } = props;

  return (
    data?.map(
      ({
        name: adName,
        insights,
        id: adId,
        adset_name: adsetName,
        thumbnail_url
      }) => ({
        adName,
        adId,
        adsetName,
        previewUrl: thumbnail_url,
        chartData: createChartSeriesData({
          chartId: adId,
          initializeActiveChartSeries,
          insights,
          activeSeriesByChart
        })
      })
    ) ?? []
  );
};

const getAggregatedAdsTableData = (
  data?: CampaignInsightByAd[]
): IAdsTableRow[] => {
  return (
    data?.map(
      ({
        adset_name,
        name,
        insights,
        id,
        campaign_objective: objective,
        campaign_name: campaignName
      }) => ({
        ...insights.summary,
        objective,
        campaignName,
        adsetName: adset_name,
        adName: name,
        adId: id,
        currency: ''
      })
    ) ?? []
  );
};

export default useGetAdsInsights;
