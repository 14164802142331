
function App() {
  return (
    <div className="App">
     <h1>somate Super Admin landing page
     </h1>
    </div>
  );
}

export default App;
