import React, { useReducer, useState } from 'react';
import Sidebar from './layout/sidebar';
import reportReducer from './store/reducer';
import TemplateTheme from './components/TemplateTheme';
import BodyBackground from './components/BodyBackground';
import CoverBackground from './components/CoverBackground';
import Pages from './components/Pages';
import { useLoaderData, useParams, useRouteLoaderData } from 'react-router-dom';
import formatSocialAccountsDict from '../../../SocialAccounts/pages/AllSocialAccounts/utils/getTableData';
import { useBrand } from 'src/User/layout/Sidebar/components/BrandSelect';
import { ROUTER_PATHS_ID } from 'src/lib/constants';
import { PromiseType } from 'src/lib/models';
import allSocialAccountsLoader from '../../../SocialAccounts/pages/AllSocialAccounts/loaders/allSocialAccountsLoader';
import getReportTemplateLoader from '../../loaders/getReportTemplateLoader';
import * as createReportTemplateState from './utils/createReportTemplateState';
import { head } from 'lodash';
import { ITemplateSectionProps } from './models';
import VerticalCollapse from 'src/User/components/VerticalCollapse';

type LoaderData =
  | PromiseType<ReturnType<typeof getReportTemplateLoader>>
  | undefined;

type LayoutLoaderData = PromiseType<ReturnType<typeof allSocialAccountsLoader>>;

type TSectionKey = 'pages' | 'theme' | 'cover' | 'background';

export default function TemplatePage() {
  const socialAccountsDict = useRouteLoaderData(
    ROUTER_PATHS_ID.REPORTING_TEMPLATE_LAYOUT
  ) as LayoutLoaderData;
  const reportTemplateData = useLoaderData() as LoaderData;
  const { getCurrentBrand } = useBrand();
  const { templateId } = useParams();

  const brand = getCurrentBrand();
  const socialAccounts = formatSocialAccountsDict(socialAccountsDict);
  const initialTemplateData = templateId
    ? createReportTemplateState.convertReportTemplateToTemplateState({
        brand,
        socialAccounts,
        activeReportTemplate: head(reportTemplateData)
      })
    : createReportTemplateState.getEmptyDraftTemplateData({
        brand,
        socialAccounts
      });

  const [templateData, dispatch] = useReducer<typeof reportReducer>(
    reportReducer,
    initialTemplateData
  );
  const [activeSection, setActiveSection] = useState<TSectionKey>();

  return (
    <div className='d-flex gap-4 flex-row-reverse'>
      <Sidebar
        templateState={templateData}
        dispatcher={dispatch}
      />
      <div className='d-flex flex-column gap-2 w-100'>
        <TemplateSections
          activeSection={activeSection}
          templateData={templateData}
          dispatcher={dispatch}
          onOpen={(sectionKey) => setActiveSection(sectionKey)}
        />
      </div>
    </div>
  );
}

interface ITemplateSectionsProps
  extends Pick<ITemplateSectionProps, 'templateData' | 'dispatcher'> {
  activeSection: TSectionKey | undefined;
  onOpen: (sectionKey: TSectionKey) => void;
}

function TemplateSections(props: ITemplateSectionsProps) {
  const { templateData, activeSection, onOpen, dispatcher } = props;

  return (
    <>
      <VerticalCollapse.ControlledVerticalCollapse
        title='Pages'
        collapse={activeSection == 'pages'}
        toggleCollapse={() => onOpen('pages')}>
        <Pages
          templateData={templateData}
          dispatcher={dispatcher}
        />
      </VerticalCollapse.ControlledVerticalCollapse>
      <VerticalCollapse.ControlledVerticalCollapse
        title='Logo & Colors'
        collapse={activeSection == 'theme'}
        toggleCollapse={() => onOpen('theme')}>
        <TemplateTheme
          templateData={templateData}
          dispatcher={dispatcher}
        />
      </VerticalCollapse.ControlledVerticalCollapse>
      <VerticalCollapse.ControlledVerticalCollapse
        title='Cover Page Settings'
        collapse={activeSection == 'cover'}
        toggleCollapse={() => onOpen('cover')}>
        <CoverBackground
          templateData={templateData}
          dispatcher={dispatcher}
        />
      </VerticalCollapse.ControlledVerticalCollapse>
      <VerticalCollapse.ControlledVerticalCollapse
        title='Body Background'
        collapse={activeSection == 'background'}
        toggleCollapse={() => onOpen('background')}>
        <BodyBackground
          templateData={templateData}
          dispatcher={dispatcher}
        />
      </VerticalCollapse.ControlledVerticalCollapse>
    </>
  );
}
