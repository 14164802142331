import { Link, useNavigate, useRouteError } from 'react-router-dom';
import { Button } from 'src/components/ui/button';

interface AnalyticsErrorRendererProps {
  title: string;
  fallbackErrorMsg: string;
  hideConnectAccount?: boolean;
}

const AnalyticsErrorRenderer = (props: AnalyticsErrorRendererProps) => {
  const { title, fallbackErrorMsg, hideConnectAccount = true } = props;

  const error = useRouteError() as { message?: string };
  const navigate = useNavigate();

  return (
    <div className='tw-bg-white tw-w-full tw-min-h-[415px] tw-shadow-xl tw-flex tw-flex-col tw-justify-center tw-items-center tw-rounded-lg tw-py-4 tw-px-6'>
      <div className='tw-text-center'>
        <h3 className='tw-font-semibold tw-mb-5'>{title}</h3>
        <p className='tw-text-slate-600 tw-font-medium'>
          {error?.message ? error.message : fallbackErrorMsg}
        </p>
      </div>
      <div className='tw-flex tw-items-center tw-gap-4 tw-mt-4'>
        {true ? (
          <Button
            variant={'default'}
            size={'lg'}
            onClick={() => navigate(0)}
            className='!tw-text-base'>
            Refresh Page
          </Button>
        ) : null}
        {hideConnectAccount ? null : (
          <Button
            asChild
            variant={'outline'}
            size={'lg'}
            onClick={() => navigate(0)}
            className='!tw-text-base tw-border-primary tw-text-primary hover:tw-text-inherit'>
            <Link
              to='/socials'
              className='tw-no-underline'>
              Connect another account
            </Link>
          </Button>
        )}
      </div>
      <Button
        asChild
        variant={'ghost'}
        size={'lg'}
        className='tw-text-primary hover:tw-text-primary-dark tw-mt-4 !tw-text-base'>
        <Link
          to='/dashboard'
          className='tw-no-underline hover:tw-text-inherit'>
          Back to dashboard
        </Link>
      </Button>
    </div>
  );
};

export default AnalyticsErrorRenderer;
