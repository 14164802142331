import { ComponentType, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Maybe } from 'src/lib/models';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import Badge from '../Badge';
import { useTranslation } from 'react-i18next';

interface IConnectedAccount {
  avatar?: Maybe<ComponentType<{ className?: string }>>;
  href: string;
  name: string;
  socialMediaLogo?: Maybe<ComponentType<{ className?: string }>>;
}

interface IAccountProvider {
  name: string;
  href: string;
  icon: JSX.Element;
  disabled: boolean;
}

interface IAnalyticsSocialAccountsRendererProps {
  type: TType;
  connectedAccounts: IConnectedAccount[];
  accountProviders: IAccountProvider[];
}

type TType = 'analytics' | 'ads-analytics';

const AnalyticsSocialAccountsRenderer = (
  props: IAnalyticsSocialAccountsRendererProps
) => {
  const { type, connectedAccounts, accountProviders } = props;

  const { t } = useTranslation();

  return (
    <AnalyticsSocialAccountsRenderer.Layout title={t(type)}>
      <AnalyticsSocialAccountsRenderer.ConnectedAccountsView
        connectedAccounts={connectedAccounts}
      />
      <AnalyticsSocialAccountsRenderer.AccountProviderView
        type={type}
        accountProviders={accountProviders}
      />
    </AnalyticsSocialAccountsRenderer.Layout>
  );
};

interface ILayoutProps {
  title: string;
  children: ReactNode;
}

const Layout = (props: ILayoutProps) => {
  const { title, children } = props;

  return (
    <section className='tw-bg-white tw-w-full tw-h-full tw-shadow-xl tw-rounded-lg tw-py-4 tw-px-6'>
      <hgroup>
        <h4 className='tw-font-semibold tw-mb-7 tw-capitalize'>{title}</h4>
      </hgroup>
      <div className='tw-flex tw-flex-col tw-gap-10'>{children}</div>
    </section>
  );
};

const LayoutItem = ({ children, title }: ILayoutProps) => {
  return (
    <div>
      <h5 className='tw-mb-5 tw-capitalize'>{title}</h5>
      <div>{children}</div>
    </div>
  );
};

const ConnectedAccountsView = ({
  connectedAccounts
}: {
  connectedAccounts: IConnectedAccount[];
}) => {
  const { t } = useTranslation();

  return (
    <AnalyticsSocialAccountsRenderer.LayoutItem title={t('connected-accounts')}>
      {isEmpty(connectedAccounts) ? (
        <div className='tw-py-3 tw-px-2 tw-text-slate-500 tw-font-medium'>
          ⚠️ {t('no-account-found')}
        </div>
      ) : (
        <div className='tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-gap-7'>
          {connectedAccounts.map(
            ({
              avatar: Avatar,
              href,
              name,
              socialMediaLogo: SocialMediaLogo
            }) => {
              return (
                <Link
                  key={href}
                  to={href}
                  className={
                    'tw-no-underline tw-border p-4 tw-rounded-xl tw-min-w-52 lg:tw-min-w-80 tw-flex tw-flex-col tw-gap-4 tw-text-primary-black tw-shadow tw-transition hover:tw-text-primary-black hover:tw-shadow-primary hover:tw-border-primary-beige-dark'
                  }>
                  <div className='tw-w-10 tw-h-10'>
                    <Badge
                      className='tw-w-full tw-h-full'
                      badgeContent={
                        SocialMediaLogo ? (
                          <SocialMediaLogo className='tw-w-3 tw-h-3' />
                        ) : null
                      }>
                      {Avatar ? (
                        <Avatar className='tw-w-full tw-h-full !tw-rounded' />
                      ) : null}
                    </Badge>
                  </div>
                  <div>
                    <p className='tw-mb-1 tw-font-semibold tw-text-lg'>
                      {name}
                    </p>
                    <p className='tw-mb-0 tw-font-medium tw-text-sm tw-text-slate-600 tw-w-fit tw-border-b tw-border-primary-black tw-capitalize'>
                      {t('view-dashboard')}
                    </p>
                  </div>
                </Link>
              );
            }
          )}
        </div>
      )}
    </AnalyticsSocialAccountsRenderer.LayoutItem>
  );
};

interface IAccountProviderViewProps {
  accountProviders: IAccountProvider[];
  type: TType;
}

const AccountProviderView = (props: IAccountProviderViewProps) => {
  const { accountProviders, type } = props;
  const { t } = useTranslation();

  return (
    <div>
      <h5 className='tw-mb-5 tw-capitalize'>
        {t('connect')} {t(type)} {t('account')}
      </h5>
      <div className='tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-gap-7'>
        {accountProviders.map(({ disabled, href, icon, name }, index) => (
          <Link
            key={index}
            to={href}
            className={classNames(
              'tw-no-underline tw-border p-4 tw-rounded-xl tw-min-w-52 lg:tw-min-w-80 tw-flex tw-flex-col lg:tw-flex-row tw-gap-4 tw-text-primary-black tw-shadow tw-transition hover:tw-text-primary-black hover:tw-shadow-primary hover:tw-border-primary-beige-dark',
              {
                'tw-pointer-events-none tw-bg-slate-50 tw-opacity-90': disabled
              }
            )}>
            <div className='tw-w-10 tw-h-10'>{icon}</div>
            <div>
              <p className='tw-mb-1 tw-font-semibold tw-text-lg'>{name}</p>
              {disabled ? (
                <p className='tw-mb-0 tw-text-slate-500 tw-text-base tw-capitalize'>
                  {t('coming-soon')}
                </p>
              ) : (
                <p className='tw-mb-0 tw-font-medium tw-text-sm tw-text-slate-600 tw-w-fit tw-border-b tw-border-primary-black tw-capitalize'>
                  {t('connect-now')}
                </p>
              )}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

AnalyticsSocialAccountsRenderer.ConnectedAccountsView = ConnectedAccountsView;
AnalyticsSocialAccountsRenderer.AccountProviderView = AccountProviderView;
AnalyticsSocialAccountsRenderer.Layout = Layout;
AnalyticsSocialAccountsRenderer.LayoutItem = LayoutItem;

export default AnalyticsSocialAccountsRenderer;
