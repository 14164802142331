// css
import './Team.css';

// images
import MemberImg from '../../Assets/images/member01.png';

// dependencies
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Table } from 'reactstrap';
import Swal from 'sweetalert2';

// API services
import {
  getTeamMember,
  addTeamMember,
  TBrandUserRole
} from '../services/teamMemberServices';

// recoil
import { useRecoilState } from 'recoil';
import { activeBrand as __activeBrand } from '../recoil/Atoms/UserBrandAtom';

// TODO: refactor component and review logic, improve vars naming
const Team = () => {
  const [activeBrand] = useRecoilState(__activeBrand);
  const [option, setOption] = useState('SOCIAL_MEDIA_MANAGER');
  const [emailValue, setEmailValue] = useState('');
  const [modal, setModal] = useState(false);
  const toggle2 = () => setModal(!modal);

  const addTeamMemberFun = async () => {
    if (!activeBrand) return;

    setEmailValue('');
    toggle2();

    const data = {
      email: emailValue,
      role: option as TBrandUserRole,
      activeBrand
    };

    const response = await addTeamMember(data);

    if (response?.status == 200) {
      Swal.fire({
        icon: 'success',
        title: 'Team Member Added Successfully!!',
        showConfirmButton: false,
        timer: 1500
      }).then(() => {
        getTeamMemberFun();
      });
    }

    if (response?.status == 400) {
      Swal.fire({
        icon: 'error',
        title: `${response?.data?.details}`,
        showConfirmButton: false,
        timer: 1500
      });
    }

    if (response?.status == 500) {
      console.warn('sdfsf', response);
      Swal.fire({
        icon: 'error',
        title: `${response?.data?.details}`,
        showConfirmButton: false,
        timer: 1500
      });
    }
  };

  const getTeamMemberFun = async () => {
    if (!activeBrand) return;

    const response = await getTeamMember(activeBrand);
    // setTeamMembersList([...await response?.data?.workspace_users])
    // setOwnerData({...await response?.data?.owner[0]})
    // console.warn(response?.data?.owner)

    if (response?.status == 400) {
      Swal.fire({
        icon: 'error',
        title: `${response?.data?.detail}`,
        showConfirmButton: false,
        timer: 1500
      });
    }
  };

  useEffect(() => {
    getTeamMemberFun();
  }, []);

  return (
    <>
      <div className='team-wrapper'>
        <div className='comp-header'>
          <h2>Members</h2>
          <p>Current Workspace account</p>
        </div>
        <div className='team-list'>
          <div className='team-list-table'>
            <Table
              responsive
              className='table'>
              <thead>
                <tr>
                  <th scope='col'>Member Name</th>
                  <th scope='col'>Mail ID</th>
                  <th scope='col'>Role</th>
                  <th scope='col'>Status</th>
                  <th
                    scope='col'
                    align='right'></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className='member-name'>
                      <img
                        src={MemberImg}
                        alt='Member'
                        width='35px'
                      />
                      first_name last_name
                    </div>
                  </td>
                  <td> owner@email.com</td>
                  <td>Owner</td>
                  <td>Active</td>
                  <td align='right'></td>
                </tr>
                {[0, 4].map((v, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <div className='member-name'>
                          <img
                            src={MemberImg}
                            alt='Member'
                            width='35px'
                          />
                          atlas philips
                        </div>
                      </td>
                      <td>ownertest01@smk.com</td>
                      <td>Owner</td>
                      <td>Active</td>
                      <td align='right'>
                        {true ? (
                          <>
                            <span
                              className='dropdown-toggle btn'
                              data-bs-toggle='dropdown'>
                              <i className='bi bi-three-dots-vertical '></i>
                            </span>
                            <ul className='dropdown-menu'>
                              <li>
                                <a
                                  className='dropdown-item'
                                  onClick={() => {
                                    alert('clicked ' + v);
                                  }}>
                                  <i className='bi bi-person'></i> Update Role
                                </a>
                              </li>
                              <li>
                                <a
                                  className='dropdown-item'
                                  onClick={() => {
                                    alert('clicked ' + v);
                                  }}>
                                  <i className='bi bi-x-circle'></i> Remove
                                </a>
                              </li>
                            </ul>
                          </>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              setModal(!modal);
            }}>
            <i className='bi bi-plus'></i> Add member
          </button>

          {/* Add Account Modal */}
          <Modal
            centered
            size='lg'
            isOpen={modal}
            toggle={toggle2}>
            <ModalBody>
              <div className='modal-head'>
                <h4 id='addMemberModalLabel'>Invite Member</h4>
                <button
                  type='button'
                  className='btn-close'
                  onClick={() => {
                    setModal(!modal);
                  }}></button>
              </div>
              <div className='invite-member-body'>
                <div className='row'>
                  <div className='col-sm-6'>
                    <div className='mb-3'>
                      <label
                        htmlFor='exampleInputEmail1'
                        className='form-label'>
                        Email
                      </label>
                      <input
                        type='email'
                        value={emailValue}
                        onChange={(e) => {
                          setEmailValue(e.target.value);
                        }}
                        className='form-control'
                        id='exampleInputEmail1'
                      />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <div className='mb-3'>
                      <label
                        htmlFor='exampleInputEmail1'
                        className='form-label'>
                        Select Role
                      </label>
                      <select
                        value={option}
                        onChange={(e) => {
                          setOption(e.target.value);
                        }}
                        className='form-select'
                        aria-label='Select Role'>
                        <option value='SOCIAL_MEDIA_MANAGER'>
                          Social Media Manager
                        </option>
                        <option value='CONTENT_CREATOR'>Content Creator</option>
                        <option value='ADS_MANAGER'>Ads Manager</option>
                        <option value='ANALYST'>Analyst</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='invite-member-cta'>
                  <button
                    type='button'
                    onClick={() => {
                      addTeamMemberFun();
                    }}
                    className='btn btn-primary btn-sm'>
                    Invite
                  </button>
                  <button
                    type='button'
                    className='btn btn-outline-dark btn-sm'>
                    Cancel
                  </button>
                </div>
              </div>
              <div className='role-autorities'>
                <h5>Role Authorities*</h5>
                <div className='row'>
                  <div className='col-sm-3'>
                    <h6>Social Media Manager</h6>
                  </div>
                  <div className='col-sm-9'>
                    <p>
                      Porem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc vulputate libero et velit interdum, ac aliquet odio
                      mattis. Class aptent taciti sociosqu ad litora torquent
                      per conubia nostra, per inceptos himenaeos.
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-3'>
                    <h6>Content Creator</h6>
                  </div>
                  <div className='col-sm-9'>
                    <p>
                      Porem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc vulputate libero et velit interdum, ac aliquet odio
                      mattis. Class aptent taciti sociosqu ad litora torquent
                      per conubia nostra, per inceptos himenaeos.
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-3'>
                    <h6>Ads Manager</h6>
                  </div>
                  <div className='col-sm-9'>
                    <p>
                      Porem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc vulputate libero et velit interdum, ac aliquet odio
                      mattis. Class aptent taciti sociosqu ad litora torquent
                      per conubia nostra, per inceptos himenaeos.
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-3'>
                    <h6>Analyst</h6>
                  </div>
                  <div className='col-sm-9'>
                    <p>
                      Porem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc vulputate libero et velit interdum, ac aliquet odio
                      mattis. Class aptent taciti sociosqu ad litora torquent
                      per conubia nostra, per inceptos himenaeos.
                    </p>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Team;
