import React from 'react'
import {useState} from "react"
import './Content.css'

const Content = () => {
    const [selecteStatus, setSelectStatus] = useState("Spanish");
    const [selecteStatus2, setSelectStatus2] = useState("3");
    const [selecteStatus3, setSelectStatus3] = useState("Poland");
    const [selecteStatus4, setSelectStatus4] = useState("Category 3");

    const [formValue1, setFormValue1] = useState("");
    const [formValue2, setFormValue2] = useState("");
    const [formValue3, setFormValue3] = useState("");
    const [formValue4, setFormValue4] = useState("");
    const [formValue5, setFormValue5] = useState("");
    
    const saveQuerry = () => {
        console.warn("dfdfdfdfdfdfdfdf",formValue1,formValue2,formValue3,formValue4,formValue5,selecteStatus,selecteStatus2)
    }
    return (
        <>
            <div className="content">
                <div className="content-left">
                    <div className="content-filter card-style">
                        <div className="form-group">
                            <select onChange={(e) => { setSelectStatus3(e.target.value)}} value={selecteStatus3}  className="form-select country"  aria-label="Poland">
                                <option value="Australia">Australia</option>
                                <option value="Poland">Poland</option>
                                <option value="USA">USA</option>
                                <option value="UK">UK</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <select onChange={(e) => { setSelectStatus4(e.target.value)}} value={selecteStatus4}  className="form-select category"  aria-label="Category 1">
                                <option value="Category 1" >Category 1</option>
                                <option value="Category 2">Category 2</option>
                                <option value="Category 3">Category 3</option>
                                <option value="Category 4">Category 4</option>
                            </select>
                        </div>
                        <div className="filter-btn">
                            <button onClick={()=>{ }} type="button" className="btn btn-primary waves-effect waves-light">View</button>
                        </div>
                    </div>
                    <div className="card-style">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                    </div>
                </div>
                <div className="queries-box card-style">
                    <nav>
                        <div className="nav nav-tabs queries-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link tab active" id="nav-queries-tab" data-bs-toggle="tab" data-bs-target="#nav-queries" type="button" role="tab" aria-controls="nav-queries" aria-selected="true">Queries</button>
                            <button className="nav-link tab" id="nav-savedqueries-tab" data-bs-toggle="tab" data-bs-target="#nav-savedqueries" type="button" role="tab" aria-controls="nav-savedqueries" aria-selected="false">Saved Queries</button>
                        </div>
                    </nav>
                    <div className="tab-content queries-tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-queries" role="tabpanel" aria-labelledby="nav-queries-tab" tabIndex="0">
                            <div className="queires-form">
                                <div className='mb-3'>
                                    <input type="text" value={formValue1} onChange={(e)=>{setFormValue1(e.target.value)}} className="form-control" placeholder="Keywords" />
                                </div>
                                <div className='mb-3'>
                                    <input type="date" value={formValue2} onChange={(e)=>{setFormValue2(e.target.value)}} className="form-control" placeholder="Start Date" />
                                </div>
                                <div className='mb-3'>
                                    <input type="date" value={formValue3} onChange={(e)=>{setFormValue3(e.target.value)}} className="form-control" placeholder="End Date" />
                                </div>
                                <div className='mb-3'>
                                    <select  onChange={(e) => { setSelectStatus(e.target.value)}} value={selecteStatus} className="form-select" aria-label="Language">
                                        <option value="Polish">Polish</option>
                                        <option value="Frence">French</option>
                                        <option value="Spanish">Spanish</option>
                                        <option value="English">English</option>
                                        
                                    </select>
                                </div>
                                <div className='mb-3'>
                                    <select className="form-select" onChange={(e) => { setSelectStatus2(e.target.value)}} value={selecteStatus2} aria-label="Sort by">
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                    </select>
                                </div>
                                <div className='mb-3'>
                                    <input type="text" value={formValue4} onChange={(e)=>{setFormValue4(e.target.value)}} className="form-control" placeholder="Include Domains" />
                                </div>
                                <div className='mb-3'>
                                    <input type="text" value={formValue5} onChange={(e)=>{setFormValue5(e.target.value)}} className="form-control" placeholder="Exclude Domains" />
                                </div>
                                <div className="queries-btn">
                                    <button onClick={()=>{saveQuerry()}} type="submit" className="btn btn-primary waves-effect waves-light">Save Query</button>
                                    <button onClick={()=>{alert("Searched with "+formValue1+formValue2+formValue3+formValue4+formValue5+selecteStatus+selecteStatus2)}} type="submit" className="btn btn-primary waves-effect waves-light">Search</button>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-savedqueries" role="tabpanel" aria-labelledby="nav-savedqueries-tab" tabIndex="0">
                            <ul>
                                {[1,2,34,5,6].map((v,i)=> {
                                    return (
                                        // class needs to be defined
                                        <li key={i} className="querry-class">Querry {v}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Content
