import { TOauthProvider } from '../type';

export const OAUTH_FLOW_PROVIDER_QUERY_KEY = 'oauth_flow';
export const OAUTH_FLOW_STATUS_QUERY_KEY = 'status';

export enum OAUTH_FLOW_STATUS {
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error'
}

export const GOOGLE_OAUTH_CONFIG = {
  clientId: process.env.REACT_APP_GOOGLE_APP_ID,
  redirectUri: `${window.location.protocol}//${window.location.host}/socials`,
  responseType: 'code',
  accessType: 'offline',
  scope: 'https://www.googleapis.com/auth/analytics.readonly',
  includeGrantedScopes: true,
  state: getState('google')
};

const INSTAGRAM_OAUTH_SCOPE =
  'pages_read_user_content, pages_show_list, pages_manage_metadata, pages_manage_ads, business_management, read_insights, ads_read, pages_read_engagement, ads_management, instagram_basic, instagram_manage_insights';

export const INSTAGRAM_OAUTH_CONFIG = {
  clientId: process.env.REACT_APP_META_APP_ID,
  redirectUri: `${window.location.protocol}//${window.location.host}/socials/ig-business`,
  responseType: 'code',
  scope: INSTAGRAM_OAUTH_SCOPE,
  state: getState('ig-business')
};

export const FACEBOOK_PAGE_OAUTH_CONFIG = {
  clientId: process.env.REACT_APP_META_APP_ID,
  redirectUri: `${window.location.protocol}//${window.location.host}/socials/facebook-page`,
  responseType: 'code',
  scope: INSTAGRAM_OAUTH_SCOPE,
  state: getState('facebook-page')
};

export const META_OAUTH_CONFIG = {
  ...INSTAGRAM_OAUTH_CONFIG,
  redirectUri: `${window.location.protocol}//${window.location.host}/socials/meta-ads`,
  state: getState('meta-ads')
};

export const LINKEDIN_PAGE_OAUTH_CONFIG = {
  clientId: process.env.REACT_APP_LINKEDIN_APP_ID,
  redirectUri: `${window.location.protocol}//${window.location.host}/socials/linkedin-page`,
  responseType: 'code',
  scope:
    'r_ads_reporting r_organization_social rw_organization_admin w_member_social r_ads w_organization_social rw_ads r_basicprofile r_organization_admin r_1st_connections_size',
  state: getState('linkedin-page')
};

export const LINKEDIN_ADS_OAUTH_CONFIG = {
  ...LINKEDIN_PAGE_OAUTH_CONFIG,
  redirectUri: `${window.location.protocol}//${window.location.host}/socials/linkedin-ads`,
  state: getState('linkedin-ads')
};

function getState(oauthProvider: TOauthProvider) {
  const state = {
    [OAUTH_FLOW_PROVIDER_QUERY_KEY]: oauthProvider,
    [OAUTH_FLOW_STATUS_QUERY_KEY]: OAUTH_FLOW_STATUS.PENDING
  };

  return JSON.stringify(state);
}
