export const INITIAL_POST_TABLE_SUMMARY_DATA = {
  total_reach: 0,
  total_impressions: 0,
  total_interactions: 0
};

export const POST_TABLE_SUMMARY_DATA_KEYS_MAP = {
  total_reach: 'reach',
  total_impressions: 'impressions',
  total_interactions: 'total_interactions'
} as const;
