import { useRouteLoaderData } from 'react-router-dom';
import { PostAnalyticsTableView } from '../Posts/components/PostAnalyticsTable';
import { ROUTER_PATHS_ID } from 'src/lib/constants';
import { InstagramAnalyticsLoaderData } from '../models';
import { useTranslation } from 'react-i18next';
import { TNamespace } from 'src/i18n';
import { getReelsTableData } from './utils/getReelsTableData';
import { useMemo } from 'react';
import { POST_ANALYTICS_TABLE_COLS } from '../Posts/components/PostAnalyticsTable/constants';
import { ColDef } from 'ag-grid-community';

const reelsAnalyticsTableCols = POST_ANALYTICS_TABLE_COLS?.filter(
  (record) => (record as ColDef)?.field! !== 'impressions'
);

export default function ReelsAnalytics() {
  const { mediaInsights: data } = useRouteLoaderData(
    ROUTER_PATHS_ID.IG_BUSINESS_ANALYTICS_LAYOUT
  ) as InstagramAnalyticsLoaderData;
  const { t } = useTranslation<TNamespace>();

  const { cumulativeData, tableData } = useMemo(() => {
    const reels = data?.filter(
      ({ media_product_type }) => media_product_type == 'REELS'
    );

    return getReelsTableData(reels);
  }, [data]);

  return (
    <div
      className='w-100'
      id='reels-analytics'>
      <PostAnalyticsTableView
        tableData={tableData}
        tableTitle={
          t('list-of-reels', {
            ns: 'analytics'
          }) ?? ''
        }
        tableColumnsDefs={reelsAnalyticsTableCols}
        cumulativeData={cumulativeData}
      />
    </div>
  );
}
