import { ReactNode, Suspense } from 'react';
import { useRouteLoaderData, Await, useAsyncValue } from 'react-router-dom';
import '../Plan.css';
import SomateLogo from 'src/Assets/images/somate-logo-light.svg';
import { Spinner } from 'reactstrap';
import useGetPlansData from './hooks/useGetPlansData';
import { ROUTER_PATHS_ID } from 'src/lib/constants';
import { IUserSubscriptionPlan } from 'src/User/recoil/Atoms/UserSubscriptionAtom';
import { SubscriptionRestricted, SubscriptionSuccess } from './views';

const SubscriptionPlansContainer = () => {
  const { userSubscriptionPlan } = useRouteLoaderData(
    ROUTER_PATHS_ID.USER_SUBSCRIPTION_VALIDATION_LAYOUT
  ) as any;

  return (
    <Suspense
      fallback={
        <div className='tw-w-screen tw-h-screen tw-flex tw-gap-2 tw-justify-center tw-items-center'>
          <p>Checking your subscription</p>
          <Spinner color='dark'>Loading...</Spinner>
        </div>
      }>
      <Await
        resolve={userSubscriptionPlan}
        errorElement={
          <SubscriptionPlansLayout>
            <SubscriptionPlans />
          </SubscriptionPlansLayout>
        }>
        <UserSubscriptionPlanResponseRenderer />
      </Await>
    </Suspense>
  );
};

const SubscriptionPlans = () => {
  const plan = useGetPlansData();

  return plan.subscriptionPlansList.map((currentPlan, index) => {
    return (
      <div
        key={index}
        className='plan-itm'>
        <div className='plan-title'>
          <h4>{currentPlan.title}</h4>
        </div>
        <div className='plan-cost'>
          <h2>
            {plan.duration == 'Month'
              ? currentPlan.monthlyPrice
              : currentPlan.yearlyPrice}
            <small>/ {plan.duration}</small>
          </h2>
        </div>
        <h6 className='tagline'>{currentPlan.description}</h6>
        <div className='devider'></div>
        <div className='plan-features'>
          <ul>
            {currentPlan.featureList.map((feature, featureIndex) => {
              return (
                <li key={featureIndex}>
                  <span className='ic-check'>
                    <i className='bi bi-check-circle-fill'></i>
                  </span>
                  <span>{feature}</span>
                </li>
              );
            })}
          </ul>
        </div>
        <div className='plan-action'>
          <button
            type='button'
            disabled={plan.isSubmitting}
            className='btn btn-primary'
            onClick={() => {
              plan.select(currentPlan.key, currentPlan.stripePriceId);
            }}>
            {plan.isSubmitting && plan.selectedPlanKey == currentPlan.key ? (
              <Spinner color='light'>Loading...</Spinner>
            ) : (
              'Select Plan'
            )}
          </button>
        </div>
      </div>
    );
  });
};

const SubscriptionPlansLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className='plan-wrapper'>
      <div className='plan-logo_wrapper'>
        <img
          className='velzon_logo'
          src={SomateLogo}
          alt='SomateLogo'
        />
      </div>
      <div className='plan-header'>
        <h1>Select Your Plan</h1>
      </div>
      <div className='plan-inner'>{children}</div>
    </div>
  );
};

const UserSubscriptionPlanResponseRenderer = () => {
  const data = useAsyncValue() as IUserSubscriptionPlan;

  if (data.state == 'RESTRICTED') {
    return <SubscriptionRestricted />;
  }

  return <SubscriptionSuccess />;
};

export default SubscriptionPlansContainer;
