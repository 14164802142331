import { ColDef } from 'ag-grid-community';
import TableLink from '../../../components/TableLink';
import { OBJECTIVES_TABLE_COLS } from '../../Objectives/constants';
import { ICampaignsTableRow } from '../../../types';

export const CAMPAIGN_TABLE_COLS: ColDef<ICampaignsTableRow>[] = [
  {
    field: 'campaignId',
    headerName: 'Campaign',
    sortable: true,
    filter: true,
    cellRenderer: TableLink,
    pinned: 'left',
    minWidth: 300
  },
  {
    field: 'objective',
    headerName: 'Objective',
    sortable: true,
    filter: true
  },
  ...(OBJECTIVES_TABLE_COLS.slice(1) as ColDef[])
];
