import { IInstagramBusinessDailyInsight } from '../../../types';

const createHistoryTableData = (data: IInstagramBusinessDailyInsight[]) =>
  data
    .map((item) => ({
      date: item.date,
      followers_count: { value: item.followers },
      following_count: { value: item.following },
      media_count: { value: item.media },
      engagement_rate: { value: 'N/A' }
    }))
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

export default createHistoryTableData;
