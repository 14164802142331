import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { getReportTemplateById } from 'src/User/services/reporting';
import { USER_CONFIG } from 'src/configs';
import { LoaderFunctionArgs } from 'react-router-dom';

const getReportTemplateLoader = async ({ params }: LoaderFunctionArgs) => {
  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);

  const { data: reportTemplate } = await getReportTemplateById({
    brandId,
    templateId: params?.templateId ?? ''
  });

  return reportTemplate?.data;
};

export default getReportTemplateLoader;
