import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { login, profile } from 'src/User/services/auth';
import { USER_CONFIG } from 'src/configs';
import displayAxiosError from 'src/lib/utils/displayAxiosError';
import { useRecoilState } from 'recoil';
import userInfoState from 'src/User/recoil/Atoms/UserInforAtom';

const initialFormData = {
  email: '',
  password: ''
};

export default function useGetLoginData() {
  const [passwordVisible, setPasswordVisibile] = useState(false);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);

  const togglePasswordVisibility = () => {
    setPasswordVisibile((prevState) => !prevState);
  };

  const onSubmit = async (values: typeof initialFormData) => {
    localStorage.setItem(USER_CONFIG.storageUserSubscriptionKey, 'null');

    try {
      const { data } = await login(values);
      const { key: token } = data;
      localStorage.setItem(USER_CONFIG.storageTokenKeyName, token);

      Swal.fire({
        icon: 'success',
        title: 'Logged in',
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        toast: true,
        position: 'top-right'
      });

      await getUserInfo();
    } catch (error: any) {
      const { response } = error;
      const { data } = response ?? {};

      displayAxiosError(error, {
        title: data?.non_field_errors ?? 'Unable to login',
        fallbackMsg: data?.non_field_errors ? '' : 'Please contact support',
        toast: true,
        duration: 3000
      });
    }
  };

  const getUserInfo = async () => {
    try {
      const { data } = await profile();
      const { user_type } = data;

      if (user_type !== 'False') {
        setUserInfo(data);
        return navigate('/');
      }

      navigate('/user-type');
    } catch (error: any) {
      displayAxiosError(error, {
        title: 'Unable to get profile data',
        fallbackMsg: '',
        toast: true,
        duration: 3000
      });
    }
  };

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email.').required('Required'),
      password: Yup.string().required('Required')
    }),
    onSubmit
  });

  useEffect(() => {
    const token = localStorage.getItem(USER_CONFIG.storageTokenKeyName);
    const userType = userInfo?.user_type;

    localStorage.removeItem(USER_CONFIG.storageUserSubscriptionKey);

    const navigateHome = (userType: string) => {
      if (userType === 'STANDARD') {
        navigate('/');
      } else if (userType === 'MENTOR') {
        navigate('/mentor/');
      } else if (userType === 'SUPERADMIN') {
        navigate('/admin/');
      }
    };

    if (token && userType) {
      navigateHome(userType);
    }
  }, [navigate, userInfo?.user_type]);

  return {
    togglePasswordVisibility,
    passwordVisible,
    formik
  };
}
