import { useSetRecoilState, useResetRecoilState } from 'recoil';
import { userBrands, activeBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import userInfoState from 'src/User/recoil/Atoms/UserInforAtom';
import userSubscriptionPlan from 'src/User/recoil/Atoms/UserSubscriptionAtom';
import { USER_CONFIG } from 'src/configs';

export default function useClearAppState() {
  const setBrandsAtom = useSetRecoilState(userBrands);
  const setActiveBrandAtom = useSetRecoilState(activeBrand);
  const setUserSubscriptionAtom = useSetRecoilState(userSubscriptionPlan);
  const resetUserInfoState = useResetRecoilState(userInfoState);

  return () => {
    setBrandsAtom([]);
    setActiveBrandAtom(null);
    setUserSubscriptionAtom(null);
    resetUserInfoState();
    localStorage.removeItem(USER_CONFIG.storageTokenKeyName);
    localStorage.removeItem(USER_CONFIG.storageUserInfoKeyName);
    localStorage.removeItem(USER_CONFIG.storageUserSubscriptionKey);
    localStorage.removeItem(USER_CONFIG.storageUserBrand);
  };
}
