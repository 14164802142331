import UsrImg from 'src/Assets/images/workspace.jpg';
import FormDialog from 'src/User/components/FormDialog';
import { useBrand, usePopup } from '.';
import classNames from 'classnames';
import brandSelectStyles from './brand-select.module.css';
import TooltipButton from 'src/User/components/TooltipButton';
import { truncate } from 'lodash';

const BrandSelect = ({ collapse }: { collapse: boolean }) => {
  const { getAllBrands, changeActiveBrand, getCurrentBrand } = useBrand();
  const popup = usePopup();

  const brands = getAllBrands();
  const currentBrand = getCurrentBrand();

  return (
    <>
      <div
        className={classNames(brandSelectStyles['brand-menu'], {
          [brandSelectStyles['collapse-brand-menu']]: collapse
        })}>
        {brands.map((brand, index) => {
          return (
            <div key={index}>
              <TooltipButton
                tooltipContent={brand.name}
                placement='right'
                className={classNames(
                  'tw-flex tw-flex-col tw-items-center',
                  brandSelectStyles['brand-item'],
                  {
                    [brandSelectStyles['active-brand-item']]:
                      brand.id === currentBrand?.id
                  }
                )}
                onClick={() => changeActiveBrand(brand)}>
                <div className={brandSelectStyles['brand-item-avatar']}>
                  <img
                    src={UsrImg}
                    alt={`${brand.name} avatar in sidebar`}
                  />
                </div>
                <p className='!tw-mb-0'>
                  {truncate(brand.name, { length: 5, omission: '..' })}
                </p>
              </TooltipButton>
            </div>
          );
        })}
        <TooltipButton
          tooltipContent={'Create Brand'}
          placement='right'
          className={classNames(
            brandSelectStyles['brand-item'],
            brandSelectStyles['add-brand-btn']
          )}
          onClick={popup.createBrandInitiator}>
          <i className='bi bi-plus'></i>
        </TooltipButton>
      </div>
      {popup.content ? (
        <FormDialog
          isOpen={popup.visibility}
          buttonText={popup.content.dispatcherText ?? ''}
          popupTitle={popup.content.title ?? ''}
          onClose={popup.togglePopupVisibility}
          form={popup.content.form}
        />
      ) : null}
    </>
  );
};

export default BrandSelect;
