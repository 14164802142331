import { ROUTER_PATHS } from 'src/lib/constants';
import NewConversationView from '../../components/NewConversationView';
import { AGENTS, SUGGESTION_BY_AGENT } from '../../constants';
import { useParams } from 'react-router-dom';
import { TAgents } from '../../types';

const StartAgentConversation = () => {
  const { somateAiAgentId } = useParams();
  const agentMetadata = AGENTS.find(({ id }) => id == somateAiAgentId);
  const suggestions = SUGGESTION_BY_AGENT?.[agentMetadata?.id as TAgents] ?? [];

  return (
    <NewConversationView
      header={
        agentMetadata
          ? {
              title: agentMetadata.name,
              description: agentMetadata.description,
              icon: agentMetadata.icon
            }
          : undefined
      }
      actionPath={`${ROUTER_PATHS.SOMATE_AI_LAYOUT}/${ROUTER_PATHS.SOMATE_AI_NEW_CONVERSATION}`}
      suggestions={suggestions}
      suggestionsLayout='2x2'
    />
  );
};

export default StartAgentConversation;
