import { useRouteLoaderData } from 'react-router-dom';
import { ROUTER_PATHS_ID } from 'src/lib/constants';
import analyticsLayoutLoader from '../../layout/loaders/analyticsLayoutLoader';
import { PromiseType } from 'src/lib/models';
import { ANALYTICS_OAUTH_SOCIAL_ACCOUNT_PROVIDERS } from './constant';
import AnalyticsSocialAccountsRenderer from 'src/User/components/AnalyticsSocialAccountsRenderer';
import { DEFAULT_DATE_DURATION } from 'src/configs';
import addParamsToPathname from 'src/lib/utils/addParamsToPathname';

const AnalyticsIndexRoute = () => {
  const { socialAccountsSelectorListOptions } = useRouteLoaderData(
    ROUTER_PATHS_ID.ANALYTICS_LAYOUT
  ) as PromiseType<ReturnType<typeof analyticsLayoutLoader>>;

  return (
    <AnalyticsSocialAccountsRenderer
      type='analytics'
      connectedAccounts={socialAccountsSelectorListOptions?.map(
        ({ path, ...rest }) => ({
          href: addParamsToPathname(path, DEFAULT_DATE_DURATION),
          ...rest
        })
      )}
      accountProviders={ANALYTICS_OAUTH_SOCIAL_ACCOUNT_PROVIDERS}
    />
  );
};

export default AnalyticsIndexRoute;
