import styles from '../../components/index.module.css';
import classNames from 'classnames';
import i18next, { type TNamespace } from 'src/i18n';

export const AUDIT_UI_MAP = {
  follower_growth: {
    icon: (
      <div
        className={classNames(styles['icon-container'], styles['purple-icon'])}>
        <i className='bi bi-bar-chart-line-fill'></i>
      </div>
    ),
    description: i18next.t('Followers Growth Rate (90 Days)', {
      ns: 'competitionAnalysis' satisfies TNamespace
    })
  },
  steady_growth: {
    icon: (
      <div
        className={classNames(styles['icon-container'], styles['green-icon'])}>
        <i className='bi bi-reception-4'></i>
      </div>
    ),
    description: i18next.t('Steady Growth Factor', {
      ns: 'competitionAnalysis' satisfies TNamespace
    })
  },
  daily_follower: {
    icon: (
      <div
        className={classNames(styles['icon-container'], styles['orange-icon'])}>
        <i className='bi bi-people-fill'></i>
      </div>
    ),
    description: i18next.t('Daily Followers', {
      ns: 'competitionAnalysis' satisfies TNamespace
    })
  },
  weekly_follower: {
    icon: (
      <div className={classNames(styles['icon-container'], styles['red-icon'])}>
        <i className='bi bi-people-fill'></i>
      </div>
    ),
    description: i18next.t('Weekly Followers', {
      ns: 'competitionAnalysis' satisfies TNamespace
    })
  },
  monthly_followers: {
    icon: (
      <div
        className={classNames(styles['icon-container'], styles['blue-icon'])}>
        <i className='bi bi-people-fill'></i>
      </div>
    ),
    description: i18next.t('Monthly Followers', {
      ns: 'competitionAnalysis' satisfies TNamespace
    })
  },
  engagement_rate: {
    icon: (
      <div
        className={classNames(styles['icon-container'], styles['orange-icon'])}>
        <i className='bi bi-lightning-charge-fill'></i>
      </div>
    ),
    description: i18next.t('Engagement Rate', {
      ns: 'competitionAnalysis' satisfies TNamespace
    })
  },
  avg_like: {
    icon: (
      <div className={classNames(styles['icon-container'], styles['red-icon'])}>
        <i className='bi bi-heart-fill'></i>
      </div>
    ),
    description: i18next.t('Average Likes', {
      ns: 'competitionAnalysis' satisfies TNamespace
    })
  },
  weekly_engagement: {
    icon: (
      <div
        className={classNames(styles['icon-container'], styles['green-icon'])}>
        <i className='bi bi-lightning-charge-fill'></i>
      </div>
    ),
    description: i18next.t('Weekly Engagement Rate', {
      ns: 'competitionAnalysis' satisfies TNamespace
    })
  },
  weekly_post: {
    icon: (
      <div
        className={classNames(styles['icon-container'], styles['blue-icon'])}>
        <i className='bi bi-image-fill'></i>
      </div>
    ),
    description: i18next.t('Weekly Posts', {
      ns: 'competitionAnalysis' satisfies TNamespace
    })
  },
  avg_comments: {
    icon: (
      <div
        className={classNames(styles['icon-container'], styles['purple-icon'])}>
        <i className='bi bi-chat-left-fill'></i>
      </div>
    ),
    description: i18next.t('Average Comments', {
      ns: 'competitionAnalysis' satisfies TNamespace
    })
  },
  comments_ratio: {
    icon: (
      <div
        className={classNames(styles['icon-container'], styles['purple-icon'])}>
        <i className='bi bi-chat-left-fill'></i>
      </div>
    ),
    description: i18next.t('Comments Ratio', {
      ns: 'competitionAnalysis' satisfies TNamespace
    })
  }
};

export const AUDIT_DATA = {
  follower_growth: {
    maxValue: 220,
    value: 7.51,
    minValue: 0,
    avgValue: 56.18,
    rating: 'C'
  },
  steady_growth: {
    maxValue: 220,
    value: 42.46,
    minValue: 0,
    avgValue: 56.18,
    rating: 'B'
  },
  daily_follower: {
    maxValue: 220,
    value: 4,
    minValue: 0,
    avgValue: 56.18,
    rating: 'E'
  },
  weekly_follower: {
    maxValue: 220,
    value: 186,
    minValue: 0,
    avgValue: 56.18,
    rating: 'B+'
  },
  monthly_followers: {
    maxValue: 220,
    value: 122,
    minValue: 0,
    avgValue: 56.18,
    rating: 'B'
  },
  engagement_rate: {
    maxValue: 220,
    value: 1.01,
    minValue: 0,
    avgValue: 56.18,
    rating: 'D'
  },
  avg_like: {
    maxValue: 220,
    value: 220,
    minValue: 0,
    avgValue: 56.18,
    rating: 'A+'
  },
  weekly_engagement: {
    maxValue: 220,
    value: 0,
    minValue: 0,
    avgValue: 56.18,
    rating: 'E'
  },
  weekly_post: {
    maxValue: 220,
    value: 0,
    minValue: 0,
    avgValue: 56.18,
    rating: 'E'
  },
  avg_comments: {
    maxValue: 220,
    value: 24,
    minValue: 0,
    avgValue: 56.18,
    rating: 'C+'
  },
  comments_ratio: {
    maxValue: 220,
    value: 11.04,
    minValue: 0,
    avgValue: 56.18,
    rating: 'B'
  }
} as const;
