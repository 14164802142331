import { startCase, truncate } from 'lodash';
import { TABLE_COLS_HEADER_NAME_MAPPER } from '../constants';
import i18n, { TNamespace } from 'src/i18n';

const getTabelColLabel = (field: string, truncateLength?: number) => {
  return (
    TABLE_COLS_HEADER_NAME_MAPPER?.[
      field as keyof typeof TABLE_COLS_HEADER_NAME_MAPPER
    ] ??
    truncate(
      startCase(
        i18n.t(field, {
          ns: 'adAnalytics' satisfies TNamespace
        }) ?? field
      ),
      {
        length: truncateLength
      }
    )
  );
};

export default getTabelColLabel;
