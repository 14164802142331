import { Await, useLoaderData } from 'react-router-dom';
import MetricView from 'src/User/features/CompetitionAnalysis/views/Instagram/components/MetricsView';
import { pick } from 'lodash';
import { Suspense } from 'react';
import { IGetFacebookPageInsightOverviewResponse } from 'src/User/services/analytics/models';
import { AxiosResponse } from 'axios';
import { getSummaryData } from './utils/getSummaryData';
import getChartData, { isChartDataArg } from '../../utils/getChartData';
import ErrorRenderer from 'src/User/components/Error/AnalyticsErrorRenderer';
import { IFacebookPageInsightOverviewDailyDataRecord } from './types';
import { type TNamespace } from 'src/i18n';
import { useTranslation } from 'react-i18next';

const Container = () => {
  const { facebookPageInsightOverviewResponse } = useLoaderData() as any;
  const { t } = useTranslation<TNamespace>();

  return (
    <Suspense fallback={<MetricView.Loading chartGridFlow='column' />}>
      <Await
        resolve={facebookPageInsightOverviewResponse}
        errorElement={
          <ErrorRenderer
            title={t('error.title', {
              ns: 'analytics',
              accountType: 'facebook page',
              feature: 'overview'
            })}
            fallbackErrorMsg={t('error.description', {
              ns: 'analytics'
            })}
            hideConnectAccount
          />
        }>
        {(response: AxiosResponse<IGetFacebookPageInsightOverviewResponse>) => (
          <Overview {...response.data} />
        )}
      </Await>
    </Suspense>
  );
};

interface OverviewProps extends IGetFacebookPageInsightOverviewResponse {}

const Overview = (props: OverviewProps) => {
  const { data } = props;

  const metricViewSummary = pick(data, [
    'fan_count',
    'fans_acquired',
    'fans_lost',
    'followers_count',
    'impressions',
    'page_actions_total_post_reactions',
    'views'
  ]);

  const chartMetrics = [
    'followers',
    'page_actions_total_post_reactions',
    'impressions',
    'views',
    'fans_acquired',
    'fans_lost'
  ] satisfies Exclude<
    keyof IFacebookPageInsightOverviewDailyDataRecord,
    'date'
  >[];

  const unformattedChartData = isChartDataArg(data.daily) ? data.daily : [];

  return (
    <div className='-tw-mt-7'>
      <MetricView
        chart={getChartData(unformattedChartData, chartMetrics)}
        metricsData={getSummaryData(metricViewSummary)}
        chartGridFlow='column'
      />
    </div>
  );
};

export default Container;
