import { Link } from 'react-router-dom';
import PatternRight from 'src/Assets/images/pattern-right.png';
import SOMateLogo from 'src/Assets/images/somate-logo.svg';
import useValidateCode from './hooks/useValidateCode';
import styles from './view.module.css';
import classNames from 'classnames';
import FormDialog from 'src/User/components/FormDialog';
import useResendVerificationCode from './hooks/useResendVerificationCode';

export default function EmailVerificationView() {
  useValidateCode();
  const popupData = useResendVerificationCode();

  return (
    <div
      style={{ outline: 'none' }}
      className='authentication-form-container'>
      <div
        style={{ opacity: '1' }}
        className='logo_wrapper'>
        <img
          className='somate_logo'
          src={SOMateLogo}
          alt='SOMateLogo'
        />
      </div>
      <div className='d-flex flex-column gap-5'>
        <div className='d-flex flex-column'>
          <h4 className='mb-4 signup-form-title'>
            Verification in progress...
          </h4>
          <p>
            <span className={styles['description-text']}>
              Unable to verify?{' '}
            </span>
            <button
              type='button'
              className={classNames(
                'border-0 bg-transparent text-decoration-underline',
                styles['link']
              )}
              onClick={popupData.togglePopupVisibility}>
              Resend link
            </button>
          </p>
        </div>
        <div className='d-flex gap-4'>
          <Link
            to='/login'
            className={styles['link']}>
            Login
          </Link>
          <Link
            to='/register'
            className={styles['link']}>
            Sign up
          </Link>
        </div>
        <FormDialog
          isOpen={popupData.popupVisibility}
          buttonText={'Send link'}
          popupTitle={'Resend verification link'}
          onClose={popupData.togglePopupVisibility}
          form={popupData.form}
        />
      </div>
      <img
        className='pattern_right'
        src={PatternRight}
        alt='pattern_right'
        style={{ zIndex: -1 }}
      />
    </div>
  );
}
