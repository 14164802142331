import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { USER_CONFIG } from 'src/configs';
import { LoaderFunctionArgs } from 'react-router-dom';
import { getAllAdAccountsInBrandByMetaId } from 'src/User/services/meta-ads';

const connectMetaAdAccountsLoader = async ({ params }: LoaderFunctionArgs) => {
  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);
  const { metaId } = params;

  const adsAccountResponse = await getAllAdAccountsInBrandByMetaId({
    brandId,
    metaId: metaId as string
  });
  const { data: adsAccountsByMetaId } = adsAccountResponse;

  return adsAccountsByMetaId.data;
};

export default connectMetaAdAccountsLoader;
