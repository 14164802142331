// dependencies
import React from 'react';
import { Form } from 'reactstrap';
import { Link } from 'react-router-dom';

// images
import PatternRight from 'src/Assets/images/pattern-right.png';
import SOMateLogo from 'src/Assets/images/somate-logo.svg';

// Hooks
import useGetLoginData from './hooks/useGetLoginData';

// TODO: Refactor Login page, rethink the entire component structure and logic
const Login = () => {
  const { formik, passwordVisible, togglePasswordVisibility } =
    useGetLoginData();
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    isSubmitting
  } = formik;

  return (
    <React.Fragment>
      <div className='authentication-form-container'>
        <div className='logo_wrapper'>
          <img
            className='somate_logo'
            src={SOMateLogo}
            alt='SOMateLogo'
          />
        </div>
        <div className='login_form'>
          <div>
            <h4 className='signup-form-title mb-4'>Welcome Back !</h4>
            {/* <p>Sign in to continue to SoMate.app</p> */}
          </div>

          <Form
            className='signup-form-container'
            onSubmit={handleSubmit}>
            <fieldset className='mb-3'>
              <label
                htmlFor='email'
                className='form-label'>
                Email
              </label>
              <input
                className='form-control'
                id='email'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                style={{
                  border:
                    errors.email && touched.email ? '1px solid red' : undefined
                }}
                name='email'
                placeholder='example@xyz.com'
                type='email'
              />
              {errors.email && touched.email ? (
                <div
                  style={{
                    color: 'red',
                    maxWidth: '363px',
                    fontSize: '12px'
                  }}>
                  {errors.email}
                </div>
              ) : null}
            </fieldset>

            <fieldset className='mb-3 forgot-pass-wrapper'>
              <label
                htmlFor='password'
                className='form-label'>
                Password
              </label>
              <input
                className='form-control'
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                style={{
                  border:
                    errors.password && touched.password
                      ? '1px solid red'
                      : undefined
                }}
                type={passwordVisible ? 'text' : 'password'}
                id='password'
                name='password'
              />
              <span
                onClick={togglePasswordVisibility}
                className='show_password'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-eye-fill'
                  viewBox='0 0 16 16'>
                  <path d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z' />
                  <path d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z' />
                </svg>
              </span>
              <Link
                to='/forgot_password'
                className='forgot-password'>
                Forgot Password?
              </Link>
              {errors.password && touched.password ? (
                <div
                  style={{
                    color: 'red',
                    maxWidth: '363px',
                    fontSize: '12px'
                  }}>
                  {errors.password}
                </div>
              ) : null}
            </fieldset>

            {/* <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="remember"
                      onChange={handleRememberCheck}
                    />
                    <label className="form-check-label" htmlFor="remember">
                      Remember me
                    </label>
                  </div> */}

            <button
              className='my-3 btn btn-primary waves-effect waves-light'
              color='primary'
              disabled={isSubmitting}
              type='submit'>
              {!isSubmitting ? (
                'Sign in'
              ) : (
                <div
                  className='spinner-border text-light'
                  role='status'>
                  <span className='sr-only'></span>
                </div>
              )}
            </button>

            {/* <div className='other_sign_options'>
                    <h5>Sign In with</h5>
                    <div className='sign_options'>
                      <a
                        href={process.env.REACT_APP_FACEBOOK}
                        target='_blank'
                        className='sign_option'>
                        <img
                          alt=''
                          src={FacebookIc}
                        />
                      </a>
                      <a
                        href={process.env.REACT_APP_GOOGLE}
                        target='_blank'
                        className='sign_option'>
                        <img
                          alt=''
                          src={GoogleIc}
                        />
                      </a>
                      <a
                        href={process.env.REACT_APP_INSTAGRAM}
                        target='_blank'
                        className='sign_option'>
                        <img
                          alt=''
                          src={InstaIc}
                        />
                      </a>
                      <a
                        href={process.env.REACT_APP_GITHUB}
                        target='_blank'
                        className='sign_option'>
                        <img
                          alt=''
                          src={GithubIc}
                        />
                      </a>
                      <a
                        href={process.env.REACT_APP_TWITTER_LINK}
                        target='_blank'
                        className='sign_option'>
                        <img
                          alt=''
                          src={TwiterIc}
                        />
                      </a>
                    </div>
                  </div> */}

            <h4 className='signup-form-link'>
              Don&apos;t have an account ?{' '}
              <Link
                className='social_link'
                to='/register'>
                Sign Up
              </Link>
            </h4>
          </Form>
        </div>
        <img
          className='pattern_right'
          src={PatternRight}
          alt='pattern_right'
        />
      </div>
    </React.Fragment>
  );
};

export default Login;
