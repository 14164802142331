import Api from 'src/Api';
import {
  ICreateReportResponse,
  ICreateReportTemplateRequest,
  ICreateReportTemplateResponse,
  IGetReportTemplatesByBrandResponse,
  IGetReportsByBrandResponse,
  IUpdateReportTemplateProps,
  IUploadImagesToReportTemplateProps
} from './models';
import { ICreateReportRequest } from 'src/User/features/Reporting/actions/model';

export const getReportsByBrand = (brandId: string) =>
  Api.get<IGetReportsByBrandResponse>(`/reporting/?brand_id=${brandId}`);

export const getReportTemplatesByBrand = (brandId: string) =>
  Api.get<IGetReportTemplatesByBrandResponse>(
    `/reporting/templates/?brand_id=${brandId}`
  );

export const getReportTemplateById = (props: {
  templateId: string;
  brandId: string;
}) => {
  return Api.get<Pick<IGetReportTemplatesByBrandResponse, 'data'>>(
    `reporting/templates/?brand_id=${props.brandId}&report_template_id=${props.templateId}`
  );
};

export const createReport = (props: ICreateReportRequest) => {
  const { brandId, endDate, startDate, templateId, language, reportType } =
    props;

  return Api.post<ICreateReportResponse>(`/reporting/${reportType}/`, {
    brand_id: brandId,
    report_template_id: templateId,
    start_date: startDate,
    end_date: endDate,
    language
  });
};

export const createReportTemplate = (props: ICreateReportTemplateRequest) => {
  return Api.post<ICreateReportTemplateResponse>('/reporting/templates/', {
    brand_id: props.brand_id,
    colors: props.colors,
    cover_title: props.cover_title,
    name: props.name,
    social_media_sections: props.social_media_sections,
    summary_section_pages: props.summary_section_pages
  });
};

export const updateReportTempate = (props: IUpdateReportTemplateProps) => {
  return Api.patch<ICreateReportTemplateResponse>(
    `/reporting/templates/${props.reportTemplateId}/`,
    props
  );
};

export const uploadImagesToReportTemplate = (
  props: IUploadImagesToReportTemplateProps
) => {
  const { reportTemplateId, ...payload } = props;

  return Api.post<ICreateReportTemplateResponse>(
    `/reporting/templates/${reportTemplateId}/images/`,
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const deleteReportTemplate = (reportTemplateId: string) => {
  return Api.delete(`/reporting/templates/${reportTemplateId}/`);
};
