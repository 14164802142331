import ReactRouterProvider from './ReactRouterProvider';
import RecoilProvider from './RecoilProvider';

const Providers = () => {
  return (
    <RecoilProvider>
      <ReactRouterProvider />
    </RecoilProvider>
  );
};

export default Providers;
