import classNames from 'classnames';
import { startCase } from 'lodash';
import styles from './chartHeader.module.css';
import { Button } from 'src/components/ui/button';
import {
  IChartHeaderFilterOption,
  TChartHeaderFilterOptionValue
} from '../../types';

export interface IChartHeaderFiltersProps {
  data: IChartHeaderFilterOption;
  theme?: {
    colors: string[];
  };
  preventDefaultFormatter?: boolean;
  onFilterOptionClick?: (filterKey: string) => void;
  className?: string;
}

export default function ChartHeaderFilters(props: IChartHeaderFiltersProps) {
  const { data, preventDefaultFormatter, className, onFilterOptionClick } =
    props;

  return (
    <div
      className={classNames('tw-flex tw-p-3 tw-gap-4 tw-flex-wrap', className)}>
      {Object.entries(data).map(([key, filterOption], index) => {
        const { isOptionActive, optionBgColor, optionValue } =
          getFilterOptionData(filterOption);
        const isClickableOption = typeof filterOption !== 'number';

        return (
          <Button
            key={index}
            variant={'ghost'}
            className={classNames('tw-w-fit tw-h-fit !tw-p-0', {
              '!tw-cursor-default': typeof onFilterOptionClick == 'undefined'
            })}
            onClick={() => onFilterOptionClick?.(key)}>
            <div
              className={classNames(
                'p-3 rounded d-flex flex-column-reverse justify-content-between gap-1 tw-relative tw-group',
                styles.engagementCardItem,
                {
                  '!tw-bg-gray-100 hover:!tw-bg-primary-beige-light':
                    !isOptionActive,
                  '!tw-bg-primary': isOptionActive && isClickableOption
                }
              )}
              style={
                optionBgColor ? { backgroundColor: optionBgColor } : undefined
              }>
              <div
                className={classNames(
                  'tw-bg-black tw-w-full tw-h-full tw-absolute tw-opacity-5 tw-left-0 tw-top-0 tw-rounded group-hover:tw-opacity-0',
                  { 'tw-hidden': isOptionActive }
                )}
              />

              <h5
                className={classNames(
                  'd-flex align-items-end gap-1',
                  styles.engagementCardItemLabel,
                  {
                    '!tw-text-white': optionBgColor,
                    '!tw-text-primary-beige-light':
                      isOptionActive && isClickableOption,
                    '!tw-text-slate-500 group-hover:!tw-text-slate-700':
                      !isOptionActive
                  }
                )}>
                {preventDefaultFormatter ? key : startCase(key)}{' '}
              </h5>

              <h2
                className={classNames(styles.engagementCardItemValue, {
                  '!tw-text-white':
                    optionBgColor || (isOptionActive && isClickableOption),
                  '!tw-text-slate-600 group-hover:!tw-text-primary-black':
                    !isOptionActive
                })}>
                {optionValue ?? 0}
              </h2>
            </div>
          </Button>
        );
      })}
    </div>
  );
}

const getFilterOptionData = (filterOption: TChartHeaderFilterOptionValue) => {
  if (typeof filterOption == 'number') {
    return {
      isOptionActive: true,
      optionValue: filterOption,
      optionBgColor: undefined
    };
  }

  return {
    isOptionActive: filterOption?.isActive,
    optionValue: filterOption?.value,
    optionBgColor: filterOption?.bgColor
  };
};
