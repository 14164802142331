import { isEmpty } from 'lodash';
import { IAdCreative, MetaMarketingInsights } from '../../../types';
import { ITableData, TTableInsightColumnsData } from '../type';
import aggregateMetricInsights from 'src/lib/utils/aggregateMetricsInsights';

const createTableData = (adsData: IAdCreative[]): ITableData[] => {
  const insights = adsData?.map(
    ({ insights, name, ad_id, permalink, thumbnail_url }) => ({
      ...insights,
      name,
      ad_id,
      permalink,
      thumbnail_url
    })
  );

  return _createTableData(insights);
};

type TCreateTableDataFuncRecord = IAdCreative['insights'] & {
  name: string;
  ad_id: string;
  permalink: string | null;
  thumbnail_url: string | null;
};

const _createTableData = (insights: TCreateTableDataFuncRecord[]) => {
  return insights.map(
    ({
      summary: insight,
      ad_id,
      name,
      permalink,
      thumbnail_url,
      audience_network,
      facebook,
      instagram,
      messenger
    }) => {
      const subCategories = {
        audience_network,
        facebook,
        instagram,
        messenger
      };

      const optimizationGoal = insight?.optimization_goal ?? 'N/A';

      return {
        key: ad_id,
        name: name,
        permalink: permalink ?? undefined,
        thumbnail_url: thumbnail_url ?? undefined,
        ..._formatInsightKeys(insight),
        sub_categorie: Object.entries(subCategories)
          .filter(([, items]) => Array.isArray(items))
          .map(([key, items]) => ({
            key,
            name: key,
            permalink: permalink ?? undefined,
            thumbnail_url: thumbnail_url ?? undefined,
            ..._aggregateInsights(items, optimizationGoal),
            sub_categorie:
              items?.map((item) => ({
                ..._formatInsightKeys({
                  ...item,
                  optimization_goal: optimizationGoal
                }),
                key: ad_id,
                name: item.placement ?? 'N/A',
                permalink: permalink ?? undefined,
                thumbnail_url: thumbnail_url ?? undefined,
                sub_categorie: []
              })) ?? []
          }))
      };
    }
  );
};

const _aggregateInsights = (
  insights?: MetaMarketingInsights[],
  optimizationGoal?: string
) => {
  if (isEmpty(insights)) return INITIAL_AGGREAGATE_INSIGHT_DATA;

  const formattedInsights = insights?.map(_formatInsightKeys);
  const aggregatedInsights = Object.keys(
    INITIAL_AGGREAGATE_INSIGHT_DATA
  ).reduce(
    (acc, key) => {
      const metric = key as keyof TTableInsightColumnsData;

      if (metric === 'optimization_goal') {
        acc[metric] = optimizationGoal ?? 'N/A';
        return acc;
      }

      const metricInsightValues =
        formattedInsights
          ?.filter?.((item) => !!item?.[metric] && item?.[metric] !== 'N/A')
          .map((item) => item?.[metric]) ?? [];
      const aggregatedValue = aggregateMetricInsights(
        metric,
        metricInsightValues
      );

      acc[metric] = Number.isNaN(aggregatedValue) ? 'N/A' : aggregatedValue;

      return acc;
    },
    { ...INITIAL_AGGREAGATE_INSIGHT_DATA }
  );

  return aggregatedInsights;
};

const _formatInsightKeys = (insight: MetaMarketingInsights) => {
  const videoWatched25Percent =
    insight?.['video_p25_watched_actions.video_view'];
  const videoWatched50Percent =
    insight?.['video_p50_watched_actions.video_view'];
  const videoWatched75Percent =
    insight?.['video_p75_watched_actions.video_view'];
  const videoWatched95Percent =
    insight?.['video_p95_watched_actions.video_view'];
  const videoWatched100Percent =
    insight?.['video_p100_watched_actions.video_view'];
  // const costPerAction = insight?.cost_per_action_type
  // const purchaseROAS = insight?.purchase_roas;
  // const purchase = insight?.actions?.filter(
  //   ({ action_type }) =>
  //     action_type === 'purchase' || action_type === 'omni_purchase'
  // );

  return {
    optimization_goal: insight?.optimization_goal ?? 'N/A',
    cpc: insight?.cpc ?? 'N/A',
    cpm: insight?.cpm ?? 'N/A',
    cpp: insight?.cpp ?? 'N/A',
    ctr: insight?.ctr ?? 'N/A',
    reach: insight?.reach ?? 'N/A',
    spend: insight?.spend ?? 'N/A',
    // cost_per_action: costPerAction
    //   ? cumulateInsightActions(costPerAction)
    //   : 'N/A',
    // purchase_roas: purchaseROAS
    //   ? cumulateInsightActions(purchaseROAS)
    //   : 'N/A',
    // purchase:
    //   purchase && !isEmpty(purchase)
    //     ? cumulateInsightActions(purchase)
    //     : 'N/A',
    clicks: insight?.clicks ?? 'N/A',
    video_p25_watched: videoWatched25Percent ?? 'N/A',
    video_p50_watched: videoWatched50Percent ?? 'N/A',
    video_p75_watched: videoWatched75Percent ?? 'N/A',
    video_p95_watched: videoWatched95Percent ?? 'N/A',
    video_p100_watched: videoWatched100Percent ?? 'N/A'
  };
};

// const cumulateInsightActions = (actions: MetaMarketingInsightAction[]) => {
//   return actions?.reduce((acc, { value }) => acc + (Number(value) || 0), 0);
// };

const INITIAL_AGGREAGATE_INSIGHT_DATA: TTableInsightColumnsData = {
  optimization_goal: 'N/A',
  cpc: 'N/A',
  cpm: 'N/A',
  cpp: 'N/A',
  ctr: 'N/A',
  reach: 'N/A',
  spend: 'N/A',
  clicks: 'N/A',
  video_p25_watched: 'N/A',
  video_p50_watched: 'N/A',
  video_p75_watched: 'N/A',
  video_p95_watched: 'N/A',
  video_p100_watched: 'N/A'
};

export default createTableData;
