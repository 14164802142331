import { TColumDef } from 'src/User/features/Viz/components/types';
import AccountTypeCellRenderer from '../components/AccountTypeCellRenderer';
import { ITableRowData } from '../models';
import formatCellValueToAmount from 'src/User/features/Viz/components/Table/utils/formatCellValue';

export const ALL_SOCIALS_TABLE_COLS_DEFS: TColumDef<ITableRowData> = [
  {
    field: 'name',
    headerName: 'Account Name',
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    },
    autoHeight: true,
    sortable: true,
    flex: 1,
    minWidth: 250
  },
  {
    field: 'accountType',
    headerName: 'Account Type',
    cellRenderer: AccountTypeCellRenderer,
    autoHeight: true,
    sortable: true,
    flex: 1,
    minWidth: 200
  },
  {
    field: 'followers',
    headerName: 'Followers',
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    },
    autoHeight: true,
    sortable: true,
    flex: 1,
    minWidth: 150
  },
  {
    field: 'amountSpent',
    headerName: 'Amount Spent',
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    },
    valueFormatter: ({ value, data }) => {
      const currency =
        !!data?.currency && data?.currency !== 'N/A'
          ? data?.currency
          : undefined;

      return formatCellValueToAmount(value ?? 'N/A', currency);
    },
    autoHeight: true,
    sortable: true,
    flex: 1,
    minWidth: 150
  }
];
