import { ROUTER_PATHS } from 'src/lib/constants';
import NewSomateAIConversationView from '../../components/NewConversationView';
import { SUGGESTION_BY_AGENT } from '../../constants';

const StartGenericConversation = () => (
  <NewSomateAIConversationView
    actionPath={`${ROUTER_PATHS.SOMATE_AI_LAYOUT}/${ROUTER_PATHS.SOMATE_AI_NEW_CONVERSATION}`}
    suggestions={SUGGESTION_BY_AGENT['general-purpose-agent']}
  />
);

export default StartGenericConversation;
