import styles from '../../components/index.module.css';
import classNames from 'classnames';

export const METRICS_DATA = [
  {
    icon: (
      <div
        className={classNames(styles['icon-container'], styles['purple-icon'])}>
        <i className='bi bi-bar-chart-line-fill'></i>
      </div>
    ),
    title: '7.51%',
    description: 'Followers Growth Rate (90 Days)'
  },
  {
    icon: (
      <div
        className={classNames(styles['icon-container'], styles['green-icon'])}>
        <i className='bi bi-reception-4'></i>
      </div>
    ),
    title: '42.46%',
    description: 'Steady Growth Factor'
  },
  {
    icon: (
      <div
        className={classNames(styles['icon-container'], styles['orange-icon'])}>
        <i className='bi bi-people-fill'></i>
      </div>
    ),
    title: '4',
    description: 'Daily Followers'
  },
  {
    icon: (
      <div className={classNames(styles['icon-container'], styles['red-icon'])}>
        <i className='bi bi-people-fill'></i>
      </div>
    ),
    title: '186',
    description: 'Weekly Followers'
  },
  {
    icon: (
      <div
        className={classNames(styles['icon-container'], styles['blue-icon'])}>
        <i className='bi bi-people-fill'></i>
      </div>
    ),
    title: '122',
    description: 'Monthly Followers'
  }
];
