import { ReactNode, useState } from 'react';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';
import styles from './index.module.css';

interface IVertialCollapseProps
  extends Omit<IControlledVerticalCollapseProps, 'toggleCollapse' | 'open'> {}

export default function VerticalCollapse(porps: IVertialCollapseProps) {
  const { title, children, onOpen, onOpening } = porps;

  const [collapse, setCollapse] = useState(false);

  const toggleCollapse = () => setCollapse((prev) => !prev);

  return (
    <VerticalCollapse.ControlledVerticalCollapse
      title={title}
      collapse={collapse}
      toggleCollapse={toggleCollapse}
      onOpen={onOpen}
      onOpening={onOpening}>
      {children}
    </VerticalCollapse.ControlledVerticalCollapse>
  );
}

interface IControlledVerticalCollapseProps {
  toggleCollapse: () => void;
  title: string;
  children: ReactNode;
  collapse: boolean;
  onOpen?: () => void;
  onOpening?: () => void;
}

VerticalCollapse.ControlledVerticalCollapse = (
  props: IControlledVerticalCollapseProps
) => {
  const { children, title, collapse, toggleCollapse, onOpen, onOpening } =
    props;

  return (
    <div className='w-100 d-flex flex-column gap-3'>
      <VerticalCollapse.Header
        title={title}
        toggleCollapse={toggleCollapse}
        collapse={collapse}
      />
      <Collapse
        isOpen={collapse}
        onEntering={() => {
          onOpening?.();
        }}
        onEntered={() => {
          onOpen?.();
        }}
        className='px-2'>
        {children}
      </Collapse>
    </div>
  );
};

VerticalCollapse.Header = (props: {
  toggleCollapse: () => void;
  title: string;
  collapse: boolean;
}) => {
  const { toggleCollapse, title, collapse } = props;

  return (
    <div
      className={classNames(
        'w-100 d-flex justify-content-between rounded',
        styles['collapse-btn']
      )}
      onClick={toggleCollapse}>
      <p className='m-0'>{title}</p>
      <i
        className={classNames('bi bi-chevron-double-down', {
          [styles['rotate-180']]: !collapse,
          [styles['rotate-0']]: collapse
        })}></i>
    </div>
  );
};
