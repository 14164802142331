import { startCase, sum } from 'lodash';
import { facebookPageDailyInsightTitleKeyMapper } from '../../../constants';
import { format } from 'date-fns';

export const getSeriesData = <T extends { date: string }>(
  data: T[],
  preventDefaultFormatter?: boolean
) => {
  const keys = Object.keys(data?.[0] ?? {}).filter((key) => key !== 'date');

  return keys.map((key) => {
    return {
      name: preventDefaultFormatter
        ? key
        : facebookPageDailyInsightTitleKeyMapper?.[
            key as keyof typeof facebookPageDailyInsightTitleKeyMapper
          ] ?? startCase(key),
      data: data?.map((item) => Number(item[key as keyof typeof item]))
    };
  });
};

export const getChartFilterOptions = (
  seriesData: {
    name: string;
    data: number[];
  }[]
) => {
  return seriesData.reduce(
    (acc, { name, data }) => {
      return {
        ...acc,
        [name]: Number(sum(data).toFixed(2))
      };
    },
    {} as Record<string, number>
  );
};

export const getChartOptions = (dates: string[]): ApexCharts.ApexOptions => {
  return {
    chart: {
      stacked: true
    },
    xaxis: {
      type: 'datetime' as const,
      categories: dates,
      labels: {
        formatter: (value: string) => format(new Date(value), 'dd/MM/yyyy')
      }
    },
    legend: {
      show: true,
      position: 'top'
    },
    tooltip: {
      shared: true,
      followCursor: true
    }
  };
};
