import React from 'react'
import './AddAcocuntModal.css'
import FacebookCircle from "../../Assets/images/facebook-circle.svg";
import InstaCircle from "../../Assets/images/insta-circle.svg";
import TwiterCircle from "../../Assets/images/twiter-circle.svg";
import DirectionIc from "../../Assets/images/direction.svg"
import LocationIc from "../../Assets/images/location.svg"
import EditIc from "../../Assets/images/edit.svg"
import ImageIc from "../../Assets/images/image.svg"
import ChatIc from "../../Assets/images/chat.svg"
import ShareIc from "../../Assets/images/share.svg"
import DeleteIc from "../../Assets/images/delete.svg"
import TimingIc from "../../Assets/images/time-laps.svg"
import AccountThumb from "../../Assets/images/profile-img.png"
import AccountThumb2 from "../../Assets/images/workspace.jpg"
import ActiveCheck from "../../Assets/images/active-check-green.svg"

const AddAccountModal = (props) => {
    return (
        <>
            <div className="modal-head">
                <button type="button" className="btn-close" onClick={() => { props.setModal(!props.modal) }}></button>
                <div className="account-for-new-event">
                    <div className="account-wrapper">
                        <div className="account-thumb">
                            <img src={AccountThumb} alt='account' />
                        </div>
                        <div className="accout-type">
                            <img src={FacebookCircle} alt='facebook' />
                        </div>
                    </div>
                    <div className="account-wrapper account-active">
                        <div className="account-thumb">
                            <img src={AccountThumb2} alt='account' />
                        </div>
                        <div className="accout-type">
                            <img src={InstaCircle} alt='Insta' />
                        </div>
                        <div className="account-active-check">
                            <img src={ActiveCheck} alt='active' />
                        </div>
                    </div>
                </div>
            </div>
            <div className="post-area">
                <div className="create-post-inner">
                    <div className="post-type-wrapper">
                        <div className="post-type">
                            <div className="account-img">
                                <img src={InstaCircle} alt='InstaCircle' />
                            </div>
                            <span className='badges badge-active'>Post</span>
                            <span className='badges'>Reel</span>
                        </div>
                        <div className="select-post-type">
                            <select>
                                <option>Instagram</option>
                                <option>Facebook</option>
                                <option>Linkedin</option>
                                <option>Twiter</option>
                                <option>Pinterest</option>
                            </select>
                        </div>
                    </div>
                    <div className="enter-post-details">
                        <textarea type="text" className='form-control' alt="" placeholder='Description' rows="4" />
                        <div className="editor-row">
                            <div className="action-left">
                                <button type="button" className="btn btn-outline-primary waves-effect waves-light mr-1">#Hashtag</button>
                                <button type="button" className="btn btn-outline-primary waves-effect waves-light">Edit Image</button>
                            </div>
                            <div className="action-right">
                                <select className="font-siz">
                                    <option>6</option>
                                    <option>8</option>
                                    <option>10</option>
                                    <option>12</option>
                                    <option>14</option>
                                    <option>16</option>
                                </select>
                                <span className="font-bold">B</span>
                                <span className="font-italic">/</span>
                                <span className="emoji">🙂</span>
                            </div>
                        </div>
                        <button type="button" className="btn btn-primary waves-effect waves-light w-100">Click or Drag & Drop media</button>
                    </div>
                    <div className="feed-actions">
                        <div className="feed-action-list">
                            <ul>
                                <li>
                                    <img src={DirectionIc} alt='Direction' />
                                </li>
                                <li>
                                    <img src={LocationIc} alt='Location' />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src={EditIc} alt='Edit' />
                                </li>
                                <li>
                                    <img src={ImageIc} alt='Image' />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src={ChatIc} alt='Chat' />
                                </li>
                                <li>
                                    <img src={ShareIc} alt='Share' />
                                </li>
                                <li>
                                    <img src={DeleteIc} alt='Delete' />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src={TimingIc} alt='Timing' />
                                </li>
                            </ul>
                        </div>
                        <div className="new-post-modal-action">
                            <button type="button" className="btn btn-outline-primary waves-effect waves-light">Preview</button>
                            <button type="button" className="btn btn-outline-primary waves-effect waves-light">Draft</button>
                            <button type="button" className="btn btn-outline-primary waves-effect waves-light">Publish</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddAccountModal
