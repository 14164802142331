import { uniq } from 'lodash';
import { TData } from '../../types';

const getAllValuesForTableField = (
  data: TData[],
  field: string,
  order: 'asc' | 'desc'
) => {
  return uniq(data.map((item) => Number(item?.[field]) || 0))
    .sort((a, b) => (order == 'desc' ? b - a : a - b))
    .filter((value) => !!value);
};

export default getAllValuesForTableField;
