import classNames from 'classnames';
import styles from './index.module.css';
import metricViewStyles from 'src/User/features/CompetitionAnalysis/views/Instagram/components/index.module.css';
import ContentLoader from 'react-content-loader';

export type AuditRating =
  | 'A+'
  | 'A'
  | 'A-'
  | 'B+'
  | 'B'
  | 'B-'
  | 'C+'
  | 'C'
  | 'C-'
  | 'D+'
  | 'D'
  | 'D-'
  | 'E+'
  | 'E'
  | 'E-';

type TMetricCardProps = {
  icon: React.JSX.Element;
  title: string | number;
  description: string | number;
  showTooltip?: boolean;
  tooltipText?: string;
  rating?: AuditRating;
  withShadow?: boolean;
};

export default function MetricsCard(props: TMetricCardProps) {
  const { icon, description, title, rating, withShadow = true } = props;
  const Icon = () => icon;

  return (
    <div
      className={classNames(
        'bg-white tw-rounded-xl px-3 py-4 d-flex flex-column justify-content-center align-items-center gap-3',
        {
          'tw-shadow-lg': withShadow
        }
      )}>
      <div className={styles['icon-container']}>
        <div className={styles['icon']}>
          <Icon />
        </div>
        {rating ? (
          <span
            className={classNames(
              metricViewStyles['icon-container'],
              styles['rating'],
              {
                [metricViewStyles['green-icon']]: rating?.includes('A'),
                [metricViewStyles['blue-icon']]: rating?.includes('B'),
                [metricViewStyles['orange-icon']]: rating?.includes('C'),
                [metricViewStyles['red-icon']]:
                  rating?.includes('D') || rating?.includes('E')
              }
            )}>
            {rating}
          </span>
        ) : null}
      </div>
      <div className='d-flex flex-column justify-content-center align-items-center gap-1'>
        <p className={styles['title']}>{title}</p>
        <p className={styles['description']}>{description}</p>
      </div>
    </div>
  );
}

MetricsCard.Loading = ({ withShadow = true }: { withShadow?: boolean }) => {
  return (
    <div
      className={classNames('tw-bg-white tw-rounded-xl tw-py-4 tw-px-3', {
        'tw-shadow-lg': withShadow
      })}>
      <ContentLoader
        speed={2}
        width={181}
        viewBox='0 0 181 120'
        backgroundColor='#f3f2f2'
        foregroundColor='#e5e0e0'
        className='tw-mx-auto tw-h-full'>
        <rect
          x='25'
          y='71'
          rx='3'
          ry='3'
          width='120'
          height='20'
        />
        <rect
          x='-1'
          y='103'
          rx='3'
          ry='3'
          width='180'
          height='13'
        />
        <circle
          cx='77'
          cy='23'
          r='20'
        />
      </ContentLoader>
    </div>
  );
};
