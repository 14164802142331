import { AxiosResponse } from 'axios';
import Api from 'src/Api';
import {
  IUserSubscriptionPlan,
  TPlanNames
} from '../recoil/Atoms/UserSubscriptionAtom';

export interface ISubscription {
  plan_name: Exclude<TPlanNames, 'restricted'>;
  is_default: boolean;
  expires_in_days: number;
  free_trial_period: number;
  max_brands: number;
  max_users: number;
  max_socials: number;
  max_ai: number;
  stripe_price_id: string;
  stripe_product_id: string;
  price: number;
  currency: string;
}

export const getPlans = async (): Promise<AxiosResponse<ISubscription[]>> => {
  return await Api.get('/subscription/checkout/');
};

export const getUserPlan = async (): Promise<
  AxiosResponse<IUserSubscriptionPlan>
> => {
  return await Api.get('/subscription/user/');
};

interface ISelectPlanResponse {
  message: string;
  checkout_url: string;
}

interface ISelectPlanProps {
  planName: string;
  stripePriceId: string;
}

export const selectPlan = async ({
  planName,
  stripePriceId
}: ISelectPlanProps): Promise<AxiosResponse<ISelectPlanResponse>> => {
  const data = {
    plan_name: planName,
    stripe_price_id: stripePriceId
  };

  return await Api.post('/subscription/checkout/', data);
};

export const changePlan = async (
  planName: string
): Promise<AxiosResponse<void>> => {
  const data = {
    plan_name: planName
  };
  return await Api.post('/subscription/checkout/', data);
};
