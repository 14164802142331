import { HISTORY_DATA } from './constants';
import HistoryTable from '../../components/HistoryTable';

export default function History() {
  return (
    <HistoryTable
      data={HISTORY_DATA}
      showComingSoonOverlay
    />
  );
}
