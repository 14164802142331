import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { Form } from 'reactstrap';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

// recoil
// import { UserInfo } from "../recoil/Atoms/UserInforAtom";
// import { useRecoilState } from "recoil";

import { useSearchParams } from 'react-router-dom';
// user services
import { inviteUser } from '../services/auth';
// import { getUserSubscription } from "../services/account";

// images
import PatternRight from '../../Assets/images/pattern-right.png';
import PatternStars from '../../Assets/images/pattern-stars.png';
import SOMateLogo from '../../Assets/images/somate-logo.svg';
// import FacebookIc from "../../Assets/images/facebook.svg";
// import GoogleIc from "../../Assets/images/google.svg";
// import InstaIc from "../../Assets/images/instagram.svg";
// import GithubIc from "../../Assets/images/github.svg";
// import TwiterIc from "../../Assets/images/twiter.svg";

const InviteUser = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const paramEmail = searchParams.get('email') ? searchParams.get('email') : '';
  // const [userData,setUserData] = useRecoilState(UserInfo);

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const [showPassword2, setShowPassword2] = React.useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);

  const [isProgress, setIsProgress] = useState(false);
  useEffect(() => {
    localStorage.setItem('user_current_subscription', 'null');
  });
  return (
    <>
      <React.Fragment>
        <div className='authentication-form-container'>
          <div className='logo_wrapper'>
            <img
              className='somate_logo'
              src={SOMateLogo}
              alt='SOMateLogo'
            />
          </div>
          <div className='login_form'>
            <h4 className='tw-mb-6'>Complete invitation registration</h4>
            {/* <p>(Text to be changed)</p> */}
            <Formik
              initialValues={{
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                password_confirm: ''
              }}
              onSubmit={async (values) => {
                values.email = paramEmail;
                console.warn('valuessss', values);
                setIsProgress(true);
                const response = await inviteUser(values);
                if (response.status === 201) {
                  // const login_response = await login(values);
                  // if (login_response.status === 200) {
                  Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'Sucessfully Registered, Verification email sent!!',
                    showConfirmButton: false,
                    timer: 1500
                  }).then(() => {
                    navigate('/login');
                  });

                  //   const response = await profile(values);
                  //   localStorage.setItem("user_type",await response?.data?.user_type)
                  //   if (await response.data.user_type!=="False")
                  //   {
                  //     const planRes = await getUserSubscription()
                  //     localStorage.setItem("user_current_subscription",await planRes?.data?.length)
                  // if (!planRes?.data?.length>0)
                  // {
                  //   navigate("/flow-plan")
                  // }
                  // else
                  // {
                  //   navigate("/")
                  // }
                  //   }
                  //   else
                  //   {
                  //     navigate("/user-type")
                  //   }
                  //   localStorage.setItem("userId", response.data.pk);
                  //   localStorage.setItem(
                  //     "first_name",
                  //     response.data.first_name
                  //   );
                  //   localStorage.setItem(
                  //     "last_name",
                  //     response.data.last_name
                  //   );
                  //   localStorage.setItem("email", response.data.email);
                  //   localStorage.setItem("company_name", response.data.company_name);
                  //   setIsProgress(false);
                  // } else {
                  //   Swal.fire({
                  //     title: "Error",
                  //     text: login_response?.data?.non_field_errors?.[0] ?? "Wrong credentials.",
                  //     icon: "error",
                  //     confirmButtonText: "OK",
                  //   });
                  setIsProgress(false);
                  // }
                } else {
                  Swal.fire({
                    title: 'Error',
                    // text: response?.data?.email?.[0]?response?.data?.email?.[0]:response?.data?.non_field_errors?.[0]?response?.data?.non_field_errors?.[0]:"Couldn't Sign Up.",
                    text: "Couldn't register.",
                    icon: 'error',
                    confirmButtonText: 'OK'
                  });
                  setIsProgress(false);
                }
              }}
              validationSchema={Yup.object().shape({
                first_name: Yup.string()
                  .trim()
                  .min(2, 'Too Short!')
                  .required('Required')
                  .matches(
                    /^[aA-zZ\s]+$/,
                    'Only alphabets are allowed for this field '
                  ),
                last_name: Yup.string()
                  .trim()
                  .min(2, 'Last Name must be at least 2 characters.')
                  .matches(
                    /^[aA-zZ\s]+$/,
                    'Only alphabets are allowed for this field '
                  )
                  .required('Required'),
                // email: Yup.string()
                //   .email("Invalid email.")
                //   .required("Required"),
                password: Yup.string()
                  .required('Required')
                  .min(8, 'Password must be at least 8 characters.')
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character.'
                  ),
                password_confirm: Yup.string()
                  .required('Required')
                  .oneOf([Yup.ref('password'), null], 'Passwords must match.')
              })}>
              {({
                values,
                handleChange,
                handleSubmit,
                handleBlur,
                errors,
                touched
              }) => (
                <Form>
                  <div className='mb-3'>
                    <label
                      htmlFor='first_name'
                      className='form-label'>
                      First Name
                    </label>
                    <input
                      className='form-control'
                      id='first_name'
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      style={{
                        border:
                          errors.first_name &&
                          touched.first_name &&
                          '1px solid red'
                      }}
                      name='first_name'
                      placeholder='Jane'
                      type='text'
                    />
                    {errors.first_name && touched.first_name ? (
                      <div style={{ color: 'red' }}>{errors.first_name}</div>
                    ) : null}
                  </div>
                  <div className='mb-3'>
                    <label
                      htmlFor='last_name'
                      className='form-label'>
                      Last Name
                    </label>
                    <input
                      className='form-control'
                      id='last_name'
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      style={{
                        border:
                          errors.last_name &&
                          touched.last_name &&
                          '1px solid red'
                      }}
                      name='last_name'
                      placeholder='Acme'
                      type='text'
                    />
                    {errors.last_name && touched.last_name ? (
                      <div style={{ color: 'red' }}>{errors.last_name}</div>
                    ) : null}
                  </div>

                  <div className='mb-3'>
                    <label
                      htmlFor='email'
                      className='form-label'>
                      Email
                    </label>
                    <input
                      className='form-control'
                      id='email'
                      value={paramEmail}
                      // onChange={handleChange}
                      disabled
                      // onBlur={handleBlur}
                      // required
                      // style={{
                      //   border:
                      //     errors.email && touched.email && "1px solid red",
                      // }}
                      // error={errors.email && touched.email}
                      // helpertext={
                      //   errors.email && touched.email && errors.email
                      // }
                      name='email'
                      placeholder='example@xyz.com'
                      type='email'
                    />
                    {/* {errors.email && touched.email ? (
                        <div style={{ color: "red", maxWidth: "363px", fontSize: "12px" }}>{errors.email}</div>
                      ) : null} */}
                  </div>

                  <div className='mb-3 forgot-pass-wrapper'>
                    <label htmlFor='password'>Password</label>
                    <input
                      className='form-control'
                      value={values.password}
                      onChange={handleChange}
                      required
                      style={{
                        border:
                          errors.password && touched.password && '1px solid red'
                      }}
                      onBlur={handleBlur}
                      type={showPassword ? 'text' : 'password'}
                      id='password'
                      name='password'
                    />
                    <span
                      onClick={handleClickShowPassword}
                      className='show_password'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='bi bi-eye-fill'
                        viewBox='0 0 16 16'>
                        <path d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z' />
                        <path d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z' />
                      </svg>
                    </span>
                    {errors.password && touched.password ? (
                      <div
                        style={{
                          color: 'red',
                          maxWidth: '363px',
                          fontSize: '12px'
                        }}>
                        {errors.password}
                      </div>
                    ) : null}
                  </div>

                  <div className='mb-3 forgot-pass-wrapper'>
                    <label htmlFor='password_confirm'>Confirm Password</label>
                    <input
                      className='form-control'
                      value={values.password_confirm}
                      onChange={handleChange}
                      required
                      style={{
                        border:
                          errors.password_confirm &&
                          touched.password_confirm &&
                          '1px solid red'
                      }}
                      onBlur={handleBlur}
                      type={showPassword2 ? 'text' : 'password'}
                      id='password_confirm'
                      name='password_confirm'
                    />
                    <span
                      onClick={handleClickShowPassword2}
                      className='show_password'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='bi bi-eye-fill'
                        viewBox='0 0 16 16'>
                        <path d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z' />
                        <path d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z' />
                      </svg>
                    </span>
                    {errors.password_confirm && touched.password_confirm ? (
                      <div
                        style={{
                          color: 'red',
                          maxWidth: '363px',
                          fontSize: '12px'
                        }}>
                        {errors.password_confirm}
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <label>
                      By registering you agree to SoMate.app
                    </label>
                  </div>

                  <button
                    className='my-3 btn btn-primary waves-effect waves-light'
                    color='primary'
                    type='submit'
                    disabled={isProgress}
                    onClick={handleSubmit}>
                    {!isProgress && ' Create Account'}
                    {isProgress && (
                      <div
                        className='spinner-border text-light'
                        role='status'>
                        <span className='sr-only'></span>
                      </div>
                    )}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <img
            className='pattern_right'
            src={PatternRight}
            alt='pattern_right'
          />
        </div>
        <img
          className='pattern_stars'
          src={PatternStars}
          alt='pattern_stars'
        />
      </React.Fragment>
    </>
  );
};

export default InviteUser;
