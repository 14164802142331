import { Table, TableLoading } from 'src/User/features/Viz/components/Table';
import { TColumDef } from 'src/User/features/Viz/components/types';
import {
  GradientCellRenderer,
  GradientCellRendererProps,
  getGradientCellRange
} from 'src/User/features/Viz/components/Table/components/GradientCellRenderer';
import { Campaign } from '../../types';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle
} from 'src/components/ui/card';
import './campaign-perfomance.css';
import { TNamespace } from 'src/i18n';
import { startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

interface CampaignPerformanceProps {
  data: Campaign[];
}

const CampaignPerformance = (props: CampaignPerformanceProps) => {
  const { data } = props;

  const { t } = useTranslation<TNamespace>();

  return (
    <CampaignPerformance.Container>
      <Table
        data={data}
        columnsDefs={getTableColumnDefs(data, t)}
        isLoading={false}
      />
    </CampaignPerformance.Container>
  );
};

const Container = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation<TNamespace>();

  return (
    <Card className='tw-w-full'>
      <CardHeader>
        <CardTitle>
          {t('meta.summary.campaignPerformance.title', {
            ns: 'adAnalytics'
          })}
        </CardTitle>
      </CardHeader>
      <CardContent className='tw-p-0'>{children}</CardContent>
    </Card>
  );
};
CampaignPerformance.Container = Container;

CampaignPerformance.Loading = () => (
  <CampaignPerformance.Container>
    <TableLoading />
  </CampaignPerformance.Container>
);

const Error = () => {
  const { t } = useTranslation<TNamespace>();

  return (
    <CampaignPerformance.Container>
      <p className='!tw-my-0'>
        {t('meta.summary.campaignPerformance.error.404', {
          ns: 'adAnalytics'
        })}
      </p>
    </CampaignPerformance.Container>
  );
};
CampaignPerformance.Error = Error;

type TTableFields =
  | 'clicks'
  | 'spend'
  | 'reach'
  | 'impressions'
  | 'cpc'
  | 'ctr'
  | 'cpm';

const getMaximumValue = (data: Campaign[], field: TTableFields) => {
  return Math.max(...data.map((item) => item[field]));
};

const getTableColumnDefs = (
  data: Campaign[],
  translate: TFunction<TNamespace, undefined, TNamespace>
): TColumDef => {
  return [
    {
      field: 'name',
      headerName: startCase(
        translate('meta.summary.common.campaignName', {
          ns: 'adAnalytics'
        }) ?? ''
      ),
      sortable: true,
      flex: 1,
      minWidth: 350,
      pinned: 'left' as const
    },
    {
      field: 'clicks',
      headerName:
        translate('meta.adcreative.common.clicks', {
          ns: 'adAnalytics'
        }) ?? '',
      flex: 1,
      minWidth: 200,
      cellStyle: {
        paddingInline: 0
      },
      cellRenderer: GradientCellRenderer,
      sortable: true,
      cellRendererParams: {
        gradientMax: getMaximumValue(data, 'clicks'),
        range: getGradientCellRange(data, 'clicks', 'desc'),
        color: 'green',
        variant: 'gradient'
      } satisfies Omit<GradientCellRendererProps, 'value'>
    },
    {
      field: 'cpc',
      headerName:
        translate('meta.adcreative.common.cpc', {
          ns: 'adAnalytics'
        }) ?? '',
      sortable: true,
      flex: 0,
      width: 120,
      cellStyle: {
        paddingInline: 0
      },
      cellRenderer: GradientCellRenderer,
      cellRendererParams: {
        gradientMax: getMaximumValue(data, 'cpc'),
        range: getGradientCellRange(data, 'cpc', 'asc'),
        color: 'green',
        variant: 'gradient'
      } satisfies Omit<GradientCellRendererProps, 'value'>
    },
    {
      field: 'spend',
      headerName: startCase(
        translate('meta.adcreative.common.spend', {
          ns: 'adAnalytics'
        }) ?? ''
      ),
      flex: 1,
      minWidth: 200,
      sortable: true,
      cellRenderer: GradientCellRenderer,
      cellRendererParams: {
        gradientMax: getMaximumValue(data, 'spend'),
        range: getGradientCellRange(data, 'spend', 'desc'),
        color: 'green',
        variant: 'progress'
      } satisfies Omit<GradientCellRendererProps, 'value'>
    },
    {
      field: 'reach',
      headerName: startCase(
        translate('meta.adcreative.common.reach', {
          ns: 'adAnalytics'
        }) ?? ''
      ),
      flex: 1,
      minWidth: 200,
      sortable: true,
      cellStyle: {
        paddingInline: 0
      },
      cellRenderer: GradientCellRenderer,
      cellRendererParams: {
        gradientMax: getMaximumValue(data, 'reach'),
        range: getGradientCellRange(data, 'reach', 'desc'),
        color: 'green',
        variant: 'gradient'
      } satisfies Omit<GradientCellRendererProps, 'value'>
    },
    {
      field: 'impressions',
      headerName: startCase(translate('impressions') ?? ''),
      flex: 1,
      minWidth: 200,
      sortable: true,
      cellStyle: {
        paddingInline: 0
      },
      cellRenderer: GradientCellRenderer,
      cellRendererParams: {
        gradientMax: getMaximumValue(data, 'impressions'),
        range: getGradientCellRange(data, 'impressions', 'desc'),
        color: 'green',
        variant: 'gradient'
      } satisfies Omit<GradientCellRendererProps, 'value'>
    },
    {
      field: 'ctr',
      headerName: startCase(
        translate('meta.adcreative.common.ctr', {
          ns: 'adAnalytics'
        }) ?? ''
      ),
      sortable: true,
      flex: 0,
      width: 120,
      cellStyle: {
        paddingInline: 0
      },
      cellRenderer: GradientCellRenderer,
      cellRendererParams: {
        gradientMax: getMaximumValue(data, 'ctr'),
        range: getGradientCellRange(data, 'ctr', 'asc'),
        color: 'green',
        variant: 'gradient'
      } satisfies Omit<GradientCellRendererProps, 'value'>
    },
    {
      field: 'cpm',
      headerName: startCase(
        translate('meta.adcreative.common.cpm', {
          ns: 'adAnalytics'
        }) ?? ''
      ),
      sortable: true,
      flex: 0,
      width: 120,
      cellStyle: {
        paddingInline: 0
      },
      cellRenderer: GradientCellRenderer,
      cellRendererParams: {
        gradientMax: getMaximumValue(data, 'cpm'),
        range: getGradientCellRange(data, 'cpm', 'asc'),
        color: 'green',
        variant: 'gradient'
      } satisfies Omit<GradientCellRendererProps, 'value'>
    }
  ];
};

export default CampaignPerformance;
