import { Table } from 'src/User/features/Viz/components/Table';
import { TColumDef } from 'src/User/features/Viz/components/types';
import {
  useBrand,
  usePopup
} from 'src/User/layout/Sidebar/components/BrandSelect';
import FormDialog from 'src/User/components/FormDialog';
import { ICellRendererParams } from 'ag-grid-community';
import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import styles from '../settings.module.css';
import classNames from 'classnames';

export default function BrandSettings() {
  const { getAllBrands } = useBrand();
  const popup = usePopup();

  const brands = getAllBrands();
  const brandsTableData = brands.map((brand) => ({
    name: brand.name,
    actions: [
      {
        handler: popup.deleteBrandInitiator,
        brand,
        label: 'Delete',
        type: 'delete'
      },
      {
        handler: popup.updateBrandInitiator,
        brand,
        label: 'Update',
        type: 'update'
      }
    ]
  }));

  return (
    <div
      className={styles['setting-card']}
      id='notifications-setting'>
      <div className={classNames('mb-4', styles['setting-card-head'])}>
        <h5>Brands List</h5>
        <button
          onClick={() => {
            popup.createBrandInitiator();
          }}
          className='btn btn-outline-primary'>
          New Brand
        </button>
      </div>
      <div>
        <Table
          isLoading={false}
          columnsDefs={brandsTableColDefs}
          data={brandsTableData}
        />
      </div>
      {popup.content ? (
        <FormDialog
          isOpen={popup.visibility}
          buttonText={popup.content.dispatcherText ?? ''}
          popupTitle={popup.content.title ?? ''}
          onClose={popup.togglePopupVisibility}
          form={popup.content.form}
        />
      ) : null}
    </div>
  );
}

type TData = {
  actions: {
    handler: (brand: IBrand) => void;
    brand: IBrand;
    label: string;
    type: 'update' | 'delete';
  }[];
};

const CellBlock = (props: ICellRendererParams<TData>) => {
  return (
    <div className='d-flex gap-3 align-items-center h-100'>
      {props?.data?.actions.map(({ handler, brand, label, type }) => (
        <button
          key={label}
          onClick={() => {
            handler(brand);
          }}
          className={classNames(
            styles['action-btn'],
            'd-flex justify-content-center align-items-center',
            {
              [styles['delete-btn']]: type == 'delete',
              [styles['update-btn']]: type == 'update'
            }
          )}>
          <span> {label}</span>
        </button>
      ))}
    </div>
  );
};

const brandsTableColDefs: TColumDef = [
  { field: 'name', headerName: 'Brand Name', minWidth: 300, flex: 1 },
  {
    field: 'actions',
    headerName: 'Actions',
    cellRenderer: CellBlock,
    flex: 1
  }
];
