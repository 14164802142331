import {
  useLoaderData,
  useNavigate,
  useNavigation,
  useSubmit
} from 'react-router-dom';
import { DialogDescription } from 'src/components/ui/dialog';
import { Switch } from 'src/components/ui/switch';
import { Label } from 'src/components/ui/label';
import isEmpty from 'lodash/isEmpty';
import { IMetaAdAccount } from 'src/lib/models/socialAccounts';
import { useBrand } from 'src/User/layout/Sidebar/components/BrandSelect';
import UnControlledDialog from 'src/User/components/UnControlledDialog';

const ConnectMetaAds = () => {
  // Hooks
  const { getCurrentBrand } = useBrand();
  const navigate = useNavigate();
  const allSocialAccounts = useLoaderData() as IMetaAdAccount[];
  const submit = useSubmit();

  // Functions
  const { id: brandId } = getCurrentBrand();
  const redirectToSocials = () => navigate('/socials/meta-ads');

  const onConnect = (props: IConnectFieldAccountDef & IMetaAdAccount) => {
    const { act_account_id, connected, meta_id } = props;
    const formData = {
      brand_id: brandId,
      act_account_id,
      connected: `${!connected}`,
      meta_id
    };

    submit(formData, {
      method: 'post',
      action: `/socials/meta-ads/${meta_id}/connect-meta-ads-account`
    });
  };

  return (
    <UnControlledDialog
      title={'Connect Meta Ad Accounts'}
      open={true}
      onCancel={redirectToSocials}
      onOpenChange={redirectToSocials}>
      {isEmpty(allSocialAccounts) ? (
        <DialogDescription>
          You have no Meta Ad Accounts to connect
        </DialogDescription>
      ) : (
        allSocialAccounts?.map((adAccount) => (
          <div
            key={adAccount.act_account_id}
            className='tw-flex tw-w-full tw-justify-between'>
            <ConnectMetaAdsAccountField
              brandId={brandId}
              onConnect={onConnect}
              accountData={{
                ...adAccount,
                name: adAccount.name,
                connected: adAccount.connected,
                accountId: adAccount.act_account_id
              }}
            />
          </div>
        ))
      )}
    </UnControlledDialog>
  );
};

export interface IConnectFieldAccountDef {
  accountId: string;
  name: string;
  connected: boolean;
}

interface IConnectMetaAdsAccountFieldProps<T extends IConnectFieldAccountDef> {
  accountData: T;
  brandId: string;
  onConnect: (account: T) => void;
}

export const ConnectMetaAdsAccountField = <T extends IConnectFieldAccountDef>(
  props: IConnectMetaAdsAccountFieldProps<T>
) => {
  const { accountData, onConnect } = props;
  const { accountId, name, connected } = accountData;
  const navigation = useNavigation();

  return (
    <>
      <Label htmlFor={`account-${accountId}-connect-checkbox`}>{name}</Label>
      <Switch
        id={`account-${accountId}-connect-checkbox`}
        checked={connected}
        onCheckedChange={() => onConnect(accountData)}
        disabled={navigation.state == 'submitting'}
      />
    </>
  );
};

export default ConnectMetaAds;
