import { useState } from 'react';
import getSubscriptionData from '../utils/getSubscriptionData';
import { selectPlan } from 'src/User/services/planServices';
import displayAxiosError from 'src/lib/utils/displayAxiosError';
import { useRouteLoaderData } from 'react-router-dom';
import { ROUTER_PATHS_ID } from 'src/lib/constants';
import { getSubscriptionPlansLoader } from '../loaders/getSubscriptionPlansLoader';

type TDuration = 'Month' | 'Year';

export default function useGetPlansData() {
  const subscriptionPlans = useRouteLoaderData(
    ROUTER_PATHS_ID.SUBSCRIPTION_PLANS_PAGE
  ) as Awaited<ReturnType<typeof getSubscriptionPlansLoader>>;

  const [planDuration, setPlanDuration] = useState<TDuration>('Month');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPlanKey, setSelectedPlanKey] = useState<string>();

  const selectPlanHandler = async (planName: string, stripePriceId: string) => {
    setSelectedPlanKey(planName);
    setIsSubmitting(true);
    await registerUserPlan(planName, stripePriceId);

    setIsSubmitting(false);
  };

  const registerUserPlan = async (planName: string, stripePriceId: string) => {
    try {
      const { data: responpeData } = await selectPlan({
        planName,
        stripePriceId
      });
      const { checkout_url } = responpeData;

      // Redirect to stripe checkout page
      window.location.href = checkout_url;
    } catch (error: any) {
      displayAxiosError(error, {
        title: 'Unable to register plan',
        fallbackMsg:
          'Error Occured during process, please try contact support for more info'
      });
    }
  };

  return {
    duration: planDuration,
    changeDuration: setPlanDuration,
    isSubmitting,
    selectedPlanKey,
    subscriptionPlansList: getSubscriptionData(subscriptionPlans),
    select: selectPlanHandler
  };
}
