import Swal from 'sweetalert2';

interface AdCreativeState {
  activeAdCreativeIds: string[];
  activeMetrics: string[];
}

export type AdCreativeAction = {
  type:
    | 'ADD_ACTIVE_AD_CREATIVE_ID'
    | 'REMOVE_ACTIVE_AD_CREATIVE_ID'
    | 'ADD_ACTIVE_METRIC'
    | 'REMOVE_ACTIVE_METRIC'
    | 'TOGGLE_ACTIVE_METRIC'
    | 'TOGGLE_ACTIVE_AD_CREATIVE_ID';
  payload: string;
};

const MAX_FILTERS_COUNT = 4;
const MIN_FILTERS_COUNT = 1;
const MAX_DATASET_COUNT = 8;
const MIN_DATASET_COUNT = 1;

const warnUser = (title: string, text: string) => {
  Swal.fire({
    title: title,
    text: text,
    icon: 'info',
    showConfirmButton: false,
    toast: true,
    position: 'top-right',
    timer: 5000,
    timerProgressBar: true,
    showCloseButton: true
  });
};

const metricReducer = (state: AdCreativeState, action: AdCreativeAction) => {
  switch (action.type) {
    case 'ADD_ACTIVE_AD_CREATIVE_ID': {
      return addAdCreativeId(state, action.payload);
    }
    case 'REMOVE_ACTIVE_AD_CREATIVE_ID': {
      return removeAdCreativeId(state, action.payload);
    }
    case 'TOGGLE_ACTIVE_AD_CREATIVE_ID': {
      if (state.activeAdCreativeIds.includes(action.payload)) {
        return removeAdCreativeId(state, action.payload);
      }

      return addAdCreativeId(state, action.payload);
    }
    case 'ADD_ACTIVE_METRIC': {
      return addMetric(state, action.payload);
    }
    case 'REMOVE_ACTIVE_METRIC': {
      return removeMetric(state, action.payload);
    }
    case 'TOGGLE_ACTIVE_METRIC': {
      if (state.activeMetrics.includes(action.payload)) {
        return removeMetric(state, action.payload);
      }

      return addMetric(state, action.payload);
    }
    default:
      return state;
  }
};

const addAdCreativeId = (state: AdCreativeState, adCreativeId: string) => {
  if (state.activeAdCreativeIds.length === MAX_DATASET_COUNT) {
    warnUser(
      'Max ad creatives reached',
      `You can not select more than ${MAX_DATASET_COUNT} ad creatives`
    );

    return state;
  }

  return {
    ...state,
    activeAdCreativeIds: [...state.activeAdCreativeIds, adCreativeId]
  };
};

const removeAdCreativeId = (state: AdCreativeState, adCreativeId: string) => {
  if (state.activeAdCreativeIds.length == MIN_DATASET_COUNT) {
    warnUser(
      'Min ad creatives reached',
      `You can not select less than ${MIN_DATASET_COUNT} ad creatives`
    );

    return state;
  }

  return {
    ...state,
    activeAdCreativeIds: state.activeAdCreativeIds.filter(
      (id) => id !== adCreativeId
    )
  };
};

const addMetric = (state: AdCreativeState, metric: string) => {
  if (state.activeMetrics.length === MAX_FILTERS_COUNT) {
    warnUser(
      'Max metrics reached',
      `You can not select more than ${MAX_FILTERS_COUNT} metrics`
    );

    return state;
  }

  return {
    ...state,
    activeMetrics: [...state.activeMetrics, metric]
  };
};

const removeMetric = (state: AdCreativeState, metric: string) => {
  if (state.activeMetrics.length == MIN_FILTERS_COUNT) {
    warnUser(
      'Min metrics reached',
      `You can not select less than ${MIN_FILTERS_COUNT} metrics`
    );

    return state;
  }

  return {
    ...state,
    activeMetrics: state.activeMetrics.filter(
      (oldMetric) => oldMetric !== metric
    )
  };
};

export default metricReducer;
