import React from 'react';
import './CampaignAdsOverview.css';
import Card from '../../../../components/Card';
import { Chart } from 'src/User/features/Viz/components/Chart';
import { Table } from 'src/User/features/Viz/components/Table';
import { ADS_TABLE_COLS } from './constants';
import ChartHeaderFilters from 'src/User/features/Viz/components/Chart/components/ChartHeaderFilters';
import useGetAdsInsights from './hooks/useGetAdsInsights';
import { TChartMetrics } from '../../types';
import { IChartHeaderFilterOption } from 'src/User/features/Viz/components/types';
import AvatarWithFallback from 'src/User/components/AvatarWithFallback';
import getChartOptions from '../../utils/getChartOptions';

const AdCampaignAdsOverview = () => {
  const { adsCharts, aggregatedAdsTableData, loading, toggleChartSeries } =
    useGetAdsInsights();

  return (
    <div className='ad-analytics tw-flex tw-flex-col tw-gap-6 tw-flex-grow tw-mt-4 tw-h-full'>
      <Card
        title='Ads'
        className='tw-h-full tw-min-h-[450px] tw-max-h-[500px] tw-flex tw-flex-col tw-flex-grow'
        cardContentProps={{
          className: 'tw-flex tw-flex-col tw-flex-grow !tw-pl-6'
        }}>
        <Table
          isLoading={loading}
          height={'100%'}
          data={aggregatedAdsTableData}
          columnsDefs={ADS_TABLE_COLS}
        />
      </Card>

      {adsCharts.map(({ chartData, adId, adName, adsetName, previewUrl }) => (
        <Card
          key={adId}
          title={
            <>
              <p className='!tw-mb-0'>{adName}</p>
              <p className='tw-text-base tw-text-slate-600 !tw-mb-0'>
                Ad Set: <span className='tw-italic'>{adsetName}</span>
              </p>
            </>
          }
          className='tw-min-h-[400px] tw-flex tw-flex-col tw-flex-grow'
          cardContentProps={{
            className: 'tw-flex tw-flex-col tw-flex-grow !tw-pl-6'
          }}>
          <div className='ads-itm'>
            <ChartHeaderFilters
              className='!tw-p-0 tw-mb-5'
              data={chartData.seriesData.reduce(
                (acc, { name, total, showSeries }, seriesIndex) => {
                  acc[name] = {
                    value: total,
                    isActive: showSeries
                  };

                  return acc;
                },
                {} as IChartHeaderFilterOption
              )}
              onFilterOptionClick={(seriesName) => {
                toggleChartSeries(adId, seriesName as TChartMetrics);
              }}
            />
            <div className='ads-card-inner'>
              <div className='ad-image-container'>
                <AvatarWithFallback
                  src={previewUrl ?? ''}
                  alt={`${adName} creative preview for`}
                  className='ad-image tw-rounded-sm'
                  imgClassName='tw-object-cover'
                />
              </div>
              <div className='ads-chart'>
                <Chart
                  type='area'
                  data={{
                    options: getChartOptions(adId, chartData.dates),
                    series: chartData.seriesData?.filter(
                      ({ showSeries }) => showSeries
                    )
                  }}
                  height='350px'
                />
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default AdCampaignAdsOverview;
