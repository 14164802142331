import { useTranslation } from 'react-i18next';
import { TNamespace } from 'src/i18n';
import AnalyticsErrorRenderer from 'src/User/components/Error/AnalyticsErrorRenderer';

const MetaAdsAnalyticsErrorBoundary = () => {
  const { t } = useTranslation<TNamespace>();

  return (
    <AnalyticsErrorRenderer
      title={t('meta.layout.error.title', { ns: 'adAnalytics' })}
      fallbackErrorMsg={t('meta.layout.error.description', {
        ns: 'adAnalytics'
      })}
    />
  );
};

export default MetaAdsAnalyticsErrorBoundary;
