import { Await, useLoaderData } from 'react-router-dom';
import { pick } from 'lodash';
import MetricView from 'src/User/features/CompetitionAnalysis/views/Instagram/components/MetricsView';
import { Suspense } from 'react';
import { IGetFacebookPageClicksInsightResponse } from 'src/User/services/analytics/models';
import { AxiosResponse } from 'axios';
import { getSummaryData } from './utils/getSummaryData';
import getChartData, { isChartDataArg } from '../../utils/getChartData';
import ErrorRenderer from 'src/User/components/Error/AnalyticsErrorRenderer';
import { IFacebookPageClicksDailyInsight } from './types';
import { type TNamespace } from 'src/i18n';
import { useTranslation } from 'react-i18next';

const ClicksOnPageContainer = () => {
  const { facebookPageClicksInsightResponse } = useLoaderData() as any;
  const { t } = useTranslation<TNamespace>();

  return (
    <Suspense fallback={<MetricView.Loading chartGridFlow='column' />}>
      <Await
        resolve={facebookPageClicksInsightResponse}
        errorElement={
          <ErrorRenderer
            title={t('error.title', {
              ns: 'analytics',
              accountType: 'facebook page',
              feature: 'clicks'
            })}
            fallbackErrorMsg={t('error.description', {
              ns: 'analytics'
            })}
            hideConnectAccount
          />
        }>
        {(response: AxiosResponse<IGetFacebookPageClicksInsightResponse>) => (
          <ClicksOnPage {...response.data} />
        )}
      </Await>
    </Suspense>
  );
};

interface IClicksOnPageProps extends IGetFacebookPageClicksInsightResponse {}

const ClicksOnPage = (props: IClicksOnPageProps) => {
  const { data } = props;

  const metricViewSummary = pick(data, [
    'cta_clicks',
    'page_visits',
    'website_clicks',
    'fan_count',
    'followers_count'
  ]);

  const chartMetrics = [
    'cta_clicks',
    'page_visits',
    'website_clicks'
  ] satisfies Exclude<keyof IFacebookPageClicksDailyInsight, 'date'>[];

  const unformattedChartData = isChartDataArg(data.daily) ? data.daily : [];

  return (
    <div className='-tw-mt-7'>
      <MetricView
        chart={getChartData(unformattedChartData, chartMetrics)}
        metricsData={getSummaryData(metricViewSummary)}
        chartGridFlow='column'
      />
    </div>
  );
};

export default ClicksOnPageContainer;
