import { useState } from 'react';
import styles from '../settings.module.css';
import classNames from 'classnames';

export default function NotificationSettings() {
  const [option, setOption] = useState([true, false, true]);

  const setOptionFun = (index: number) => {
    const arr = option;
    arr[index] = !arr[index];
    setOption([...arr]);
  };

  return (
    <div
      className={styles['setting-card']}
      id='notifications-setting'>
      <div className={styles['setting-card-head']}>
        <h5>Manage Notifications</h5>
        <button
          onClick={() => {
            alert('clicked');
          }}
          className='btn btn-outline-primary'>
          Update
        </button>
      </div>
      <div className={styles['settings-toggle']}>
        <div className={styles['toggle-item']}>
          <h6>
            <span>
              <i className='bi bi-bell'></i>
            </span>{' '}
            Post Notification
          </h6>
          <div className={classNames(styles['form-switch'])}>
            <input
              className='form-check-input'
              checked={option[0]}
              onChange={() => {
                setOptionFun(0);
              }}
              type='checkbox'
              role='switch'
              id='notification1'
            />
          </div>
        </div>
        <div className={styles['toggle-item']}>
          <h6>
            <span>
              <i className='bi bi-calendar4'></i>
            </span>{' '}
            Post scheduled reminder Notifications
          </h6>
          <div className='form-switch'>
            <input
              className='form-check-input'
              checked={option[1]}
              onChange={() => {
                setOptionFun(1);
              }}
              type='checkbox'
              role='switch'
              id='notification2'
            />
          </div>
        </div>
        <div className={styles['toggle-item']}>
          <h6>
            <span>
              <i className='bi bi-bell'></i>
            </span>{' '}
            Social Notifications
          </h6>
          <div className='form-switch'>
            <input
              className='form-check-input'
              checked={option[2]}
              onChange={() => {
                setOptionFun(2);
              }}
              type='checkbox'
              role='switch'
              id='notification3'
            />
          </div>
        </div>
      </div>
    </div>
  );
}
