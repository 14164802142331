import { Maybe } from '../models';

/**
 * Adds query parameters to a given pathname and returns the updated pathname.
 *
 * @param pathname - The original pathname.
 * @param params - The query parameters to add.
 * @returns The updated pathname with the added query parameters.
 */
const addParamsToPathname = (
  pathname: string,
  params: Record<string, Maybe<string>>
) => {
  const urlObj = new URL(pathname, window.location.origin);
  const searchParams = new URLSearchParams(urlObj.search);

  Object.entries(params).forEach(([key, value]) => {
    if (typeof value === 'string') searchParams.set(key, value);
  });

  urlObj.search = searchParams.toString();
  const pathnameOldSearchQueryIndex = pathname.indexOf('?');
  const pathnameWithoutSearchQuery =
    pathnameOldSearchQueryIndex > -1
      ? pathname.slice(0, pathnameOldSearchQueryIndex)
      : pathname;

  return `${pathnameWithoutSearchQuery}${urlObj.search}`;
};

export default addParamsToPathname;
