import { ActionFunctionArgs } from 'react-router-dom';
import { USER_CONFIG } from 'src/configs';
import displayAxiosError from 'src/lib/utils/displayAxiosError';
import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { promptConversation } from 'src/User/services/somate-ai';

export async function promptConversationAction(args: ActionFunctionArgs) {
  const { request, params } = args;

  const formData = await request.formData();
  const prompt = formData.get('prompt');
  const conversationId = params?.conversationId;

  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);

  if (typeof prompt == 'string' && typeof conversationId == 'string') {
    try {
      const { data: conservation } = await promptConversation({
        brandId,
        prompt: prompt,
        conversationId
      });

      return conservation;
    } catch (error) {
      displayAxiosError(error, {
        title: 'Unable to respond to your prompt',
        fallbackMsg: '',
        toast: true,
        duration: 7000
      });

      return null;
    }
  }

  return null;
}
