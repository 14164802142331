import React from 'react';
import {
  Outlet,
  useNavigate,
  useParams,
  useRouteLoaderData,
  useSearchParams
} from 'react-router-dom';
import SocialAccountSelector from 'src/User/components/SocialAccountSelector';
import analyticsLayoutLoader from '../loaders/analyticsLayoutLoader';
import { PromiseType } from 'src/lib/models';
import { ROUTER_PATHS_ID } from 'src/lib/constants';
import addParamsToPathname from 'src/lib/utils/addParamsToPathname';
import { DATE_SEARCH_PARAMS } from 'src/configs';

export default function RootLayout() {
  const { socialAccountsSelectorListOptions } = useRouteLoaderData(
    ROUTER_PATHS_ID.ANALYTICS_LAYOUT
  ) as PromiseType<ReturnType<typeof analyticsLayoutLoader>>;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { id: activeSocialAccountId } = useParams();

  const duration = {
    start: searchParams.get(DATE_SEARCH_PARAMS.start) ?? undefined,
    stop: searchParams.get(DATE_SEARCH_PARAMS.stop) ?? undefined
  };

  const activeSocialAccount = socialAccountsSelectorListOptions?.find(
    ({ id }) => id === activeSocialAccountId
  );

  return (
    <section>
      <header className='tw-rounded-t-lg shadow tw-overflow-hidden'>
        <SocialAccountSelector
          activeSocialAccount={activeSocialAccount}
          socialAccounts={socialAccountsSelectorListOptions}
          dateRange={{ start: duration.start, stop: duration.stop }}
          onActiveSocialAccountChange={(accountId) => {
            const accountMatch = socialAccountsSelectorListOptions.find(
              (account) => account.id === accountId
            );

            if (accountMatch) {
              const href = addParamsToPathname(accountMatch.path, duration);
              navigate(href);
            }
          }}
          onDateRangeChange={(dateRange) =>
            setSearchParams((prev) => ({
              ...prev,
              ...dateRange
            }))
          }
        />
      </header>
      <Outlet />
    </section>
  );
}
