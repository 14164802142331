import { Outlet } from 'react-router-dom';
import ProfileCard from 'src/User/components/ProfileCard';
import AnalyticsNavbar from 'src/User/components/AnalyticsNavbar';
import { TNamespace } from 'src/i18n';
import { useTranslation } from 'react-i18next';

const navLinks = [
  {
    href: 'overview',
    i18nKey: 'overview'
  },
  {
    href: 'audit',
    i18nKey: 'audit'
  },
  {
    href: 'engagement',
    i18nKey: 'engagement'
  },
  {
    href: 'growth',
    i18nKey: 'growth'
  },
  {
    href: 'history',
    i18nKey: 'history'
  },
  {
    href: 'post',
    i18nKey: 'posts'
  }
];

export default function Layout() {
  const { t } = useTranslation<TNamespace>();

  return (
    <section>
      <header className='tw-flex tw-flex-col tw-gap-6'>
        <div className='shadow tw-rounded-lg tw-overflow-hidden'>
          <ProfileCard
            profileMetrics={profileMetrics}
            username='Lorem Ipsum'
            bio={`🧚🏼‍♂️ Nemo enim ipsam voluptatem
          ☕️ Ut enim ad minima veniam
          📈 Quis autem vel eum iure reprehenderit
          ⬇️ voluptate velit:`}
            profilePhotoUrl={'/images/place-holder-avatar.png'}
            created_at={'2024-05-02'}
            updated_at={'2024-05-08'}
          />
        </div>
        <AnalyticsNavbar
          navLinks={navLinks.map(({ href, i18nKey }) => ({
            title: t(i18nKey) ?? '',
            href
          }))}
        />
      </header>

      <Outlet />
    </section>
  );
}

const profileMetrics = [
  {
    label: 'Followers',
    value: '24,227',
    direction: 1,
    changeRate: '+78 ( 0.3% )'
  },
  {
    label: 'Following',
    value: '390',
    direction: -1,
    changeRate: '+12 ( 3.2% )'
  },
  {
    label: 'Posts',
    value: '840',
    direction: 1,
    changeRate: '+3 ( 0.4% )'
  }
];
