import Api from 'src/Api';
import { IUserInfo } from 'src/lib/models/userInfo';
import { TUpdateUserInfoProps } from './types';

export const sendCode = async (code: string) => {
  const newData = { code };
  return await Api.post(`social-media/instagram/get-code/`, newData);
};

export const getUserSubscription = async () => {
  return await Api.get(`/subscription/my-subscription-items/`);
};

export const setUserType = async (type: string) => {
  const newData = {
    user_type: type
  };
  return await Api.post(`auth/user-type/`, newData);
};

export const updateUserInfo = async (userInfo: TUpdateUserInfoProps) => {
  return await Api.put<IUserInfo>(`auth/user/`, userInfo);
};
