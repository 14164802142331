import { isEmpty } from 'lodash';
import { IInstagramBusinessAudience } from '../../..';

export default function getRegionsData(data?: IInstagramBusinessAudience) {
  const initialData = {
    data: {} as Record<string, number>,
    topRegionData: {
      name: 'N/A',
      count: 0
    }
  };
  const { audience_city: cityData } = data ?? {};

  if (isEmpty(cityData)) return initialData;

  const topRegionData = Object.entries(cityData).reduce(
    (acc, [cityName, count]) => {
      if (count > acc.count)
        return {
          name: cityName,
          count
        };

      return acc;
    },
    initialData.topRegionData
  );

  return {
    data: cityData,
    topRegionData
  };
}
