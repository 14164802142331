import React, { useEffect, useState } from 'react'
import './UserType.css'
import { useNavigate } from 'react-router';

import SOMateLogo from "../../Assets/images/somate-logo.svg";
import userType1 from "../../Assets/User-Type/Personal.jpg";
import userType2 from "../../Assets/User-Type/ContentCreater.webp";
import userType3 from "../../Assets/User-Type/Student.webp";
import userType4 from "../../Assets/User-Type/SmallCompany.jpg";
import userType5 from "../../Assets/User-Type/LargeCompany.jpg";
import userType6 from "../../Assets/User-Type/Charity.jpg";
import { setUserType } from '../services/account';
import Swal from 'sweetalert2';




const UserType = () => {

    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const [selected,setSelected] = useState("");
    useEffect(() => {
        if (!token) {
          navigate("/login");
        }
        // else {
        //     if (localStorage.getItem("user_type")!=="False") 
        //     {
        //         navigate("/flow-plan")
        //     }
        // }
      });
    return (
        <>
            <div className="user-type">
                <div className="user-type-logo_wrapper">
                    <img className="somate_logo" src={SOMateLogo} alt="SOMateLogo" />
                </div>
                <div className="user-type-header">
                    <h1>welcome to somate.app, your social media analytics hub</h1>
                    <p>Connect your Facebook, Instagram, Twitter, Youtube, and so on...</p>
                </div>
                <h2>User Type</h2>
                <div  className="user-type_inner">
                    <div  onClick={()=>{setSelected("PERSONAL")}}  className="user-type-itm ">
                        <div className={selected=="PERSONAL"?"user-type-itm-img user-type-itm-active ":"user-type-itm-img"}>
                            <img className="user type" src={userType1} alt="user type" />
                        </div>
                        <h4>Personal</h4>
                    </div>
                    <div  onClick={()=>{setSelected("CONTENT_CREATOR")}} className="user-type-itm">
                        <div className={selected=="CONTENT_CREATOR"?"user-type-itm-img user-type-itm-active ":"user-type-itm-img"}>
                            <img className="user type" src={userType2} alt="user type" />
                        </div>
                        <h4>Content Creator</h4>
                    </div>
                    <div   onClick={()=>{setSelected("STUDENT")}} className="user-type-itm">
                        <div className={selected=="STUDENT"?"user-type-itm-img user-type-itm-active ":"user-type-itm-img"}>
                            <img className="user type" src={userType3} alt="user type" />
                        </div>
                        <h4>Student</h4>
                    </div>
                    <div  onClick={()=>{setSelected("SMALL_COMPANY")}} className="user-type-itm">
                        <div className={selected=="SMALL_COMPANY"?"user-type-itm-img user-type-itm-active ":"user-type-itm-img"}>
                            <img className="user type" src={userType4} alt="user type" />
                        </div>
                        <h4>Small Company</h4>
                    </div>
                    <div  onClick={()=>{setSelected("LARGE_COMPANY")}} className="user-type-itm">
                        <div className={selected=="LARGE_COMPANY"?"user-type-itm-img user-type-itm-active ":"user-type-itm-img"}>
                            <img className="user type" src={userType5} alt="user type" />
                        </div>
                        <h4>Large Company</h4>
                    </div>
                    <div onClick={()=>{setSelected("NON_PROFIT_OR_CHARITY")}} className="user-type-itm">
                        <div className={selected=="NON_PROFIT_OR_CHARITY"?"user-type-itm-img user-type-itm-active ":"user-type-itm-img"}>
                            <img className="user type" src={userType6} alt="user type" />
                        </div>
                        <h4>Non-Profit or Charity</h4>
                    </div>
                </div>
                <div className="next-btn">
                    <button onClick={()=>{
                        if(selected!="")
                        {
                            setUserType(selected);
                            localStorage.setItem("user_type",selected)
                            // if(localStorage.getItem("user_current_subscription")==="null"||localStorage.getItem("user_current_subscription").length==0)
                            // {
                              navigate("/flow-plan")
                            // }
                            // else
                            // {
                            //   navigate("/")
                            // }
                        }
                        else {
                            Swal.fire({
                                position: 'top-center',
                                icon: 'warning',
                                title: 'Select a type.',
                                showConfirmButton: false,
                                timer: 1000
                              })
                        }
                        }
                        } className="btn btn-primary">
                            
                        Next
                    </button>
                </div>
            </div>
        </>
    )
}

export default UserType
