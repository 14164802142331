import { AvatarCellRenderer } from 'src/User/features/Viz/components/Table';
import { Input } from 'reactstrap';
import styles from '../TableHeaderComponent/table-header.module.css';
import { ITableData } from '../../../type';
import TableCellExternalLink from 'src/User/features/Viz/components/Table/components/TableCellExternalLink';

export interface IPostCellRendererProps {
  data: ITableData;
  activeAdCreativeIds: string[];
  toggleAdCreative: (adCreativeId: string) => void;
}

const PostCellRenderer = (props: IPostCellRendererProps) => {
  const { thumbnail_url, name, permalink } = props.data ?? {};
  const { activeAdCreativeIds, toggleAdCreative } = props;

  const datasetKey = props.data?.key;
  const isActive = !!datasetKey && activeAdCreativeIds?.includes(datasetKey);

  return (
    <div className='d-flex gap-3 align-items-center'>
      <Input
        type='checkbox'
        className={styles['checkbox']}
        onChange={() => {
          if (!!datasetKey) toggleAdCreative?.(datasetKey);
        }}
        checked={isActive}
      />
      <AvatarCellRenderer
        avatar={thumbnail_url}
        name={name}
        username={''}
        avatarClassName='!tw-rounded'>
        <TableCellExternalLink href={permalink} />
      </AvatarCellRenderer>
    </div>
  );
};

export default PostCellRenderer;
