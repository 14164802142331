import Api from 'src/Api';
import type {
  IConnectAccountResponse,
  IConnectAccountProfileProps,
  IGetAllAccountProfilesInBrandByMediaTypeIdProps,
  IGetAllAccountProfilesInBrandByMediaTypeIdResponse,
  IConnectAccountProps
} from './models';

export const connectAccount = async (props: IConnectAccountProps) => {
  const { code, brandId, mediaType } = props;

  return await Api.post<IConnectAccountResponse>(`/connect/${mediaType}/`, {
    code,
    brand_id: brandId
  });
};

export const connectAccountProfile = async (
  props: IConnectAccountProfileProps
) => {
  const { accountId, brandId, connected, mediaType } = props;
  const mediaIdKey =
    mediaType === 'ig-business' ? 'ig_business_account_id' : 'facebook_page_id';

  return await Api.post(`/connect/${mediaType}/account/`, {
    brand_id: brandId,
    [mediaIdKey]: accountId,
    connected: connected
  });
};

export const disconnectIgBusiness = connectAccountProfile;

export const getAllAccountProfilesInBrandByMediaTypeId = async (
  props: IGetAllAccountProfilesInBrandByMediaTypeIdProps
) => {
  const { brandId, metaId, mediaType } = props;

  return await Api.get<IGetAllAccountProfilesInBrandByMediaTypeIdResponse>(
    `/connect/${mediaType}/accounts/?brand_id=${brandId}&meta_id=${metaId}`
  );
};
