import { ColDef } from 'ag-grid-community';
import { ADSETS_TABLE_COLS } from '../../Adsets/constants';
import { IAdsTableRow } from '../../../types';

export const ADS_TABLE_COLS: ColDef<IAdsTableRow>[] = [
  {
    field: 'adName',
    headerName: 'Ads',
    sortable: true,
    filter: true,
    pinned: 'left',
    minWidth: 300
  },
  {
    field: 'adsetName',
    headerName: 'Adset',
    sortable: true,
    filter: true
  },
  ...(ADSETS_TABLE_COLS.slice(1) as ColDef[])
];
