import { ApexOptions } from 'apexcharts';
import { faker } from '@faker-js/faker';
import GoogleAdsIcon from 'src/components/icons/google-ads';
import MetaIcon from 'src/components/icons/meta';
import LinkedIcon from 'src/components/icons/linkedin';

const generateCampaignPermanceData = () => ({
  spent: {
    value: faker.finance.amount({ symbol: '$' }),
    change_rate: faker.number.float({ min: -200, max: 200, precision: 0.01 })
  },
  impressions: {
    value: faker.number.float({ precision: 0.01 }),
    change_rate: faker.number.float({ min: -200, max: 200, precision: 0.01 })
  },
  conversions: {
    value: faker.number.float({ precision: 0.01 }),
    change_rate: faker.number.float({ min: -200, max: 200, precision: 0.01 })
  },
  clicks: {
    value: faker.number.float({ precision: 0.01 }),
    change_rate: faker.number.float({ min: -200, max: 200, precision: 0.01 })
  },
  cost_per_conversion: {
    value: faker.finance.amount(),
    change_rate: faker.number.float({ min: -200, max: 200, precision: 0.01 })
  }
});

export const CAMPAIGN_PERFORMANCE_DATA = {
  FACEBOOK_ADS: {
    data: generateCampaignPermanceData(),
    logo: MetaIcon,
    label: 'Meta Ads'
  },
  GOOGLE_ADS: {
    data: generateCampaignPermanceData(),
    logo: GoogleAdsIcon,
    label: 'Google Ads'
  },
  LINKEDIN_ADS: {
    data: generateCampaignPermanceData(),
    logo: LinkedIcon,
    label: 'LinkedIn Ads'
  }
};

export const DashboardChartData: ApexOptions[] = [
  {
    colors: ['#9A5333', '#9A5333', '#9A5333'],
    series: [
      {
        name: 'Series 1',
        data: [80, 50, 30, 40, 100, 20]
      }
    ],
    chart: {
      height: 350,
      type: 'radar'
    },
    // title: {
    //   text: 'Basic Radar Chart'
    // },
    xaxis: {
      categories: ['January', 'February', 'March', 'April', 'May', 'June']
    }
  },
  {
    colors: ['#9A5333', '#9A5333', '#9A5333'],
    series: [
      {
        name: 'Desktops',
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
      }
    ],
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: 'Product Trends by Month',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep'
      ]
    }
  }
];
