import { Suspense, useEffect } from 'react';
import {
  Await,
  Outlet,
  useAsyncError,
  useAsyncValue,
  useRouteLoaderData,
  useNavigate,
  Link
} from 'react-router-dom';
import userSubscriptionAtom, {
  IUserSubscriptionPlan
} from '../../recoil/Atoms/UserSubscriptionAtom';
import { useRecoilState } from 'recoil';
import { LoadingUI } from './components';
import { ROUTER_PATHS, ROUTER_PATHS_ID } from 'src/lib/constants';
import displayAxiosError from 'src/lib/utils/displayAxiosError';
import { AxiosError } from 'axios';
import { TriangleAlert } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import { USER_CONFIG } from 'src/configs';
import { SubscriptionRestricted } from 'src/User/auth/CompleteFlow';

export default function SubscriptionWrapper() {
  const { userSubscriptionPlan } = useRouteLoaderData(
    ROUTER_PATHS_ID.USER_SUBSCRIPTION_VALIDATION_LAYOUT
  ) as any;

  return (
    <Suspense fallback={<LoadingUI />}>
      <Await
        resolve={userSubscriptionPlan}
        errorElement={<SubscriptionError />}>
        <Content />
      </Await>
    </Suspense>
  );
}

const Content = () => {
  const data = useAsyncValue() as IUserSubscriptionPlan;
  const [, setUserSubscriptionPlan] = useRecoilState(userSubscriptionAtom);

  useEffect(() => {
    setUserSubscriptionPlan(data);
    localStorage.setItem(
      USER_CONFIG.storageUserSubscriptionKey,
      JSON.stringify(data)
    );
  }, [data, setUserSubscriptionPlan]);

  if (data.state == 'RESTRICTED') return <SubscriptionRestricted />;

  return <Outlet />;
};

const SubscriptionError = () => {
  const error = useAsyncError();
  const navigate = useNavigate();

  useEffect(() => {
    const typedError = error as AxiosError | undefined;
    const responseStatusCode = typedError?.response?.status;

    if (!responseStatusCode || responseStatusCode == 503)
      return navigate(ROUTER_PATHS.MAINTEANCE);

    displayAxiosError(error, {
      title: 'No Active subscription found',
      fallbackMsg: 'You must be enrolled in a subscription plan to continue',
      toast: true,
      duration: 5000
    });
  }, [navigate, error]);

  return (
    <div className='tw-flex tw-min-h-[100dvh] tw-flex-col tw-items-center tw-justify-center tw-bg-background tw-px-4 tw-py-12 sm:tw-px-6 lg:tw-px-8'>
      <div className='tw-mx-auto tw-max-w-md md:tw-max-w-lg lg:tw-max-w-3xl tw-text-center'>
        <TriangleAlert className='tw-mx-auto tw-h-12 tw-w-12 tw-text-primary' />
        <h1 className='tw-mt-4 tw-text-3xl tw-font-bold tw-tracking-tighter tw-text-foreground md:tw-text-5xl lg:tw-text-7xl'>
          We couldn&apos;t retrieve your subscription
        </h1>
        <p className='tw-mt-4 tw-text-muted-foreground tw-max-w-lg tw-text-center tw-mx-auto'>
          We&apos;re sorry, but we were unable to retrieve your subscription
          information. Please try again later or contact our support team if the
          issue persists.
        </p>
        <div className='tw-mt-6'>
          <Button
            asChild
            size={'lg'}>
            <Link
              to={ROUTER_PATHS.SUBSCRIPTION_PLANS}
              className='tw-no-underline tw-text-primary-foreground tw-transition-colors tw-hover:tw-bg-primary/90 hover:tw-text-primary-foreground'>
              Try Again
            </Link>
          </Button>
        </div>
      </div>
    </div>
  );
};
