import { ActionFunctionArgs, redirect } from 'react-router-dom';
import {
  createReportTemplate,
  uploadImagesToReportTemplate,
  updateReportTempate
} from 'src/User/services/reporting';
import displayAxiosError from 'src/lib/utils/displayAxiosError';
import { TSocialAccountKind } from 'src/lib/models/socialAccounts';
import { pickBy } from 'lodash';

const reportTemplateAction = async ({
  request,
  params
}: ActionFunctionArgs) => {
  const templateId = params?.templateId;
  const formData = await request.formData();
  const data = formData.get('templateData');
  const templateData = JSON.parse((data as string) ?? '{}');
  const templateImages = {
    cover_image: formData.get('cover_image') as File,
    logo: formData.get('logo') as File,
    background: formData.get('background') as File
  };

  try {
    if (!isValidFormData({ ...templateData, ...templateImages })) {
      throw new Error('Invalid form data');
    }

    const typedTemplateData = templateData as Omit<
      ValidFormData,
      'cover_image' | 'logo' | 'background'
    >;
    const reportTemplateHandler = getReportTemplateHandler(templateId);
    const { data: reportTemplateResponse } = await reportTemplateHandler({
      ...typedTemplateData,
      reportTemplateId: templateId ?? ''
    });
    const { data: newReportTemplate } = reportTemplateResponse;
    const imagesToUpload = pickBy(
      {
        logo: formatFormDataImage(templateImages?.logo),
        cover_image: formatFormDataImage(templateImages?.cover_image),
        background: formatFormDataImage(templateImages?.background)
      },
      (value) => value instanceof File
    );
    await uploadImagesToReportTemplate({
      brand_id: typedTemplateData.brand_id,
      reportTemplateId: newReportTemplate.id,
      ...imagesToUpload
    });

    return redirect('/reports');
  } catch (error: any) {
    displayAxiosError(error, {
      title: 'Unable to create report template',
      fallbackMsg: 'Error occured during creation process',
      toast: true,
      duration: 7000
    });

    return null;
  }
};

const getReportTemplateHandler = (templateId?: string) => {
  return !!templateId ? updateReportTempate : createReportTemplate;
};

interface ValidFormData {
  brand_id: string;
  name: string;
  cover_title: string;
  colors: string[];
  summary_section_pages: string[];
  social_media_sections: {
    account_type: TSocialAccountKind;
    account_id: string;
    pages: string[];
  }[];
  cover_image: File | string;
  logo: File | string;
  background: File | string;
}

const isValidFormData = (data: any): data is ValidFormData => {
  return (
    typeof data === 'object' &&
    'brand_id' in data &&
    'name' in data &&
    'cover_title' in data &&
    'colors' in data &&
    'summary_section_pages' in data &&
    'social_media_sections' in data &&
    'cover_image' in data &&
    'logo' in data &&
    'background' in data
  );
};

const formatFormDataImage = (image: File | string) => {
  return image == 'null' ? null : image;
};

export default reportTemplateAction;
