import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translation files
import translationEN from './locales/en/translation.json';
import analyticsEN from './locales/en/analytics.json';
import competitionAnalysisEN from './locales/en/competition-analysis.json';
import adAnalyticsEN from './locales/en/ad-analytics.json';
import translationPL from './locales/pl/translation.json';
import analyticsPL from './locales/pl/analytics.json';
import competitionAnalysisPL from './locales/pl/competition-analysis.json';
import adAnalyticsPL from './locales/pl/ad-analytics.json';

const LANGUAGES = {
  en: 'English',
  pl: 'Polish'
};

//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
    analytics: analyticsEN,
    competitionAnalysis: competitionAnalysisEN,
    adAnalytics: adAnalyticsEN
  } satisfies Record<TNamespace, Record<string, any>>,
  pl: {
    translation: translationPL,
    analytics: analyticsPL,
    competitionAnalysis: competitionAnalysisPL,
    adAnalytics: adAnalyticsPL
  } satisfies Record<TNamespace, Record<string, any>>
};

//i18N Initialization
i18n.use(initReactI18next).init({
  resources,
  lng: 'en', //default language
  interpolation: {
    escapeValue: false
  },
  debug: true
});

type TNamespace =
  | 'translation'
  | 'analytics'
  | 'competitionAnalysis'
  | 'adAnalytics';

export default i18n;
export { LANGUAGES, type TNamespace };
