import { Suspense } from 'react';
import { useLoaderData, Await } from 'react-router-dom';
import { PostAnalyticsTableView } from '../Posts/components/PostAnalyticsTable';
import { useTranslation } from 'react-i18next';
import { type TNamespace } from 'src/i18n';
import AnalyticsErrorRenderer from 'src/User/components/Error/AnalyticsErrorRenderer';
import { TableLoading } from 'src/User/features/Viz/components/Table';
import { IInstagramBusinessMediaInsight } from '../../types';
import useGetStoriesTableData from './hooks/useGetStoriesTableData';

const StoriesContainer = () => {
  const { igBusinessStoriesInsight } = useLoaderData() as any;
  const { t } = useTranslation<TNamespace>();

  return (
    <Suspense
      fallback={
        <div className='tw-bg-white tw-rounded-xl tw-flex tw-justify-center'>
          <TableLoading />
        </div>
      }>
      <Await
        errorElement={
          <AnalyticsErrorRenderer
            title={t('error.title', {
              ns: 'analytics',
              accountType: 'instagram business',
              feature: 'stories'
            })}
            fallbackErrorMsg={t('error.description', {
              ns: 'analytics'
            })}
            hideConnectAccount
          />
        }
        resolve={igBusinessStoriesInsight}>
        {(data) => <StoriesTable data={data} />}
      </Await>
    </Suspense>
  );
};

const StoriesTable = ({ data }: { data: IInstagramBusinessMediaInsight[] }) => {
  const { t } = useTranslation<TNamespace>();

  const { summaryData, tableCols, tableData } = useGetStoriesTableData(data);

  return (
    <PostAnalyticsTableView
      cumulativeData={summaryData}
      tableTitle={t('list-of-stories', { ns: 'analytics' }) ?? ''}
      tableData={tableData}
      tableColumnsDefs={tableCols}
    />
  );
};

export default StoriesContainer;
