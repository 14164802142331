'use client';

import * as React from 'react';
import {
  format,
  isSameDay,
  startOfMonth,
  subDays,
  subMonths,
  subWeeks
} from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { DateRange } from 'react-day-picker';

import { cn } from 'src/lib/utils/shadcn-utils';
import { Button } from './button';
import { Calendar } from './calendar';
import { Popover, PopoverContent, PopoverTrigger } from './popover';

interface DatePickerWithRangeProps extends Omit<CalendarProps, 'onDateChange'> {
  id?: string;
  className?: string;
  fullHeight?: boolean;
  onDateChange?: (date?: DateRange) => void;
  size?: 'md' | 'lg';
}

const DATE_FILTERS = [
  {
    label: 'Yesterday',
    startDate: subDays(Date.now(), 1),
    endDate: subDays(Date.now(), 1)
  },
  {
    label: 'Last week',
    startDate: subWeeks(Date.now(), 1),
    endDate: new Date(Date.now())
  },
  {
    label: 'Current month',
    startDate: startOfMonth(Date.now()),
    endDate: new Date(Date.now())
  },
  {
    label: 'Last 30 days',
    startDate: subDays(Date.now(), 30),
    endDate: new Date(Date.now())
  },
  {
    label: 'Previous month',
    startDate: subMonths(startOfMonth(Date.now()), 1),
    endDate: subDays(startOfMonth(Date.now()), 1)
  },
  {
    label: 'Last 3 months',
    startDate: subMonths(Date.now(), 3),
    endDate: new Date(Date.now())
  },
  {
    label: 'Last 6 months',
    startDate: subMonths(Date.now(), 6),
    endDate: new Date(Date.now())
  },
  {
    label: 'Last 12 months',
    startDate: subMonths(Date.now(), 12),
    endDate: new Date(Date.now())
  }
];

export function DatePickerWithRange(props: DatePickerWithRangeProps) {
  const { className, date: dateProp, fullHeight, id, size = 'md' } = props;

  const [date, setDate] = React.useState<DateRange | undefined>(dateProp);
  const [open, setOpen] = React.useState(false);
  const [displayMonth, setDisplayMonth] = React.useState(date?.from);

  return (
    <div
      id={id}
      className={cn('tw-grid tw-gap-2', fullHeight && 'tw-h-full', className)}>
      <Popover
        onOpenChange={(open) => {
          setOpen(open);
          if (!open) {
            setDate(dateProp);
          }
        }}
        open={open}>
        <PopoverTrigger asChild>
          <Button
            id='date'
            variant={'outline'}
            className={cn(
              'tw-w-full tw-justify-start tw-text-left tw-font-normal tw-h-full',
              !date && 'tw-text-muted-foreground',
              fullHeight && 'tw-h-full',
              {
                '!tw-text-sm': size == 'md',
                '!tw-text-base !tw-py-3 !tw-px-5': size == 'lg'
              }
            )}>
            <CalendarIcon
              className={cn('tw-mr-2 tw-h-4 tw-w-4', {
                'tw-h-5 tw-w-5': size == 'lg'
              })}
            />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, 'LLL dd, y')} -{' '}
                  {format(date.to, 'LLL dd, y')}
                </>
              ) : (
                format(date.from, 'LLL dd, y')
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className='tw-w-auto tw-p-0 tw-flex tw-flex-col tw-gap-5'
          align='start'>
          <div className='tw-flex tw-gap-5'>
            <DatePickerWithRange.Calendar
              {...props}
              date={date}
              onDateChange={(date) => setDate(date)}
              displayMonth={displayMonth}
              onDisplayMonthChange={(month) => setDisplayMonth(month)}
            />
            <div className='tw-flex tw-flex-col tw-gap-1'>
              {DATE_FILTERS.map(({ label, endDate, startDate }, index) => {
                const isActive =
                  date?.from &&
                  date?.to &&
                  isSameDay(date?.from, startDate) &&
                  isSameDay(date?.to, endDate);

                return (
                  <Button
                    key={index}
                    variant={isActive ? 'link' : 'ghost'}
                    className={cn('!tw-justify-start', {
                      '!tw-no-underline tw-bg-primary-beige-light': isActive
                    })}
                    onClick={() => {
                      setDate?.({ from: startDate, to: endDate });
                      setDisplayMonth(startDate);
                    }}>
                    {label}
                  </Button>
                );
              })}
            </div>
          </div>
          <div className='tw-flex tw-justify-between tw-gap-5'>
            <Button
              variant={'outline'}
              className='tw-w-fit tw-mx-2'
              onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              variant={'default'}
              className='tw-w-fit'
              onClick={() => {
                setOpen(false);
                props?.onDateChange?.({ from: date?.from, to: date?.to });
              }}>
              Apply
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}

interface CalendarProps {
  date?: DateRange;
  minDate?: Date;
  maxDate?: Date;
  displayMonth?: Date;
  onDateChange?: (date?: DateRange) => void;
  onDisplayMonthChange?: (month?: Date) => void;
}

DatePickerWithRange.Calendar = (props: CalendarProps) => {
  const {
    date,
    onDateChange,
    minDate,
    maxDate,
    displayMonth,
    onDisplayMonthChange
  } = props;

  return (
    <Calendar
      initialFocus
      mode='range'
      defaultMonth={date?.from}
      month={displayMonth}
      selected={date}
      onSelect={(range) => {
        // Prevent calendar from auto defining selection if both dates haven't
        // been selected by user
        if (date?.from && date?.to) {
          const firstDate =
            range?.from !== date?.from ? range?.from : range?.to;

          onDisplayMonthChange?.(firstDate);
          return onDateChange?.({
            from: firstDate,
            to: undefined
          });
        }

        onDisplayMonthChange?.(range?.from);
        onDateChange?.(range);
      }}
      onMonthChange={(month) => onDisplayMonthChange?.(month)}
      numberOfMonths={2}
      disabled={(date) =>
        date > (maxDate ?? new Date()) ||
        date < (minDate ?? new Date('1900-01-01'))
      }
    />
  );
};
