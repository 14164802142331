import getChartOptions from 'src/User/features/CompetitionAnalysis/views/Instagram/components/MetricsView/utils/getChartOptions';
import useGetIGBussinessEngagement from '../../Engagement/hooks/useGetIGBussinessEngagement';
import getInsightDataWithMetricCountDifference from '../../../utils/getInsightDataWithMetricCountDifference';
import { InstagramAnalyticsLoaderData } from '../../models';
import { useRouteLoaderData } from 'react-router-dom';
import { ROUTER_PATHS_ID } from 'src/lib/constants';
import { useTranslation } from 'react-i18next';
import { TNamespace } from 'src/i18n';
// import getAverageClicksByDay from '../utils/getAverageClicksByDay';

const useGetIGBusinessOverviewInsight = () => {
  const { dailyInsights } = useRouteLoaderData(
    ROUTER_PATHS_ID.IG_BUSINESS_ANALYTICS_LAYOUT
  ) as InstagramAnalyticsLoaderData;

  const { weeklyPosts } = useGetIGBussinessEngagement();
  const { t } = useTranslation<TNamespace>();

  const sortedData = dailyInsights?.sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });

  const knownChartData = (
    ['followers', 'following', 'impressions', 'reach', 'media'] as const
  ).map((key) => {
    return {
      options: getChartOptions(sortedData?.map(({ date }) => date)),
      series: {
        name: t(key == 'media' ? 'Cumulative Posts' : key, {
          ns: 'analytics'
        }),
        data: sortedData?.map((insight) => insight[key]) || []
      }
    };
  });

  const computedDailyFollowers = getInsightDataWithMetricCountDifference(
    sortedData,
    'followers'
  );
  const newDailyFollowersInsights = {
    options: getChartOptions(computedDailyFollowers?.map(({ date }) => date)),
    series: {
      name: t('New Daily Followers', {
        ns: 'analytics'
      }),
      data: computedDailyFollowers?.map(({ followers }) => followers) || []
    }
  };

  knownChartData.splice(1, 0, newDailyFollowersInsights);

  // const commetsRatio = {
  //   options: getChartOptions(sortedData?.map(({ date }) => date)),
  //   series: {
  //     name: 'Comments Ratio (WIP)',
  //     data: sortedData?.map(() => 0) || []
  //   }
  // };

  // const averageClicks = getAverageClicksByDay(sortedData);
  // const averageClicksInsights = {
  //   options: getChartOptions(averageClicks?.map(({ date }) => date)),
  //   series: {
  //     name: 'Ave Clicks',
  //     data: averageClicks?.map(({ average_clicks }) => average_clicks) || []
  //   }
  // };

  return {
    chartData: [...knownChartData, weeklyPosts],
    sortedDailyInsightsData: sortedData
  };
};

export default useGetIGBusinessOverviewInsight;
