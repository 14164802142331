import React from 'react';
import { FACEBOOK_PAGE_OAUTH_CONFIG } from '../../constants';

export default function FaebokPageOauthModal() {
  return (
    <div>
      <p>
        Monitor your facebook page&apos;s performance and manage your
        page&apos;s content.
      </p>
    </div>
  );
}

FaebokPageOauthModal.headerTitle = 'Connect Facebook';

FaebokPageOauthModal.headerIcon = (
  <div>
    <i className='bi bi-flag-fill modal-icon'></i>
  </div>
);

FaebokPageOauthModal.oauthReqUrl =
  `https://www.facebook.com/dialog/oauth?` +
  `client_id=${FACEBOOK_PAGE_OAUTH_CONFIG.clientId}&` +
  `redirect_uri=${FACEBOOK_PAGE_OAUTH_CONFIG.redirectUri}&` +
  `response_type=${FACEBOOK_PAGE_OAUTH_CONFIG.responseType}&` +
  `scope=${FACEBOOK_PAGE_OAUTH_CONFIG.scope}&` +
  `state=${FACEBOOK_PAGE_OAUTH_CONFIG.state}`;
