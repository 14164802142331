import { useNavigate, useRouteError } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from 'src/components/ui/alert-dialog';

const ConnectMetaAdsAccountErrorBoundary = () => {
  const navigate = useNavigate();
  const error = useRouteError() as { message?: string };

  const redirectToSocials = () => navigate(`/socials/meta-ads`);

  return (
    <AlertDialog open={true}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Unable to get meta ads accounts for the specified meta id
          </AlertDialogTitle>
          <AlertDialogDescription>
            {error?.message
              ? error.message
              : 'please reload the page and try again'}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className='sm:tw-justify-between tw-w-full'>
          <AlertDialogCancel onClick={redirectToSocials}>
            Back
          </AlertDialogCancel>
          <AlertDialogAction onClick={() => navigate(0)}>
            Refresh Page
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConnectMetaAdsAccountErrorBoundary;
