import Api from 'src/Api';
import {
  IGetInstagramBusinessAccountInfo,
  IGetInstagramBusinessAccountInfoResponse,
  IGetInstagramBusinessDailyInsights,
  IGetInstagramBusinessDailyInsightsResponse,
  IGetInstagramBusinessInsightsSummaryResponse,
  IGetInstagramBusinessLifetimeInsightsResponse,
  IGetInstagramBusinessMediaInsights,
  IGetInstagramBusinessMediaInsightsResponse
} from './models';

export const getInstagramBusinessAccountInfo = async (
  props: IGetInstagramBusinessAccountInfo
) => {
  const { brandId, igBusinessAccountId } = props;
  const url = `/instagram/account-info/?brand_id=${brandId}&ig_business_account_id=${igBusinessAccountId}`;

  return await Api.get<IGetInstagramBusinessAccountInfoResponse>(url);
};

export const getInstagramBusinessDailyInsights = async (
  props: IGetInstagramBusinessDailyInsights
) => {
  const { brandId, igBusinessAccountId, date } = props;
  const startDateQuery = date?.start ? `&start_date=${date.start}` : '';
  const endDateQuery = date?.stop ? `&end_date=${date.stop}` : '';
  const url = `/instagram/daily-insights/?brand_id=${brandId}&ig_business_account_id=${igBusinessAccountId}${startDateQuery}${endDateQuery}`;

  return await Api.get<IGetInstagramBusinessDailyInsightsResponse>(url);
};

export const getInstagramBusinessLifetimeInsights = async (
  props: IGetInstagramBusinessAccountInfo
) => {
  const { brandId, igBusinessAccountId } = props;
  const url = `/instagram/lifetime-insights/?brand_id=${brandId}&ig_business_account_id=${igBusinessAccountId}`;

  return await Api.get<IGetInstagramBusinessLifetimeInsightsResponse>(url);
};

export const getInstagramBusinessMediaInsights = async (
  props: IGetInstagramBusinessMediaInsights
) => {
  const { brandId, igBusinessAccountId, date, type } = props;
  const startDateQuery = date?.start ? `&start_date=${date.start}` : '';
  const endDateQuery = date?.stop ? `&end_date=${date.stop}` : '';
  const typeQuery = type ? `&type=${type}` : '';
  const url =
    `/instagram/media/?brand_id=${brandId}` +
    `&ig_business_account_id=${igBusinessAccountId}` +
    `${startDateQuery}${endDateQuery}` +
    `${typeQuery}`;

  return await Api.get<IGetInstagramBusinessMediaInsightsResponse>(url);
};

export const getInstagramBusinessInsightsSummary = async (
  props: IGetInstagramBusinessDailyInsights
) => {
  const { brandId, igBusinessAccountId, date } = props;
  const startDateQuery = date?.start ? `&start_date=${date.start}` : '';
  const endDateQuery = date?.stop ? `&end_date=${date.stop}` : '';
  const url = `/instagram/summary/?brand_id=${brandId}&ig_business_account_id=${igBusinessAccountId}${startDateQuery}${endDateQuery}`;

  return Api.get<IGetInstagramBusinessInsightsSummaryResponse>(url);
};
