import React from 'react'
import './GroupsModal.css'
import { useState } from "react"
import usrImage from '../../Assets/images/profile-img.png'
import SendOutlined from '../../Assets/images/SendOutlined.svg'

const CommentsModal = (props) => {
    const [searchValue, SetSearchValue] = useState("");
    return (
        <>
            <div className="modal-header">
                <button type="button" className="btn-close" onClick={() => { props.setModal(!props.modal) }}></button>
            </div>
            <div className="modal-body">
                <div className="question-head">
                    <h4>I am trying to post feeds auto-publishing but it causing an error.</h4>
                    <p className='text-muted que-date'>September  8, 2022, <span>10:29 AM</span></p>
                </div>
                <div className="all-answer">
                    <div className="answer-itm">
                        <div className="user-image">
                            <img src={usrImage} alt='profile' />
                        </div>
                        <div className="answer-details">
                            <div className="answer-top-row">
                                <h3>Peter JJ</h3>
                                <span className='mentor-badge'>Mentor</span>
                                <p className='que-date text-muted'>September  8, 2022, <span>10:29 AM</span></p>
                                <div className="dropdown ans-dropdown">
                                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical"></i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" onClick={() => { props.setModal(!props.modal) }}><i className="bi bi-newspaper"></i> Group News Feed</a></li>
                                        <li><a className="dropdown-item" onClick={() => { props.setModal(!props.modal) }}><i className="bi bi-book"></i> Learning Materials</a></li>
                                        <li><a className="dropdown-item" onClick={() => { props.setModal(!props.modal) }}><i className="bi bi-calendar-week"></i> Upcoming Session</a></li>
                                        <li><a className="dropdown-item" onClick={() => { props.setModal(!props.modal) }}><i className="bi bi-camera-reels"></i> Recorded  Session</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="ans-row">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                    the industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                            </div>
                        </div>
                    </div>
                    <div className="answer-itm">
                        <div className="user-image">
                            <img src={usrImage} alt='profile' />
                        </div>
                        <div className="answer-details">
                            <div className="answer-top-row">
                                <h3>Peter JJ</h3>
                                <span className='mentor-badge'>Mentor</span>
                                <p className='que-date text-muted'>September  8, 2022, <span>10:29 AM</span></p>
                                <div className="dropdown ans-dropdown">
                                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical"></i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" onClick={() => { props.setModal(!props.modal) }}><i className="bi bi-newspaper"></i> Group News Feed</a></li>
                                        <li><a className="dropdown-item" onClick={() => { props.setModal(!props.modal) }}><i className="bi bi-book"></i> Learning Materials</a></li>
                                        <li><a className="dropdown-item" onClick={() => { props.setModal(!props.modal) }}><i className="bi bi-calendar-week"></i> Upcoming Session</a></li>
                                        <li><a className="dropdown-item" onClick={() => { props.setModal(!props.modal) }}><i className="bi bi-camera-reels"></i> Recorded  Session</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="ans-row">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                    the industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <div className="user-image">
                    <img src={usrImage} alt='profile' />
                </div>
                <div className="input-box">
                    <input value={searchValue}
                        onChange={(e) => { SetSearchValue(e.target.value); }} type='text' className='form-control' placeholder='Your message..' />
                    <span onClick={() => {
                        alert("Message Sent : " + searchValue);
                        SetSearchValue("");
                    }} className='btn-send'>
                        <img src={SendOutlined} alt='send' />
                    </span>
                </div>
            </div>
        </>
    )
}

export default CommentsModal
