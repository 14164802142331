import { format } from 'date-fns';

const getChartOptions = (date: string[]) => ({
  chart: {
    height: 350,
    width: '100%'
  },
  markers: {
    size: 0
  },
  xaxis: {
    type: 'datetime' as const,
    categories: date?.map((date) => date) || [],
    labels: {
      formatter: (value: string) => format(new Date(value), 'dd/MM/yyyy')
    }
  }
});

export default getChartOptions;
