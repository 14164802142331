import { startCase } from 'lodash';
import { ICONS_MAP } from 'src/User/features/CompetitionAnalysis/views/Instagram/views/Overview/constants';
import i18next, { TNamespace } from 'src/i18n';

// TODO: Need to get this data from the API, for now it's hardcoded
const getMetricSummaryData = () => [
  {
    icon: ICONS_MAP['engagementRate'],
    title: '...',
    description: startCase(i18next.t('engagement_rate') ?? '')
  },
  {
    icon: ICONS_MAP['averageLikes'],
    title: '...',
    description: i18next.t('Average Likes', {
      ns: 'analytics' satisfies TNamespace
    })
  },
  {
    icon: ICONS_MAP['engagementRate'],
    title: '...',
    description: i18next.t('Weekly Engagement Rate', {
      ns: 'analytics' satisfies TNamespace
    })
  },
  {
    icon: ICONS_MAP['weeklyPosts'],
    title: '...',
    description: i18next.t('Weekly Posts', {
      ns: 'analytics' satisfies TNamespace
    })
  },
  {
    icon: ICONS_MAP['averageComments'],
    title: '...',
    description: i18next.t('Average Comments', {
      ns: 'analytics' satisfies TNamespace
    })
  },
  {
    icon: ICONS_MAP['commentsRatio'],
    title: '...',
    description: i18next.t('Comments Ratio', {
      ns: 'analytics' satisfies TNamespace
    })
  }
];

export default getMetricSummaryData;
