import classNames from 'classnames';
import styles from './index.module.css';
import { NavLink } from 'react-router-dom';

export type TNavbarProps = {
  navLinks: TNavLink[];
};

export type TNavLink = {
  href: string;
  title: string;
  disabled?: boolean;
};

export default function AnalyticsNavbar({ navLinks }: TNavbarProps) {
  return (
    <nav>
      <menu
        className={classNames(
          'd-flex justify-content-center p-3 gap-4 shadow bg-white tw-rounded-xl',
          styles['nav-menu']
        )}>
        {navLinks.map(({ href, title, disabled }) => (
          <li key={href}>
            <NavLink
              to={href}
              className={({ isActive }) => {
                const classes = classNames(
                  styles['nav-link'],
                  'tw-font-medium tw-text-muted-foreground tw-border tw-border-transparent hover:tw-text-primary',
                  {
                    [`${styles['active-nav-link']} tw-bg-primary-beige-light tw-border tw-border-primary-beige-dark tw-text-primary tw-rounded-lg`]:
                      isActive,
                    'tw-pointer-events-none': disabled
                  }
                );

                return classes;
              }}
              aria-disabled={disabled}>
              {title}
            </NavLink>
          </li>
        ))}
      </menu>
    </nav>
  );
}
