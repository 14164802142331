import ErrorRenderer from 'src/User/components/Error/AnalyticsErrorRenderer';

const AllSocialAccountsErrorBoundary = () => {
  return (
    <ErrorRenderer
      title='Something went wrong while fetching all connected social accounts'
      fallbackErrorMsg='please reload the page and try again'
      hideConnectAccount={true}
    />
  );
};

export default AllSocialAccountsErrorBoundary;
