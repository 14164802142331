import { Outlet } from 'react-router-dom';

export default function LoginContainerLayout() {
  return (
    <div className='login'>
      <Outlet />
      <p className='powered_by'>
        © {new Date(Date.now()).getFullYear()} SoMate.app
      </p>
    </div>
  );
}
