import classNames from 'classnames';

type TColor =
  | 'brown'
  | 'purple'
  | 'green'
  | 'red'
  | 'yellow'
  | 'cyan'
  | 'orange'
  | 'amber';

export interface GradientCellRendererProps {
  value: number;
  gradientMax: number;
  range?: number[];
  color: TColor;
  variant: 'progress' | 'gradient';
  formatNumber?: boolean;
  className?: string;
}

const percentageToWeightMap = {
  9: 50,
  19: 100,
  29: 200,
  39: 300,
  49: 400,
  59: 500,
  69: 600,
  79: 700,
  89: 800,
  100: 900
};

const rangeIndexToWeightMapper = {
  0: 400,
  1: 300,
  2: 200,
  3: 100,
  4: 50
};

const getColorWeight = (percentage: number) => {
  return (
    Object.entries(percentageToWeightMap).find(([key]) => {
      return percentage <= Number(key);
    })?.[1] ?? 50
  );
};

export const GRADIENT_COLORS: TColor[] = [
  'brown',
  'green',
  'red',
  'yellow',
  'cyan',
  'purple',
  'orange',
  'amber'
];

/**
 * When using the GradientCellRenderer component, always give the table cell a padding-block of 0.
  Because the component itself has padding-block of 16px.
 */
// ! A better way of harmonizing the padding-block on the enclosing table cell is to give this
// ! component a specific class or id, and use the :has() selector to target the parent table cell.
const GradientCellRenderer = (props: GradientCellRendererProps) => {
  const {
    color,
    gradientMax,
    value,
    variant,
    formatNumber = true,
    range,
    className
  } = props;

  const valuePercentage = ((Number(value) || 0) / gradientMax) * 100;

  const weight = range
    ? rangeIndexToWeightMapper?.[
        range?.indexOf(Number(value)) as 0 | 1 | 2 | 3 | 4
      ]
    : getColorWeight(valuePercentage);
  const formattedValue = !Boolean(Number(value))
    ? value ?? 'N/A'
    : formatNumber
    ? new Intl.NumberFormat().format(value)
    : value;

  return (
    <div
      className={classNames(
        `gradient-cell-renderer tw-w-full tw-h-full tw-px-4`,
        className,
        {
          '!tw-bg-transparent':
            !Number(value) && !range?.includes(Number(value)),
          [`tw-bg-${color}-${weight}`]: variant === 'gradient',
          'tw-grid tw-gap-1 tw-grid-cols-5 tw-items-center tw-text-right':
            variant === 'progress'
        }
      )}>
      <p
        className={classNames('tw-m-0 tw-h-full tw-flex tw-items-center', {
          'tw-col-span-2': variant === 'progress'
        })}>
        {formattedValue}
      </p>
      {variant === 'progress' ? (
        <p
          className={`tw-m-0 tw-h-5 tw-bg-primary-black tw-col-span-3 tw-rounded-br tw-rounded-tr`}
          style={{ width: `${valuePercentage}%` }}></p>
      ) : null}
    </div>
  );
};

export default GradientCellRenderer;
