import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';

interface IProps {
  src?: string;
  alt?: string;
  className?: string;
  imgClassName?: string;
}

const AvatarWithFallback = (props: IProps) => {
  const { alt, src, className, imgClassName } = props;
  const fallback = typeof alt === 'string' ? alt.substring(0, 2) : 'A';

  return (
    <Avatar className={className}>
      <AvatarImage
        className={imgClassName}
        src={src}
        alt={alt}
      />
      <AvatarFallback className='tw-rounded-none tw-bg-slate-200 tw-uppercase'>
        {fallback}
      </AvatarFallback>
    </Avatar>
  );
};

export default AvatarWithFallback;
