import { isEmpty } from 'lodash';
import {
  AllSocialAccountDict,
  IFacebookPageAccount,
  IInstagramAccount,
  IMetaAdAccount,
  TSocialAccountKind
} from 'src/lib/models/socialAccounts';
import {
  isFacebookPageAccount,
  isMetaAdAccount
} from 'src/lib/utils/type-guards';

export default function getTableData(data: AllSocialAccountDict) {
  if (isEmpty(data)) return [];

  const accountsWithType = Object.entries(data).flatMap(
    function addSocialAccountTypeToListOfAccounts([accountType, accounts]) {
      return accounts.map((account) => ({
        account_type: accountType as TSocialAccountKind,
        ...account
      }));
    }
  );

  const tableData = accountsWithType.map((account) => {
    const unifiedAccountData = getUnifiedAccountData(account);

    return {
      name: unifiedAccountData.name,
      accountType: account.account_type,
      accountId: unifiedAccountData.id,
      profilePicture: unifiedAccountData.profilePicture,
      followers: unifiedAccountData.followers,
      amountSpent: unifiedAccountData.amountSpent,
      currency: unifiedAccountData.currency
    };
  });

  return tableData;
}

export const getUnifiedAccountData = (
  accountData: IInstagramAccount | IFacebookPageAccount | IMetaAdAccount
) => {
  if (isMetaAdAccount(accountData)) {
    return {
      name: accountData.name,
      id: accountData.act_account_id,
      profilePicture: '',
      followers: 'N/A',
      amountSpent: accountData.amount_spent,
      currency: accountData.currency
    };
  }

  if (isFacebookPageAccount(accountData))
    return {
      name: accountData.name,
      id: accountData.facebook_page_id,
      profilePicture: accountData.profile_pic,
      followers: accountData.fan_count?.toLocaleString(),
      amountSpent: undefined,
      currency: ''
    };

  return {
    name: accountData.username,
    id: accountData.ig_business_account_id,
    profilePicture: accountData.profile_picture_url,
    followers: accountData.followers?.toLocaleString(),
    amountSpent: undefined,
    currency: ''
  };
};
