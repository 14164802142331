import React from 'react'
import './PostModal.css'
import LinkedinCircle from "../../Assets/images/linkedin-circle.svg";
import TitokCircle from "../../Assets/images/tiktok-circle.svg";
import usrImage from '../../Assets/images/profile-img.png'
import SendOutlined from '../../Assets/images/SendOutlined.svg'

const PostModal = (props) => {
    return (
        <>
            <div className="post-of_date-time">
                <div className="post-of_ic-wrapper">
                    <div className="post-of-ic active">
                        <img src={LinkedinCircle} alt='image' />
                    </div>
                    <div onClick={() => { }} className="post-of-ic">
                        <img src={TitokCircle} alt='image' />
                    </div>
                </div>
                <div className="date-time text-muted">
                    <span>08:16 am</span>,
                    <span> 15 Apr 2023</span>
                </div>
                <button type="button" className="btn-close" onClick={() => { props.setModal(!props.modal) }}></button>
            </div>
            <div className="post-img">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYJbCE2pn9JcyIpsqksNN4T4qyQ2S_suFayQ&usqp=CAU" alt="Thumbnail" />
            </div>
            <div className="like-comm-share">
                <div className="likes">
                    <i className="bi bi-heart"></i> <span>40k</span> Likes
                </div>
                <div className="coments">
                    <i className="bi bi-chat"></i> <span>5</span> Comments
                </div>
                <div className="shares">
                    <i className="bi bi-send"></i> <span>6k</span> Shares
                </div>
                <div className="goto-plateform">
                    <button onClick={() => { }} type="button" className="btn btn-primary">Visit Page</button>
                </div>
            </div>
            <div className="hashtags-div">
                {[1, 2, 3].map((v, i) => {
                    return (
                        <span key={i} className='text-muted'>#Hashtags{v}</span>
                    )
                })}
            </div>
            <div className="comments-sec">
                {[1, 2, 3].map((v, i) => {
                    return (
                        <div key={i} className="comment-by">
                            <div className="comment-by-img">
                                <img src={LinkedinCircle} alt='image' />
                            </div>
                            <div className="name-n-comment">
                                <h4>Username {v}</h4>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam deleniti doloribus.</p>
                                <div className="comments-action">
                                    <a onClick={() => { alert("clicked " + v) }} className="btn-link" href="#">Reply</a>
                                    <a onClick={() => { alert("clicked " + v) }} className="btn-link" href="#">See Translation</a>
                                </div>
                            </div>
                            <div className="like-comment">
                                <i className="bi bi-heart"></i>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default PostModal
