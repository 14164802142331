import { LoaderFunctionArgs, defer } from 'react-router-dom';
import { DATE_SEARCH_PARAMS, USER_CONFIG } from 'src/configs';
import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { getFacebookPageOverviewAnalytics } from 'src/User/services/analytics/facebookPageAnalyticsService';

const facebookPageOverviewAnalyticsLoader = async (
  props: LoaderFunctionArgs
) => {
  const { request, params } = props;

  const requestUrl = new URL(request.url);
  const searchParams = new URLSearchParams(requestUrl.search);
  const startDate = searchParams.get(DATE_SEARCH_PARAMS.start);
  const endDate = searchParams.get(DATE_SEARCH_PARAMS.stop);

  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);

  const facebookPageInsightOverviewResponse = getFacebookPageOverviewAnalytics({
    date: { start: startDate, stop: endDate },
    brandId,
    facebookPageId: params.id ?? ''
  });

  return defer({ facebookPageInsightOverviewResponse });
};

export default facebookPageOverviewAnalyticsLoader;
