import classNames from 'classnames';
import rootStyles from 'src/User/features/CompetitionAnalysis/root.module.css';
import styles from './index.module.css';
import InstagramPostComponent from 'src/User/components/InstagramPost/Post';
import ComingSoonWrapper from 'src/User/components/ComingSoonWrapper';

const postTypes = [
  'img',
  'video',
  'carousel',
  'video',
  'carousel',
  'img'
] as const;

export default function Posts() {
  return (
    <div>
      <p className={classNames('my-4 w-100', rootStyles['title'])}>Posts</p>
      <div className='mt-2 w-100'>
        <div className={classNames('masonry', styles['posts-container'])}>
          {postTypes.map((variant, index) => (
            <ComingSoonWrapper key={index}>
              <InstagramPostComponent variant={variant} />
            </ComingSoonWrapper>
          ))}
        </div>
      </div>
    </div>
  );
}
