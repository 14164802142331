import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import {
  IFacebookPageAccount,
  IInstagramAccount,
  IMetaAdAccount
} from '../models/socialAccounts';

export function isListOfMetaAdAccount(obj: any): obj is IMetaAdAccount[] {
  return Array.isArray(obj) && obj.every(isMetaAdAccount);
}

export function isMetaAdAccount(obj: any): obj is IMetaAdAccount {
  return (
    obj &&
    typeof obj.act_account_id === 'string' &&
    typeof obj.meta_id === 'string' &&
    typeof obj.meta_name === 'string' &&
    typeof obj.connected === 'boolean' &&
    typeof obj.name === 'string' &&
    (obj.timezone_name === undefined ||
      typeof obj.timezone_name === 'string') &&
    (obj.account_status === undefined ||
      typeof obj.account_status === 'string') &&
    (obj.age === undefined || typeof obj.age === 'string') &&
    (obj.amount_spent === undefined || typeof obj.amount_spent === 'number') &&
    (obj.currency === undefined || typeof obj.currency === 'string')
  );
}

export function isIgBusinessAccount(obj: any): obj is IInstagramAccount {
  return (
    obj &&
    typeof obj.ig_business_account_id === 'string' &&
    typeof obj.facebook_page_id === 'string' &&
    typeof obj.meta_id === 'string' &&
    typeof obj.username === 'string' &&
    (obj.category === null || typeof obj.category === 'string') &&
    typeof obj.followers === 'number' &&
    typeof obj.following === 'number' &&
    typeof obj.media_count === 'number' &&
    (typeof obj.bio === 'string' || obj.bio === null) &&
    (typeof obj.profile_picture_url === 'string' ||
      obj.profile_picture_url === null) &&
    typeof obj.connected === 'boolean' &&
    (typeof obj.updated_at === 'string' || obj.updated_at === null) &&
    typeof obj.created_at === 'string'
  );
}

export function isFacebookPageAccount(obj: any): obj is IFacebookPageAccount {
  return (
    obj &&
    typeof obj.facebook_page_id === 'string' &&
    typeof obj.meta_id === 'string' &&
    typeof obj.name === 'string' &&
    typeof obj.connected === 'boolean' &&
    (typeof obj.about === 'string' || obj.about === null) &&
    (typeof obj.fan_count === 'number' || obj.fan_count === null) &&
    (typeof obj.cover === 'string' || obj.cover === null) &&
    (typeof obj.profile_pic === 'string' || obj.profile_pic === null) &&
    (typeof obj.category === 'string' || obj.category === null)
  );
}

export function isIBrand(data: any): data is IBrand {
  return (
    typeof data.name === 'string' &&
    typeof data.id === 'string' &&
    typeof data.create_at === 'string' &&
    typeof data.updated_at === 'string'
  );
}
