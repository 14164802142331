import React from 'react';
import styles from './index.module.css';
import classnames from 'classnames';
import { INSTAGRAM_OAUTH_CONFIG } from '../../constants';

export default function InstragamOauthModal() {
  return (
    <div>
      <p>
        At the moment are supported only Instagram Business accounts and creator
        accounts that have been connected to a Facebook page.
      </p>
      <p>
        To convert your instagram account to a business account, follow the
        instructions on the{' '}
        <a
          className={styles.link}
          href='https://help.instagram.com/502981923235522'
          target='_blank'
          rel='noreferrer'>
          Instagram Help Center
        </a>
        .
      </p>
      <p>
        To connect your facebook page to your instagram account, follow the
        instructions on the{' '}
        <a
          className={styles.link}
          href='https://help.instagram.com/399237934150902'
          target='_blank'
          rel='noreferrer'>
          Instagram Help Center
        </a>
        .
      </p>
    </div>
  );
}

InstragamOauthModal.headerTitle = 'Connect Instagram';

InstragamOauthModal.headerIcon = (
  <i
    className={classnames(
      'bi bi-instagram modal-icon',
      styles.headerContentIcon
    )}></i>
);

InstragamOauthModal.oauthReqUrl =
  `https://www.facebook.com/dialog/oauth?` +
  `client_id=${INSTAGRAM_OAUTH_CONFIG.clientId}&` +
  `redirect_uri=${INSTAGRAM_OAUTH_CONFIG.redirectUri}&` +
  `response_type=${INSTAGRAM_OAUTH_CONFIG.responseType}&` +
  `scope=${INSTAGRAM_OAUTH_CONFIG.scope}&` +
  `state=${INSTAGRAM_OAUTH_CONFIG.state}`;
