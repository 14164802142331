import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { logout as logoutService } from 'src/User/services/auth';
import useClearAppState from 'src/lib/hooks/useClearAppState';
import displayAxiosError from 'src/lib/utils/displayAxiosError';
import Swal from 'sweetalert2';
import classNames from 'classnames';
import styles from './navbar.module.css';
import { DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useRecoilValue } from 'recoil';
import userInfoState from 'src/User/recoil/Atoms/UserInforAtom';
import { Dropdown } from 'reactstrap';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from 'src/components/ui/select';
import { LANGUAGES } from 'src/i18n';

interface INavbarProps {
  translator: any;
  changeLanguageHandler: (languageValue: string) => void;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
}

const NavBar = ({
  toggleSidebar,
  changeLanguageHandler,
  isSidebarOpen,
  translator
}: INavbarProps) => {
  const navigate = useNavigate();
  const [drop, setDrop] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const clearAppState = useClearAppState();
  const userInfo = useRecoilValue(userInfoState);

  const toggleUserProfileMenu = () => {
    setDrop((prev) => !prev);
  };

  const logout = async () => {
    try {
      const response = await logoutService();

      if (response?.status == 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Sucessfully Logged Out !',
          showConfirmButton: false,
          timer: 1000
        });
        setDrop(false);
        clearAppState();
        navigate('/login');
      }
    } catch (error: any) {
      displayAxiosError(error, {
        title: 'Unable to logout',
        fallbackMsg: '',
        toast: true
      });
    }
  };

  return (
    <nav
      className={classNames(
        'card-style tw-sticky tw-w-full tw-bg-white tw-z-50 tw-top-0 tw-mb-3',
        styles['action-bar']
      )}>
      <div className={styles['nav-bar-control-panels-col']}>
        <button
          onClick={toggleSidebar}
          className={classNames('border-0 bg-transparent', styles['nav-btn'])}
          title={`${
            isSidebarOpen
              ? translator('expand-sidebar')
              : translator('collapse-sidebar')
          }`}
          type='submit'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            className={classNames(styles['nav-btn-icon'], styles['menu-icon'])}>
            <g
              fill='none'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}>
              {!isSidebarOpen ? (
                <>
                  <path d='M0 0h24v24H0z' />
                  <path
                    fill='currentColor'
                    d='M18 3a3 3 0 0 1 2.995 2.824L21 6v12a3 3 0 0 1-2.824 2.995L18 21H6a3 3 0 0 1-2.995-2.824L3 18V6a3 3 0 0 1 2.824-2.995L6 3h12zm0 2H9v14h9a1 1 0 0 0 .993-.883L19 18V6a1 1 0 0 0-.883-.993L18 5zm-2.293 4.293a1 1 0 0 1 .083 1.32l-.083.094L14.415 12l1.292 1.293a1 1 0 0 1 .083 1.32l-.083.094a1 1 0 0 1-1.32.083l-.094-.083l-2-2a1 1 0 0 1-.083-1.32l.083-.094l2-2a1 1 0 0 1 1.414 0z'
                  />
                </>
              ) : (
                <>
                  <path d='M0 0h24v24H0z' />
                  <path
                    fill='currentColor'
                    d='M18 3a3 3 0 0 1 2.995 2.824L21 6v12a3 3 0 0 1-2.824 2.995L18 21H6a3 3 0 0 1-2.995-2.824L3 18V6a3 3 0 0 1 2.824-2.995L6 3h12zm0 2H9v14h9a1 1 0 0 0 .993-.883L19 18V6a1 1 0 0 0-.883-.993L18 5zm-4.387 4.21l.094.083l2 2a1 1 0 0 1 .083 1.32l-.083.094l-2 2a1 1 0 0 1-1.497-1.32l.083-.094L13.585 12l-1.292-1.293a1 1 0 0 1-.083-1.32l.083-.094a1 1 0 0 1 1.32-.083z'
                  />
                </>
              )}
            </g>
          </svg>
        </button>
        <div className={styles['history-controls']}>
          <button
            type='button'
            onClick={() => navigate(-1)}
            className={styles['nav-btn']}
            title={translator('go-to-previous-page')}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={2}
              stroke='currentColor'
              className={styles['nav-btn-icon']}>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18'
              />
            </svg>
          </button>
          <button
            type='button'
            onClick={() => navigate(1)}
            className={styles['nav-btn']}
            title={translator('go-to-next-page')}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={2}
              stroke='currentColor'
              className={styles['nav-btn-icon']}>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3'
              />
            </svg>
          </button>
        </div>
        <div>
          <form
            className='search-box'
            onSubmit={(e) => e.preventDefault()}>
            <input
              type='text'
              className={classNames('form-control', styles['input-elt'])}
              id='search'
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              placeholder={translator('search')}
            />
            <button
              type='submit'
              className={classNames(
                'border-0 bg-transparent',
                styles['nav-btn'],
                styles['search-icon']
              )}
              title={translator('search-app')}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 256 256'
                className={classNames(styles['nav-btn-icon'])}
                strokeWidth={2}>
                <path
                  fill='currentColor'
                  d='M232.49 215.51L185 168a92.12 92.12 0 1 0-17 17l47.53 47.54a12 12 0 0 0 17-17ZM44 112a68 68 0 1 1 68 68a68.07 68.07 0 0 1-68-68Z'
                />
              </svg>
            </button>
          </form>
        </div>
      </div>

      <div className={styles['nav-bar-user-dropdown-col']}>
        <button
          className={classNames(
            'bg-transparent border-0',
            styles['nav-btn'],
            styles['notification-btn']
          )}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='currentColor'
            className={classNames(
              styles['nav-btn-icon'],
              styles['notification-icon']
            )}>
            <path d='M5.85 3.5a.75.75 0 00-1.117-1 9.719 9.719 0 00-2.348 4.876.75.75 0 001.479.248A8.219 8.219 0 015.85 3.5zM19.267 2.5a.75.75 0 10-1.118 1 8.22 8.22 0 011.987 4.124.75.75 0 001.48-.248A9.72 9.72 0 0019.266 2.5z' />
            <path
              fillRule='evenodd'
              d='M12 2.25A6.75 6.75 0 005.25 9v.75a8.217 8.217 0 01-2.119 5.52.75.75 0 00.298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 107.48 0 24.583 24.583 0 004.83-1.244.75.75 0 00.298-1.205 8.217 8.217 0 01-2.118-5.52V9A6.75 6.75 0 0012 2.25zM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 004.496 0l.002.1a2.25 2.25 0 11-4.5 0z'
              clipRule='evenodd'
            />
          </svg>
        </button>
        <Select
          defaultValue={'en'}
          onValueChange={changeLanguageHandler}>
          <SelectTrigger
            className='tw-w-full tw-max-w-fit tw-capitalize'
            title={translator('select-language')}>
            <SelectValue
              defaultValue={'en'}
              placeholder={translator('select-language')}
            />
          </SelectTrigger>
          <SelectContent>
            {Object.entries(LANGUAGES).map(([languageCode, language]) => (
              <SelectItem
                key={languageCode}
                value={languageCode}
                className='tw-text-base tw-font-normal tw-capitalize'>
                {language}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <div className='user-dropdown'>
          <Dropdown
            isOpen={drop}
            toggle={toggleUserProfileMenu}>
            <DropdownToggle
              className='user-dropdown-anchor'
              title={translator('user-menu-anchor-btn')}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='currentColor'
                className={classNames(
                  styles['nav-btn-icon'],
                  styles['user-profile-icon']
                )}>
                <path
                  fillRule='evenodd'
                  d='M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z'
                  clipRule='evenodd'
                />
              </svg>
              <span className={styles['user-dropdown-anchor-text']}>
                {userInfo?.first_name} {userInfo?.last_name}
              </span>
            </DropdownToggle>
            <DropdownMenu className={classNames(styles['user-dropdown-menu'])}>
              <DropdownItem tag={'div'}>
                <Link to='/change_password'>Change Password</Link>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={logout}
                tag={'button'}>
                Log Out
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
