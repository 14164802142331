import { Await, useLoaderData, useRouteError } from 'react-router-dom';
import CampaignsPerformance from './components/CampaignPerformance';
import CampaginEngagement from './components/CampaignEngagement';
import CampaignSummary from './components/CampaignSummary';
import OptionsMenu from './components/OptionsMenu';
import { omit } from 'lodash';
import { Suspense, Fragment } from 'react';
import { CampaignInfo, CampaignSummaryAnalytics } from './types';
import { TNamespace } from 'src/i18n';
import { useTranslation } from 'react-i18next';

const MetaAdsOverview = () => {
  const loaderData = useLoaderData() as any;

  return (
    <div className='tw-flex tw-flex-col tw-gap-6 tw-pb-8'>
      <OptionsMenuContainer loaderData={loaderData} />
      <DataVizContainer loaderData={loaderData} />
    </div>
  );
};

const OptionsMenuContainer = ({ loaderData }: { loaderData?: any }) => {
  return (
    <OptionsMenu.Container>
      <Suspense fallback={<OptionsMenu.ItemLoading itemType='campaignType' />}>
        <Await
          resolve={loaderData?.campaignObjectives}
          errorElement={<OptionsMenu.ItemError itemType='campaignType' />}>
          {(objectives: string[]) => {
            return (
              <OptionsMenu.Item
                itemType='campaignType'
                options={objectives?.map((objective) => ({
                  label: objective,
                  value: objective
                }))}
              />
            );
          }}
        </Await>
      </Suspense>
      <Suspense fallback={<OptionsMenu.ItemLoading itemType='campaign' />}>
        <Await
          resolve={loaderData?.campaignFilterList}
          errorElement={<OptionsMenu.ItemError itemType='campaign' />}>
          {(campaignFilterList: CampaignInfo[]) => {
            return (
              <OptionsMenu.Item
                itemType='campaign'
                options={campaignFilterList?.map(({ id, name }) => ({
                  label: name,
                  value: id
                }))}
              />
            );
          }}
        </Await>
      </Suspense>
    </OptionsMenu.Container>
  );
};

const DataVizContainer = ({ loaderData }: { loaderData?: any }) => {
  return (
    <Suspense fallback={<DataVizContainer.Loading />}>
      <Await
        resolve={loaderData?.adAnalyticsSummary}
        errorElement={<DataVizError />}>
        {(adAnalyticsSummary: CampaignSummaryAnalytics) => (
          <Fragment key={JSON.stringify(adAnalyticsSummary)}>
            <CampaignSummary
              data={omit(adAnalyticsSummary.summary, ['cpm'])}
              currency={adAnalyticsSummary.currency}
            />
            <CampaignsPerformance data={adAnalyticsSummary.campaigns} />
            <CampaginEngagement
              clicksByDate={adAnalyticsSummary.clicks_by_date}
              frequencyByDate={adAnalyticsSummary.frequency_by_date}
              impresssionsByDate={adAnalyticsSummary.impressions_by_date}
              reachByDate={adAnalyticsSummary.reach_by_date}
              spendingByDate={adAnalyticsSummary.spend_by_date}
            />
          </Fragment>
        )}
      </Await>
    </Suspense>
  );
};

DataVizContainer.Loading = () => (
  <>
    <CampaignSummary.Loading />
    <CampaignsPerformance.Loading />
    <CampaginEngagement.Loading />
  </>
);

const DataVizError = () => {
  const error = useRouteError() as { message?: string };
  const { t } = useTranslation<TNamespace>();

  return (
    <div className='tw-h-[60vh]'>
      <div className='tw-text-center tw-mt-5'>
        <h3 className='tw-font-semibold tw-mb-5'>
          {t('meta.summary.error.404', { ns: 'adAnalytics' })}{' '}
        </h3>
        <p className='tw-text-slate-600 tw-font-medium'>
          {error?.message
            ? t(`meta.summary.error.${error.message}`, error.message, {
                ns: 'adAnalytics'
              })
            : t('meta.summary.error.unspecified', { ns: 'adAnalytics' })}
        </p>
      </div>
    </div>
  );
};

export default MetaAdsOverview;
