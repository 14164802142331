import { defer, LoaderFunctionArgs } from 'react-router-dom';
import { USER_CONFIG } from 'src/configs';
import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { getConversation } from 'src/User/services/somate-ai';

export const conversationLoader = (props: LoaderFunctionArgs) => {
  const { params } = props;

  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);

  const conversationDetailsPromise = getConversation({
    brandId,
    conversationId: params.conversationId ?? ''
  });

  return defer({
    conversation: conversationDetailsPromise.then(({ data }) => data)
  });
};
