import { isEmpty, startCase } from 'lodash';
import { useMemo } from 'react';
import styles from './index.module.css';
import classNames from 'classnames';
import { Table } from 'src/User/features/Viz/components/Table';
import { POST_ANALYTICS_TABLE_COLS } from './constants';
import getTableData from '../../utils/getTableData';
import chartSytles from 'src/User/features/Viz/components/Chart/chart.module.css';
import { IInstagramBusinessMediaInsight } from '../../../../types';
import { TColumDef, TData } from 'src/User/features/Viz/components/types';
import { useTranslation } from 'react-i18next';
import { TNamespace } from 'src/i18n';

interface IPostAnalyticsTableProps {
  data?: IInstagramBusinessMediaInsight[];
  tableTitle?: string;
}

export default function PostAnalyticsTable(props: IPostAnalyticsTableProps) {
  const { data, tableTitle } = props;

  const { t } = useTranslation<TNamespace>();
  const { cumulativeData, data: tableData } = useMemo(
    () => getTableData(data),
    [data]
  );

  return (
    <PostAnalyticsTableView
      tableData={tableData}
      tableTitle={
        tableTitle ??
        startCase(
          t('list-of-posts', {
            ns: 'analytics'
          }) ?? ''
        )
      }
      tableColumnsDefs={POST_ANALYTICS_TABLE_COLS}
      cumulativeData={cumulativeData}
    />
  );
}

interface IPostAnalyticsTableViewProps {
  tableData: TData[];
  tableTitle: string;
  tableColumnsDefs: TColumDef;
  cumulativeData: Record<string, number>;
}

export function PostAnalyticsTableView(props: IPostAnalyticsTableViewProps) {
  const { tableData, tableTitle, tableColumnsDefs, cumulativeData } = props;

  return (
    <div className='shadow bg-white p-3 tw-rounded-xl tw-flex tw-flex-col tw-gap-5'>
      {!isEmpty(cumulativeData) ? (
        <div className={styles.engagementCardContainer}>
          {Object.entries(cumulativeData)?.map(([metric, value], index) => (
            <div
              className={classNames(
                'p-3 rounded d-flex flex-column-reverse justify-content-between gap-2',
                styles.cumulativeEngagementCardItem
              )}
              key={index}>
              <h5 className='d-flex align-items-end gap-1'>
                {startCase(metric)}{' '}
              </h5>
              <h2>
                {typeof value == 'number' ? value.toLocaleString() : 'N/A'}
              </h2>
            </div>
          ))}
        </div>
      ) : null}
      <div>
        <h4 className={classNames('mb-4', chartSytles['chart-title'])}>
          {tableTitle}
        </h4>
        <Table
          columnsDefs={tableColumnsDefs}
          data={tableData}
          pagination
          paginationPageSize={20}
          height={850}
        />
      </div>
    </div>
  );
}
