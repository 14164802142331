import AvatarWithFallback from 'src/User/components/AvatarWithFallback';
import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { getAllConnectSocialAccounts } from 'src/User/services/connected-social-accts';
import ColorfulFacebookIcon from 'src/components/icons/colorful-facebook';
import ColorfulInstagramIcon from 'src/components/icons/colorful-instagram';
import { USER_CONFIG } from 'src/configs';
import { ROUTER_PATHS } from 'src/lib/constants';

const analyticsLayoutLoader = async () => {
  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);

  const { data: response } = await getAllConnectSocialAccounts(brandId);

  const { data: socialAccounts } = response;
  const igBusinessAccounts =
    socialAccounts?.IG_BUSINESS?.map((account) => ({
      ...account,
      id: account.ig_business_account_id,
      name: account.username,
      path: `${ROUTER_PATHS.ANALYTICS_ROOT_LAYOUT}/${ROUTER_PATHS.ANALYTICS_INSTAGRAM_PAGE_LAYOUT}/${account.ig_business_account_id}/overview`,
      avatar: (props: any) => (
        <AvatarWithFallback
          {...props}
          src={account.profile_picture_url}
          alt={account.username}
        />
      ),
      socialMediaLogo: ColorfulInstagramIcon
    })) ?? [];

  const facebookPageAccounts =
    socialAccounts?.FACEBOOK_PAGE?.map((account) => ({
      ...account,
      id: account.facebook_page_id,
      name: account.name,
      path: `${ROUTER_PATHS.ANALYTICS_ROOT_LAYOUT}/${ROUTER_PATHS.ANALYTICS_FACEBOOK_PAGE_LAYOUT}/${account.facebook_page_id}/overview`,
      avatar: (props: any) => (
        <AvatarWithFallback
          {...props}
          src={account.profile_pic}
          alt={account.name}
        />
      ),
      socialMediaLogo: ColorfulFacebookIcon
    })) ?? [];

  const socialAccountsSelectorListOptions = [
    ...igBusinessAccounts,
    ...facebookPageAccounts
  ];

  return {
    socialAccountsSelectorListOptions,
    socialAccounts
  };
};

export default analyticsLayoutLoader;
