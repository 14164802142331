import analyticsLayoutLoader from './layout/loaders/analyticsLayoutLoader';
import instagramBusinessAnalyticsLoader from './views/InstagramAnalytics/loaders/instagramBusinessAnalyticsLoader';

export { default as RootErrorBoundary } from './layout/ErrorBoundary';
export { default as Layout } from './layout/Root';
export { default as InstagramBusinessErrorBoundary } from './views/InstagramAnalytics/ErrorBoundary';
export { default as AnalyticsIndexRoute } from './views/AnalyticsIndexRoute';
export { default as AnalyticsIndexRouteErrorBoundary } from './views/AnalyticsIndexRoute/ErrorBoundary';
export * as IGBusiness from './views/InstagramAnalytics';
export * from './views/FacebookAnalytics';

export const loaders = {
  analyticsLayoutLoader,
  instagramBusinessAnalyticsLoader
};
