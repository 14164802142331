import { Table, AvatarCellRenderer } from '../Viz/components/Table';
import { TColumDef } from '../Viz/components/types';
import classNames from 'classnames';
import { ICellRendererParams } from 'ag-grid-community';
import { COMPETITION_DATA } from './constants';
import styles from './root.module.css';
import settingsStyles from '../Settings/settings.module.css';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { IAccountCellRendererProps } from '../Viz/components/Table/components/AvaterCellRenderer';
import { Button } from 'src/components/ui/button';
import { CircleFadingPlus } from 'lucide-react';

const tableColDefs: TColumDef = [
  {
    field: 'account_info',
    headerName: 'Account',
    cellRenderer: AccountCellRenderer,
    cellStyle: { display: 'flex', alignItems: 'center' },
    sortable: true,
    autoHeight: true,
    flex: 1,
    minWidth: 300
  },
  {
    field: 'follower_count',
    headerName: 'Follower Count',
    cellRenderer: FollowerCellRenderer,
    cellRendererParams: '',
    autoHeight: true,
    sortable: true,
    flex: 0.5,
    minWidth: 180
  },
  {
    field: 'following_count',
    headerName: 'Following Count',
    cellRenderer: FollowerCellRenderer,
    cellRendererParams: '',
    autoHeight: true,
    sortable: true,
    flex: 0.5,
    minWidth: 180
  },
  {
    field: 'media_count',
    headerName: 'Media Count',
    cellRenderer: FollowerCellRenderer,
    cellRendererParams: '',
    autoHeight: true,
    sortable: true,
    flex: 0.5,
    minWidth: 180
  },
  {
    field: 'engagement_rate',
    headerName: 'Engagement Rate',
    cellRenderer: FollowerCellRenderer,
    cellRendererParams: '',
    autoHeight: true,
    sortable: true,
    flex: 0.5,
    minWidth: 180
  },
  {
    field: 'actions',
    headerName: 'Actions',
    sortable: true,
    autoHeight: true,
    cellRenderer: ActionsCellRenderer,
    cellStyle: { display: 'flex', alignItems: 'center' },
    flex: 0.7,
    minWidth: 180
  }
];

export default function CompAnalysisRoot() {
  return (
    <section>
      <hgroup className='d-flex justify-content-between align-items-center'>
        <h3 className={styles['title']}>Competition Analysis</h3>
        <Button size={'lg'}>
          Add Competitor
          <CircleFadingPlus className='tw-w-5 tw-h-5 tw-ml-2' />
        </Button>
      </hgroup>

      <div className='shadow my-3 p-3 rounded bg-white'>
        <div className=''>
          <Table
            isLoading={false}
            data={COMPETITION_DATA}
            columnsDefs={tableColDefs}
          />
        </div>
      </div>
    </section>
  );
}

type TFollowerCellValue = {
  value: number;
  change_count: number;
  change_rate: number;
};

function FollowerCellRenderer(
  props: ICellRendererParams<any, TFollowerCellValue>
) {
  const { value, change_rate, change_count } = props?.value ?? {};

  return (
    <div
      className={classNames(
        'd-flex flex-column justify-content-center py-3',
        styles['table-cell']
      )}>
      <p className={styles['cell-value']}>{value ?? 'N/A'}</p>
      <div className='d-flex gap-3'>
        <span
          className={classNames({
            [styles['success-badge']]: Math.sign(change_count ?? 1) == 1,
            [styles['danger-badge']]: Math.sign(change_count ?? -1) == -1
          })}>
          {change_count ?? 'N/A'}
        </span>
        <span
          className={classNames({
            [styles['success-badge']]: Math.sign(change_rate ?? 1) == 1,
            [styles['danger-badge']]: Math.sign(change_rate ?? -1) == -1
          })}>
          {change_rate ?? 'N/A'}
        </span>
      </div>
    </div>
  );
}

type TAccount = {
  avatar: string;
  username: string;
  name: string;
  borderRadius: IAccountCellRendererProps['borderRadius'];
};

function AccountCellRenderer(props: ICellRendererParams<any, TAccount>) {
  const { avatar, username, name, borderRadius } = props.value ?? {};

  return (
    <Link
      to='instagram/ipsum/overview'
      className={styles['account-info-cell']}>
      <AvatarCellRenderer
        avatar={avatar}
        username={username}
        name={name}
        borderRadius={borderRadius}
      />
    </Link>
  );
}

function ActionsCellRenderer(props: ICellRendererParams) {
  const togglePopupVisibility = () => {
    Swal.fire({
      title: 'Are you sure you want to remove this account',
      confirmButtonText: 'Yes',
      showDenyButton: true,
      denyButtonText: 'No'
    });
  };

  return (
    <div className='d-flex align-items-center h-100 py-4'>
      <button
        type='button'
        onClick={() => {
          togglePopupVisibility();
        }}
        className={classNames(
          settingsStyles['action-btn'],
          'd-flex justify-content-center align-items-center',
          [settingsStyles['delete-btn']]
        )}>
        <span className={styles['btn-text']}>Delete</span>
      </button>
    </div>
  );
}
