import { useNavigate } from 'react-router-dom';
import styles from '../settings.module.css';

export default function SubscriptionSettings() {
  const navigate = useNavigate();

  return (
    <div
      className={styles['setting-card']}
      id='subscription-setting'>
      <div className={styles['setting-card-head']}>
        <h5>Subscription</h5>
        <button
          onClick={() => {
            navigate('/upgrade-plan');
          }}
          className='btn btn-outline-primary'>
          Upgrade
        </button>
      </div>
      <div>
        <small>Current plan</small>
        <h4>
          $300<small>/&quot;year&quot;</small>
        </h4>
      </div>
    </div>
  );
}
