import ErrorRenderer from '../../../../components/Error/AnalyticsErrorRenderer';

const AnalyticsIndexRouteErrorBoundary = () => {
  return (
    <ErrorRenderer
      title='Error occured while fetching your social accounts'
      fallbackErrorMsg='please reload the page and try again'
    />
  );
};

export default AnalyticsIndexRouteErrorBoundary;
