import { ReactNode } from 'react';
import {
  Card as ShadcnCard,
  CardContent,
  CardHeader,
  CardTitle
} from 'src/components/ui/card';
import { cn } from 'src/lib/utils/shadcn-utils';

interface ICardProps {
  children: ReactNode;
  title: ReactNode;
  className?: string;
  cardContentProps?: {
    className?: string;
  };
}

const Card = ({ children, title, className, cardContentProps }: ICardProps) => (
  <ShadcnCard className={cn('tw-w-full', className)}>
    <CardHeader>
      <CardTitle>{title}</CardTitle>
    </CardHeader>
    <CardContent className={cn('!tw-pl-2', cardContentProps?.className)}>
      {children}
    </CardContent>
  </ShadcnCard>
);

export default Card;
