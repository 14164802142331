import Api from '../../Api';
import { IBrand } from '../recoil/Atoms/UserBrandAtom';

interface IAddTeamMember {
  email: string;
  role: TBrandUserRole;
  activeBrand: IBrand;
}

export type TBrandUserRole = 'BRAND_MANAGER' | 'CLIENT';

export const getTeamMember = async (activeBrand: IBrand) => {
  return await Api.get(`/brand/${activeBrand.id}/brand_users/`);
};

export const addTeamMember = async ({
  email,
  role,
  activeBrand
}: IAddTeamMember) => {
  const bodyParameters = { email, role };
  const brandId = activeBrand.id;

  return await Api.post(`/brand/${brandId}/add_user/`, bodyParameters);
};

// export const updateTeamMember = async (dataa) => {
//   const bodyParameters = {
//     name: dataa.name,
//     business_admin: dataa.badmin
//   };
//   return await Api.put(`workspace/${dataa.id}/`, bodyParameters);
// };

// export const deleteTeamMember = async (dataa) => {
//   return await Api.delete(`workspace/${dataa}/`);
// };
