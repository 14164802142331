import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { getConnectedSocialAccountsByType } from 'src/User/services/connected-social-accts';
import { USER_CONFIG } from 'src/configs';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { connectParentMetaAccount } from 'src/User/services/meta-ads';
import Swal from 'sweetalert2';
import {
  OAUTH_FLOW_STATUS,
  OAUTH_FLOW_STATUS_QUERY_KEY
} from '../../SelectOauthSocialMediaType/views/Integrations/constants';
import displayAxiosError from 'src/lib/utils/displayAxiosError';
import { startCase } from 'lodash';

const metaAdsAccountsLoader = async ({ request }: LoaderFunctionArgs) => {
  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);

  const requestURL = new URL(request.url);
  const searchParams = new URLSearchParams(requestURL.search);
  const oauthStateParam = searchParams.get('state');
  const oauthState = JSON.parse(oauthStateParam ?? '{}');

  // Do noting if oauth flow is pending
  if (oauthState?.[OAUTH_FLOW_STATUS_QUERY_KEY] === OAUTH_FLOW_STATUS.PENDING)
    return null;

  // Print error message if oauth flow failed
  if (searchParams.has('error')) {
    Swal.fire({
      title: 'Unable to connect meta account',
      text: searchParams.get('error') ?? 'Please try again, or contact support',
      icon: 'error',
      showConfirmButton: false,
      toast: true,
      position: 'top-right',
      timer: 7000,
      timerProgressBar: true
    });
  }

  // Send oauth code to the server if oauth flow is successful, then
  // redirect to the connect account profile page
  if (oauthState?.oauth_flow === 'meta-ads' && searchParams.has('code')) {
    try {
      const { data } = await connectParentMetaAccount({
        brandId,
        code: searchParams.get('code') as string
      });

      Swal.fire({
        title: 'Meta account connected successfully',
        icon: 'success',
        showConfirmButton: false,
        toast: true,
        position: 'top-right',
        timer: 5000,
        timerProgressBar: true
      });

      const { data: metaAdAccounts } = data;
      const metaId = metaAdAccounts[0]?.meta_id;

      if (metaId)
        return redirect(`/socials/meta-ads/${metaId}/connect-meta-ads-account`);
    } catch (error) {
      displayAxiosError(error, {
        title: 'Connection failed',
        fallbackMsg: `Unable to validate connection with ${startCase(
          oauthState?.oauth_flow
        )}`
      });
    }
  }

  const { data: reponse } = await getConnectedSocialAccountsByType({
    brandId,
    accountType: 'META_ADS'
  });
  const { data: allIgBusinessAccount } = reponse;

  return allIgBusinessAccount.META_ADS ?? [];
};

export default metaAdsAccountsLoader;
