import { ROUTER_PATHS } from 'src/lib/constants';
import { INavLinks } from '../types';
import { Sparkles } from 'lucide-react';

export const NAV_LINKS: INavLinks = {
  header: [
    {
      href: '/',
      icon: <i className='bi bi-house-fill'></i>,
      title: 'dashboard',
      i18nKey: 'dashboard',
      children: []
    },
    {
      href: `${ROUTER_PATHS.SOMATE_AI_LAYOUT}/${ROUTER_PATHS.SOMATE_AI_NEW_CONVERSATION}`,
      icon: (
        <Sparkles
          fill='inherit'
          className='tw-h-5 tw-w-5'
        />
      ),
      title: 'Somate AI',
      i18nKey: 'somate-ai',
      children: []
    },
    {
      href: '/createpost',
      icon: <i className='bi bi-house-fill'></i>,
      title: 'createpost',
      i18nKey: 'create-post',
      children: [],
      hidden: true
    },
    {
      href: '/posts',
      icon: <i className='bi bi-house-fill'></i>,
      title: 'posts',
      i18nKey: 'posts',
      children: [],
      hidden: true
    },
    {
      href: '/calendar',
      icon: <i className='bi bi-house-fill'></i>,
      title: 'calendar',
      i18nKey: 'calendar',
      children: [],
      hidden: true
    },
    {
      href: '/analytics',
      icon: <i className='bi bi-bar-chart-fill'></i>,
      i18nKey: 'analytics',
      title: 'analytics'
    },
    {
      href: '/ad-analytics',
      icon: <i className='bi bi-clipboard-data-fill'></i>,
      title: 'Ads Analytics',
      i18nKey: 'ads-analytics',
      children: []
    },
    {
      href: '/competition-analysis',
      icon: <i className='bi bi-pie-chart-fill'></i>,
      title: 'competition analysis',
      i18nKey: 'competition-analysis',
      children: [],
      hidden: true
    },
    {
      href: '/groups',
      icon: <i className='bi bi-people-fill'></i>,
      title: 'groups',
      i18nKey: 'groups',
      children: [],
      hidden: true
    },
    {
      href: '/reports',
      icon: <i className='bi bi-bar-chart-steps'></i>,
      i18nKey: 'reports',
      title: 'reports'
    }
  ],
  body: [
    {
      href: '/content',
      icon: <i className='bi bi-house-fill'></i>,
      title: 'content',
      i18nKey: 'content',
      hidden: true
    },
    {
      href: '/socials',
      icon: <i className='bi bi-globe-europe-africa'></i>,
      i18nKey: 'socials',
      title: 'socials'
    },
    {
      href: '/team',
      icon: <i className='bi bi-people-fill'></i>,
      i18nKey: 'team',
      title: 'team',
      hidden: true
    },
    {
      href: '/settings',
      icon: <i className='bi bi-gear-wide-connected'></i>,
      i18nKey: 'settings',
      title: 'settings',
      hidden: true
    }
  ],
  footer: [
    {
      href: '/support',
      icon: <i className='bi bi-sliders2'></i>,
      i18nKey: 'support',
      title: 'support'
    }
  ]
};
