import * as Router from 'react-router-dom';
import { DialogDescription } from 'src/components/ui/dialog';
import isEmpty from 'lodash/isEmpty';
import type * as ISocialAccounts from 'src/lib/models/socialAccounts';
import { useBrand } from 'src/User/layout/Sidebar/components/BrandSelect';
import UnControlledDialog from 'src/User/components/UnControlledDialog';
import {
  ConnectMetaAdsAccountField,
  IConnectFieldAccountDef
} from '../../../MetaAdsAccounts/views/ConnectMetaAds';
import connectIgBusinessAccountProfileLoader from './loaders/connectIgBusinessAccountProfileLoader';
import connectIgBusinessAccountProfileAction from './actions/connectIgBusinessAccountProfileAction';
import * as STATIC from './constants';
import { getUnifiedAccountData } from '../../../AllSocialAccounts/utils/getTableData';
import getFallbackDialogDescription from './utils/getFallbackDialogDescription';
import { startCase } from 'lodash';

type TLoaderData =
  | ISocialAccounts.IInstagramAccount[]
  | ISocialAccounts.IFacebookPageAccount[];

type TEmptyDialogDescriptionKey =
  keyof typeof STATIC.CONNECT_MEDIA_ACCOUNT_DIALOG_EMPTY_DESCRIPTION;

type TMediaAccountDialogTitlesKey =
  keyof typeof STATIC.CONNECT_MEDIA_ACCOUNT_DIALOG_TITLES;

const ConnectIgBusinessProfile = () => {
  // Hooks
  const { getCurrentBrand } = useBrand();
  const { mediaType } = Router.useParams();
  const navigate = Router.useNavigate();
  const allSocialAccounts = Router.useLoaderData() as TLoaderData;
  const submit = Router.useSubmit();

  // Vars
  const emptyDialogDescription =
    STATIC.CONNECT_MEDIA_ACCOUNT_DIALOG_EMPTY_DESCRIPTION?.[
      mediaType as TEmptyDialogDescriptionKey
    ] ?? getFallbackDialogDescription(mediaType);

  // Functions
  const { id: brandId } = getCurrentBrand();
  const redirectToSocials = () => navigate(`/socials/${mediaType}`);

  const onConnect = (props: IConnectFieldAccountDef & { meta_id: string }) => {
    const { accountId, connected, meta_id } = props;
    const formData = {
      brand_id: brandId,
      account_id: accountId,
      connected: `${!connected}`
    };

    submit(formData, {
      method: 'post',
      action: `/socials/${mediaType}/${meta_id}/connect-account`
    });
  };

  return (
    <UnControlledDialog
      title={
        STATIC.CONNECT_MEDIA_ACCOUNT_DIALOG_TITLES?.[
          mediaType as TMediaAccountDialogTitlesKey
        ] ?? `Connect ${startCase(mediaType)} Account Profiles`
      }
      open={true}
      onCancel={redirectToSocials}
      onOpenChange={redirectToSocials}>
      {isEmpty(allSocialAccounts) ? (
        <DialogDescription>{emptyDialogDescription}</DialogDescription>
      ) : (
        allSocialAccounts?.map((account, index) => {
          const unifiedAccountData = getUnifiedAccountData(account);

          return (
            <div
              key={`${account.meta_id}-${index}`}
              className='tw-flex tw-w-full tw-justify-between'>
              <ConnectMetaAdsAccountField
                brandId={brandId}
                onConnect={onConnect}
                accountData={{
                  name: unifiedAccountData.name,
                  connected: account.connected,
                  accountId: unifiedAccountData.id,
                  meta_id: account.meta_id
                }}
              />
            </div>
          );
        })
      )}
    </UnControlledDialog>
  );
};

export default ConnectIgBusinessProfile;
export {
  connectIgBusinessAccountProfileLoader,
  connectIgBusinessAccountProfileAction
};
