import { METRICS_DATA } from './constants';
import getChartData from './utils/getChartData';
import MetricView from '../../components/MetricsView';

export default function Engagement() {
  const { chartSeries, chartOptions } = getChartData();

  return (
    <MetricView
      metricsData={METRICS_DATA}
      chart={chartSeries.map((serie) => ({
        series: serie,
        options: chartOptions
      }))}
      showComingSoonOverlay
    />
  );
}
