import { useNavigate, useParams } from 'react-router-dom';
import IntegrationModal from './common/integration-modal';
import InstragamOauthModal from './views/instagram';
import MetaAdsOauthModal from './views/meta-ads';
import FaebokPageOauthModal from './views/facebook-page';
import LinkedinPageOauthModal from './views/linkedin-page';
import LinkedinAdsOauthModal from './views/linked-ads';

export const INTEGRATION_MODAL_MAPPER = {
  'ig-business': InstragamOauthModal,
  'meta-ads': MetaAdsOauthModal,
  'facebook-page': FaebokPageOauthModal,
  'linkedin-page': LinkedinPageOauthModal,
  'linkedin-ads': LinkedinAdsOauthModal
};

const IntegrationDialog = () => {
  const navigate = useNavigate();
  const { integrationMediaType } = useParams();
  const typedIntegrationMediaType =
    integrationMediaType as keyof typeof INTEGRATION_MODAL_MAPPER;

  const ActiveIntegration = INTEGRATION_MODAL_MAPPER[typedIntegrationMediaType];

  return (
    <IntegrationModal
      isOpen={true}
      toggleModal={() => navigate(-1)}
      header={{
        title: ActiveIntegration.headerTitle,
        icon: ActiveIntegration.headerIcon
      }}
      oauthReqUrl={ActiveIntegration.oauthReqUrl}>
      <ActiveIntegration />
    </IntegrationModal>
  );
};

export default IntegrationDialog;
