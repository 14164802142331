import { TColumDef } from 'src/User/features/Viz/components/types';
import PostPreviewCellRenderer from 'src/User/features/Analytics/views/InstagramAnalytics/views/Posts/components/PostPreviewCellRenderer';
import { startCase } from 'lodash';
import { IFacebookPageMediaInsight } from '../types';
import { format } from 'date-fns';
import i18next, { type TNamespace } from 'src/i18n';

type TKeys =
  | Exclude<
      keyof IFacebookPageMediaInsight,
      'full_picture' | 'message' | 'permalink_url'
    >
  | 'media_preview_details';

export const createTableColDefs = (tableFields: TKeys[]) => {
  const columnDefs: TColumDef = tableFields.map((key) => ({
    field: key,
    headerName: startCase(
      i18next.t(key, { ns: 'analytics' satisfies TNamespace }) ?? ''
    ),
    minWidth:
      key === 'media_preview_details' ? 400 : key.length > 10 ? 200 : 150,
    sortable: true,
    autoHeight: true,
    flex: 1,
    valueFormatter:
      key === 'created_time'
        ? ({ value }) => format(new Date(value), 'MMM dd yyyy')
        : undefined,
    cellRenderer:
      key === 'media_preview_details' ? PostPreviewCellRenderer : undefined,
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    }
  }));

  return columnDefs;
};
