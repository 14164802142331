import { useRouteLoaderData } from 'react-router-dom';
import { InstagramAnalyticsLoaderData } from '../models';
import PostAnalyticsTable from './components/PostAnalyticsTable';
import { ROUTER_PATHS_ID } from 'src/lib/constants';

export default function PostsAnalytics() {
  const { mediaInsights: data } = useRouteLoaderData(
    ROUTER_PATHS_ID.IG_BUSINESS_ANALYTICS_LAYOUT
  ) as InstagramAnalyticsLoaderData;

  const posts = data?.filter(
    ({ media_product_type }) => media_product_type == 'FEED'
  );

  return (
    <div
      className='w-100'
      id='posts-analytics'>
      <PostAnalyticsTable data={posts} />
    </div>
  );
}
