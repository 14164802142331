import React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import {
  Tooltip as ShadcnTooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from 'src/components/ui/tooltip';

type TTooltipProps = TooltipPrimitive.TooltipTriggerProps & {
  children: React.ReactNode;
  tooltipContent: React.ReactNode;
  placement?: 'top' | 'right' | 'bottom' | 'left';
};

const Tooltip = ({
  children,
  tooltipContent,
  placement = 'top',
  ...rest
}: TTooltipProps) => {
  return (
    <>
      <TooltipProvider delayDuration={350}>
        <ShadcnTooltip>
          <TooltipTrigger {...rest}>{children}</TooltipTrigger>
          {tooltipContent ? (
            <TooltipContent side={placement}>{tooltipContent}</TooltipContent>
          ) : null}
        </ShadcnTooltip>
      </TooltipProvider>
    </>
  );
};

export default Tooltip;
