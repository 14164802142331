import { round, sum } from 'lodash';

/**
 * Aggregates metric insights by summing or averaging the values.
 *
 * @param {string} metric - The metric type to aggregate.
 * @param {(string | number)[]} insights - An array of insights which can be either strings or numbers.
 * @returns {number} - The aggregated metric value, rounded to 2 decimal places.
 *
 * @example
 * // Returns 3.00
 * aggregateMetricInsights('cpc', [2, 3, '4']);
 *
 * @example
 * // Returns 9.00
 * aggregateMetricInsights('clicks', [2, 3, '4']);
 */
const aggregateMetricInsights = (
  metric: string,
  insights: (string | number)[]
) => {
  const exceptions = ['cpc', 'ctr', 'cpm', 'cpp'];
  const insightsAsNumbers = insights.map((item) => Number(item) || 0);

  if (exceptions.includes(metric)) {
    return round(sum(insightsAsNumbers) / insightsAsNumbers.length, 2);
  }

  return round(sum(insightsAsNumbers), 2);
};

export default aggregateMetricInsights;
