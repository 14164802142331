import { isEmpty } from 'lodash';
import { IInstagramBusinessAudience } from '../../..';

export default function getGenderData(data?: IInstagramBusinessAudience) {
  const initialData = {
    data: {
      male: 0,
      female: 0
    },
    topGenderData: {
      name: 'N/A',
      count: 0
    }
  };
  const { audience_gender_age: genderData } = data ?? {};

  if (isEmpty(genderData)) return initialData;

  const formattedData = Object.entries(genderData).reduce(
    (acc, [key, value]) => {
      if (key.startsWith('M'))
        return {
          ...acc,
          male: acc.male + value
        };

      if (key.startsWith('F'))
        return {
          ...acc,
          female: acc.female + value
        };

      return acc;
    },
    {
      male: 0,
      female: 0
    }
  );

  const topGenderData = Object.entries(formattedData).reduce(
    (acc, [name, count]) => {
      if (count >= acc.count)
        return {
          name,
          count
        };

      return acc;
    },
    {
      name: 'N/A',
      count: 0
    }
  );

  return {
    data: formattedData,
    topGenderData
  };
}
