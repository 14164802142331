import React, { useState } from 'react';
import './Groups.css';
import wsLogo from '../../../Assets/images/workspace.jpg';
import IntaCircle from '../../../Assets/images/insta-circle.svg';
import tabsHome from '../../../Assets/images/home.png';
import tabsHomeActive from '../../../Assets/images/home-active.svg';
import tabsFeed from '../../../Assets/images/feed.svg';
import tabsFeedActive from '../../../Assets/images/feed-active.svg';
import tabsMaterials from '../../../Assets/images/materials.svg';
import tabsMaterialsActive from '../../../Assets/images/materials-active.svg';
import tabsHomePattern from '../../../Assets/images/tab-home-pattern.svg';
import tabsFeedPattern from '../../../Assets/images/tab-feed-pattern.svg';
import tabsTutsPattern from '../../../Assets/images/tab-tutorials-pattern.svg';
import NewsImg from '../../../Assets/images/workspace.jpg';
import GroupsModal from '../../components/GroupsModal';
import ProfileImg from '../../../Assets/images/profile-img.png';
import CommentsModal from '../../components/CommentsModal';

import { Modal, ModalBody } from 'reactstrap';

const Groups = (props) => {
  const [modal, setModal] = useState(false);
  const toggle2 = () => setModal(!modal);

  const [modal2, setModal2] = useState(false);
  const toggle3 = () => setModal2(!modal2);
  return (
    <>
      <div className='groups-wrapper'>
        {/* <div className='groups-list'>
                    <div className="groups-item card-style">
                        <div className="groups-pro-thumb">
                            <img src={wsLogo} alt='feed logo' />
                        </div>
                        <h3>Home</h3>
                        <p className='text-muted'>News & Upcoming meeting</p>
                        <div className='group-accounts'>
                            <span className='group-accounts-itm'>
                                <img src={IntaCircle} alt='feed logo' />
                            </span>
                            <span className='group-accounts-itm'>
                                <img src={IntaCircle} alt='feed logo' />
                            </span>
                            <span className='group-accounts-itm'>
                                <img src={IntaCircle} alt='feed logo' />
                            </span>
                            <span className='group-accounts-itm'>
                                <img src={IntaCircle} alt='feed logo' />
                            </span>
                        </div>
                        <div className="item-action">
                            <div className="dropdown">
                                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-three-dots-vertical"></i>
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#GroupsModal"><i className="bi bi-newspaper"></i> Group News Feed</a></li>
                                    <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#GroupsModal"><i className="bi bi-book"></i> Learning Materials</a></li>
                                    <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#GroupsModal"><i className="bi bi-calendar-week"></i> Upcoming Session</a></li>
                                    <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#GroupsModal"><i className="bi bi-camera-reels"></i> Recorded  Session</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="groups-item card-style">
                        <div className="groups-pro-thumb">
                            <img src={wsLogo} alt='feed logo' />
                        </div>
                        <h3>Feed</h3>
                        <p className='text-muted'>Chats & Topics</p>
                        <div className='group-accounts'>
                            <span className='group-accounts-itm'>
                                <img src={IntaCircle} alt='feed logo' />
                            </span>
                            <span className='group-accounts-itm'>
                                <img src={IntaCircle} alt='feed logo' />
                            </span>
                            <span className='group-accounts-itm'>
                                <img src={IntaCircle} alt='feed logo' />
                            </span>
                            <span className='group-accounts-itm'>
                                <img src={IntaCircle} alt='feed logo' />
                            </span>
                        </div>
                        <div className="item-action">
                            <div className="dropdown">
                                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-three-dots-vertical"></i>
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#GroupsModal"><i className="bi bi-newspaper"></i> Group News Feed</a></li>
                                    <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#GroupsModal"><i className="bi bi-book"></i> Learning Materials</a></li>
                                    <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#GroupsModal"><i className="bi bi-calendar-week"></i> Upcoming Session</a></li>
                                    <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#GroupsModal"><i className="bi bi-camera-reels"></i> Recorded  Session</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="groups-item card-style">
                        <div className="groups-pro-thumb">
                            <img src={wsLogo} alt='feed logo' />
                        </div>
                        <h3>Meetings</h3>
                        <p className='text-muted'>Upcoming & Archived Meetings</p>
                        <div className='group-accounts'>
                            <span className='group-accounts-itm'>
                                <img src={IntaCircle} alt='feed logo' />
                            </span>
                            <span className='group-accounts-itm'>
                                <img src={IntaCircle} alt='feed logo' />
                            </span>
                            <span className='group-accounts-itm'>
                                <img src={IntaCircle} alt='feed logo' />
                            </span>
                            <span className='group-accounts-itm'>
                                <img src={IntaCircle} alt='feed logo' />
                            </span>
                        </div>
                        <div className="item-action">
                            <div className="dropdown">
                                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-three-dots-vertical"></i>
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#GroupsModal"><i className="bi bi-newspaper"></i> Group News Feed</a></li>
                                    <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#GroupsModal"><i className="bi bi-book"></i> Learning Materials</a></li>
                                    <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#GroupsModal"><i className="bi bi-calendar-week"></i> Upcoming Session</a></li>
                                    <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#GroupsModal"><i className="bi bi-camera-reels"></i> Recorded  Session</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="groups-item card-style">
                        <div className="groups-pro-thumb">
                            <img src={wsLogo} alt='feed logo' />
                        </div>
                        <h3>Materials</h3>
                        <p className='text-muted'>Learning Material</p>
                        <div className='group-accounts'>
                            <span className='group-accounts-itm'>
                                <img src={IntaCircle} alt='feed logo' />
                            </span>
                            <span className='group-accounts-itm'>
                                <img src={IntaCircle} alt='feed logo' />
                            </span>
                            <span className='group-accounts-itm'>
                                <img src={IntaCircle} alt='feed logo' />
                            </span>
                            <span className='group-accounts-itm'>
                                <img src={IntaCircle} alt='feed logo' />
                            </span>
                        </div>
                        <div className="item-action">
                            <div className="dropdown">
                                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-three-dots-vertical"></i>
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#GroupsModal"><i className="bi bi-newspaper"></i> Group News Feed</a></li>
                                    <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#GroupsModal"><i className="bi bi-book"></i> Learning Materials</a></li>
                                    <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#GroupsModal"><i className="bi bi-calendar-week"></i> Upcoming Session</a></li>
                                    <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#GroupsModal"><i className="bi bi-camera-reels"></i> Recorded  Session</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div onClick={()=>{ }} className="groups-item add-groups">
                        <button type="button" className="btn btn-outline-secondary">
                            <i className="bi bi-plus"></i> Add Group
                        </button>
                    </div>
                </div> */}
        <div className='groups-tabs-wrapper'>
          <nav>
            <div
              className='nav nav-tabs'
              id='nav-tab'
              role='tablist'>
              <button
                className='nav-link active'
                id='nav-home-tab'
                data-bs-toggle='tab'
                data-bs-target='#nav-home'
                type='button'
                role='tab'
                aria-controls='nav-home'
                aria-selected='true'>
                <div className='tabs-icon'>
                  <img
                    src={tabsHome}
                    alt='Home'
                  />
                  <img
                    src={tabsHomeActive}
                    alt='Home'
                    className='active-img'
                  />
                </div>
                <div className='tabs-title'>
                  <h4>Home</h4>
                  <p>News & Upcoming meeting</p>
                </div>
                <img
                  src={tabsHomePattern}
                  alt='Home pattern'
                  className='tab-pattern'
                />
              </button>
              <button
                className='nav-link'
                id='nav-feed-tab'
                data-bs-toggle='tab'
                data-bs-target='#nav-feed'
                type='button'
                role='tab'
                aria-controls='nav-feed'
                aria-selected='false'>
                <div className='tabs-icon'>
                  <img
                    src={tabsFeed}
                    alt='Home'
                  />
                  <img
                    src={tabsFeedActive}
                    alt='Home'
                    className='active-img'
                  />
                </div>
                <div className='tabs-title'>
                  <h4>Feed</h4>
                  <p>Chats & Topics</p>
                </div>
                <img
                  src={tabsFeedPattern}
                  alt='Feed pattern'
                  className='tab-pattern'
                />
              </button>
              <button
                className='nav-link'
                id='nav-materials-tab'
                data-bs-toggle='tab'
                data-bs-target='#nav-materials'
                type='button'
                role='tab'
                aria-controls='nav-materials'
                aria-selected='false'>
                <div className='tabs-icon'>
                  <img
                    src={tabsMaterials}
                    alt='Home'
                  />
                  <img
                    src={tabsMaterialsActive}
                    alt='Home'
                    className='active-img'
                  />
                </div>
                <div className='tabs-title'>
                  <h4>Materials</h4>
                  <p>Learning Material</p>
                </div>
                <img
                  src={tabsTutsPattern}
                  alt='Tutorials pattern'
                  className='tab-pattern'
                />
              </button>
            </div>
          </nav>
          <div
            className='tab-content'
            id='nav-tabContent'>
            <div
              className='tab-pane fade show active'
              id='nav-home'
              role='tabpanel'
              aria-labelledby='nav-home-tab'
              tabIndex='0'>
              <div className='news-card-wrapper'>
                <div className='news-card'>
                  <img
                    src={NewsImg}
                    alt='news'
                  />
                  <div className='news-card-content'>
                    <h3>Workspace</h3>
                    <small>20 Apr 2023</small>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Consectetur porro, aut ratione magnam nisi adipisci quas
                      incidunt inventore nemo? Consectetur recusandae est illum
                      molestiae tempora inventore, pariatur impedit mollitia
                      repellendus!
                    </p>
                  </div>
                </div>
                <div className='news-card'>
                  <img
                    src={NewsImg}
                    alt='news'
                  />
                  <div className='news-card-content'>
                    <h3>Workspace</h3>
                    <small>20 Apr 2023</small>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Consectetur porro, aut ratione magnam nisi adipisci quas
                      incidunt inventore nemo? Consectetur recusandae est illum
                      molestiae tempora inventore, pariatur impedit mollitia
                      repellendus!
                    </p>
                  </div>
                </div>
                <div className='news-card'>
                  <img
                    src={NewsImg}
                    alt='news'
                  />
                  <div className='news-card-content'>
                    <h3>Workspace</h3>
                    <small>20 Apr 2023</small>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Consectetur porro, aut ratione magnam nisi adipisci quas
                      incidunt inventore nemo? Consectetur recusandae est illum
                      molestiae tempora inventore, pariatur impedit mollitia
                      repellendus!
                    </p>
                  </div>
                </div>
              </div>
              <div className='text-center'>
                <button
                  className='btn btn-primary my-4 mx-auto'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  Load more
                </button>
              </div>
              <div className='upcoming-meetings'>
                <h4>Upcoming Meetings</h4>

                <div className='upcoming-meetings-lists'>
                  <div className='upcoming-meetings-card card-style'>
                    <div className='date-cell'>
                      <h5>29</h5>
                      <span>May</span>
                    </div>
                    <div className='card-details'>
                      <p className='meeting-tagline'>Meeting Tagline</p>
                      <h4>Upcoming Meetings Title</h4>
                    </div>
                    <div className='time-cell'>
                      <span>11:30 AM</span>
                    </div>
                  </div>
                  <div className='upcoming-meetings-card card-style'>
                    <div className='date-cell'>
                      <h5>29</h5>
                      <span>May</span>
                    </div>
                    <div className='card-details'>
                      <p className='meeting-tagline'>Meeting Tagline</p>
                      <h4>Upcoming Meetings Title</h4>
                    </div>
                    <div className='time-cell'>
                      <span>11:30 AM</span>
                    </div>
                  </div>
                  <div className='upcoming-meetings-card card-style'>
                    <div className='date-cell'>
                      <h5>29</h5>
                      <span>May</span>
                    </div>
                    <div className='card-details'>
                      <p className='meeting-tagline'>Meeting Tagline</p>
                      <h4>Upcoming Meetings Title</h4>
                    </div>
                    <div className='time-cell'>
                      <span>11:30 AM</span>
                    </div>
                  </div>
                  <div className='upcoming-meetings-card card-style'>
                    <div className='date-cell'>
                      <h5>29</h5>
                      <span>May</span>
                    </div>
                    <div className='card-details'>
                      <p className='meeting-tagline'>Meeting Tagline</p>
                      <h4>Upcoming Meetings Title</h4>
                    </div>
                    <div className='time-cell'>
                      <span>11:30 AM</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='tab-pane fade'
              id='nav-feed'
              role='tabpanel'
              aria-labelledby='nav-feed-tab'
              tabIndex='0'>
              <div className='chat-wrapper'>
                <div className='topic-cell'>
                  <div className='topic-cell-head'>
                    <h5>All Topics</h5>
                    <small>203 Messages, 5 Unread chats</small>
                  </div>
                  <div className='search-topic'>
                    <input type='search' />
                    <span className='search-ic'>
                      <i className='bi bi-search'></i>
                    </span>
                  </div>
                  <div className='topic-list'>
                    <div className='topic-list-item'>
                      <div className='topic-thumb'>
                        <img
                          src={NewsImg}
                          alt='news'
                        />
                      </div>
                      <div className='topic-details'>
                        <h5>
                          Topic 1 <span className='count-badge'>3</span>{' '}
                          <span className='topic-time'>20m</span>
                        </h5>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Eligendi excepturi dolore qui nostrum...
                        </p>
                      </div>
                    </div>
                    <div className='topic-list-item'>
                      <div className='topic-thumb'>
                        <img
                          src={NewsImg}
                          alt='news'
                        />
                      </div>
                      <div className='topic-details'>
                        <h5>
                          Topic 1 <span className='count-badge'>3</span>{' '}
                          <span className='topic-time'>20m</span>
                        </h5>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Eligendi excepturi dolore qui nostrum...
                        </p>
                      </div>
                    </div>
                    <div className='topic-list-item'>
                      <div className='topic-thumb'>
                        <img
                          src={NewsImg}
                          alt='news'
                        />
                      </div>
                      <div className='topic-details'>
                        <h5>
                          Topic 1 <span className='count-badge'>3</span>{' '}
                          <span className='topic-time'>20m</span>
                        </h5>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Eligendi excepturi dolore qui nostrum...
                        </p>
                      </div>
                    </div>
                    <div className='topic-list-item'>
                      <div className='topic-thumb'>
                        <img
                          src={NewsImg}
                          alt='news'
                        />
                      </div>
                      <div className='topic-details'>
                        <h5>
                          Topic 1 <span className='count-badge'>3</span>{' '}
                          <span className='topic-time'>20m</span>
                        </h5>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Eligendi excepturi dolore qui nostrum...
                        </p>
                      </div>
                    </div>
                    <div className='topic-list-item'>
                      <div className='topic-thumb'>
                        <img
                          src={NewsImg}
                          alt='news'
                        />
                      </div>
                      <div className='topic-details'>
                        <h5>
                          Topic 1 <span className='count-badge'>3</span>{' '}
                          <span className='topic-time'>20m</span>
                        </h5>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Eligendi excepturi dolore qui nostrum...
                        </p>
                      </div>
                    </div>
                    <div className='topic-list-item'>
                      <div className='topic-thumb'>
                        <img
                          src={NewsImg}
                          alt='news'
                        />
                      </div>
                      <div className='topic-details'>
                        <h5>
                          Topic 1 <span className='count-badge'>3</span>{' '}
                          <span className='topic-time'>20m</span>
                        </h5>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Eligendi excepturi dolore qui nostrum...
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='chatting-cell'>
                  <div className='chatting-cell-head'>
                    {/* <div className="group-profile">
                                            <img src={ProfileImg} alt='profile' />
                                            <small>My profile</small>
                                        </div> */}
                    <div
                      className='active-group'
                      onClick={() => {
                        setModal2(!modal2);
                      }}>
                      <h5>Groups 1</h5>
                      <small>5 Members</small>
                    </div>
                    <div className='search-chat-wrapper'>
                      <span className='search-chat'>
                        <i className='bi bi-search'></i>
                      </span>
                      <span className='search-more'>
                        <i className='bi bi-three-dots-vertical'></i>
                      </span>
                    </div>
                  </div>
                  <div className='chatting-area'>
                    <div className='chat-date'>
                      <span>07 May, 2023</span>
                    </div>
                    <div className='chat-left'>
                      <div className='member-img'>
                        <img
                          src={ProfileImg}
                          alt='Profile'
                        />
                      </div>
                      <span>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </span>
                    </div>
                    <div className='chat-right'>
                      <span>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ut, sed!
                      </span>
                    </div>
                    <div className='chat-left'>
                      <div className='member-img'>
                        <img
                          src={ProfileImg}
                          alt='Profile'
                        />
                      </div>
                      <span>
                        Nesciunt dolorum repellendus maiores repellat?
                      </span>
                    </div>
                    <div className='chat-left'>
                      <div className='member-img'>
                        <img
                          src={ProfileImg}
                          alt='Profile'
                        />
                      </div>
                      <span>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </span>
                    </div>
                    <div className='chat-right'>
                      <span>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ut, sed!
                      </span>
                    </div>
                    <div className='chat-left'>
                      <div className='member-img'>
                        <img
                          src={ProfileImg}
                          alt='Profile'
                        />
                      </div>
                      <span>
                        Nesciunt dolorum repellendus maiores repellat?
                      </span>
                    </div>
                    <div className='chat-left'>
                      <div className='member-img'>
                        <img
                          src={ProfileImg}
                          alt='Profile'
                        />
                      </div>
                      <span>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </span>
                    </div>
                    <div className='chat-right'>
                      <span>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ut, sed!
                      </span>
                    </div>
                    <div className='chat-left'>
                      <div className='member-img'>
                        <img
                          src={ProfileImg}
                          alt='Profile'
                        />
                      </div>
                      <span>
                        Nesciunt dolorum repellendus maiores repellat?
                      </span>
                    </div>
                    <div className='chat-left'>
                      <div className='member-img'>
                        <img
                          src={ProfileImg}
                          alt='Profile'
                        />
                      </div>
                      <span>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </span>
                    </div>
                    <div className='chat-right'>
                      <span>Lorem ipsum Ut, sed!</span>
                    </div>
                    <div className='chat-left'>
                      <div className='member-img'>
                        <img
                          src={ProfileImg}
                          alt='Profile'
                        />
                      </div>
                      <span>
                        Nesciunt dolorum repellendus maiores repellat?
                      </span>
                    </div>
                  </div>
                  <div className='chat-input'>
                    <input
                      type='text'
                      placeholder='Your message...'
                    />
                    <div className='send-ic'>
                      <i className='bi bi-send-fill'></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='tab-pane fade'
              id='nav-materials'
              role='tabpanel'
              aria-labelledby='nav-materials-tab'
              tabIndex='0'>
              <div className='materials-filter'>
                <ul className='nav nav-tabs'>
                  <li className='nav-item'>
                    <a
                      className='nav-link active'
                      aria-current='page'
                      href='#'>
                      All Materials
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      href='#'>
                      Recent
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      href='#'>
                      Upcoming
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link disabled'>Saved</a>
                  </li>
                </ul>
              </div>
              <div className='material-lists'>
                <div className='material-list-items'>
                  <div className='material-list-items'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="modal fade" id="GroupsModal" tabIndex="-1" aria-labelledby="GroupsModalLabel" aria-hidden="true"> */}
      <Modal
        centered
        isOpen={modal}
        toggle={toggle2}>
        <ModalBody>
          <CommentsModal
            modal={modal}
            setModal={setModal}
          />
        </ModalBody>
      </Modal>
      <Modal
        centered
        size='md'
        isOpen={modal2}
        toggle={toggle3}>
        <ModalBody>
          <GroupsModal
            modal2={modal2}
            setModal2={setModal2}
          />
        </ModalBody>
      </Modal>
      {/* </div> */}
    </>
  );
};

export default Groups;
