import ColorPicker from 'src/User/components/ColorPicker';
import classNames from 'classnames';
import styles from './templateTheme.module.css';
import sidebarStyles from '../../layout/sidebar/index.module.css';
import SOmateLogoBg from 'src/Assets/images/somate-logo.svg';
import coverBgStyles from '../CoverBackground/coverBg.module.css';
import { Chart } from 'src/User/features/Viz/components/Chart';
import getAgeData from 'src/User/features/Analytics/views/InstagramAnalytics/views/Audience/utils/getAgeData';
import { API_AUDIENCE_DATA } from 'src/User/features/Analytics/views/InstagramAnalytics/views/Audience/constants/index';
import ChartHeaderFilters from 'src/User/features/Viz/components/Chart/components/ChartHeaderFilters';
import { ITemplateSectionProps } from '../../models';

export default function TemplateTheme(props: ITemplateSectionProps) {
  const { templateData, dispatcher } = props;
  const pieChartData = getAgeData(API_AUDIENCE_DATA);

  const genderChartData = Object.entries(pieChartData.data).reduce(
    (acc, [, value]) => {
      acc.push(value);

      return acc;
    },
    [] as number[]
  );

  const colors = Object.entries(templateData.colors)
    .map(([, color]) => color)
    // Only take the first 5 colors
    // bacause the backend only supports 5 colors
    .filter((col, index) => index < 5);
  const logo =
    templateData.logo instanceof File
      ? URL.createObjectURL(templateData.logo)
      : templateData.logo ?? SOmateLogoBg;

  return (
    <div className='d-flex flex-column justify-content-between gap-5'>
      <div className={classNames('d-flex gap-5', styles['controls'])}>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className='d-flex flex-column gap-5'>
            <fieldset className='d-flex flex-column gap-4'>
              <div>
                <h4 className={'tw-text-2xl tw-font-medium tw-mb-0'}>Logo</h4>
                <p className={classNames('m-0', sidebarStyles['subtitle'])}>
                  Dimension: XX * XXpx
                </p>
              </div>
              <label htmlFor='report_template_logo'>
                <div
                  className={classNames(
                    'position-relative',
                    coverBgStyles['image-select']
                  )}>
                  <p
                    className={classNames(
                      'position-absolute px-2',
                      coverBgStyles['image-select-text']
                    )}>
                    Click to select image
                  </p>
                  <div className={coverBgStyles['image-container']}>
                    <img
                      src={logo}
                      alt='Template pages logo'
                    />
                  </div>
                </div>
              </label>
              <input
                type='file'
                accept='image/*'
                id='report_template_logo'
                className='d-none'
                onChange={(e) => {
                  const file = e.target.files?.[0];

                  if (file && file?.type.startsWith('image'))
                    dispatcher({
                      type: 'UPDATE_LOGO_IMG',
                      payload: file
                    });
                }}
              />
            </fieldset>
          </div>
        </form>
        <div>
          <h4 className='tw-text-2xl tw-font-medium'>Pick your colors</h4>
          <div className={classNames(styles['color-picker-container'])}>
            {Object.entries(templateData.colors)
              // Only take the first 5 colors
              // bacause the backend only supports 5 colors
              .filter(([key]) => parseInt(key) <= 5)
              .map(([key, color]) => (
                <ColorPicker
                  key={key}
                  initialColor={color}
                  onColorChange={(color) =>
                    dispatcher({
                      type: 'UPDATE_COLOR',
                      payload: {
                        [key]: color
                      }
                    })
                  }
                />
              ))}
          </div>
        </div>
      </div>
      <div
        className={classNames(
          'h-100 d-flex flex-column justify-content-between gap-3',
          coverBgStyles['preview']
        )}>
        <p
          className={classNames(
            'd-flex justify-content-start',
            coverBgStyles['cover-title']
          )}>
          Gender Analysis
        </p>
        <div className={'w-100 d-flex align-items-baseline'}>
          {/* Donut chart sample */}
          <div className={styles['pie-chart-container']}>
            <Chart
              data={{
                options: {
                  legend: {
                    show: true
                  },
                  colors: colors,
                  fill: {
                    colors: colors
                  },
                  labels: Object.keys(pieChartData.data)
                },
                series: genderChartData
              }}
              height={200}
              type='donut'
            />
          </div>
          <div className={styles['chart-metrics-container']}>
            <ChartHeaderFilters
              theme={{ colors }}
              data={{
                '5yrs+': 50,
                '20yrs+': 30,
                '30yrs+': 25
              }}
            />
          </div>
        </div>
        {/* Bar chart sample */}
        <div className={styles['bar-chart-container']}>
          <Chart
            height={200}
            data={{
              options: {
                chart: {
                  width: '100%',
                  toolbar: {
                    show: false
                  }
                },
                xaxis: {
                  type: 'datetime',
                  categories: [
                    '2018-09-19T00:00:00.000Z',
                    '2018-09-19T01:30:00.000Z',
                    '2018-09-19T02:30:00.000Z',
                    '2018-09-19T03:30:00.000Z',
                    '2018-09-19T04:30:00.000Z',
                    '2018-09-19T05:30:00.000Z',
                    '2018-09-19T06:30:00.000Z'
                  ]
                },
                tooltip: {
                  enabled: false
                },
                colors,
                fill: {
                  colors: colors
                }
              },
              series: [
                {
                  name: '5ys+',
                  data: [31, 40, 28, 51, 42, 109, 100]
                },
                {
                  name: '20ys+',
                  data: [11, 32, 45, 32, 34, 52, 41]
                },
                {
                  name: '30ys+',
                  data: [20, 10, 30, 50, 70, 90, 110]
                }
              ]
            }}
            type='bar'
          />
        </div>
      </div>
    </div>
  );
}
