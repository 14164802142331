import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Formik } from 'formik';
import { Form } from 'reactstrap';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { change } from 'src/User/services/auth';
import PatternRight from 'src/Assets/images/pattern-right.png';
import SOMateLogo from 'src/Assets/images/somate-logo.svg';
import displayAxiosError from 'src/lib/utils/displayAxiosError';
import { PASSWORD_REGEX } from 'src/configs';

const ChangePassword = () => {
  const navigate = useNavigate();
  const [isProgress, setIsProgress] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);

  return (
    <>
      <React.Fragment>
        <div className='authentication-form-container'>
          <div className='logo_wrapper'>
            <img
              className='somate_logo'
              src={SOMateLogo}
              alt='SOMateLogo'
            />
          </div>
          <div className='login_form'>
            <h4>Change Password</h4>

            <Formik
              initialValues={{
                password: '',
                confirm_password: ''
              }}
              onSubmit={async (values) => {
                try {
                  setIsProgress(true);
                  await change(values);
                  Swal.fire({
                    title: 'Success',
                    text: 'Password Changed Successfully',
                    icon: 'success',
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 5000
                  });

                  navigate('/');
                } catch (error: any) {
                  displayAxiosError(error, {
                    title: 'Error occured while changing password',
                    fallbackMsg: '',
                    toast: true
                  });
                } finally {
                  setIsProgress(false);
                }
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .required('Required')
                  .min(8, 'Password must be at least 8 characters.')
                  .matches(
                    PASSWORD_REGEX,
                    'Password must contain at least 8 charactes'
                  ),
                confirm_password: Yup.string()
                  .required('Required')
                  .oneOf([Yup.ref('password')], 'Passwords must match.')
              })}>
              {({
                values,
                handleChange,
                handleSubmit,
                handleBlur,
                errors,
                touched
              }) => (
                <Form
                  className='mt-5 signup-form-container'
                  onSubmit={handleSubmit}>
                  {/* <div className="mb-3 forgot-pass-wrapper">
                      <label htmlFor="old_password">Current Password</label>
                      <input
                        className="form-control"
                        value={values.old_password}
                        onChange={handleChange}
                        required
                        style={{
                          border:
                            errors.old_password &&
                            touched.old_password &&
                            "1px solid red",
                        }}
                        onBlur={handleBlur}
                        error={errors.old_password && touched.old_password}
                        helpertext={
                          errors.old_password && touched.old_password && errors.old_password
                        }
                        type={showPassword3 ? "text" : "password"}
                        id="old_password"
                        name="old_password"
                      />
                      <span
                        onClick={handleClickShowPassword3}
                        className="show_password"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-eye-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                          <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                        </svg>
                      </span>
                      {errors.old_password && touched.old_password ? (
                        <div style={{ color: "red", maxWidth: "363px", fontSize: "12px" }}>{errors.old_password}</div>
                      ) : null}
                    </div> */}

                  <fieldset className='mb-3 forgot-pass-wrapper'>
                    <label htmlFor='password'>New Password</label>
                    <input
                      className='form-control'
                      value={values.password}
                      onChange={handleChange}
                      required
                      style={{
                        border:
                          errors.password && touched.password
                            ? '1px solid red'
                            : undefined
                      }}
                      onBlur={handleBlur}
                      type={showPassword ? 'text' : 'password'}
                      id='password'
                      name='password'
                    />
                    <span
                      onClick={handleClickShowPassword}
                      className='show_password'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='bi bi-eye-fill'
                        viewBox='0 0 16 16'>
                        <path d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z' />
                        <path d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z' />
                      </svg>
                    </span>
                    {errors.password && touched.password ? (
                      <div
                        style={{
                          color: 'red',
                          maxWidth: '363px',
                          fontSize: '12px'
                        }}>
                        {errors.password}
                      </div>
                    ) : null}
                  </fieldset>
                  <fieldset className='mb-3 forgot-pass-wrapper'>
                    <label htmlFor='confirm_password'>
                      Re-Type New Password
                    </label>
                    <input
                      className='form-control'
                      value={values.confirm_password}
                      onChange={handleChange}
                      required
                      style={{
                        border:
                          errors.confirm_password && touched.confirm_password
                            ? '1px solid red'
                            : undefined
                      }}
                      onBlur={handleBlur}
                      type={showPassword2 ? 'text' : 'password'}
                      id='confirm_password'
                      name='confirm_password'
                    />
                    <span
                      onClick={handleClickShowPassword2}
                      className='show_password'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='bi bi-eye-fill'
                        viewBox='0 0 16 16'>
                        <path d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z' />
                        <path d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z' />
                      </svg>
                    </span>
                    {errors.confirm_password && touched.confirm_password ? (
                      <div
                        style={{
                          color: 'red',
                          maxWidth: '363px',
                          fontSize: '12px'
                        }}>
                        {errors.confirm_password}
                      </div>
                    ) : null}
                  </fieldset>

                  <button
                    className='my-3 btn btn-primary waves-effect waves-light'
                    color='primary'
                    type='submit'
                    disabled={isProgress}>
                    {!isProgress && 'Save'}
                    {isProgress && (
                      <div
                        className='spinner-border text-light'
                        role='status'>
                        <span className='sr-only'></span>
                      </div>
                    )}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <img
            className='pattern_right'
            src={PatternRight}
            alt='pattern_right'
          />
        </div>
      </React.Fragment>
    </>
  );
};

export default ChangePassword;
