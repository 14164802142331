import React, { useEffect, useState } from "react";
import "./AddAccount.css";
import SOMateLogo from "../../Assets/images/somate-logo-light.svg";
import { useNavigate } from "react-router";
import {  Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import IntaCircle from "../../Assets/images/insta-circle.svg";
import { useSearchParams } from "react-router-dom";
import { sendCode } from "../services/account";

const AddAccount = () => {
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const MetaCode = (searchParams.get('code') ? searchParams.get('code') : "");
    const [selected, setSelected] = useState("Instagram");
    const [modal, setModal] = useState(false);
    const toggle2 = () => setModal(!modal);
    const OauthData =  {
      "client-id":process.env.REACT_APP_META_APP_ID,
  "redirect-uri":`${window.location.protocol}//${window.location.host}/add-account`,
  response_type:"code",
  scope:"pages_read_engagement,instagram_basic"
    }
    useEffect(() => {
        const response = sendCode(MetaCode);
        if (response) {
            // console.warn(response)
        }


    }
    );
    useEffect(() => {
        if (MetaCode) {
            setModal(true)
        }
    }, [MetaCode])
    return (
        <>
            <div>
                <div className="new_user">
                    <div className="new_user-logo_wrapper">
                        <img className="somate_logo" src={SOMateLogo} alt="SOMateLogo" />
                    </div>
                    <div className="new_user-header">
                        <h1>Add your social accounts</h1>
                        {/* <h2>Account type selected : {state?.accountT}</h2> */}
                        <p>
                            Connect your Facebook, Instagram, Twitter, Youtube, and so on...
                        </p>
                    </div>
                    <div className="new_user_inner">
                        <div
                            onClick={() => {
                                setSelected("Facebook");
                            }}
                            style={{ opacity: "1" }}
                            className={
                                selected == "Facebook"
                                    ? "new_user-itm-active new_user-itm itm-enable"
                                    : "new_user-itm itm-enable"
                            }
                        >
                            <div className="new_user-itm-ic">
                                <i className="bi bi-facebook"></i>
                            </div>
                            <h4>Facebook</h4>
                        </div>
                        <div
                            onClick={() => {
                                setSelected("Instagram");
                                setModal(!modal);
                            }}
                            style={{ opacity: "1" }}
                            className={
                                selected == "Instagram"
                                    ? "new_user-itm-active new_user-itm itm-enable"
                                    : "new_user-itm itm-enable"
                            }
                        >
                            <div className="new_user-itm-ic">
                                <i className="bi bi-instagram"></i>
                            </div>
                            <h4>Instagram</h4>
                        </div>
                        <div
                            onClick={() => {
                                setSelected("Twitter");
                            }}
                            className="new_user-itm itm-disable"
                        >
                            <div className="new_user-itm-ic">
                                <i className="bi bi-twitter"></i>
                            </div>
                            <h4>
                                Upcoming
                                {/* Twitter */}
                            </h4>
                        </div>
                        <div
                            onClick={() => {
                                setSelected("TikTok");
                            }}
                            className="new_user-itm itm-disable"
                        >
                            <div className="new_user-itm-ic">
                                <i className="bi bi-tiktok"></i>
                            </div>
                            <h4>
                                Upcoming
                                {/* Tiktok */}
                            </h4>
                        </div>
                        <div
                            onClick={() => {
                                setSelected("Linkedin");
                            }}
                            className="new_user-itm itm-disable"
                        >
                            <div className="new_user-itm-ic">
                                <i className="bi bi-linkedin"></i>
                            </div>
                            <h4>
                                Upcoming
                                {/* Linked In */}
                            </h4>
                        </div>
                        <div
                            onClick={() => {
                                setSelected("Pinterest");
                            }}
                            className="new_user-itm itm-disable"
                        >
                            <div className="new_user-itm-ic">
                                <i className="bi bi-pinterest"></i>
                            </div>
                            <h4>
                                Upcoming
                                {/* Pinterest */}
                            </h4>
                        </div>
                        <div
                            onClick={() => {
                                setSelected("Google");
                            }}
                            className="new_user-itm itm-disable"
                        >
                            <div className="new_user-itm-ic">
                                <i className="bi bi-google"></i>
                            </div>
                            <h4>
                                Upcoming
                                {/* Google */}
                            </h4>
                        </div>
                        <div
                            onClick={() => {
                                setSelected("Youtube");
                            }}
                            className="new_user-itm itm-disable"
                        >
                            <div className="new_user-itm-ic">
                                <i className="bi bi-youtube"></i>
                            </div>
                            <h4>
                                Upcoming
                                {/* Youtube */}
                            </h4>
                        </div>
                        <div
                            onClick={() => {
                                setSelected("Telegram");
                            }}
                            className="new_user-itm itm-disable"
                        >
                            <div className="new_user-itm-ic">
                                <i className="bi bi-telegram"></i>
                            </div>
                            <h4>
                                Upcoming
                                {/* Telegram */}
                            </h4>
                        </div>
                        <div
                            onClick={() => {
                                setSelected("Reddit");
                            }}
                            className="new_user-itm itm-disable"
                        >
                            <div className="new_user-itm-ic">
                                <i className="bi bi-reddit"></i>
                            </div>
                            <h4>
                                Upcoming
                                {/* Reddit */}
                            </h4>
                        </div>
                    </div>
                    <div className="next-btn">
                        <button
                            onClick={() => {
                                navigate("/", { state: { accountT: selected } });
                            }}
                            className="btn btn-primary"
                        >
                            Skip
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                className="add-account-modal-class"
                centered
                isOpen={modal}
                toggle={toggle2}
            >
                <ModalHeader toggle={toggle2}>
                    <div className="add-account-modal-class-header">
                        <img src={IntaCircle} alt="profile logo" />
                        <div>
                            <h2>Instagram</h2>
                            <h3>Follow us on Instagram</h3>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <hr />
                    <br />
                    {/* <div onClick={()=>{navigate("/")}} style={{ display: "flex", justifyContent: "center" }}>
                        <div className="new_user-itm-inside">
                            <img src={IntaCircle} alt="profile logo" />
                            <h4>Business</h4>
                        </div>
                    </div> */}
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div onClick={()=>{window.location.href=`https://www.facebook.com/dialog/oauth?client_id=${OauthData["client-id"]}&redirect_uri=${OauthData["redirect-uri"]}&response_type=${OauthData.response_type}&scope=${OauthData.scope}`}} className="new_user-itm-inside">
                            <img src={IntaCircle} alt="profile logo" />
                            <h4>Business</h4>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                <button
                            onClick={() => {
                                navigate("/");
                            }}
                            className="btn btn-primary"
                        >
                            Continue
                        </button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default AddAccount;
