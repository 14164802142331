import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { getAllConnectSocialAccounts } from 'src/User/services/connected-social-accts';
import { USER_CONFIG } from 'src/configs';

const allSocialAccountsLoader = async () => {
  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);

  const { data: reponse } = await getAllConnectSocialAccounts(brandId);
  const { data: allSocialAccounts } = reponse;

  return allSocialAccounts;
};

export default allSocialAccountsLoader;
