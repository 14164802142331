// ** React Imports
import { Suspense, useState } from 'react';

// ** React Router Imports
import {
  Outlet,
  Link,
  Await,
  useLoaderData,
  useAsyncError,
  useLocation,
  useFetcher
} from 'react-router-dom';

// ** Icons Imports
import { Sparkles, PenIcon, Trash } from 'lucide-react';

// ** Shadcn Components
import { Button } from 'src/components/ui/button';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup
} from 'src/components/ui/resizable';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from 'src/components/ui/alert-dialog';
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent
} from 'src/components/ui/tabs';

// ** Custom Hooks
import { useTranslation } from 'react-i18next';
import { TNamespace } from 'src/i18n';

// ** Constants
import { ROUTER_PATHS } from 'src/lib/constants';
import { AGENTS } from '../constants';

// ** Types
import { ISomateAIConversationMetadata } from '../types';
import { IGetSomateAIConversationsResponse } from 'src/User/services/somate-ai/models';

// ** Utils
import { isAxiosError } from 'axios';
import classNames from 'classnames';

// ** Custom Components
import Tooltip from 'src/User/components/TooltipButton';

const SomateAILayoutContainer = () => {
  const { conversationHistory } = useLoaderData() as any;

  return (
    <Suspense fallback={<SomateAILayoutLoading />}>
      <Await
        resolve={conversationHistory}
        errorElement={<SomateAILayoutErrorElement />}>
        {(response: IGetSomateAIConversationsResponse) => (
          <SomateAILayout conversationsHistory={response.data} />
        )}
      </Await>
    </Suspense>
  );
};

const SomateAILayoutLoading = () => {
  const { t } = useTranslation<TNamespace>();

  return (
    <div className='tw-min-h-screen-95 tw-w-full tw-flex tw-justify-center tw-items-center tw-bg-white tw-shadow tw-rounded-xl'>
      <div className='tw-flex tw-flex-col tw-items-center tw-gap-2'>
        <div className='tw-flex tw-items-center tw-gap-2'>
          <span className='!tw-font-bold tw-text-2xl'>{t('somate-ai')}</span>
          <Sparkles className='tw-w-10 tw-h-10' />
        </div>
        <div className='tw-text-foreground tw-text-base'>Loading...</div>
      </div>
    </div>
  );
};

const SomateAILayoutErrorElement = () => {
  const { t } = useTranslation<TNamespace>();
  const error = useAsyncError();
  const errorMessage = isAxiosError(error)
    ? error?.response?.data?.message
    : `
        We're sorry, but your request for <b>${t('somate-ai')}</b> cannot be
        completed at this time. Please try a different request or go back to
        the previous page.
      `;

  return (
    <div className='tw-min-h-screen-95 tw-w-full flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8'>
      <div className='mx-auto max-w-md text-center'>
        <div className='mx-auto h-12 w-12 text-primary' />
        <h1 className='mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl'>
          Oops, we can&apos;t perform that request
        </h1>
        <p className='mt-4 text-muted-foreground'>{errorMessage}</p>
        <div className='mt-6'>
          <Button
            asChild
            size={'lg'}>
            <Link
              to={ROUTER_PATHS.DASHBOARD}
              className='tw-no-underline tw-text-primary-foreground tw-transition-colors tw-hover:tw-bg-primary/90 hover:tw-text-primary-foreground'>
              Go Home
            </Link>
          </Button>
        </div>
      </div>
    </div>
  );
};

interface ISomateAILayoutProps {
  conversationsHistory: ISomateAIConversationMetadata[];
}

const SomateAILayout = (props: ISomateAILayoutProps) => {
  const { conversationsHistory } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <ResizablePanelGroup
      direction='horizontal'
      className='tw-h-full tw-max-h-screen-95 tw-w-full tw-bg-white tw-shadow tw-rounded-xl '>
      <ResizablePanel
        maxSize={22}
        minSize={11}
        collapsible
        collapsedSize={6}
        onCollapse={() => {
          setIsCollapsed(true);
        }}
        onExpand={() => {
          setIsCollapsed(false);
        }}>
        <Sidebar
          conversationsHistory={conversationsHistory}
          isCollapsed={isCollapsed}
        />
      </ResizablePanel>
      <ResizableHandle withHandle />
      <ResizablePanel>
        <div className='tw-pl-4 tw-h-full'>
          <Outlet />
        </div>
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};

interface ISidebarProps {
  conversationsHistory: ISomateAIConversationMetadata[];
  isCollapsed: boolean;
}

const sidebarTabs = [
  {
    value: 'history',
    label: 'History'
  },
  {
    value: 'agents',
    label: 'Agents'
  }
];

const Sidebar = (props: ISidebarProps) => {
  const { conversationsHistory, isCollapsed } = props;

  const [activeTab, setActiveTab] = useState('history');
  const { t } = useTranslation<TNamespace>();

  return (
    <div className='tw-flex-col tw-hidden tw-gap-2 tw-text-foreground tw-rounded-tl-xl tw-rounded-bl-xl md:tw-flex tw-overflow-auto tw-max-h-screen-95'>
      <div className='tw-sticky tw-top-0 tw-p-2'>
        <div
          className={classNames(
            'tw-flex tw-justify-start tw-items-center tw-w-full tw-gap-2 tw-px-2 tw-text-left',
            { 'tw-flex-col tw-justify-center': isCollapsed }
          )}>
          <div className='tw-flex tw-items-center tw-justify-center tw-rounded-full tw-w-7 tw-h-7'>
            <Sparkles className='tw-w-5 tw-h-5' />
          </div>
          <div
            className={classNames(
              'tw-overflow-hidden tw-text-sm tw-grow tw-text-Trash tw-whitespace-nowrap tw-font-semibold',
              { 'tw-hidden': isCollapsed }
            )}>
            {t('somate-ai')}
          </div>

          <Tooltip
            tooltipContent={'Create new conversation'}
            placement='right'>
            <Button
              variant='ghost'
              asChild
              className='tw-text-primary-black'>
              <Link
                to={`${ROUTER_PATHS.SOMATE_AI_LAYOUT}/${ROUTER_PATHS.SOMATE_AI_NEW_CONVERSATION}`}>
                <PenIcon className='tw-w-4 tw-h-4' />
              </Link>
            </Button>
          </Tooltip>
        </div>
      </div>
      <Tabs
        value={activeTab}
        onValueChange={setActiveTab}
        className='tw-w-full tw-bg-white tw-shadow-lg tw-rounded-lg tw-overflow-hidden'>
        <TabsList
          className={classNames(
            'tw-grid tw-w-full tw-grid-cols-2 tw-p-0 tw-bg-transparent',
            { 'tw-hidden': isCollapsed }
          )}>
          {sidebarTabs.map(({ label, value }) => (
            <TabsTrigger
              key={value}
              value={value}
              className='data-[state=active]:tw-text-primary data-[state=active]:tw-border-b-2 data-[state=active]:tw-border-primary data-[state=active]:tw-shadow-none tw-rounded-none tw-py-2 tw-px-4 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-text-gray-700'>
              {label}
            </TabsTrigger>
          ))}
        </TabsList>
        <TabsContent
          value='history'
          className='tw-mt-0 tw-px-1 tw-py-4 tw-grid tw-flex-1 tw-overflow-auto'>
          {conversationsHistory.map(({ title, id }) => (
            <SidebarDeletableLink
              key={id}
              href={id}
              title={title}
            />
          ))}
        </TabsContent>
        <TabsContent
          value='agents'
          className='tw-mt-0 tw-px-1 tw-py-4 tw-grid tw-flex-1 tw-overflow-auto'>
          {AGENTS.map((item) => (
            <SidebarStaticLink
              key={item.id}
              href={ROUTER_PATHS.SOMATE_AI_AGENT_LAYOUT + '/' + item.id}
              title={item.name}
              hide={item?.hide}
            />
          ))}
        </TabsContent>
      </Tabs>
    </div>
  );
};

interface ISidebarDeletableLinkProps {
  href: string;
  title: string;
  hide?: boolean;
}

const SidebarDeletableLink = (props: ISidebarDeletableLinkProps) => {
  const { href, title } = props;

  const [isHovered, setIsHovered] = useState(false);
  const [modalVisible, setModalVisibility] = useState(false);

  const { pathname } = useLocation();
  const fetcher = useFetcher();

  const isCurrentPageLink = pathname.includes(href);

  const toggleModalVisibility = () => {
    setModalVisibility((prev) => !prev);
  };

  return (
    <>
      <Button
        asChild
        variant={'ghost'}
        className='!tw-justify-start tw-overflow-x-hidden group'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <Link
          to={href}
          className={classNames(
            'tw-relative tw-no-underline tw-font-medium hover:tw-bg-muted/50',
            {
              'tw-text-primary': isCurrentPageLink,
              'tw-text-slate-700': !isCurrentPageLink
            }
          )}>
          {title}
          <div
            className={classNames(
              'tw-absolute tw-bottom-0 tw-top-0 tw-to-transparent tw-right-0 tw-bg-gradient-to-l tw-from-white tw-w-8 tw-from-0% tw-flex tw-justify-end tw-items-center'
            )}>
            <Button
              variant={'ghost'}
              className={classNames(
                'tw-bg-white !tw-p-1 tw-h-fit hover:tw-bg-white',
                {
                  'tw-visible': isHovered,
                  'tw-invisible': !isHovered
                }
              )}
              onClick={(e) => {
                e.preventDefault();
                toggleModalVisibility();
              }}>
              <Trash className='tw-text-red-500 tw-w-4 tw-h-4 ' />
            </Button>
          </div>
        </Link>
      </Button>

      <AlertDialog
        open={modalVisible}
        onOpenChange={toggleModalVisibility}>
        <AlertDialogContent>
          <fetcher.Form
            action={ROUTER_PATHS.SOMATE_AI_LAYOUT}
            method='DELETE'>
            <AlertDialogHeader>
              <AlertDialogTitle>
                Are you sure you want to delete chat?
              </AlertDialogTitle>
              <AlertDialogDescription className='!tw-mb-5'>
                This will delete <strong>{title}</strong>
                <input
                  type='string'
                  name='conversationId'
                  readOnly
                  value={href}
                  className='tw-hidden'
                />
                <input
                  type='string'
                  name='isCurrentPageLink'
                  readOnly
                  value={isCurrentPageLink ? 'yes' : 'no'}
                  className='tw-hidden'
                />
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter className='sm:tw-justify-between tw-w-full'>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction
                type='submit'
                className='tw-bg-red-500'>
                Delete
              </AlertDialogAction>
            </AlertDialogFooter>
          </fetcher.Form>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

const SidebarStaticLink = (props: ISidebarDeletableLinkProps) => {
  const { href, title, hide } = props;

  const { pathname } = useLocation();
  const isCurrentPageLink = pathname.includes(href);

  return (
    <Button
      key={href}
      asChild
      variant={'ghost'}
      className={classNames('!tw-justify-start tw-overflow-x-hidden group', {
        'tw-hidden': hide
      })}>
      <Link
        to={href}
        className={classNames(
          'tw-relative tw-no-underline tw-font-medium hover:tw-bg-muted/50',
          {
            'tw-text-primary': isCurrentPageLink,
            'tw-text-slate-700': !isCurrentPageLink
          }
        )}>
        {title}
      </Link>
    </Button>
  );
};

export default SomateAILayoutContainer;
