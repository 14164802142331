import { METRICS_DATA } from './constants';
import getChartData from './utils/getChartData';
import MetricView from '../../components/MetricsView';
import History from '../History';

export default function Overview() {
  const { chartSeries, chartOptions } = getChartData();

  return (
    <>
      <MetricView
        metricsData={METRICS_DATA}
        chart={chartSeries.map((serie) => ({
          series: serie,
          options: chartOptions
        }))}
        showComingSoonOverlay
      />
      <History />
    </>
  );
}
