import { truncate } from 'lodash';
import Swal from 'sweetalert2';

export default function displayAxiosError(
  error: any,
  dialogContent: {
    title: string;
    fallbackMsg: string;
    btnText?: string;
    toast?: boolean;
    duration?: number;
  }
) {
  const { response } = error ?? {};
  const { data: responseData } = response ?? {};
  let errorMessage: string = '';

  if (responseData) {
    errorMessage = responseData?.message ?? responseData.detail;
  }

  Swal.fire({
    title: dialogContent.title,
    text: errorMessage ?? dialogContent.fallbackMsg,
    html: `
    <div>
      <p>${errorMessage ?? dialogContent.fallbackMsg}</p>
      ${
        responseData?.errors
          ? `<p>${truncate(responseData.errors, {
              length: 120
            })}</p>`
          : ''
      }
    </div>`,
    icon: 'error',
    showConfirmButton: !!dialogContent?.btnText,
    confirmButtonText: dialogContent.btnText,
    toast: dialogContent.toast,
    position: dialogContent.toast ? 'top-right' : 'center',
    timer: !!dialogContent?.toast ? dialogContent?.duration : 5000,
    timerProgressBar: !!dialogContent?.duration,
    showCloseButton: !!dialogContent?.toast
  });
}
