import MetricView from 'src/User/features/CompetitionAnalysis/views/Instagram/components/MetricsView';
import { useRouteLoaderData } from 'react-router-dom';
import { InstagramAnalyticsLoaderData } from '../models';
import { ROUTER_PATHS_ID } from 'src/lib/constants';
import HistoryTable from 'src/User/features/CompetitionAnalysis/views/Instagram/components/HistoryTable';
import createHistoryTableData from './utils/createTableData';
import getSummaryData from './utils/getSummaryData';
import useGetIGBusinessOverviewInsight from './hooks/useGetIGBusinessOverview';

const Overview = () => {
  const { insightsSummary } = useRouteLoaderData(
    ROUTER_PATHS_ID.IG_BUSINESS_ANALYTICS_LAYOUT
  ) as InstagramAnalyticsLoaderData;

  const { chartData, sortedDailyInsightsData } =
    useGetIGBusinessOverviewInsight();

  return (
    <div className='-tw-mt-7'>
      <MetricView
        chart={chartData}
        metricsData={getSummaryData(insightsSummary)}
        chartGridFlow='column'
      />
      <HistoryTable
        data={createHistoryTableData(sortedDailyInsightsData)}
        excludeFields={['engagement_rate']}
      />
    </div>
  );
};

export default Overview;
