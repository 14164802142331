import { capitalize, reduce } from 'lodash';
import { Progress } from 'reactstrap';
import styles from './audience.module.css';
import classNames from 'classnames';
import { Chart } from 'src/User/features/Viz/components/Chart';
import getGenderData from './utils/getGenderData';
import getAgeData from './utils/getAgeData';
import getRegionsData from './utils/getRegionData';
import { CHART_COLORS } from 'src/User/features/Viz/components/Chart/constants/colors';
import { useRouteLoaderData } from 'react-router-dom';
import { ROUTER_PATHS_ID } from 'src/lib/constants';
import { InstagramAnalyticsLoaderData } from '../models';
import { useTranslation } from 'react-i18next';
import { TNamespace } from 'src/i18n';

export default function Audience() {
  const { lifetimeInsights: audienceData } = useRouteLoaderData(
    ROUTER_PATHS_ID.IG_BUSINESS_ANALYTICS_LAYOUT
  ) as InstagramAnalyticsLoaderData;
  const { t } = useTranslation<TNamespace>();

  const gender = getGenderData(audienceData);
  const age = getAgeData(audienceData);
  const regions = getRegionsData(audienceData);

  const genderChartLabels = Object.keys(gender.data);
  const genderChartData = Object.values(gender.data);

  return (
    <div id='audience-analytics'>
      <div className='d-flex flex-column tw-gap-3'>
        <div className='d-flex tw-gap-3 w-100'>
          <Card
            name={regions.topRegionData.name}
            metric={t('region', {
              ns: 'analytics'
            })}
            percentage={regions.topRegionData.count}
          />
          <Card
            name={age.topAgeData.name + ` ${t('yrs old', { ns: 'analytics' })}`}
            metric={t('age', {
              ns: 'analytics'
            })}
            percentage={age.topAgeData.count}
          />
          <Card
            name={capitalize(gender.topGenderData.name)}
            metric={t('gender', {
              ns: 'analytics'
            })}
            percentage={gender.topGenderData.count}
          />
        </div>
        <div className='d-flex tw-gap-3'>
          <div
            className={classNames(
              'bg-white rounded px-4 py-3 shadow',
              styles.width_40
            )}>
            <h5 className={classNames('mb-4 tw-capitalize', styles.boxTitle)}>
              {t('top-regions', { ns: 'analytics' })}
            </h5>
            <div className='overflow-y-auto d-flex flex-column gap-3'>
              {Object.entries(regions.data)
                .sort((a, b) => (a[1] < b[1] ? 1 : -1))
                .slice(0, 15)
                .map(([name, value], index) => (
                  <div
                    className='d-flex justify-content-between gap-5'
                    key={`${name}-${index}`}>
                    <span className={styles.label}>{name}</span>
                    <span className={styles.digit}>{value}</span>
                  </div>
                ))}
            </div>
          </div>
          <div
            className={classNames(
              'shadow d-flex justify-content-center align-items-center w-100 rounded bg-white tw-uppercase',
              styles.width_60
            )}>
            {t('map placeholder', { ns: 'analytics' })}
          </div>
        </div>
        <div className='d-flex tw-gap-3 w-100'>
          <div
            className={classNames(
              'bg-white rounded shadow py-3 px-4',
              styles.width_55
            )}>
            <AudienceProgressBarAnalyticsView
              title={capitalize(
                t('age', {
                  ns: 'analytics'
                }) ?? ''
              )}
              data={reduce(
                age.data,
                (acc, value, key) => {
                  acc[key + ' yrs old'] = value;
                  return acc;
                },
                {} as Record<string, number>
              )}
            />
          </div>
          <div
            className={classNames(
              'd-flex flex-column tw-gap-3',
              styles.width_45
            )}>
            <div className={classNames('rounded bg-white shadow py-3 px-4')}>
              <Chart
                header={{
                  title: '',
                  filtersOptions: {}
                }}
                data={{
                  options: {
                    labels: genderChartLabels,
                    dataLabels: {
                      enabled: false
                    },
                    colors: CHART_COLORS
                  },
                  series: genderChartData
                }}
                type='pie'
              />
            </div>
            <div className='d-flex flex-column tw-gap-3 shadow rounded bg-white py-3 px-4'>
              <AudienceProgressBarAnalyticsView
                title={capitalize(
                  t('gender', {
                    ns: 'analytics'
                  }) ?? ''
                )}
                data={gender.data}
                getBarColor={(_, __, optionIndex) =>
                  optionIndex === 0 ? CHART_COLORS[0] : CHART_COLORS[1]
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface IAudienceProgressBarAnalyticsViewProps<T> {
  data: T;
  title: string;
  getBarColor?: (
    optionKey: string,
    optionValue: number,
    optionIndex: number
  ) => string;
  appendRecordValueToLabel?: boolean;
}

export const AudienceProgressBarAnalyticsView = <
  T extends Record<string, number>
>(
  props: IAudienceProgressBarAnalyticsViewProps<T>
) => {
  const { data, title, appendRecordValueToLabel = true, getBarColor } = props;
  const maxValue = Math.max(...Object.values(data));
  const numberFormatter = new Intl.NumberFormat('en-US');

  return (
    <>
      <h6 className={styles.boxTitle}>{title}</h6>
      <div className='d-flex flex-column gap-3'>
        {Object.entries(data).map(([name, percentage], index) => (
          <div
            className='d-flex gap-5'
            key={`${name}-${index}`}>
            <p className={classNames('w-100', styles.label)}>
              {capitalize(name)}
              {appendRecordValueToLabel ? (
                <span className='tw-drop-shadow-xl tw-pl-2 tw-text-slate-600'>
                  ({numberFormatter.format(percentage)})
                </span>
              ) : null}
            </p>
            <div className='w-100'>
              <Progress
                barStyle={{
                  backgroundColor:
                    getBarColor?.(name, percentage, index) ?? CHART_COLORS[0]
                }}
                style={{
                  height: '15px'
                }}
                value={percentage}
                max={maxValue}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

function Card({
  name,
  percentage,
  metric
}: {
  name: string;
  percentage: number;
  metric: string;
}) {
  return (
    <div className='d-flex flex-column justify-content-between shadow w-100 bg-white rounded px-4 py-2'>
      <p
        className={classNames(
          'text-center opacity-85 h-100 d-flex align-items-center justify-content-center',
          styles.metricBody
        )}>
        {name}
      </p>
      <div
        className={classNames(
          'd-flex justify-content-between',
          styles.metricFooter
        )}>
        <span
          className={classNames(
            'opacity-50',
            styles.label,
            styles.metricFooterText
          )}>
          {capitalize(metric)}
        </span>
        <span
          className={classNames(
            'opacity-50',
            styles.digit,
            styles.metricFooterText
          )}>
          {percentage}
        </span>
      </div>
    </div>
  );
}
