'use client';

import * as React from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';

import { cn } from 'src/lib/utils/shadcn-utils';
import { Button } from 'src/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem
} from 'src/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from 'src/components/ui/popover';
import { CommandList } from 'cmdk';

interface IComboboxProps {
  emptyMessage?: string;
  placeholder?: string;
  options: IComboboxOption[];
  value?: string | IComboboxOption;
  onChange?: (activeOption: string) => void;
}

export interface IComboboxOption {
  value: string;
  label: string;
}

export default function Combobox(props: IComboboxProps) {
  const { value, options, placeholder, emptyMessage, onChange } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant='outline'
          role='combobox'
          aria-expanded={open}
          className='tw-w-full tw-justify-between'>
          {value
            ? options.find((option) => option.value === value)?.label
            : placeholder ?? 'Search option...'}
          <ChevronsUpDown className='tw-ml-2 tw-h-4 tw-w-4 tw-shrink-0 tw-opacity-50' />
        </Button>
      </PopoverTrigger>
      <PopoverContent className='tw-w-full !tw-p-0'>
        <Command className='tw-w-full'>
          <CommandInput
            className='focus:tw-outline-none'
            placeholder={placeholder ?? 'Search option...'}
          />
          <CommandList className='tw-w-[286px] tw-max-h-64 tw-overflow-auto tw-mb-2'>
            <CommandEmpty>{emptyMessage ?? 'No option found.'}</CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  key={option.value}
                  value={option.value}
                  onSelect={(currentValue) => {
                    onChange?.(currentValue === value ? '' : currentValue);
                    setOpen(false);
                  }}>
                  <Check
                    className={cn(
                      'tw-mr-2 tw-h-4 tw-w-4',
                      value === option.value ? 'tw-opacity-100' : 'tw-opacity-0'
                    )}
                  />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
