import { omit } from 'lodash';
import { IInstagramBusinessMediaInsight } from '../../../types';
import getTableData from '../../Posts/utils/getTableData';

export const getReelsTableData = (data?: IInstagramBusinessMediaInsight[]) => {
  const { cumulativeData, data: tableData } = getTableData(data);

  return {
    tableData,
    cumulativeData: omit(cumulativeData, ['total_impressions'])
  };
};
