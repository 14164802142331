import getChartOptions from 'src/User/features/CompetitionAnalysis/views/Instagram/components/MetricsView/utils/getChartOptions';
import { isPlainObject, startCase, sum, omit, size } from 'lodash';
import { facebookPageDailyInsightTitleKeyMapper } from '../constants';

export type TChartRecord = {
  date: string;
  [x: string]: string | number | Record<string, number>;
};

const getChartData = <T extends TChartRecord>(
  data: T[],
  metrics: Exclude<keyof T, 'date'>[]
) => {
  return metrics.map((metric) => getChartDataByMetric(data, metric));
};

const getChartDataByMetric = <T extends TChartRecord>(
  data: T[],
  metric: Exclude<keyof T, 'date'>
) => ({
  options: getChartOptions(data ? data.map(({ date }) => date) : []),
  series: {
    name:
      facebookPageDailyInsightTitleKeyMapper?.[
        metric as keyof typeof facebookPageDailyInsightTitleKeyMapper
      ] ?? startCase(String(metric)),
    data:
      data?.map((record) =>
        isPlainObject(record[metric])
          ? sum(Object.values(record[metric]))
          : Number(record[metric])
      ) || []
  }
});

const isChartDataArg = (data: any): data is TChartRecord[] => {
  return Array.isArray(data) && data.every(isChartRecord);
};

const isChartRecord = (data: any): data is TChartRecord => {
  const metricData = omit(data, 'date');

  return (
    isPlainObject(data) &&
    typeof data?.date == 'string' &&
    size(metricData) >= 1
  );
};

export default getChartData;
export { isChartDataArg, isChartRecord };
