import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  redirect
} from 'react-router-dom';

import * as User from 'src/User';
import * as Admin from 'src/SuperAdmin';

// ** Loaders Import
import allSocialAccountsLoader from 'src/User/features/SocialAccounts/pages/AllSocialAccounts/loaders/allSocialAccountsLoader';
import igBussinessAccountLoader from 'src/User/features/SocialAccounts/pages/InstagramAccounts/loaders/igBussinessAccountLoader';
import metaAdsAccountsLoader from 'src/User/features/SocialAccounts/pages/MetaAdsAccounts/loaders';
import connectMetaAdAccountsLoader from 'src/User/features/SocialAccounts/pages/MetaAdsAccounts/views/ConnectMetaAds/loaders/connectMetaAdAccountsLoader';
import connectMetaAdAccountAction from 'src/User/features/SocialAccounts/pages/MetaAdsAccounts/views/ConnectMetaAds/actions/connectMetaAdAccountAction';
import { ROUTER_PATHS, ROUTER_PATHS_ID } from 'src/lib/constants';

const Provider = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<User.ShellLayoutErrorBoundary />}>
        {/* User app Routes  */}
        <Route path='/'>
          {/* Need to put back the authentication wall */}
          <Route
            loader={User.Validators.loaders.userSubscriptionLoader}
            id={ROUTER_PATHS_ID.USER_SUBSCRIPTION_VALIDATION_LAYOUT}>
            {/* User app Subscription wall/layouts Routes  */}
            <Route element={<User.Validators.SubscriptionValidationWrapper />}>
              <Route
                path='/create_brand'
                element={<User.CreateBrand />}
              />
              {/* User app Brand wall/layouts Routes  */}
              <Route element={<User.Validators.BrandValidationWrapper />}>
                {/* User app Routes shell layout  */}
                <Route
                  element={<User.ShellLayout />}
                  errorElement={<User.ShellLayoutErrorBoundary />}>
                  <Route
                    index
                    element={<User.Dashboard />}
                  />
                  <Route
                    path='/dashboard'
                    element={<User.Dashboard />}
                  />
                  <Route
                    path='/posts'
                    element={<User.Posts />}
                  />

                  {/* User app Competition analytics Routes  */}
                  <Route path='/competition-analysis'>
                    <Route
                      index
                      element={<User.CompetitionAnalysis.Root />}
                    />
                    <Route
                      path='instagram/:id'
                      element={<User.CompetitionAnalysis.Instagram.Layout />}>
                      <Route
                        path='overview'
                        element={
                          <User.CompetitionAnalysis.Instagram.Overview />
                        }
                      />
                      <Route
                        path='audit'
                        element={<User.CompetitionAnalysis.Instagram.Audit />}
                      />
                      <Route
                        path='growth'
                        element={<User.CompetitionAnalysis.Instagram.Growth />}
                      />
                      <Route
                        path='engagement'
                        element={
                          <User.CompetitionAnalysis.Instagram.Engagement />
                        }
                      />
                      <Route
                        path='history'
                        element={<User.CompetitionAnalysis.Instagram.History />}
                      />
                      <Route
                        path='post'
                        element={<User.CompetitionAnalysis.Instagram.Posts />}
                      />
                    </Route>
                  </Route>

                  <Route
                    path='/groups'
                    element={<User.Groups />}
                  />

                  {/* User app Analytics Routes  */}
                  <Route
                    path={ROUTER_PATHS.ANALYTICS_ROOT_LAYOUT}
                    loader={User.Analytics.loaders.analyticsLayoutLoader}
                    errorElement={<User.Analytics.RootErrorBoundary />}
                    id={ROUTER_PATHS_ID.ANALYTICS_LAYOUT}>
                    <Route
                      index
                      element={<User.Analytics.AnalyticsIndexRoute />}
                      errorElement={
                        <User.Analytics.AnalyticsIndexRouteErrorBoundary />
                      }
                    />
                    <Route element={<User.Analytics.Layout />}>
                      <Route
                        path={ROUTER_PATHS.ANALYTICS_INSTAGRAM_PAGE}
                        loader={
                          User.Analytics.loaders
                            .instagramBusinessAnalyticsLoader
                        }
                        errorElement={
                          <User.Analytics.InstagramBusinessErrorBoundary />
                        }
                        id={ROUTER_PATHS_ID.IG_BUSINESS_ANALYTICS_LAYOUT}>
                        <Route
                          path={ROUTER_PATHS.ANALYTICS_INSTAGRAM_PAGE}
                          element={
                            <User.Analytics.IGBusiness.IGBusinessAnalyticsLayout />
                          }>
                          <Route
                            path='overview'
                            element={
                              <User.Analytics.IGBusiness.IGBusinessAnalyticsOverview />
                            }
                          />
                          <Route
                            path='audit'
                            element={
                              <User.Analytics.IGBusiness.IGBusinessAnalyticsAudit />
                            }
                          />
                          <Route
                            path='audience'
                            element={
                              <User.Analytics.IGBusiness.IGBusinessAnalyticsAudience />
                            }
                          />
                          <Route
                            path='reels'
                            element={
                              <User.Analytics.IGBusiness.IGBusinessAnalyticsReels />
                            }
                          />
                          <Route
                            path='stories'
                            element={
                              <User.Analytics.IGBusiness.IGBusinessAnalyticsStories />
                            }
                            loader={
                              User.Analytics.IGBusiness.loaders
                                .instagramStoriesInsightsLoader
                            }
                          />
                          <Route
                            path='posts'
                            element={
                              <User.Analytics.IGBusiness.IGBusinessAnalyticsPosts />
                            }
                          />
                        </Route>
                      </Route>
                      <Route path={ROUTER_PATHS.ANALYTICS_FACEBOOK_PAGE_LAYOUT}>
                        <Route
                          path={ROUTER_PATHS.ANALYTICS_FACEBOOK_PAGE}
                          element={
                            <User.Analytics.FacebookPageAnalyticsLayout />
                          }
                          id={ROUTER_PATHS_ID.FB_PAGE_ANALYTICS_LAYOUT}>
                          <Route
                            path='overview'
                            element={
                              <User.Analytics.FacebookPageAnalyticsOverview />
                            }
                            loader={
                              User.Analytics.facebookPageAnalyticsloaders
                                .facebookPageOverviewAnalyticsLoader
                            }
                          />
                          <Route
                            path='clicks-on-page'
                            element={
                              <User.Analytics.FacebookPageAnalyticsClicksOnPage />
                            }
                            loader={
                              User.Analytics.facebookPageAnalyticsloaders
                                .facebookPageClicksAnalyticsLoader
                            }
                          />
                          <Route
                            path='posts'
                            element={
                              <User.Analytics.FacebookPageAnalyticsPosts />
                            }
                            loader={
                              User.Analytics.facebookPageAnalyticsloaders
                                .facebookPageMediaAnalyticsLoader
                            }
                          />
                          <Route
                            path='stories'
                            element={
                              <User.Analytics.FacebookPageAnalyticsStories />
                            }
                            loader={
                              User.Analytics.facebookPageAnalyticsloaders
                                .facebookPageStoryAnalyticsLoader
                            }
                          />
                          <Route
                            path='audience'
                            element={
                              <User.Analytics.FacebookPageAnalyticsAudience />
                            }
                            loader={
                              User.Analytics.facebookPageAnalyticsloaders
                                .facebookPageAudienceAnalyticsLoader
                            }
                          />
                        </Route>
                      </Route>
                    </Route>
                  </Route>

                  {/* Ad Analytics Routes for user app */}
                  <Route path='/ad-analytics'>
                    <Route
                      index
                      loader={
                        User.AdAnalytics.loaders.getAdAnalyticsAccountLoader
                      }
                      element={<User.AdAnalytics.AdAnalyticsIndexRoute />}
                    />
                    <Route
                      path=':mediaType'
                      id={ROUTER_PATHS_ID.ADS_ANALYTICS_MEDIA_TYPE_LAYOUT}
                      loader={User.AdAnalytics.loaders.mediaTypeAdAccountLoader}
                      errorElement={<User.AdAnalytics.RootErrorBoundary />}>
                      {/* 
                      //* This extra route `fb` is based on the assumption that all `:media_type`s
                      //* are not going to have the same UI 
                      //* And it equally serves as a redirect to the first ad account of the user
                    */}
                      <Route path='fb'>
                        <Route
                          path=':adAccountId'
                          element={<User.AdAnalytics.MetaAdsAnalyticsLayout />}
                          errorElement={
                            <User.AdAnalytics.MetaAdsAnalyticsErrorBoundary />
                          }>
                          <Route
                            path={ROUTER_PATHS.ADS_ANALYTICS_MEDTA_ADS_SUMMARY}
                            element={
                              <User.AdAnalytics.MetaAdsOverviewAnalytics />
                            }
                            loader={
                              User.AdAnalytics.loaders.fb
                                .facebookAdSummaryLoader
                            }
                          />
                          <Route
                            path={ROUTER_PATHS.ADS_ANALYTICS_META_ADS_CREATIVE}
                            element={
                              <User.AdAnalytics.MetaAdsCreativesAnalytics />
                            }
                            loader={
                              User.AdAnalytics.loaders.fb
                                .facebookAdCreativeLoader
                            }
                          />
                          <Route
                            path={
                              ROUTER_PATHS
                                .ADS_ANALYTICS_META_ADS_CAMPAIGN_INSIGHT.LAYOUT
                            }
                            element={
                              <User.AdAnalytics.MetaAdsCampaignAnalytics.Layout />
                            }>
                            <Route
                              index
                              loader={
                                User.AdAnalytics.MetaAdsCampaignAnalytics
                                  .loaders.indexRouteLoader
                              }
                            />
                            <Route
                              path={
                                ROUTER_PATHS
                                  .ADS_ANALYTICS_META_ADS_CAMPAIGN_INSIGHT
                                  .OBJECTIVE_OVERVIEW
                              }
                              element={
                                <User.AdAnalytics.MetaAdsCampaignAnalytics.Objectives />
                              }
                            />
                            <Route
                              path={
                                ROUTER_PATHS
                                  .ADS_ANALYTICS_META_ADS_CAMPAIGN_INSIGHT
                                  .CAMPAIGNS_OVERVIEW
                              }
                              element={
                                <User.AdAnalytics.MetaAdsCampaignAnalytics.Campaigns />
                              }
                            />
                            <Route
                              path={
                                ROUTER_PATHS
                                  .ADS_ANALYTICS_META_ADS_CAMPAIGN_INSIGHT
                                  .ADSET_OVERVIEW
                              }
                              element={
                                <User.AdAnalytics.MetaAdsCampaignAnalytics.Adsets />
                              }
                            />
                            <Route
                              path={
                                ROUTER_PATHS
                                  .ADS_ANALYTICS_META_ADS_CAMPAIGN_INSIGHT
                                  .ADS_OVERVIEW
                              }
                              element={
                                <User.AdAnalytics.MetaAdsCampaignAnalytics.Ads />
                              }
                            />
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                  <Route
                    path='/createpost'
                    element={<User.CreatePost />}
                  />
                  <Route path='/calendar'>
                    <Route
                      index
                      element={<User.Calendar.EventCalendarMonth />}
                    />
                    <Route
                      path='week'
                      element={<User.Calendar.EventCalendarWeek />}
                    />
                    <Route
                      path='month'
                      element={<User.Calendar.EventCalendarMonth />}
                    />
                  </Route>

                  {/* User App Report Routes  */}
                  <Route path='/reports'>
                    <Route
                      index
                      element={<User.Reporting.ReportPage />}
                      loader={User.Reporting.loaders.rootRouteLoader}
                      action={User.Reporting.actions.rootRouteAction}
                      errorElement={<User.Reporting.ReportPageErrorElement />}
                    />
                    <Route
                      path='template'
                      loader={allSocialAccountsLoader}
                      id={ROUTER_PATHS_ID.REPORTING_TEMPLATE_LAYOUT}>
                      <Route
                        path=':templateId'
                        element={<User.Reporting.TemplatePage />}
                        loader={
                          User.Reporting.loaders.getReportTemplateByIdLoader
                        }
                        action={User.Reporting.actions.reportTemplateAction}
                      />
                      <Route
                        path='new'
                        action={User.Reporting.actions.reportTemplateAction}
                        element={<User.Reporting.TemplatePage />}
                      />
                    </Route>
                  </Route>

                  {/* User app Socials Routes  */}
                  <Route
                    path='/socials'
                    element={<User.Socials.SocialAccountsLayout />}
                    loader={User.Socials.loaders.oauthConnectRedirectLoader}>
                    <Route
                      index
                      loader={() => redirect('/socials/main')}
                    />
                    <Route
                      path='connecting'
                      element={<User.Socials.Connecting />}>
                      <Route
                        path='integrations/select-media-type'
                        element={
                          <User.Socials.SelectOauthSocialMediaProviderView />
                        }>
                        <Route
                          path=':integrationMediaType'
                          loader={User.Socials.integrationLoader}
                          element={<User.Socials.SocialMediaIntegrationView />}
                        />
                      </Route>
                    </Route>
                    <Route
                      path='main'
                      loader={allSocialAccountsLoader}
                      element={<User.Socials.AllSocialAccountsView />}
                      errorElement={
                        <User.Socials.AllSocialAccountsErrorBoundary />
                      }>
                      <Route
                        path='integrations/select-media-type'
                        element={
                          <User.Socials.SelectOauthSocialMediaProviderView />
                        }>
                        <Route
                          path=':integrationMediaType'
                          loader={User.Socials.integrationLoader}
                          element={<User.Socials.SocialMediaIntegrationView />}
                        />
                      </Route>
                    </Route>
                    {/* // TODO: Move Meta ads social account page under :mediaType */}
                    <Route
                      path='meta-ads'
                      loader={metaAdsAccountsLoader}
                      element={<User.Socials.MetaAdsAccountsView />}
                      errorElement={
                        <User.Socials.MetaAdsAccountErrorBoundary />
                      }>
                      <Route
                        path='integrations/select-media-type'
                        element={
                          <User.Socials.SelectOauthSocialMediaProviderView />
                        }>
                        <Route
                          path=':integrationMediaType'
                          loader={User.Socials.integrationLoader}
                          element={<User.Socials.SocialMediaIntegrationView />}
                        />
                      </Route>
                      <Route
                        path=':metaId/connect-meta-ads-account'
                        loader={connectMetaAdAccountsLoader}
                        action={connectMetaAdAccountAction}
                        errorElement={
                          <User.Socials.ConnectMetaAdsAccountErrorBoundary />
                        }
                        element={<User.Socials.ConnectMetaAdsAccount />}
                      />
                    </Route>
                    <Route
                      path=':mediaType'
                      id={ROUTER_PATHS_ID.IG_BUSINESS_SOCIAL_ACCOUNTS}
                      loader={igBussinessAccountLoader}
                      action={
                        User.Socials.connectIgBusinessAccountProfileAction
                      }
                      element={<User.Socials.InstagramBussinessAccountsView />}
                      errorElement={
                        <User.Socials.InstagramBussinessAccountsErrorBoundary />
                      }>
                      <Route
                        path='integrations/select-media-type'
                        element={
                          <User.Socials.SelectOauthSocialMediaProviderView />
                        }>
                        <Route
                          path=':integrationMediaType'
                          loader={User.Socials.integrationLoader}
                          element={<User.Socials.SocialMediaIntegrationView />}
                        />
                      </Route>
                      <Route
                        path=':metaId/connect-account'
                        loader={
                          User.Socials.connectIgBusinessAccountProfileLoader
                        }
                        action={
                          User.Socials.connectIgBusinessAccountProfileAction
                        }
                        errorElement={
                          <User.Socials.ConnectIgBusinessAccountErrorBoundary />
                        }
                        element={
                          <User.Socials.ConnectIgBusinessAccountProfile />
                        }
                      />
                    </Route>
                  </Route>

                  {/* User Somate Ai Routes */}
                  <Route
                    path={ROUTER_PATHS.SOMATE_AI_LAYOUT}
                    element={<User.SomateAI.Layout />}
                    action={User.SomateAI.actions.deleteConversationAction}
                    loader={User.SomateAI.loaders.conversationHistoryLoader}>
                    <Route
                      path={ROUTER_PATHS.SOMATE_AI_NEW_CONVERSATION}
                      element={<User.SomateAI.StartGenericConversation />}
                      action={User.SomateAI.actions.startConversationAction}
                    />
                    <Route
                      path={`:conversationId`}
                      loader={User.SomateAI.loaders.conversationLoader}
                      action={User.SomateAI.actions.promptConversationAction}
                      element={<User.SomateAI.Conversation />}
                    />
                    <Route path={ROUTER_PATHS.SOMATE_AI_AGENT_LAYOUT}>
                      <Route
                        path={ROUTER_PATHS.SOMATE_AI_NEW_AGENT_CONVERSATION}
                        element={<User.SomateAI.StartAgentConversation />}
                      />
                    </Route>
                  </Route>

                  <Route
                    path='/team/*'
                    element={<User.Team />}
                  />
                  <Route
                    path='/content'
                    element={<User.Content />}
                  />
                  <Route
                    path='/news-meetings'
                    element={<User.NewsMeetings />}
                  />
                  <Route
                    path='/settings'
                    element={<User.Settings />}
                  />
                  <Route
                    path='/upgrade-plan'
                    element={<User.UpgradePlan />}
                  />
                </Route>
              </Route>
            </Route>
            <Route element={<User.LoginContainerLayout />}>
              <Route
                path='/invite'
                element={<User.InviteUser />}
              />
              <Route
                path='/change_password'
                element={<User.ChangePassword />}
              />
              <Route
                path='/reset_password/:Uid/:Token'
                element={<User.ResetPassword />}
              />
            </Route>
            <Route
              path='/user-type'
              element={<User.UserType />}
            />
            <Route
              id={ROUTER_PATHS_ID.SUBSCRIPTION_PLANS_PAGE}
              path={ROUTER_PATHS.SUBSCRIPTION_PLANS}
              element={<User.Subscription.SubscriptionPlans />}
              loader={User.Subscription.loaders.getSubscriptionPlansLoader}
            />
            <Route
              path={ROUTER_PATHS.RESTRICED_ACCESS}
              element={<User.Subscription.SubscriptionRestricted />}
            />
          </Route>

          {/* User app Routes not behind authentication wall  */}
          <Route
            path='/add-account'
            element={<User.AddAccount />}
          />
          <Route element={<User.LoginContainerLayout />}>
            <Route
              path='/success/'
              element={<User.PaySuccess />}
            />
            <Route
              path='/fail'
              element={<User.Subscription.SubscriptionFail />}
            />
            <Route
              path='/login'
              element={<User.Login />}
            />
            <Route
              path='/register'
              element={<User.Signup />}
            />
            <Route
              path='/verify_registration/:code'
              element={<User.VerifyEmail />}
            />
            <Route
              path='/forgot_password'
              element={<User.ForgotPassword />}
            />
          </Route>
          <Route
            path={ROUTER_PATHS.MAINTEANCE}
            element={<User.MaintenancePage />}
          />
        </Route>

        {/* Super Admin Routes */}
        <Route path='/admin'>
          <Route
            index
            element={<Admin.App />}
          />
        </Route>
      </Route>
    ),
    {
      future: {
        v7_normalizeFormMethod: true
      }
    }
  );

  return <RouterProvider router={router} />;
};

export default Provider;
