import { ICellRendererParams } from 'ag-grid-community';
import { AvatarCellRenderer } from 'src/User/features/Viz/components/Table';
import TableCellExternalLink from 'src/User/features/Viz/components/Table/components/TableCellExternalLink';

type TValue = {
  permalink: string;
  caption: string;
  ig_media_id: string;
  thumbnail_url: string;
};

export default function PostPreviewCellRenderer({
  value
}: ICellRendererParams<any, TValue>) {
  const { permalink, caption, thumbnail_url } = value ?? {};

  return (
    <>
      <AvatarCellRenderer
        avatar={thumbnail_url}
        name={caption}
        username={''}
        avatarClassName='!tw-rounded'
        charLimit={35}>
        {permalink ? <TableCellExternalLink href={permalink} /> : null}
      </AvatarCellRenderer>
    </>
  );
}
