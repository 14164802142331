import {
  IBrand,
  activeBrand,
  userBrands
} from 'src/User/recoil/Atoms/UserBrandAtom';
import { useRecoilState } from 'recoil';
import * as brandService from 'src/User/services/brand';
import Swal from 'sweetalert2';
import displayAxiosError from 'src/lib/utils/displayAxiosError';
import { USER_CONFIG } from 'src/configs';
import { useCallback } from 'react';
import { useRevalidator } from 'react-router-dom';

export default function useBrand() {
  const [currentBrand, setActiveBrand] = useRecoilState(activeBrand);
  const [brands, setBrands] = useRecoilState(userBrands);
  const revalidator = useRevalidator();

  const updateBrand = async (
    brand: IBrand,
    onSuccess?: () => void,
    onFailure?: () => void
  ) => {
    try {
      const { data: newBrandData } = await brandService.updateBrand({ brand });

      setBrands(function updateBrandsAtom(brands) {
        const updatedBrands = brands.map((oldBrand) => {
          return oldBrand.id === newBrandData.id ? newBrandData : oldBrand;
        });

        return updatedBrands;
      });
      setActiveBrand(newBrandData);
      localStorage.setItem(
        USER_CONFIG.storageUserBrand,
        JSON.stringify(newBrandData)
      );
      onSuccess?.();
    } catch (error: any) {
      displayAxiosError(error, {
        title: 'Unable to update brand',
        fallbackMsg: 'Please try to update your brand again',
        toast: true,
        duration: 2000
      });
      onFailure?.();
    }
  };

  const deleteBrand = async (
    brandId: string,
    onSuccess?: () => void,
    onFailure?: () => void
  ) => {
    // We should delete only when there are two or more brands
    if (brands.length <= 1) {
      Swal.fire({
        title: 'Unable to perform action',
        text: 'You must have a minimum of two brands to perform this action',
        icon: 'error',
        confirmButtonText: 'Close'
      });

      return;
    }

    try {
      await brandService.deleteBrand({ brandId });

      setBrands(function removeDeletedBrandFromBrandsAtom(brands) {
        const updatedBrands = brands.filter(({ id }) => id !== brandId);

        return updatedBrands;
      });

      setActiveBrand(function updateActiveBrandAtom(brand) {
        return brand?.id !== brandId
          ? brand
          : (brands.find(
              ({ id: oldBrandId }) => oldBrandId !== brandId
            ) as IBrand);
      });
      localStorage.setItem(
        USER_CONFIG.storageUserBrand,
        JSON.stringify(activeBrand)
      );
      onSuccess?.();
    } catch (error: any) {
      displayAxiosError(error, {
        title: 'Unable to delete brand',
        fallbackMsg: 'Please try to delete your brand again',
        toast: true,
        duration: 2000
      });
      onFailure?.();
    }
  };

  const createBrand = async (
    brandName: string,
    onSuccess?: () => void,
    onFailure?: () => void
  ) => {
    try {
      const { data: newBrand } = await brandService.createBrand({
        brandName
      });

      setBrands(function updateBrandsAtom(brands) {
        const updatedBrands = [...brands];
        updatedBrands.push({ ...newBrand });

        return updatedBrands;
      });
      setActiveBrand(newBrand);
      localStorage.setItem(
        USER_CONFIG.storageUserBrand,
        JSON.stringify(newBrand)
      );
      onSuccess?.();
    } catch (error: any) {
      displayAxiosError(error, {
        title: 'Unable to create brand',
        fallbackMsg:
          'Error encountered during brand creation, please try again',
        toast: true,
        duration: 2000
      });
      localStorage.removeItem(USER_CONFIG.storageUserBrand);
      onFailure?.();
    }
  };

  const getCurrentBrand = useCallback(() => {
    return currentBrand as IBrand;
  }, [currentBrand]);

  const changeActiveBrand = (brand: IBrand | null) => {
    setActiveBrand(brand);

    if (brand) {
      localStorage.setItem(USER_CONFIG.storageUserBrand, JSON.stringify(brand));
      revalidator.revalidate();
    } else {
      localStorage.removeItem(USER_CONFIG.storageUserBrand);
    }
  };

  const getAllBrands = () => brands;

  return {
    updateBrand,
    deleteBrand,
    createBrand,
    setActiveBrand,
    getCurrentBrand,
    changeActiveBrand,
    getAllBrands
  };
}
