import {
  Outlet,
  useSearchParams,
  useParams,
  useNavigate,
  useRouteLoaderData,
  Await,
  useLocation
} from 'react-router-dom';
import getSocialMediaTypes from 'src/User/features/AdAnalytics/utils/getSocialMediaTypes';
import { IMetaAdAccount } from 'src/lib/models/socialAccounts';
import SocialAccountSelector from 'src/User/components/SocialAccountSelector';
import { ROUTER_PATHS, ROUTER_PATHS_ID } from 'src/lib/constants';
import { Suspense, useRef } from 'react';
import addParamsToPathname from 'src/lib/utils/addParamsToPathname';
import { DATE_SEARCH_PARAMS } from 'src/configs';
import { useTranslation } from 'react-i18next';
import { TNamespace } from 'src/i18n';
import { startCase } from 'lodash';
import { Button } from 'src/components/ui/button';
import { jsPDF } from 'jspdf';
import { toPng } from 'html-to-image';
import { FileText, FileImage } from 'lucide-react';

const TAB_OPTIONS = [
  {
    label: 'Summary',
    href: 'summary'
  },
  {
    label: 'Campaign Insights',
    href: 'campaign-insights'
  },
  {
    label: 'Ad Creatives Insights',
    href: 'ad-creative-insights'
  }
];

const MetaAdsAnalyticsLayout = () => {
  const { t } = useTranslation<TNamespace>();
  const ref = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  const actionButtons = [
    {
      label: 'Generate PDF',
      onClick: (domNode: HTMLDivElement | null) => {
        if (domNode) {
          toPng(domNode)
            .then((dataUrl) => {
              const pdf = new jsPDF({
                orientation: 'p',
                unit: 'px',
                format: 'a4'
              });

              const imgProps = pdf.getImageProperties(dataUrl);
              const pdfWidth = pdf.internal.pageSize.getWidth();
              const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
              pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
              pdf.save('download.pdf');
            })
            .catch((error) => {
              console.log(error);
            });
        }
      },
      icon: FileText
    },
    {
      label: 'Generate PNG',
      onClick: (domNode: HTMLDivElement | null) => {
        if (domNode) {
          toPng(domNode, {
            backgroundColor: 'white',
            style: { padding: '10px' }
          })
            .then((dataUrl) => {
              const a = document.createElement('a');
              a.href = dataUrl;
              a.download = 'download.png';
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      },
      icon: FileImage
    }
  ];

  return (
    <div className='tw-bg-white tw-shadow tw-rounded-xl tw-flex tw-flex-col tw-flex-grow  tw-h-full tw-relative'>
      <div className='tw-overflow-hidden tw-rounded-t-xl'>
        <Navbar navLinks={TAB_OPTIONS} />
      </div>
      <div className='tw-flex tw-gap-4 tw-w-fit tw-absolute tw-top-32 tw-right-6'>
        {actionButtons.map(({ label, onClick, icon: Icon }, index) => (
          <Button
            key={index}
            variant={'outline'}
            onClick={() => onClick(ref.current)}
            className='tw-w-fit tw-border-primary tw-text-primary'
            disabled={
              pathname.endsWith(ROUTER_PATHS.ADS_ANALYTICS_META_ADS_CREATIVE) ||
              pathname.endsWith(
                ROUTER_PATHS.ADS_ANALYTICS_META_ADS_CAMPAIGN_INSIGHT
                  .ADS_OVERVIEW
              )
            }>
            {label}
            <Icon className='tw-w-4 tw-h-4 tw-ml-2' />
          </Button>
        ))}
      </div>
      <div
        className='tw-px-6 tw-py-6 tw-flex tw-flex-col tw-flex-grow'
        ref={ref}>
        <h2 className='tw-text-2xl tw-font-bold tw-mt-3 tw-mb-6'>
          Meta {t('ads-analytics')}
        </h2>
        <Outlet />
      </div>
    </div>
  );
};

interface NavbarProps {
  navLinks: { label: string; href: string }[];
}

const Navbar = ({ navLinks }: NavbarProps) => {
  const loaderData = useRouteLoaderData(
    ROUTER_PATHS_ID.ADS_ANALYTICS_MEDIA_TYPE_LAYOUT
  ) as any;

  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const duration = {
    start: searchParams.get(DATE_SEARCH_PARAMS.start) ?? undefined,
    stop: searchParams.get(DATE_SEARCH_PARAMS.stop) ?? undefined
  };

  const { t } = useTranslation<TNamespace>();

  return (
    <SocialAccountSelector.Container>
      <SocialAccountSelector.DateSelector
        dateRange={duration}
        onDateRangeChange={(dateRange) => {
          setSearchParams((searchParams) => {
            searchParams.set(DATE_SEARCH_PARAMS.start, dateRange.start);
            searchParams.set(DATE_SEARCH_PARAMS.stop, dateRange.stop);

            if (searchParams.has('campaign') && pathname.endsWith('/summary')) {
              searchParams.delete('campaign');
            }

            return searchParams;
          });
        }}
      />
      <SocialAccountSelector.AccountSelectorContainer>
        <Suspense fallback={<SocialAccountSelector.AccountSelectorLoading />}>
          <Await
            resolve={loaderData?.metaAdAccounts}
            errorElement={
              <span> {t(`meta.layout.error.400`, { ns: 'adAnalytics' })}</span>
            }>
            {(metaAdAccounts: IMetaAdAccount[]) => (
              <AccountSelector
                duration={duration}
                metaAdAccounts={metaAdAccounts}
              />
            )}
          </Await>
        </Suspense>
        <SocialAccountSelector.NavLinks
          navLinks={navLinks.map(({ label, href }) => ({
            label: startCase(
              t(`meta.layout.nav.${label}`, { ns: 'adAnalytics' }) ?? ''
            ),
            href: addParamsToPathname(href, duration)
          }))}
        />
      </SocialAccountSelector.AccountSelectorContainer>
    </SocialAccountSelector.Container>
  );
};

interface INavbarAccountSelectorProps {
  metaAdAccounts: IMetaAdAccount[];
  duration: { start?: string; stop?: string };
}

const AccountSelector = (props: INavbarAccountSelectorProps) => {
  const { metaAdAccounts, duration } = props;

  const { mediaType, adAccountId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation<TNamespace>();

  const socialMediaTypes = getSocialMediaTypes(t);
  const activeMediaType = socialMediaTypes.find(
    ({ value }) => value === mediaType
  );

  const socialAccounts = metaAdAccounts?.map((account) => ({
    ...account,
    id: account.act_account_id,
    avatar: activeMediaType?.logo,
    disabled: !account.connected
  }));

  return (
    <SocialAccountSelector.AccountSelector
      activeSocialAccount={socialAccounts?.find(({ id }) => id === adAccountId)}
      socialAccounts={socialAccounts}
      onActiveSocialAccountChange={(newAdAccountId) => {
        const newUrlPath = activeMediaType?.slug
          ? `/ad-analytics/${mediaType}/${activeMediaType?.slug}` +
            `/${newAdAccountId}/summary` +
            `?${DATE_SEARCH_PARAMS.start}=${duration.start}` +
            `&${DATE_SEARCH_PARAMS.stop}=${duration.stop}`
          : `/ad-analytics`;

        navigate(newUrlPath);
      }}
    />
  );
};

export default MetaAdsAnalyticsLayout;
