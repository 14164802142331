import AvatarWithFallback from 'src/User/components/AvatarWithFallback';

interface IChatMessageProps {
  children: React.ReactNode;
  avatar?: string;
  name: string;
}

const ChatMessage = (props: IChatMessageProps) => {
  const { children, avatar, name } = props;

  return (
    <article className='tw-flex tw-items-start tw-gap-4 tw-w-full md:tw-max-w-xl lg:tw-max-w-2xl xl:tw-max-w-4xl'>
      <AvatarWithFallback
        src={avatar ?? '/images/placeholder-user.jpg'}
        alt={`${name} avatar`}
        className='tw-border'
      />
      <div className='tw-grid tw-gap-1'>
        <div className='tw-font-bold'>{name}</div>
        <div className='tw-prose '>{children}</div>
      </div>
    </article>
  );
};

export default ChatMessage;
