import { ActionFunctionArgs } from 'react-router-dom';
import { ICreateReportRequest } from './model';
import { isString } from 'lodash';
import {
  createReport,
  deleteReportTemplate
} from 'src/User/services/reporting';
import displayAxiosError from 'src/lib/utils/displayAxiosError';
import { AxiosError } from 'axios';

const rootRouteAction = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const formDataEntries = Object.fromEntries(formData);

  if (isValidDeleteRequest(request, formDataEntries)) {
    return _delete(formDataEntries.templateId as string);
  }

  if (!isValidReportCreationFormData(formDataEntries)) {
    throw new Error('Invalid form data');
  }

  return _create(formDataEntries);

  async function _delete(templateId: string) {
    try {
      await deleteReportTemplate(templateId);

      return null;
    } catch (error: any) {
      // * We are using AxiosError type guard because Safari produces weird
      // * "Failed to load resource: The network connection was lost." when
      // * we send DELETE requests that responds with 204 status code
      if (
        request.method === 'DELETE' &&
        !(error as AxiosError)?.response &&
        !!(error as AxiosError)?.request
      ) {
        return null;
      }

      displayAxiosError(error, {
        title: 'Unable to delete report template',
        fallbackMsg: 'Error occured during deletion process',
        toast: true,
        duration: 7000
      });

      return null;
    }
  }

  async function _create(props: ICreateReportRequest) {
    try {
      const { data: response } = await createReport({
        brandId: props.brandId,
        startDate: props.startDate,
        endDate: props.endDate,
        templateId: props.templateId,
        language: props.language,
        reportType: props.reportType
      });
      const { url } = response;

      return url;
    } catch (error: any) {
      displayAxiosError(error, {
        title: 'Unable to create report',
        fallbackMsg: 'Error occured during creation process',
        toast: true,
        duration: 7000
      });

      return null;
    }
  }
};

const isValidDeleteRequest = (request: Request, formData: any) =>
  request.method === 'DELETE' && typeof formData?.templateId == 'string';

const isValidReportCreationFormData = (
  formData: any
): formData is ICreateReportRequest => {
  const typedFormData = formData as ICreateReportRequest;

  return (
    isString(typedFormData?.brandId) &&
    isString(typedFormData?.templateId) &&
    isString(typedFormData?.startDate) &&
    isString(typedFormData?.endDate) &&
    (typedFormData?.reportType == 'pdf' ||
      typedFormData?.reportType == 'pptx') &&
    (typedFormData?.language == 'en' || typedFormData?.language == 'pl')
  );
};

export default rootRouteAction;
