import ErrorRenderer from 'src/User/components/Error/AnalyticsErrorRenderer';

const MetaAdsAccountErrorBoundary = () => {
  return (
    <ErrorRenderer
      title='Something went wrong while fetching all connected meta ads accounts'
      fallbackErrorMsg='please reload the page and try again'
      hideConnectAccount={true}
    />
  );
};

export default MetaAdsAccountErrorBoundary;
