import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TableLoading } from 'src/User/features/Viz/components/Table';
import { REDIRECT_URL_SEARCH_PARAMS_Key } from 'src/lib/constants';

const Connecting = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const timerId = setTimeout(() => {
      const returnUrl = searchParams.get(REDIRECT_URL_SEARCH_PARAMS_Key);

      if (!!returnUrl) {
        searchParams.delete(REDIRECT_URL_SEARCH_PARAMS_Key);
        const otherSearchParams = searchParams.toString();
        const url =
          returnUrl + (otherSearchParams ? `?${otherSearchParams}` : '');

        navigate(url);
      }
    }, 5000);

    return () => clearTimeout(timerId);
  }, [searchParams, navigate]);

  return (
    <div className='tw-h-full tw-min-h-96 tw-w-full tw-flex tw-justify-center tw-items-center'>
      <TableLoading className='tw-w-fti tw-min-w-[470px] tw-max-w-full !tw-h-full tw-mx-auto tw-mr-8' />
    </div>
  );
};

export default Connecting;
