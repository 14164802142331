import { fromPairs, omit, toPairs } from 'lodash';
import { IChartData, ITableData } from '../type';

const getChartData = (data: ITableData[], activeDatasetKeys: string[]) => {
  const selectedRecords = data.filter(({ key }) =>
    activeDatasetKeys.includes(key)
  );

  const chartData = selectedRecords.map(
    ({ key, name, thumbnail_url, permalink, ...rest }) => {
      const formattedMetricsAsList = toPairs(omit(rest, ['sub_categorie'])).map(
        ([key, value]) => {
          const valueAsNumber = parseFloat(value as string) || 0;

          return [key, valueAsNumber];
        }
      );
      const formattedMetricsDict = fromPairs(formattedMetricsAsList) as Omit<
        IChartData,
        'key' | 'name' | 'thumbnail_url' | 'permalink'
      >;

      return {
        key,
        name,
        thumbnail_url,
        permalink,
        ...formattedMetricsDict
      };
    }
  );

  return chartData;
};

export default getChartData;
