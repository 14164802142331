import { isEmpty } from 'lodash';
import { IInstagramBusinessAudience } from '../../..';

export default function getAgeData(data?: IInstagramBusinessAudience) {
  const initialData = {
    data: {} as Record<string, number>,
    topAgeData: {
      name: 'N/A',
      count: 0
    }
  };
  const { audience_gender_age: ageData } = data ?? {};

  if (isEmpty(ageData)) return initialData;

  const formattedData = Object.entries(ageData).reduce(
    (acc, [label, value]) => {
      if (label.startsWith('U.')) return acc;

      const ageGroup = label.replace(/[FMU]\./, '');

      if (acc[ageGroup]) {
        return {
          ...acc,
          [ageGroup]: acc[ageGroup] + value
        };
      }

      return {
        ...acc,
        [ageGroup]: value
      };
    },
    {} as Record<string, number>
  );

  const topAgeData = Object.entries(formattedData).reduce(
    (acc, [ageGroup, count]) => {
      if (count > acc.count)
        return {
          name: ageGroup,
          count
        };

      return acc;
    },
    initialData.topAgeData
  );

  return {
    data: formattedData,
    topAgeData
  };
}
