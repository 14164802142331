import styles from './index.module.css';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import ProgressBar from 'src/User/components/ProgressBar';
import { format } from 'date-fns';
import calculateDateDiff from 'src/lib/utils/calculateDateDiff';
import AvatarWithFallback from 'src/User/components/AvatarWithFallback';
import { useTranslation } from 'react-i18next';
import { TNamespace } from 'src/i18n';
import { Button } from 'src/components/ui/button';
import { Proportions } from 'lucide-react';

interface ProfileCardProps {
  username: string;
  bio: string;
  created_at: string | null;
  updated_at: string | null;
  profilePhotoUrl: string | null;
  profileMetrics: ProfileMetric[];
  profileMetricAlignment?: 'left' | 'right' | 'center';
}

interface ProfileMetric {
  label: string;
  value: string;
  direction?: number;
  changeRate?: string;
}

const ProfileCard = (props: ProfileCardProps) => {
  const {
    profileMetrics,
    profilePhotoUrl,
    username,
    bio,
    created_at,
    updated_at,
    profileMetricAlignment
  } = props;
  const creationDate = created_at ? new Date(created_at) : null;
  const lastUpdateDate = updated_at ? new Date(updated_at) : null;

  const { pathname } = useLocation();
  const { t } = useTranslation<TNamespace>();

  return (
    <div className='bg-white'>
      <div className={styles['profile-card']}>
        <div className={styles['profile-info-container']}>
          <div className={styles['profile-img']}>
            <AvatarWithFallback
              className='tw-w-32 tw-h-32'
              src={profilePhotoUrl ?? ''}
              alt={`${username} profile avatar`}
            />
          </div>
          <div>
            <p className={styles['profile-title']}>{username}</p>
            <p className={styles['profile-description']}>
              {bio?.split('\n').map((line, index) =>
                line.startsWith('http') ? (
                  <a
                    key={index}
                    className={styles['profile-description-link']}
                    href={line}
                    target='_blank'
                    rel='noreferrer noopener'>
                    {line}
                  </a>
                ) : (
                  <span key={index}>{line}</span>
                )
              )}

              <span className={styles['profile-description-tracking-info']}>
                {creationDate
                  ? `${t('Tracked since')} ${format(
                      creationDate,
                      'MMM, dd yyyy'
                    )}.`
                  : ''}{' '}
                {lastUpdateDate
                  ? `${t('Updated')} ${calculateDateDiff(
                      lastUpdateDate,
                      new Date()
                    )} ${t('ago')}`
                  : ''}{' '}
              </span>
            </p>
          </div>
        </div>
        <div className={classNames('w-100', styles['profile-analytics'])}>
          <div
            className={classNames(styles['profile-metric-container'], {
              'tw-flex-row-reverse': profileMetricAlignment == 'right',
              '!tw-justify-center': profileMetricAlignment == 'center'
            })}>
            {profileMetrics.map(({ changeRate, direction, label, value }) => (
              <div
                className={styles['profile-metric']}
                key={label}>
                <p className={styles['profile-metric-label']}>{label}</p>
                <p className={styles['profile-metric-value']}>{value}</p>
                <p
                  title={`${label} Change in the last 30 days`}
                  className={classNames(styles['profile-metric-rate'], {
                    'tw-hidden': !changeRate,
                    [styles['positive-profile-metric-rate']]: direction == 1,
                    [styles['negative-profile-metric-rate']]: direction == -1
                  })}>
                  {changeRate}
                </p>
              </div>
            ))}
          </div>
          <Link
            to={`${pathname}${pathname.endsWith('/') ? 'audit' : '/audit'}`}
            className={classNames(styles['profile-metric-link'])}
            title='Account score compared to similar accounts. Click to see the score breakdown'>
            <ProgressBar
              progressStyle={{
                backgroundImage:
                  'linear-gradient(90deg, #fa0000, #aeff00 50%, #08f)',
                opacity: 0.5,
                height: 5
              }}
              barColor='transparent'
              value={50}
              labelPostion='bottom'
              startLabel={{
                title: ''
              }}
              middleLabel={{
                title: 'SOmate score',
                icon: <i className='bi bi-exclamation-circle' />
              }}
              endLabel={{
                title: ''
              }}
            />
          </Link>
          <div className={styles['profile-analytics-action-area']}>
            <Button
              asChild
              size={'lg'}
              className='!tw-text-white !tw-text-[1rem] tw-tracking-wide'>
              <Link
                to='/reports'
                title='Download report'
                className={styles['download-link']}>
                Generate Report
                <Proportions className='tw-w-5 tw-h-5 tw-ml-2' />
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
