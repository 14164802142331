import { resendVerificationCode } from 'src/User/services/auth';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useState } from 'react';
import { IFormDialogProps } from 'src/User/components/FormDialog/types';

const initialFormData = {
  email: ''
};

export default function useResendVerificationCode() {
  const [popupVisibility, setPopupVisibility] = useState(false);

  const togglePopupVisibility = () => {
    setPopupVisibility((visibility) => !visibility);
  };

  const onSubmit = async (values: typeof initialFormData) => {
    try {
      await resendVerificationCode(values.email);

      Swal.fire({
        icon: 'success',
        title: 'Verification link sent!',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        toast: true,
        position: 'top-right',
        showCloseButton: true
      });

      togglePopupVisibility();
    } catch (error: any) {
      const { response } = error;
      const { data } = response ?? {};

      Swal.fire({
        title: 'Unable to send link',
        text:
          data?.email?.[0] ??
          data?.non_field_errors?.[0] ??
          data.message ??
          data.details ??
          'Please contact support',
        icon: 'error',
        showConfirmButton: false,
        showCloseButton: true,
        toast: true,
        timer: 5000,
        timerProgressBar: true,
        position: 'top-right'
      });
    }
  };

  const {
    isSubmitting,
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    errors
  } = useFormik({
    initialValues: initialFormData,
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email.').required('Required')
    }),
    onSubmit
  });

  return {
    popupVisibility,
    togglePopupVisibility,
    form: {
      isSubmitting,
      onSubmit: handleSubmit,
      inputEltValue: values.email,
      onInputChange: handleChange,
      onInputBlur: handleBlur,
      inputEltId: 'email',
      inputEltLabel: 'Email',
      errors
    } satisfies IFormDialogProps['form']
  };
}
