import { useMemo, useState } from 'react';
import {
  getFacebookPageTableData,
  getInstagramTableData
} from '../utils/getTableData';
import {
  AccountCellRenderer,
  DisconnectCellRenderer,
  IDisconnectCellRendererProps
} from '../InstagramAccountsView';
import { TColumDef } from 'src/User/features/Viz/components/types';
import { useLoaderData, useParams } from 'react-router-dom';
import {
  IFacebookPageAccount,
  IInstagramAccount
} from 'src/lib/models/socialAccounts';
import { truncate } from 'lodash';
import formatCellValueToAmount from 'src/User/features/Viz/components/Table/utils/formatCellValue';

type TLoaderData = IInstagramAccount[] | IFacebookPageAccount[];

export default function useGetConnectedInstagramAccount() {
  const { mediaType } = useParams();
  const allIgBusinessAccounts = useLoaderData() as TLoaderData;

  const tableData = useMemo(
    () =>
      mediaType == 'ig-business'
        ? getInstagramTableData(allIgBusinessAccounts as IInstagramAccount[])
        : getFacebookPageTableData(
            allIgBusinessAccounts as IFacebookPageAccount[]
          ),
    [allIgBusinessAccounts, mediaType]
  );

  return {
    tableData
  };
}

export const useGetTableColsDefs = () => {
  const { mediaType } = useParams();
  const [disconnectAcctDialog, setDisconnectAcctDialog] = useState({
    open: false,
    accountId: '',
    accountName: ''
  });

  const onDisconnectAcctDialogClose = () => {
    setDisconnectAcctDialog({
      open: false,
      accountId: '',
      accountName: ''
    });
  };

  const baseTableCols: TColumDef = [
    {
      field: 'accountInfo',
      headerName: 'Account',
      cellRenderer: AccountCellRenderer,
      sortable: true,
      autoHeight: true,
      flex: 1,
      minWidth: 250
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      cellStyle: {
        display: 'flex',
        alignItems: 'center'
      },
      autoHeight: true,
      sortable: true,
      flex: 0.8,
      minWidth: 150
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      cellStyle: {
        display: 'flex',
        alignItems: 'center'
      },
      autoHeight: true,
      sortable: true,
      flex: 0.8,
      minWidth: 150
    }
  ];

  const instagramTableCols = [...baseTableCols];
  instagramTableCols.splice(
    1,
    0,
    {
      field: 'followerCount',
      headerName: 'Followers',
      cellStyle: {
        display: 'flex',
        alignItems: 'center'
      },
      valueFormatter: ({ value }) => {
        return formatCellValueToAmount(value);
      },
      sortable: true,
      autoHeight: true,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'followingCount',
      headerName: 'Following',
      cellStyle: {
        display: 'flex',
        alignItems: 'center'
      },
      valueFormatter: ({ value }) => {
        return formatCellValueToAmount(value);
      },
      sortable: true,
      autoHeight: true,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'mediaCount',
      headerName: 'Posts',
      cellStyle: {
        display: 'flex',
        alignItems: 'center'
      },
      valueFormatter: ({ value }) => {
        return formatCellValueToAmount(value);
      },
      sortable: true,
      autoHeight: true,
      flex: 1,
      minWidth: 100
    }
  );

  instagramTableCols.push({
    field: 'igBusinessAccountId',
    headerName: 'Actions',
    cellRenderer: DisconnectCellRenderer,
    cellRendererParams: {
      onButtonClick: (accountId: string, accountName: string) =>
        setDisconnectAcctDialog({
          open: true,
          accountId,
          accountName
        })
    } satisfies Pick<IDisconnectCellRendererProps, 'onButtonClick'>,
    sortable: true,
    flex: 1,
    minWidth: 180
  });

  const facebookTableCols = [...baseTableCols];
  facebookTableCols.splice(
    1,
    0,
    {
      field: 'fanCount',
      headerName: 'Fans',
      cellStyle: {
        display: 'flex',
        alignItems: 'center'
      },
      valueFormatter: ({ value }) => {
        return formatCellValueToAmount(value);
      },
      sortable: true,
      autoHeight: true,
      flex: 0.65,
      minWidth: 150
    },
    {
      field: 'category',
      headerName: 'Category',
      cellStyle: {
        display: 'flex',
        alignItems: 'center'
      },
      valueFormatter: ({ value }) => truncate(value ?? '', { length: 28 }),
      sortable: true,
      autoHeight: true,
      flex: 1,
      minWidth: 150
    }
  );

  return {
    tableColsDefs:
      mediaType == 'ig-business' ? instagramTableCols : facebookTableCols,
    disconnectAcctDialog,
    onDisconnectAcctDialogClose
  };
};
