import classNames from 'classnames';
import { format } from 'date-fns';
import { NavLink } from 'react-router-dom';
import { DatePickerWithRange } from 'src/components/ui/datePickerWithRange';
import * as S from 'src/components/ui/select';
import Badge from '../Badge';
import { ReactNode } from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

interface SocialAccountSelectorProps {
  navLinks?: { label: string; href: string }[];
  dateRange?: { start?: string; stop?: string };
  socialAccounts: ISocialSelectorAccount[];
  activeSocialAccount?: ISocialSelectorAccount;
  onActiveSocialAccountChange: (accountId: string) => void;
  onDateRangeChange: (dateRange: { start: string; stop: string }) => void;
}

export interface ISocialSelectorAccount {
  name: string;
  socialMediaLogo?: (props: { className?: string }) => JSX.Element | null;
  avatar?: (props: { className?: string }) => JSX.Element | null;
  id: string;
  disabled?: boolean;
}

const SocialAccountSelector = (props: SocialAccountSelectorProps) => {
  const {
    navLinks,
    activeSocialAccount,
    dateRange,
    socialAccounts,
    onActiveSocialAccountChange,
    onDateRangeChange
  } = props;

  return (
    <SocialAccountSelector.Container>
      <SocialAccountSelector.DateSelector
        dateRange={dateRange}
        onDateRangeChange={onDateRangeChange}
      />
      <SocialAccountSelector.AccountSelectorContainer>
        <SocialAccountSelector.AccountSelector
          socialAccounts={socialAccounts}
          activeSocialAccount={activeSocialAccount}
          onActiveSocialAccountChange={onActiveSocialAccountChange}
        />
        <SocialAccountSelector.NavLinks navLinks={navLinks} />
      </SocialAccountSelector.AccountSelectorContainer>
    </SocialAccountSelector.Container>
  );
};

SocialAccountSelector.Container = ({ children }: { children: ReactNode }) => {
  return (
    <nav className='tw-bg-white tw-py-4 tw-px-6 tw-flex tw-flex-row-reverse tw-justify-between tw-items-end tw-border-b '>
      {children}
    </nav>
  );
};

SocialAccountSelector.DateSelector = ({
  dateRange,
  onDateRangeChange
}: Pick<SocialAccountSelectorProps, 'dateRange' | 'onDateRangeChange'>) => {
  return (
    <DatePickerWithRange
      fullHeight
      id='social_account_selector_duration'
      className='tw-min-w-64 tw-shadow-sm tw-rounded-lg'
      size='lg'
      date={{
        from: dateRange?.start ? new Date(dateRange?.start) : undefined,
        to: dateRange?.stop ? new Date(dateRange.stop) : undefined
      }}
      onDateChange={(dateRange) => {
        onDateRangeChange({
          start: dateRange?.from ? format(dateRange?.from, 'yyyy-MM-dd') : '',
          stop: dateRange?.to ? format(dateRange.to, 'yyyy-MM-dd') : ''
        });
      }}
    />
  );
};

SocialAccountSelector.AccountSelectorContainer = ({
  children
}: {
  children: ReactNode;
}) => {
  return <div className='tw-flex tw-items-end tw-gap-4'>{children}</div>;
};

SocialAccountSelector.AccountSelector = ({
  socialAccounts,
  activeSocialAccount,
  onActiveSocialAccountChange
}: Pick<
  SocialAccountSelectorProps,
  'socialAccounts' | 'activeSocialAccount' | 'onActiveSocialAccountChange'
>) => {
  return (
    <S.Select
      defaultValue={activeSocialAccount?.id}
      onValueChange={(newAdAccountId) =>
        onActiveSocialAccountChange(newAdAccountId)
      }>
      <S.SelectTrigger className='tw-w-full tw-capitalize tw-h-full tw-min-w-80 tw-shadow-sm !tw-text-base'>
        <S.SelectValue
          defaultValue={activeSocialAccount?.id}
          placeholder='Select social media'
        />
      </S.SelectTrigger>
      <S.SelectContent>
        <S.SelectGroup>
          {socialAccounts.map(
            ({
              id,
              avatar: Logo,
              name,
              disabled,
              socialMediaLogo: SocialMediaLogo
            }) => {
              return (
                <S.SelectItem
                  key={id}
                  value={id}
                  className='!tw-text-base tw-font-normal tw-capitalize tw-h-full tw-pr-4'
                  disabled={disabled}>
                  <div className='tw-flex tw-gap-3 tw-items-center'>
                    <Badge
                      badgeContent={
                        SocialMediaLogo ? (
                          <SocialMediaLogo className='tw-w-3 tw-h-3' />
                        ) : null
                      }>
                      {Logo ? (
                        <Logo className='tw-w-9 tw-h-9 !tw-rounded' />
                      ) : null}
                    </Badge>
                    <div className='tw-text-left'>
                      <p className='tw-my-0 tw-mb-0 tw-font-medium'>{name}</p>
                    </div>
                  </div>
                </S.SelectItem>
              );
            }
          )}
        </S.SelectGroup>
      </S.SelectContent>
    </S.Select>
  );
};

SocialAccountSelector.AccountSelectorLoading = (props: IContentLoaderProps) => (
  <ContentLoader
    speed={2}
    width={250}
    height={54}
    viewBox='0 0 250 54'
    backgroundColor='#f3f2f2'
    foregroundColor='#e5e0e0'
    {...props}>
    <rect
      x='2'
      y='3'
      rx='8'
      ry='8'
      width='243'
      height='49'
    />
  </ContentLoader>
);

const NavLinks = ({
  navLinks
}: Pick<SocialAccountSelectorProps, 'navLinks'>) => {
  if (!navLinks) return null;

  return (
    <ul className='tw-mb-0 tw-inline-flex tw-h-full tw-items-center tw-justify-center tw-rounded-lg tw-bg-blue-20 tw-p-[4px] tw-text-muted-foreground tw-border tw-border-input/50'>
      {navLinks.map(({ label, href }) => (
        <li key={label}>
          <NavLink
            to={href}
            className={({ isActive, isPending }) =>
              classNames(
                'tw-no-underline hover:tw-text-primary tw-block tw-whitespace-nowrap tw-rounded-lg tw-px-4 tw-py-2 tw-border tw-border-transparent tw-text-base tw-font-medium tw-ring-offset-background tw-transition-all',
                {
                  'tw-bg-white tw-border tw-border-slate-200 tw-px-4 tw-py-2 tw-text-primary tw-shadow':
                    isActive,
                  'tw-pointer-events-none tw-text-primary': isPending,
                  'tw-text-muted-foreground': !isActive
                }
              )
            }>
            {label}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

SocialAccountSelector.NavLinks = NavLinks;

export default SocialAccountSelector;
