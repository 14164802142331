import classNames from 'classnames';
import { Spinner } from 'reactstrap';

const LoadingUI = () => {
  return (
    <div
      className={classNames(
        'tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-screen tw-h-screen'
      )}>
      <p className={'tw-text-base tw-font-semibold tw-text-primary-black'}>
        Loading your analytics...
      </p>
      <Spinner color='secondary'>Loading...</Spinner>
    </div>
  );
};

export default LoadingUI;
