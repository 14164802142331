import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'reactstrap';

// images
import PatternRight from 'src/Assets/images/pattern-right.png';
import SOMateLogo from 'src/Assets/images/somate-logo.svg';
// import PatternStars from 'src/Assets/images/pattern-stars.png';
// import FacebookIc from 'src/Assets/images/facebook.svg';
// import GoogleIc from 'src/Assets/images/google.svg';
// import InstaIc from 'src/Assets/images/instagram.svg';
// import GithubIc from 'src/Assets/images/github.svg';
// import TwiterIc from 'src/Assets/images/twiter.svg';

import useGetRegistrationData from './hooks/useGetRegistrationData';

const Register = () => {
  const { formik, passwordVisible, togglePasswordVisibility } =
    useGetRegistrationData();

  const {
    errors,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    isSubmitting
  } = formik;

  return (
    <>
      <React.Fragment>
        <div className={'authentication-form-container'}>
          <div className='logo_wrapper'>
            <img
              className='somate_logo'
              src={SOMateLogo}
              alt='SOMateLogo'
            />
          </div>
          <div className='login_form'>
            <h4 className='mb-4 signup-form-title'>Create new account</h4>

            <Form
              onSubmit={handleSubmit}
              className='signup-form-container'>
              <fieldset className='mb-3'>
                <label
                  htmlFor='first_name'
                  className='form-label'>
                  First Name
                </label>
                <input
                  className='form-control'
                  id='first_name'
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  style={{
                    border:
                      errors.first_name && touched.first_name
                        ? '1px solid red'
                        : undefined
                  }}
                  name='first_name'
                  placeholder='Jane'
                  type='text'
                />
                {errors.first_name && touched.first_name ? (
                  <div style={{ color: 'red' }}>{errors.first_name}</div>
                ) : null}
              </fieldset>
              <fieldset className='mb-3'>
                <label
                  htmlFor='last_name'
                  className='form-label'>
                  Last Name
                </label>
                <input
                  className='form-control'
                  id='last_name'
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  style={{
                    border:
                      errors.last_name && touched.last_name
                        ? '1px solid red'
                        : undefined
                  }}
                  name='last_name'
                  placeholder='Acme'
                  type='text'
                />
                {errors.last_name && touched.last_name ? (
                  <div style={{ color: 'red' }}>{errors.last_name}</div>
                ) : null}
              </fieldset>

              <fieldset className='mb-3'>
                <label
                  htmlFor='email'
                  className='form-label'>
                  Email
                </label>
                <input
                  className='form-control'
                  id='email'
                  name='email'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{
                    border:
                      errors.email && touched.email
                        ? '1px solid red'
                        : undefined
                  }}
                  placeholder='example@xyz.com'
                  type='email'
                />
                {errors.email && touched.email ? (
                  <div
                    style={{
                      color: 'red',
                      maxWidth: '363px',
                      fontSize: '12px'
                    }}>
                    {errors.email}
                  </div>
                ) : null}
              </fieldset>

              <fieldset className='mb-3 forgot-pass-wrapper'>
                <label
                  htmlFor='password'
                  className='form-label'>
                  Password
                </label>
                <input
                  className='form-control '
                  value={values.password}
                  onChange={handleChange}
                  required
                  style={{
                    border:
                      errors.password && touched.password
                        ? '1px solid red'
                        : undefined
                  }}
                  onBlur={handleBlur}
                  type={passwordVisible.mainPassword ? 'text' : 'password'}
                  id='password'
                  name='password'
                />
                <span
                  onClick={() => togglePasswordVisibility('mainPassword')}
                  className='show_password'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    className='bi bi-eye-fill'
                    viewBox='0 0 16 16'>
                    <path d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z' />
                    <path d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z' />
                  </svg>
                </span>
                {errors.password && touched.password ? (
                  <div
                    style={{
                      color: 'red',
                      maxWidth: '363px',
                      fontSize: '12px'
                    }}>
                    {errors.password}
                  </div>
                ) : null}
              </fieldset>

              <fieldset className='mb-3 forgot-pass-wrapper'>
                <label
                  htmlFor='password_confirm'
                  className='form-label'>
                  Confirm Password
                </label>
                <input
                  className='form-control'
                  value={values.password_confirm}
                  onChange={handleChange}
                  required
                  style={{
                    border:
                      errors.password_confirm && touched.password_confirm
                        ? '1px solid red'
                        : undefined
                  }}
                  onBlur={handleBlur}
                  type={passwordVisible.confirmPassword ? 'text' : 'password'}
                  id='password_confirm'
                  name='password_confirm'
                />
                <span
                  onClick={() => togglePasswordVisibility('confirmPassword')}
                  className='show_password'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    className='bi bi-eye-fill'
                    viewBox='0 0 16 16'>
                    <path d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z' />
                    <path d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z' />
                  </svg>
                </span>
                {errors.password_confirm && touched.password_confirm ? (
                  <div
                    style={{
                      color: 'red',
                      maxWidth: '363px',
                      fontSize: '12px'
                    }}>
                    {errors.password_confirm}
                  </div>
                ) : null}
              </fieldset>

              <button
                className='my-3 btn btn-primary waves-effect waves-light'
                color='primary'
                type='submit'
                disabled={isSubmitting}>
                {!isSubmitting && ' Create Account'}
                {isSubmitting && (
                  <div
                    className='spinner-border text-light'
                    role='status'>
                    <span className='sr-only'></span>
                  </div>
                )}
              </button>

              {/* <div className='other_sign_options'>
                      <h5>Sign In with</h5>
                      <div className='sign_options'>
                        <a
                          href={process.env.REACT_APP_FACEBOOK}
                          target='_blank'
                          className='sign_option'>
                          <img
                            alt=''
                            src={FacebookIc}
                          />
                        </a>
                        <a
                          href={process.env.REACT_APP_GOOGLE}
                          target='_blank'
                          className='sign_option'>
                          <img
                            alt=''
                            src={GoogleIc}
                          />
                        </a>
                        <a
                          href={process.env.REACT_APP_INSTAGRAM}
                          target='_blank'
                          className='sign_option'>
                          <img
                            alt=''
                            src={InstaIc}
                          />
                        </a>
                        <a
                          href={process.env.REACT_APP_GITHUB}
                          target='_blank'
                          className='sign_option'>
                          <img
                            alt=''
                            src={GithubIc}
                          />
                        </a>
                        <a
                          href={process.env.REACT_APP_TWITTER_LINK}
                          target='_blank'
                          className='sign_option'>
                          <img
                            alt=''
                            src={TwiterIc}
                          />
                        </a>
                      </div>
                    </div> */}

              <h4 className='signup-form-link'>
                Already have an account ?{' '}
                <Link
                  className='social_link'
                  to='/login'>
                  Sign In
                </Link>
              </h4>
            </Form>
          </div>
          <img
            className='pattern_right'
            src={PatternRight}
            alt='pattern_right'
          />
        </div>
      </React.Fragment>
    </>
  );
};

export default Register;
