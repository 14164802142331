import React from 'react'
import HomeIc from '../../../Assets/images/home.png'

const NewsMeetings = () => {
    return (
        <>
            <div className='news-meetings-wrapper'>
                <div className='news-meetings-breadcrumb'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="#"><img src={HomeIc} alt='Home icon' /> Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">News & Upcoming Meetings</li>
                        </ol>
                    </nav>
                </div>
                <div className="meetingcarousel">

                </div>
                <div className="upcoming-meetings">
                    <h2>Upcoming Meetings</h2>
                </div>
            </div>

        </>
    )
}

export default NewsMeetings
