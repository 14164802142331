import { useLoaderData } from 'react-router-dom';
import { Table } from 'src/User/features/Viz/components/Table';
import { IMetaAdAccount } from 'src/lib/models/socialAccounts';
import { META_ADS_TABLE_COLS_DEFS } from './constants';
import { Outlet } from 'react-router-dom';

const MetaAdsAccountView = () => {
  const allSocialAccounts = useLoaderData() as IMetaAdAccount[];

  return (
    <div className='w-100'>
      <Table
        data={allSocialAccounts}
        columnsDefs={META_ADS_TABLE_COLS_DEFS}
        isLoading={false}
        height={'100%'}
      />
      <Outlet />
    </div>
  );
};

export default MetaAdsAccountView;
