export type TSocialAccountKind =
  | 'IG_BUSINESS'
  | 'META_ADS'
  | 'FACEBOOK_PAGE'
  | 'LINKEDIN_PAGE'
  | 'LINKEDIN_ADS';

export enum SocialAccountKindMap {
  'ig-business' = 'IG_BUSINESS',
  'facebook-page' = 'FACEBOOK_PAGE',
  'linkedin-page' = 'LINKEDIN_PAGE',
  'linkedin-ads' = 'LINKEDIN_ADS'
}

export type AllSocialAccountDict = {
  IG_BUSINESS: IInstagramAccount[];
  META_ADS: IMetaAdAccount[];
  FACEBOOK_PAGE: IFacebookPageAccount[];
  LINKEDIN_PAGE: any[];
  LINKEDIN_ADS: any[];
};

export const TEST_DATA = [
  {
    id: '1',
    brand_id: 'brand1',
    account_type: 'Instagram',
    account_list: [
      {
        ig_business_account_id: 'ig1',
        social_account_id: 'social1',
        username: 'tchassi_jordan',
        followers: 100,
        following: 50,
        media_count: 10,
        bio: 'This is a bio',
        profile_picture_url: 'http://example.com/pic.jpg',
        created_at: '2022-01-01T00:00:00Z',
        updated_at: '2022-01-02T00:00:00Z'
      }
    ],
    last_synced: null,
    created_at: '2022-01-01T00:00:00Z',
    updated_at: '2022-01-02T00:00:00Z'
  },
  {
    id: '2',
    brand_id: 'brand2',
    account_type: 'MetaAds',
    account_list: [
      {
        ig_business_account_id: 'ig2',
        social_account_id: 'social2',
        username: 'tchassi_jordan_ads',
        followers: 100,
        following: 50,
        media_count: 10,
        bio: 'This is a bio',
        profile_picture_url: 'http://example.com/pic.jpg',
        created_at: '2022-01-01T00:00:00Z',
        updated_at: '2022-01-02T00:00:00Z'
      }
    ],
    last_synced: null,
    created_at: '2022-01-01T00:00:00Z',
    updated_at: '2022-01-02T00:00:00Z'
  }
];

export interface IInstagramAccount {
  ig_business_account_id: string;
  facebook_page_id: string;
  meta_id: string;
  username: string;
  category: string | null;
  followers: number;
  following: number;
  media_count: number;
  bio: string | null;
  profile_picture_url: string | null;
  connected: boolean;
  created_at: string;
  updated_at: string | null;
}

export interface IMetaAdAccount {
  act_account_id: string;
  meta_id: string;
  meta_name: string;
  connected: boolean;
  name: string;
  timezone_name?: string;
  account_status?: string;
  age?: string;
  amount_spent?: number;
  currency?: string;
}

export interface IFacebookPageAccount {
  facebook_page_id: string;
  meta_id: string;
  name: string;
  connected: boolean;
  about: string | null;
  fan_count: number | null;
  cover: string | null;
  profile_pic: string | null;
  website: string | null;
  link: string | null;
  category: string | null;
  emails: string | null;
  created_at: string;
  updated_at: string | null;
}

export type AllInstagramAccounts = IInstagramAccount[];
