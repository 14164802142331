import classNames from 'classnames';
import styles from '../../settings.module.css';

type TLink = {
  key: string;
  label: string;
};

type TSidebarProps = {
  links: TLink[];
  activeLink: string;
  onLinkChange: (key: string) => void;
};

export default function Sidebar(props: TSidebarProps) {
  const { links, activeLink, onLinkChange } = props;

  return (
    <div className={styles['settings-menu']}>
      {links.map((link) => (
        <button
          key={link.key}
          onClick={() => {
            onLinkChange(link.key);
          }}
          className={classNames({
            [styles['active']]: activeLink == link.key
          })}>
          {link.label}
        </button>
      ))}
    </div>
  );
}
