import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Posts.css';
import SearchIc from '../../Assets/images/search_ic.svg';
import LinkedinCircle from '../../Assets/images/linkedin-circle.svg';
import TitokCircle from '../../Assets/images/tiktok-circle.svg';
import InstaCircle from '../../Assets/images/insta-circle.svg';
import FacebookCircle from '../../Assets/images/facebook-circle.svg';
import TwiterCircle from '../../Assets/images/twiter-circle.svg';
import PostModal from '../components/PostModal';

import { Modal, ModalBody } from 'reactstrap';
const Posts = () => {
  // const [tabPreview, setTabPreview] = useState(false);
  // const [tabSchedule, setTabSchedule] = useState(false);
  // const [tabAccounts, setTabAccounts] = useState(false);
  const [searchValue, SetSearchValue] = useState('');
  const [selectedOption, setSelectedOption] = useState('All');
  const [selectedOption2, setSelectedOption2] = useState('Yesterday');
  const [modal, setModal] = useState(false);
  const toggle2 = () => setModal(!modal);
  return (
    <>
      <div className='posts-wrapper'>
        <div className='action-header'>
          <div className='header-left'>
            <div className='search-post'>
              <div className='search-wrapper'>
                <div className='search-box'>
                  <input
                    type='text'
                    className='form-control'
                    id='search'
                    placeholder='Search'
                    value={searchValue}
                    onChange={(e) => {
                      SetSearchValue(e.target.value);
                    }}
                  />
                  <div
                    onClick={() => {
                      alert('Searched with ' + searchValue);
                      SetSearchValue('');
                    }}
                    className='search_ic'>
                    <img
                      src={SearchIc}
                      alt='Search'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='select-post-type'>
              <select
                onChange={(e) => {
                  setSelectedOption(e.target.value);
                }}
                value={selectedOption}>
                <option value='All'>All</option>
                <option value='Scheduled'>Scheduled</option>
              </select>
            </div>
          </div>
          <div className='header-right'>
            <div
              onClick={() => {}}
              className='cal-ic'>
              <i className='bi bi-calendar2'></i>
            </div>
            <div className='select-post-type'>
              <select
                onChange={(e) => {
                  setSelectedOption2(e.target.value);
                }}
                value={selectedOption2}>
                <option value='Today'>Today</option>
                <option value='Yesterday'>Yesterday</option>
              </select>
            </div>
          </div>
        </div>

        <div className='posts-area'>
          <div className='posts-inner'>
            <div className='posts-list-col'>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYJbCE2pn9JcyIpsqksNN4T4qyQ2S_suFayQ&usqp=CAU'
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <></>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://plus.unsplash.com/premium_photo-1671130295823-78f170465794?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dGV4dCUyMGJhY2tncm91bmR8ZW58MHx8MHx8&w=1000&q=80'
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://media.istockphoto.com/id/1322277517/photo/wild-grass-in-the-mountains-at-sunset.jpg?s=612x612&w=0&k=20&c=6mItwwFFGqKNKEAzv0mv6TaxhLN3zSE43bWmFN--J5w='
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://static.vecteezy.com/packs/media/photos/term-bg-3-f6a12264.jpg'
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://media.istockphoto.com/id/533129473/photo/sunflowers-in-the-sunset.jpg?b=1&s=170667a&w=0&k=20&c=9CbG1t0kv9GT2stDb2Ch1el-UBY7XWb0_l6YpfiobXQ='
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
            </div>
            <div className='posts-list-col'>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://images.unsplash.com/photo-1509043759401-136742328bb3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80'
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://bd.gaadicdn.com/processedimages/royal-enfield/classic350/source/classic350612f277880878.jpg'
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://media.istockphoto.com/id/1322277517/photo/wild-grass-in-the-mountains-at-sunset.jpg?s=612x612&w=0&k=20&c=6mItwwFFGqKNKEAzv0mv6TaxhLN3zSE43bWmFN--J5w='
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://static.vecteezy.com/packs/media/photos/term-bg-3-f6a12264.jpg'
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://images.ctfassets.net/hrltx12pl8hq/a2hkMAaruSQ8haQZ4rBL9/8ff4a6f289b9ca3f4e6474f29793a74a/nature-image-for-website.jpg?fit=fill&w=480&h=320'
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
            </div>
            <div className='posts-list-col'>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://www.whatsappimages.in/wp-content/uploads/2021/07/Top-HD-sad-quotes-for-whatsapp-status-in-hindi-Pics-Images-Download-Free.gif'
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://bd.gaadicdn.com/processedimages/royal-enfield/classic350/source/classic350612f277880878.jpg'
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://media.istockphoto.com/id/1322277517/photo/wild-grass-in-the-mountains-at-sunset.jpg?s=612x612&w=0&k=20&c=6mItwwFFGqKNKEAzv0mv6TaxhLN3zSE43bWmFN--J5w='
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://static.vecteezy.com/packs/media/photos/term-bg-3-f6a12264.jpg'
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://images.ctfassets.net/hrltx12pl8hq/a2hkMAaruSQ8haQZ4rBL9/8ff4a6f289b9ca3f4e6474f29793a74a/nature-image-for-website.jpg?fit=fill&w=480&h=320'
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
            </div>
            <div className='posts-list-col'>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://images.unsplash.com/photo-1495344517868-8ebaf0a2044a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8bG9va2luZ3xlbnwwfHwwfHw%3D&w=1000&q=80'
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://media.istockphoto.com/id/1138248690/photo/make-a-wish.jpg?s=170667a&w=0&k=20&c=I530mvkEPnwwi3GQ6dhr-q-RnijaJbzxW0lTwY3tJdk='
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://media.istockphoto.com/id/172313928/photo/eagle-owl.jpg?s=612x612&w=is&k=20&c=NfFcyo01CQZMukodDfsWf1lsJhet7ItMfNY8b_wP8og='
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://static.vecteezy.com/packs/media/photos/term-bg-3-f6a12264.jpg'
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>08:16 am</span>,<span> 15 Apr 2023</span>
                  </div>
                </div>
                <div
                  className='post-thumbnail'
                  onClick={() => {
                    setModal(!modal);
                  }}>
                  <img
                    src='https://images.ctfassets.net/hrltx12pl8hq/a2hkMAaruSQ8haQZ4rBL9/8ff4a6f289b9ca3f4e6474f29793a74a/nature-image-for-website.jpg?fit=fill&w=480&h=320'
                    alt='Thumbnail'
                  />
                </div>
                <div className='hashtags'>
                  <span>#Hashtag1</span>
                  <span>#Hashtag2</span>
                  <span>#Hashtag3</span>
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={LinkedinCircle}
                        alt='Linkedin'
                      />
                    </span>
                    <span>
                      <img
                        src={TitokCircle}
                        alt='Tiktok'
                      />
                    </span>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                    <span>
                      <img
                        src={FacebookCircle}
                        alt='facebook'
                      />
                    </span>
                    <span>
                      <img
                        src={TwiterCircle}
                        alt='Twiter'
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {}}
                    className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Repost
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="modal fade" id="PostModal" tabIndex="-1" aria-labelledby="PostModalLabel" aria-hidden="true"> */}
      <Modal
        size='lg'
        isOpen={modal}
        toggle={toggle2}>
        <ModalBody>
          <PostModal
            modal={modal}
            setModal={setModal}
          />
        </ModalBody>
      </Modal>
      {/* </div> */}
    </>
  );
};

export default Posts;
