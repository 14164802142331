import { getMetaAdCampaignInsightsByAdsets } from 'src/User/services/meta-ads';
import useChartSeries, {
  IActiveChartSeries
} from '../../../hooks/useChartSeries';
import useGetData from '../../../hooks/useGetData';
import {
  CampaignInsightByAdset,
  IAdsetsTableRow,
  TActiveChartSeriesInitializer
} from '../../../types';
import createChartSeriesData from '../../../utils/createChartSeriesData';

const useGetAdsetsInsightsData = () => {
  const { response, loading } = useGetData({
    fetcher: getMetaAdCampaignInsightsByAdsets
  });
  const { activeChartSeries, initializeActiveChartSeries, toggleChartSeries } =
    useChartSeries();

  const { data } = response ?? {};
  const aggregatedAdsetsTableData = getAggregatedAdsetsTableData(data);
  const adsetCharts = getAllChartData({
    data,
    initializeActiveChartSeries,
    activeSeriesByChart: activeChartSeries
  });

  return {
    aggregatedAdsetsTableData,
    adsetCharts,
    loading,
    toggleChartSeries
  };
};

interface IGetAllChartData {
  data?: CampaignInsightByAdset[];
  activeSeriesByChart?: IActiveChartSeries;
  initializeActiveChartSeries: TActiveChartSeriesInitializer;
}

const getAllChartData = (props: IGetAllChartData) => {
  const { data, activeSeriesByChart, initializeActiveChartSeries } = props;

  return (
    data?.map(({ insights, name, id, ads_count }) => ({
      adsetName: name,
      adsetId: id,
      adsCount: ads_count,
      chartData: createChartSeriesData({
        chartId: id,
        initializeActiveChartSeries,
        insights,
        activeSeriesByChart
      })
    })) ?? []
  );
};

const getAggregatedAdsetsTableData = (
  data?: CampaignInsightByAdset[]
): IAdsetsTableRow[] => {
  return (
    data?.map(
      ({
        insights,
        name: adsetName,
        id: adsetId,
        campaign_name: campaignName,
        campaign_objective: objective,
        campaign_id: campaignId
      }) => ({
        ...insights.summary,
        objective,
        campaignName,
        campaignId,
        adsetName,
        adsetId,
        currency: ''
      })
    ) ?? []
  );
};

export default useGetAdsetsInsightsData;
