import classNames from 'classnames';
import { ReactNode } from 'react';

interface IBadgeProps {
  children: ReactNode;
  badgeContent: ReactNode;
  className?: string;
}

const Badge = (props: IBadgeProps) => {
  const { badgeContent, children, className } = props;

  return (
    <div className={classNames('tw-relative', className)}>
      {children}
      <div className='tw-absolute -tw-right-3 -tw-bottom-1 tw-bg-white tw-p-1 tw-rounded-xl'>
        {badgeContent}
      </div>
    </div>
  );
};

export default Badge;
