import classNames from 'classnames';
import {
  Table,
  NumberCellRenderer
} from 'src/User/features/Viz/components/Table';
import { TColumDef, TData } from 'src/User/features/Viz/components/types';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import ComingSoonWrapper from 'src/User/components/ComingSoonWrapper';
import { Fragment } from 'react';
import i18n, { TNamespace } from 'src/i18n';
import { startCase } from 'lodash';

const tableColDefs: TColumDef = [
  {
    field: 'date',
    headerName: startCase(i18n.t('date', {}) ?? ''),
    autoHeight: true,
    flex: 1,
    cellRenderer: (props: ICellRendererParams<any, string>) => {
      return (
        <span
          className={'tw-flex tw-items-center tw-h-full tw-min-h-full tw-py-2'}>
          {props.value ?? 'N/A'}
        </span>
      );
    }
  },
  {
    field: 'followers_count',
    headerName:
      i18n.t('Followers Count', {
        ns: 'competitionAnalysis' satisfies TNamespace
      }) ?? '',
    cellRenderer: FollowerCellRenderer,
    cellRendererParams: '',
    autoHeight: true,
    flex: 1
  },
  {
    field: 'following_count',
    headerName:
      i18n.t('Following Count', {
        ns: 'competitionAnalysis' satisfies TNamespace
      }) ?? '',
    cellRenderer: FollowerCellRenderer,
    cellRendererParams: '',
    autoHeight: true,
    flex: 1
  },
  {
    field: 'media_count',
    headerName: startCase(
      i18n.t('media count', {
        ns: 'competitionAnalysis' satisfies TNamespace
      }) ?? ''
    ),
    cellRenderer: FollowerCellRenderer,
    cellRendererParams: '',
    autoHeight: true,
    flex: 1
  },
  {
    field: 'engagement_rate',
    headerName: i18n.t('engagement_rate') ?? '',
    cellRenderer: FollowerCellRenderer,
    cellRendererParams: '',
    autoHeight: true,
    flex: 1
  }
];

type TTableField =
  | 'date'
  | 'engagement_rate'
  | 'followers_count'
  | 'following_count'
  | 'media_count';

interface IHistoryTableProps {
  data: TData[];
  excludeFields?: TTableField[];
  showComingSoonOverlay?: boolean;
}

export default function HistoryTable(props: IHistoryTableProps) {
  const { data, excludeFields, showComingSoonOverlay } = props;

  const Container = !!showComingSoonOverlay ? ComingSoonWrapper : Fragment;

  return (
    <Container>
      <div className='mt-5 shadow p-4 tw-rounded-xl bg-white'>
        <h5 className={classNames('tw-text-2xl tw-font-semibold tw-mb-6')}>
          {i18n.t('Historical Stats', {
            ns: 'competitionAnalysis' satisfies TNamespace
          })}
        </h5>
        <Table
          isLoading={false}
          data={data}
          columnsDefs={tableColDefs?.filter(
            (col) =>
              !excludeFields?.includes((col as ColDef).field as TTableField)
          )}
          height={850}
          pagination
          paginationPageSize={13}
        />
      </div>
    </Container>
  );
}

type TFollowerCellValue = {
  value: number;
  change_count: number;
};

export function FollowerCellRenderer(
  props: ICellRendererParams<any, TFollowerCellValue>
) {
  const { value, change_count } = props?.value ?? {};

  return (
    <NumberCellRenderer
      changeCount={change_count}
      value={value}
    />
  );
}
