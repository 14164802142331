import { ApexOptions } from 'apexcharts';

export default function getChartData() {
  const chartOptions: ApexOptions = {
    chart: {
      height: 350,
      width: '100%',
      type: 'line'
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
      categories: [
        '2018-09-19T00:00:00.000Z',
        '2018-09-19T01:30:00.000Z',
        '2018-09-19T02:30:00.000Z',
        '2018-09-19T03:30:00.000Z',
        '2018-09-19T04:30:00.000Z',
        '2018-09-19T05:30:00.000Z',
        '2018-09-19T06:30:00.000Z'
      ]
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      }
    }
  };

  const chartSeries = [
    {
      name: 'Engagement Rate',
      data: [31, 40, 28, 51, 42, 109, 100]
    },
    {
      name: 'Average Likes',
      data: [11, 32, 45, 32, 34, 52, 41]
    },
    {
      name: 'Weekly Engagement Rate',
      data: [20, 35, 50, 25, 30, 45, 60]
    },
    {
      name: 'Weekly Posts',
      data: [20, 10, 30, 50, 70, 90, 110]
    },
    {
      name: 'Average Comments',
      data: [11, 32, 45, 32, 34, 52, 41]
    },
    {
      name: 'Comments Ratio',
      data: [11, 32, 45, 32, 34, 52, 41]
    }
  ];

  return { chartSeries, chartOptions };
}
