import { LoaderFunctionArgs, defer } from 'react-router-dom';
import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import {
  getMetaAdAnalyticsSummary,
  getMetaAdCampaignObjectives,
  getMetaAdCampaigns
} from 'src/User/services/meta-ads';
import { USER_CONFIG } from 'src/configs';
import { CampaignSummaryAnalytics } from '../views/AdOverview/types';
import i18n, { TNamespace } from 'src/i18n';

const facebookAdSummaryLoader = async (props: LoaderFunctionArgs) => {
  const { params, request } = props;

  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);
  const requestUrl = new URL(request.url);
  const searchParams = new URLSearchParams(requestUrl.search);
  const startDate = searchParams.get('start');
  const endDate = searchParams.get('stop');
  const campaignId = searchParams.get('campaign');
  const campaignObjective = searchParams.get('campaignType');
  const { adAccountId } = params;

  if (!adAccountId) {
    throw new Response(
      i18n.t('meta.loaders.error.404', 'meta.loaders.error.unspecified', {
        ns: 'adAnalytics' satisfies TNamespace
      }),
      { status: 404 }
    );
  }

  const adAnalyticsSummaryPromise = getMetaAdAnalyticsSummary({
    adAccountId,
    brandId,
    campaignId,
    campaignObjective,
    date: {
      start: startDate,
      stop: endDate
    }
  });

  const campaignObjectivePromise = getMetaAdCampaignObjectives({
    brandId,
    adAccountId
  });

  const campaignsFilterListPromise = getMetaAdCampaigns({
    adAccountId,
    brandId,
    date: {
      start: startDate,
      stop: endDate
    }
  });

  return defer({
    adAnalyticsSummary: adAnalyticsSummaryPromise.then(
      ({ data: adAnalyticsSummaryResponse }) => adAnalyticsSummaryResponse.data
    ),
    campaignObjectives: campaignObjectivePromise.then(
      ({ data: campaignObjectivesResponse }) => campaignObjectivesResponse.data
    ),
    campaignFilterList: campaignsFilterListPromise.then(
      ({ data: campaignsFilterListResponse }) =>
        campaignsFilterListResponse.data
    )
  });
};

export interface IFacebookAdSummaryLoaderResponse {
  adAnalyticsSummary: CampaignSummaryAnalytics;
  campaignObjectives: string[];
}

export default facebookAdSummaryLoader;
