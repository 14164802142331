import { ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
import Combobox, { IComboboxOption } from 'src/User/components/Combobox';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import { TNamespace } from 'src/i18n';
import { useTranslation } from 'react-i18next';

interface OptionsMenuProps {
  objectives: string[];
  campaigns: {
    id: string;
    name: string;
  }[];
}

const OptionsMenu = (props: OptionsMenuProps) => {
  const { objectives, campaigns } = props;

  return (
    <OptionsMenu.Container>
      <OptionsMenu.Item
        itemType='campaignType'
        options={objectives?.map((objective) => ({
          label: objective,
          value: objective
        }))}
      />
      <OptionsMenu.Item
        itemType='campaign'
        options={
          campaigns?.map(({ id, name }) => ({
            label: name,
            value: id
          })) ?? []
        }
      />
    </OptionsMenu.Container>
  );
};

const Container = ({ children }: { children: ReactNode }) => (
  <div className='tw-flex tw-gap-4 xl:tw-gap-6 tw-justify-between tw-max-w-2xl'>
    {children}
  </div>
);

type TItemType = 'campaign' | 'campaignType';

interface IItemProps {
  itemType: TItemType;
  options: IComboboxOption[];
}

const Item = (props: IItemProps) => {
  const { itemType, options } = props;

  const [searchParmas, setSearchParams] = useSearchParams();
  const labels = useOptionsMenuLabels();

  return (
    <OptionsMenu.ItemContainer title={labels[itemType].title}>
      <Combobox
        options={options}
        placeholder={labels[itemType].placeholder}
        emptyMessage={labels[itemType].emptyMessage}
        value={searchParmas.get(itemType) ?? ''}
        onChange={(activeValue) => {
          if (
            searchParmas.has(itemType) &&
            searchParmas.get(itemType) === activeValue
          ) {
            return setSearchParams((prev) => {
              prev.append(itemType, '');

              return prev;
            });
          }

          setSearchParams((prev) => {
            prev.set(itemType, activeValue);

            return prev;
          });
        }}
      />
    </OptionsMenu.ItemContainer>
  );
};

interface ItemContainerProps {
  children: ReactNode;
  title: string;
}

const ItemContainer = ({ children, title }: ItemContainerProps) => (
  <div className='tw-w-full'>
    <p className='tw-text-sm tw-font-semibold tw-mb-2'>{title}</p>
    {children}
  </div>
);

const ContainerError = () => {
  return (
    <OptionsMenu.Container>
      <OptionsMenu.ItemError itemType='campaignType' />
      <OptionsMenu.ItemError itemType='campaign' />
    </OptionsMenu.Container>
  );
};

const ItemError = ({ itemType }: { itemType: 'campaign' | 'campaignType' }) => {
  const labels = useOptionsMenuLabels();

  return (
    <OptionsMenu.ItemContainer title={labels[itemType].title}>
      <p className='!tw-my-0'>{labels[itemType].error}</p>
    </OptionsMenu.ItemContainer>
  );
};

const Loading = () => {
  return (
    <OptionsMenu.Container>
      <OptionsMenu.ItemLoading itemType='campaignType' />
      <OptionsMenu.ItemLoading itemType='campaign' />
    </OptionsMenu.Container>
  );
};

const ItemLoading = ({ itemType }: { itemType: TItemType }) => {
  const labels = useOptionsMenuLabels();

  return (
    <OptionsMenu.ItemContainer title={labels[itemType].title}>
      <OptionsMenu.ItemComboboxLoading />
    </OptionsMenu.ItemContainer>
  );
};

const ItemComboboxLoading = (props: IContentLoaderProps) => (
  <ContentLoader
    speed={2}
    width={280}
    height={44}
    viewBox='0 0 280 44'
    backgroundColor='#f3f2f2'
    foregroundColor='#e5e0e0'
    {...props}>
    <rect
      x='2'
      y='3'
      rx='8'
      ry='8'
      width='273'
      height='39'
    />
  </ContentLoader>
);

const useOptionsMenuLabels = () => {
  const { t } = useTranslation<TNamespace>();

  return {
    campaignType: {
      title: t('meta.summary.optionsMenu.campaignType', {
        ns: 'adAnalytics'
      }),
      placeholder: t('meta.summary.optionsMenu.campaignTypePlaceholder', {
        ns: 'adAnalytics'
      }),
      emptyMessage: t('meta.summary.optionsMenu.campaignTypeEmptyMessage', {
        ns: 'adAnalytics'
      }),
      error: t('meta.summary.optionsMenu.error.404.campaignType', {
        ns: 'adAnalytics'
      })
    },
    campaign: {
      title: t('meta.summary.optionsMenu.campaign', {
        ns: 'adAnalytics'
      }),
      placeholder: t('meta.summary.optionsMenu.campaignPlaceholder', {
        ns: 'adAnalytics'
      }),
      emptyMessage: t('meta.summary.optionsMenu.campaignEmptyMessage', {
        ns: 'adAnalytics'
      }),
      error: t('meta.summary.optionsMenu.error.404.campaign', {
        ns: 'adAnalytics'
      })
    }
  };
};

OptionsMenu.Error = ContainerError;
OptionsMenu.ItemError = ItemError;
OptionsMenu.Loading = Loading;
OptionsMenu.ItemComboboxLoading = ItemComboboxLoading;
OptionsMenu.ItemLoading = ItemLoading;
OptionsMenu.ItemContainer = ItemContainer;
OptionsMenu.Item = Item;
OptionsMenu.Container = Container;
export default OptionsMenu;
