import { LoaderFunctionArgs } from 'react-router-dom';
import { generateMockFacebookPageDailyInsight } from '../utils/mock-data-generators';
import { DATE_SEARCH_PARAMS } from 'src/configs';

const facebookPageStoryAnalyticsLoader = async (props: LoaderFunctionArgs) => {
  const { request } = props;

  const requestUrl = new URL(request.url);
  const searchParams = new URLSearchParams(requestUrl.search);
  const startDate = searchParams.get(DATE_SEARCH_PARAMS.start);
  const endDate = searchParams.get(DATE_SEARCH_PARAMS.stop);

  const mockData = generateMockFacebookPageDailyInsight({
    from: startDate ?? '',
    to: endDate ?? ''
  });

  return mockData;
};

export default facebookPageStoryAnalyticsLoader;
