import { ITemplateState, ITemplateStateAccount } from '../../../../types';
import { TSidebarAction } from '../../store/models';
import { FormGroup, Label, Input } from 'reactstrap';
import classNames from 'classnames';
import styles from './index.module.css';
import { useNavigation, useParams, useSubmit } from 'react-router-dom';
import { pickBy, every } from 'lodash';
import { SOCIAL_ACCOUNT_ICONS } from './constant';

interface ISidebarProps {
  templateState: ITemplateState;
  dispatcher: React.Dispatch<TSidebarAction>;
}

export default function Sidebar(props: ISidebarProps) {
  const { templateState, dispatcher } = props;

  const submit = useSubmit();
  const navigation = useNavigation();
  const { templateId } = useParams();

  const templatePagesValidity = checkTemplateSocialAccountsSectionValidity(
    templateState.socialAccounts
  );
  const disabled =
    navigation.state === 'submitting' || templatePagesValidity == 'invalid';

  return (
    <div
      style={{
        minWidth: '250px'
      }}>
      <form
        onSubmit={(e) => e.preventDefault()}
        className='d-flex flex-column gap-5'>
        <div className='d-flex flex-column gap-2'>
          <h4 className={'tw-text-xl'}>
            <label htmlFor='template_name'>Template Name</label>
          </h4>
          <input
            type='text'
            id='template_name'
            name='template_name'
            className={classNames('form-control', styles['title-input-elt'])}
            value={templateState.templateName}
            onChange={(e) => {
              dispatcher({
                type: 'UPDATE_TEMPLATE_NAME',
                payload: e.target.value
              });
            }}
          />
        </div>
        <div className='d-flex flex-column gap-2'>
          <h4 className={'tw-text-xl'}>Social Accounts</h4>
          <fieldset className='d-flex flex-column gap-2'>
            {templateState.socialAccounts
              .filter(
                ({ accountType }) =>
                  accountType == 'META_ADS' || accountType == 'IG_BUSINESS'
              )
              .map((value) => {
                const Icon =
                  SOCIAL_ACCOUNT_ICONS[
                    value.accountType as keyof typeof SOCIAL_ACCOUNT_ICONS
                  ];

                return (
                  <FormGroup
                    check
                    key={value.accountId}
                    className='d-flex align-items-center gap-2'>
                    <Input
                      type='switch'
                      role='switch'
                      checked={value.status == 'add-to-template'}
                      id={value.accountId}
                      value={value.accountName}
                      onChange={(e) => {
                        dispatcher({
                          type: 'UPDATE_SELECTED_ACCOUNT',
                          payload: {
                            accountId: value.accountId,
                            status: !!e.target.checked
                              ? 'add-to-template'
                              : 'do-not-add-to-template'
                          }
                        });
                      }}
                    />
                    <Icon />
                    <Label
                      check
                      htmlFor={value.accountId}
                      className={styles['checkbox-label']}>
                      {value.accountName}
                    </Label>
                  </FormGroup>
                );
              })}
          </fieldset>
        </div>
        <div className='d-flex gap-4'>
          <button
            type='button'
            className={classNames(
              styles['btn'],
              styles['update-btn'],
              'disabled:tw-cursor-not-allowed disabled:tw-opacity-70'
            )}
            onClick={() => {
              const formData = createFormData(templateState);

              submit(formData, {
                method: 'POST',
                encType: 'multipart/form-data'
              });
            }}
            disabled={disabled}>
            {templateId ? 'Update' : 'Create'}
          </button>
          <button
            type='button'
            disabled={true}
            className={classNames(
              styles['btn'],
              styles['delete-btn'],
              'disabled:tw-hidden'
            )}>
            Delete
          </button>
        </div>
      </form>
    </div>
  );
}

const checkTemplateSocialAccountsSectionValidity = (
  accounts: ITemplateStateAccount[]
): 'valid' | 'invalid' => {
  const hasAccountsInTemplate = accounts.some(
    ({ status }) => status == 'add-to-template'
  );

  const accountsInTemplateWithNoActiveReportPages = accounts.some(
    ({ status, pages }) =>
      status == 'add-to-template' && every(pages, (isActive) => !isActive)
  );

  return !hasAccountsInTemplate || accountsInTemplateWithNoActiveReportPages
    ? 'invalid'
    : 'valid';
};

const createFormData = (templateState: ITemplateState) => {
  const formData = new FormData();

  formData.append(
    'templateData',
    JSON.stringify({
      brand_id: templateState.brand.id,
      name: templateState.templateName,
      cover_title: templateState.coverTitle,
      colors: Object.values(templateState.colors),
      summary_section_pages: ['COVER_PAGE'],
      social_media_sections: templateState.socialAccounts
        .filter(({ status }) => status == 'add-to-template')
        .map((account) => ({
          account_type: account.accountType,
          account_id: account.accountId,
          pages: Object.keys(pickBy(account.pages, (isActive) => !!isActive))
        }))
    })
  );
  formData.append('cover_image', templateState.coverBackgroundImage ?? 'null');
  formData.append('logo', templateState.logo ?? 'null');
  formData.append('background', templateState.backgroundImage ?? 'null');

  return formData;
};
