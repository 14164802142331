import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { verify } from 'src/User/services/auth';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import displayAxiosError from 'src/lib/utils/displayAxiosError';

export default function useValidateCode() {
  const navigate = useNavigate();
  let { code: verificaitonCode } = useParams();

  const handleVerify = useCallback(async () => {
    try {
      if (!verificaitonCode) {
        Swal.fire({
          title: 'Unable to find email verification code',
          icon: 'error',
          toast: true,
          position: 'top-right',
          timer: 5000,
          showConfirmButton: false
        });

        return;
      }

      await verify(verificaitonCode);
      Swal.fire({
        title: 'Email verified',
        icon: 'success',
        toast: true,
        position: 'top-right',
        timer: 5000,
        showConfirmButton: false
      });
      navigate('/login', { replace: true });
    } catch (error: any) {
      displayAxiosError(error, {
        toast: true,
        title: 'Unable to verify Email',
        fallbackMsg: 'Please connact support',
        duration: 2000
      });
    }
  }, [verificaitonCode, navigate]);

  useEffect(() => {
    handleVerify();
  }, [handleVerify]);
}
