import Api from 'src/Api';
import {
  IGetAllMetaCampaignsByDateFuncProps,
  IConnectMetaAccountProps,
  IConnectMetaAccountResponse,
  IConnectMetaAdAccountProps,
  IConnectMetaAdAccountResponse,
  IGetAllAdAccountInBrandByMetaIdProps,
  IGetAllAdAccountsInBrandByMetaIdResponse,
  IGetAdCreativeByDateResponse,
  IGetAdCampaignsByDateResponse,
  IGetMetaAdAnalyticsSummaryResponse,
  IGetMetaAdAnalyticsSummaryProps,
  IGetMetaAdAnalyticsCampaignInsightsProps,
  IGetMetaAdAnalyticsCampaignInsightsByObjectiveResponse,
  IGetMetaAdAnalyticsCampaignInsightsByCampaignResponse,
  IGetMetaAdAnalyticsCampaignInsightsByAdsetResponse,
  IGetMetaAdAnalyticsCampaignInsightsByAdResponse,
  IGetMetaAdAnalyticsCampaignsInfoResponse
} from './models';

export const connectParentMetaAccount = async (
  props: IConnectMetaAccountProps
) => {
  const { code, brandId } = props;

  return await Api.post<IConnectMetaAccountResponse>('/connect/meta-ads/', {
    code,
    brand_id: brandId
  });
};

export const connectMetaAdAccount = async (
  props: IConnectMetaAdAccountProps
) => {
  const { actAccountId, brandId, connected, metaId } = props;

  return await Api.post<IConnectMetaAdAccountResponse>(
    `/connect/meta-ads/ad-account/`,
    {
      brand_id: brandId,
      meta_id: metaId,
      act_account_id: actAccountId,
      connected: connected
    }
  );
};

export const getAllAdAccountsInBrandByMetaId = async (
  props: IGetAllAdAccountInBrandByMetaIdProps
) => {
  const { brandId, metaId } = props;

  return await Api.get<IGetAllAdAccountsInBrandByMetaIdResponse>(
    `/connect/meta-ads/ad-accounts/?brand_id=${brandId}&meta_id=${metaId}`
  );
};

export const getAllMetaAdCreativesByDate = async (
  props: IGetAllMetaCampaignsByDateFuncProps
) => {
  const { adAccountId, brandId, date } = props;
  const startDateQuery = date?.start ? `&start_time=${date?.start}` : '';
  const stopDateQuery = date?.stop ? `&stop_time=${date?.stop}` : '';
  const url =
    `/meta-ads/analytics/adcreatives/?brand_id=${brandId}` +
    `&act_account_id=${adAccountId}` +
    startDateQuery +
    stopDateQuery;

  return Api.get<IGetAdCreativeByDateResponse>(url);
};

// TODO: Need to delete this service
export const getAllMetaAdCampaignsByDate = async (
  props: IGetAllMetaCampaignsByDateFuncProps
) => {
  const { adAccountId, brandId, date } = props;
  const startDateQuery = date?.start ? `&start_time=${date?.start}` : '';
  const stopDateQuery = date?.stop ? `&stop_time=${date?.stop}` : '';
  const url =
    `/meta-ads/analytics/campaigns/?brand_id=${brandId}` +
    `&act_account_id=${adAccountId}` +
    startDateQuery +
    stopDateQuery;

  return Api.get<IGetAdCampaignsByDateResponse>(url);
};

export const getMetaAdAnalyticsSummary = async (
  props: IGetMetaAdAnalyticsSummaryProps
) => {
  const { adAccountId, brandId, campaignId, campaignObjective, date } = props;
  const startDateQuery = date?.start ? `&start_time=${date?.start}` : '';
  const stopDateQuery = date?.stop ? `&stop_time=${date?.stop}` : '';
  const campaignIdQuery = campaignId ? `&campaign_id=${campaignId}` : '';
  const campaignObjectiveQuery = campaignObjective
    ? `&campaign_objective=${campaignObjective}`
    : '';

  const url =
    `/meta-ads/analytics/summary/?brand_id=${brandId}` +
    `&act_account_id=${adAccountId}` +
    startDateQuery +
    stopDateQuery +
    campaignIdQuery +
    campaignObjectiveQuery;

  return Api.get<IGetMetaAdAnalyticsSummaryResponse>(url);
};

export const getMetaAdCampaignObjectives = async (
  props: Omit<IGetAllMetaCampaignsByDateFuncProps, 'date'>
) => {
  const { adAccountId, brandId } = props;

  return Api.get<{ data: string[] }>(
    `/meta-ads/campaign-objectives/?brand_id=${brandId}&act_account_id=${adAccountId}`
  );
};

export const getMetaAdCampaignInsightsByObjectives = async (
  props: Omit<
    IGetMetaAdAnalyticsCampaignInsightsProps,
    'campaignAdsets' | 'campaignIds' | 'campaignObjectives'
  >
) => {
  const { adAccountId, brandId, date } = props;
  const url =
    `/meta-ads/analytics/campaigns-insights/objectives` +
    `?brand_id=${brandId}&act_account_id=${adAccountId}` +
    `&start_time=${date?.start}` +
    `&stop_time=${date?.stop}`;

  return Api.get<IGetMetaAdAnalyticsCampaignInsightsByObjectiveResponse>(url);
};

export const getMetaAdCampaignInsightsByCampaigns = async (
  props: Omit<
    IGetMetaAdAnalyticsCampaignInsightsProps,
    'campaignAdsets' | 'campaignIds'
  >
) => {
  const { adAccountId, brandId, campaignObjectives, date } = props;
  const url =
    `/meta-ads/analytics/campaigns-insights/campaigns` +
    `?brand_id=${brandId}&act_account_id=${adAccountId}` +
    `&campaign_objectives=${campaignObjectives}` +
    `&start_time=${date?.start}` +
    `&stop_time=${date?.stop}`;

  return Api.get<IGetMetaAdAnalyticsCampaignInsightsByCampaignResponse>(url);
};

export const getMetaAdCampaignInsightsByAdsets = async (
  props: Omit<
    IGetMetaAdAnalyticsCampaignInsightsProps,
    'campaignAdsets' | 'campaignObjectives'
  >
) => {
  const { adAccountId, brandId, campaignIds, date } = props;
  const url =
    `/meta-ads/analytics/campaigns-insights/adsets` +
    `?brand_id=${brandId}&act_account_id=${adAccountId}` +
    `&campaign_ids=${campaignIds}` +
    `&start_time=${date?.start}` +
    `&stop_time=${date?.stop}`;

  return Api.get<IGetMetaAdAnalyticsCampaignInsightsByAdsetResponse>(url);
};

export const getMetaAdCampaignInsightsByAds = async (
  props: Omit<
    IGetMetaAdAnalyticsCampaignInsightsProps,
    'campaignObjectives' | 'campaignIds'
  >
) => {
  const { adAccountId, brandId, campaignAdsets, date } = props;
  const url =
    `/meta-ads/analytics/campaigns-insights/ads` +
    `?brand_id=${brandId}&act_account_id=${adAccountId}` +
    `&adset_ids=${campaignAdsets}` +
    `&start_time=${date?.start}` +
    `&stop_time=${date?.stop}`;

  return Api.get<IGetMetaAdAnalyticsCampaignInsightsByAdResponse>(url);
};

export const getMetaAdCampaigns = (
  props: IGetAllMetaCampaignsByDateFuncProps
) => {
  const { adAccountId, brandId, date } = props;
  const startDateQuery = date?.start ? `&start_time=${date?.start}` : '';
  const stopDateQuery = date?.stop ? `&stop_time=${date?.stop}` : '';

  return Api.get<IGetMetaAdAnalyticsCampaignsInfoResponse>(
    `/meta-ads/campaigns/?brand_id=${brandId}&act_account_id=${adAccountId}${startDateQuery}${stopDateQuery}`
  );
};
