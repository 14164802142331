import React, { useState } from 'react';
import { Formik } from 'formik';
import { Form } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import PatternRight from '../../Assets/images/pattern-right.png';
import PatternStars from '../../Assets/images/pattern-stars.png';
import SOMateLogo from '../../Assets/images/somate-logo.svg';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { forgot } from '../services/auth';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isProgress, setIsProgress] = useState(false);

  return (
    <>
      <React.Fragment>
        <div className='authentication-form-container'>
          <div className='logo_wrapper'>
            <img
              className='somate_logo'
              src={SOMateLogo}
              alt='SOMateLogo'
            />
          </div>
          <div className='login_form'>
            <h4>Forgot Password?</h4>
            <p>Reset password with SoMate.app</p>
            <Formik
              initialValues={{
                email: ''
              }}
              onSubmit={async (values) => {
                setIsProgress(true);
                const response = await forgot(values);
                if (response.status === 200) {
                  Swal.fire({
                    title: 'Success',
                    text: 'We have sent you an email with a link to reset your password.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                  }).then(function () {
                    // Redirect the user
                    // window.location.href = "/reset_password";
                  });
                  setIsProgress(false);
                } else {
                  Swal.fire({
                    title: 'Error',
                    text: response.data.detail,
                    icon: 'error',
                    confirmButtonText: 'OK'
                  }).then(function () {
                    // Redirect the user
                    // window.location.href = "/login";
                  });
                  setIsProgress(false);
                }
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email('Invalid email.').required('Required')
              })}>
              {({
                values,
                handleChange,
                handleSubmit,
                handleBlur,
                errors,
                touched
              }) => (
                <Form className='mt-5'>
                  <div className='mb-3'>
                    <label
                      htmlFor='email'
                      className='form-label'>
                      Email
                    </label>
                    <input
                      className='form-control'
                      id='email'
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      style={{
                        border: errors.email && touched.email && '1px solid red'
                      }}
                      name='email'
                      placeholder='example@xyz.com'
                      type='email'
                    />
                    {errors.email && touched.email ? (
                      <div
                        style={{
                          color: 'red',
                          maxWidth: '363px',
                          fontSize: '12px'
                        }}>
                        {errors.email}
                      </div>
                    ) : null}
                  </div>

                  <button
                    className='my-3 btn btn-primary waves-effect waves-light'
                    color='primary'
                    onClick={handleSubmit}
                    disabled={isProgress}
                    type='submit'>
                    {!isProgress && 'Send Reset Link'}
                    {isProgress && (
                      <div
                        className='spinner-border text-light'
                        role='status'>
                        <span className='sr-only'></span>
                      </div>
                    )}
                  </button>
                  <h4>
                    Wait, I remember my password...{' '}
                    <a
                      className='social_link'
                      onClick={() => {
                        navigate('/login');
                      }}>
                      Click Here
                    </a>
                  </h4>
                </Form>
              )}
            </Formik>
          </div>
          <img
            className='pattern_right'
            src={PatternRight}
            alt='pattern_right'
          />
        </div>
        <img
          className='pattern_stars'
          src={PatternStars}
          alt='pattern_stars'
        />
      </React.Fragment>
    </>
  );
};

export default ForgotPassword;
