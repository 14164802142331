import classNames from 'classnames';
import coverImgPreview from 'src/Assets/images/white_image.png';
import coverBgStyles from './coverBg.module.css';
import sidebarStyles from '../../layout/sidebar/index.module.css';
import { ITemplateSectionProps } from '../../models';

export default function CoverBackground(props: ITemplateSectionProps) {
  const { templateData, dispatcher } = props;

  const backgroundImage =
    templateData.coverBackgroundImage instanceof File
      ? URL.createObjectURL(templateData.coverBackgroundImage as any)
      : templateData.coverBackgroundImage ?? coverImgPreview;

  return (
    <div className='d-flex justify-content-between gap-3 py-3'>
      <form
        className={coverBgStyles['controls']}
        onSubmit={(e) => e.preventDefault()}>
        <div className='d-flex flex-column gap-5'>
          <fieldset className='d-flex flex-column gap-4'>
            <h4 className='tw-text-2xl tw-font-medium tw-mb-0'>
              <label htmlFor='report_title'>Title</label>
            </h4>
            <input
              type='text'
              id='report_title'
              className={classNames(
                'form-control',
                sidebarStyles['title-input-elt']
              )}
              value={templateData.coverTitle}
              onChange={(e) => {
                dispatcher({
                  type: 'UPDATE_COVER_TITLE',
                  payload: e.target.value
                });
              }}
            />
          </fieldset>
          <fieldset className='d-flex flex-column gap-4'>
            <div>
              <h4 className={'tw-text-2xl tw-font-medium tw-mb-0'}>
                Cover background image
              </h4>
              <p className={classNames('m-0', sidebarStyles['subtitle'])}>
                Dimension: 600 * 80px
              </p>
            </div>
            <label htmlFor='report_template_cover_bg'>
              <div
                className={classNames(
                  'position-relative',
                  coverBgStyles['image-select']
                )}>
                <p
                  className={classNames(
                    'position-absolute px-2',
                    coverBgStyles['image-select-text']
                  )}>
                  Click to select image
                </p>
                <div className={coverBgStyles['image-container']}>
                  <img
                    src={backgroundImage}
                    alt='Template cover page background'
                  />
                </div>
              </div>
            </label>
            <input
              type='file'
              accept='image/*'
              id='report_template_cover_bg'
              className='d-none'
              onChange={(e) => {
                const file = e.target.files?.[0];

                if (file && file?.type.startsWith('image'))
                  dispatcher({
                    type: 'UPDATE_COVER_IMG',
                    payload: file
                  });
              }}
            />
          </fieldset>
        </div>
      </form>
      <div
        className={coverBgStyles['preview']}
        style={{
          backgroundImage: `url(${backgroundImage})`
        }}>
        <p
          className={classNames(
            'position-relative',
            coverBgStyles['cover-title']
          )}>
          <span>{templateData.coverTitle}</span>
        </p>
      </div>
    </div>
  );
}
