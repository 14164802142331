import MetricsCard from 'src/User/components/MetricsCard';
import styles from '../index.module.css';
import { Chart } from 'src/User/features/Viz/components/Chart';
import { capitalize } from 'lodash';
import classNames from 'classnames';
import ComingSoonWrapper from 'src/User/components/ComingSoonWrapper';
import { Fragment } from 'react';
import LoadingBarChart from 'src/User/features/Viz/components/Chart/ChartLoading';
import { Props as ReactApexChartProps } from 'react-apexcharts';

type TMetricCardData = {
  icon: JSX.Element;
  title: string | number;
  description: string;
};

type TMetricViewProps = {
  metricsData: TMetricCardData[];
  chart: {
    series: { name: string; data: number[] };
    options: IMetricViewOptions;
  }[];
  chartGridFlow?: 'row' | 'column';
  showComingSoonOverlay?: boolean;
};

interface IMetricViewOptions extends ApexCharts.ApexOptions {
  chartType?: ReactApexChartProps['type'];
}

export default function MetricView({
  metricsData,
  chart,
  chartGridFlow,
  showComingSoonOverlay
}: TMetricViewProps) {
  const Container = !!showComingSoonOverlay ? ComingSoonWrapper : Fragment;

  return (
    <div className='mt-4 d-flex flex-column tw-gap-8'>
      <div className={styles['metrics-container']}>
        {metricsData.map((item, index) => (
          <Container key={index}>
            <MetricsCard
              title={item.title}
              description={item.description}
              icon={item.icon}
            />
          </Container>
        ))}
      </div>

      <div
        className={classNames(styles['charts-container'], {
          [styles['charts-container-column-flow']]: chartGridFlow == 'column'
        })}>
        {chart.map(({ options, series }, index) => (
          <Container key={index}>
            <div className='tw-rounded-xl tw-shadow-lg p-3 bg-white'>
              <Chart
                header={{
                  title: capitalize(series.name),
                  filtersOptions: {}
                }}
                data={{
                  options: options,
                  series: [series]
                }}
                type={options?.chartType ?? 'area'}
              />
            </div>
          </Container>
        ))}
      </div>
    </div>
  );
}

MetricView.Loading = ({
  chartGridFlow
}: {
  chartGridFlow?: TMetricViewProps['chartGridFlow'];
}) => {
  return (
    <div className='mt-4 d-flex flex-column tw-gap-8'>
      <div className={styles['metrics-container']}>
        {Array.from({ length: 3 }).map((_, index) => (
          <MetricsCard.Loading key={index} />
        ))}
      </div>

      <MetricView.LoadingChart chartGridFlow={chartGridFlow} />
    </div>
  );
};

MetricView.LoadingChart = ({
  chartGridFlow
}: {
  chartGridFlow?: TMetricViewProps['chartGridFlow'];
}) => {
  return (
    <div
      className={classNames(styles['charts-container'], {
        [styles['charts-container-column-flow']]: chartGridFlow == 'column'
      })}>
      {Array.from({ length: 3 }).map((_, index) => (
        <div
          className='tw-rounded-xl tw-shadow-lg p-3 bg-white tw-flex tw-items-center'
          key={index}>
          <LoadingBarChart className='tw-mx-auto' />
        </div>
      ))}
    </div>
  );
};
