import {
  Table,
  AvatarCellRenderer,
  NumberCellRenderer
} from 'src/User/features/Viz/components/Table';
import { ICellRendererParams } from 'ag-grid-community';
import {
  useGetInstagramAccountsData,
  TTableData,
  TInstagramTableData,
  useGetTableColsDefs
} from '.';
import { Button } from 'src/components/ui/button';
import { Outlet, useFetcher, useParams } from 'react-router-dom';
import UnControlledDialog from 'src/User/components/UnControlledDialog';
import { DialogDescription } from 'src/components/ui/dialog';
import * as STATIC from './constants';
import { startCase } from 'lodash';
import { IConnectAccountProfileFormEntry } from './views/ConnectIgBusinessProfile/actions/models';
import { useBrand } from 'src/User/layout/Sidebar/components/BrandSelect';

type TMediaAccountDialogTitlesKey =
  keyof typeof STATIC.DISCONNECT_MEDIA_ACCOUNT_DIALOG_TITLES;

export default function AllSocialAccountsView() {
  const { tableData } = useGetInstagramAccountsData();

  return (
    <AllSocialAccountsTable
      key={JSON.stringify(tableData)}
      tableData={tableData}
    />
  );
}

function AllSocialAccountsTable({ tableData }: { tableData: any[] }) {
  const { mediaType } = useParams();
  const fetcher = useFetcher();

  const { tableColsDefs, disconnectAcctDialog, onDisconnectAcctDialogClose } =
    useGetTableColsDefs();
  const { getCurrentBrand } = useBrand();

  return (
    <div className='w-100'>
      <Table
        data={tableData}
        columnsDefs={tableColsDefs}
        isLoading={false}
        height={'100%'}
      />
      <UnControlledDialog
        title={
          STATIC.DISCONNECT_MEDIA_ACCOUNT_DIALOG_TITLES?.[
            mediaType as TMediaAccountDialogTitlesKey
          ] ?? `Disconnect ${startCase(mediaType)} Account Profiles`
        }
        open={disconnectAcctDialog.open}
        submitButtonLabel='Disconnect'
        disableSubmit={fetcher.state == 'submitting'}
        onCancel={onDisconnectAcctDialogClose}
        onOpenChange={onDisconnectAcctDialogClose}
        onConfirm={() => {
          const formData = {
            brand_id: getCurrentBrand().id,
            account_id: disconnectAcctDialog.accountId,
            connected: 'false'
          } satisfies IConnectAccountProfileFormEntry;

          fetcher.submit(formData, {
            method: 'post',
            action: `/socials/${mediaType}`
          });
        }}>
        <DialogDescription>
          Are you sure you want to disconnect
          {disconnectAcctDialog.accountName ? (
            <i className='tw-font-semibold'>
              {' '}
              {disconnectAcctDialog.accountName}
            </i>
          ) : (
            ' this account'
          )}
          ?
        </DialogDescription>
      </UnControlledDialog>
      <Outlet />
    </div>
  );
}

export function AccountCellRenderer(
  props: ICellRendererParams<any, TTableData['accountInfo']>
) {
  const { avatar, username, name } = props.value ?? {};

  return (
    <div className='py-3'>
      <AvatarCellRenderer
        avatar={avatar}
        username={username}
        name={name}
        borderRadius='rounded-full'
      />
    </div>
  );
}

export function DateCellRenderer({ value }: ICellRendererParams<any, string>) {
  return <NumberCellRenderer value={value ?? ''} />;
}

export interface IDisconnectCellRendererProps<
  TData = any,
  TValue = any,
  TContext = any
> extends ICellRendererParams<TData, TValue, TContext> {
  onButtonClick: (accountId: string, accountName: string) => void;
}

export function DisconnectCellRenderer({
  value,
  data,
  onButtonClick
}: IDisconnectCellRendererProps<TInstagramTableData, string>) {
  return (
    <div className='d-flex gap-3 align-items-center h-100'>
      <Button
        className='tw-bg-slate-400 hover:tw-bg-slate-500 tw-rounded-lg'
        onClick={() =>
          onButtonClick(value ?? '', data?.accountInfo?.name ?? '')
        }>
        Disconnect
      </Button>
    </div>
  );
}
