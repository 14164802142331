export const API_AUDIENCE_DATA = {
  ig_business_account_id: '17841404275795028',
  audience_city: {
    'London, England': 1,
    'Louvain-la-Neuve, Wallonia': 1,
    'Mons, Wallonia': 1,
    'Yaoundé, Centre Region': 40,
    'Montreal, Quebec': 4,
    'Divrigi, Sivas Province': 1,
    'Craponne, Rhône-Alpes': 3,
    'Saint-Lin, Quebec': 1,
    'Cottage Grove, Minnesota': 1,
    'Tucson, Arizona': 1,
    'Nantes, Pays de la Loire': 1,
    'Malumfashi, Katsina State': 1,
    'Libreville, Estuaire Province': 1,
    'Moscow, Moscow': 1,
    'Trier, Rheinland-Pfalz': 1,
    'Phnom Penh, Phnom Penh': 1,
    'Tustin, California': 1,
    'Koekelberg, Brussels': 1,
    'Loganville, Pennsylvania': 1,
    'Doha, Doha': 1,
    'Bamenda, Northwest Region': 1,
    'Clayton, Delaware': 1,
    'Longueuil, Quebec': 2,
    'Dschang, West Region': 1,
    'Kigali, Kigali': 1,
    'Abidjan, Abidjan': 2,
    'Douala, Littoral Region': 20,
    'Cypress, Texas': 1,
    'Nashville, Tennessee': 1,
    'Nivelles, Wallonia': 1,
    'Le Kremlin-Bicêtre, Île-de-France': 1,
    'Gießen, Hessen': 1,
    'New York, New York': 1,
    'Dubai, Dubai': 1,
    'Mumbai, Maharashtra': 1,
    'Liège, Wallonia': 1,
    'Lilongwe, Lilongwe District': 1,
    'Leuven, Flemish Region': 1,
    'Johannesburg, Gauteng': 1,
    'North Laurel, Maryland': 1,
    'Bafoussam, West Region': 2,
    'Leesburg, Virginia': 1,
    'Bielefeld, Nordrhein-Westfalen': 1,
    'Hanoi, Hanoi': 1,
    'Quebec, Quebec': 2
  },
  audience_country: {
    DE: 3,
    RU: 1,
    FI: 1,
    BE: 6,
    AE: 1,
    RW: 1,
    IN: 2,
    CI: 2,
    ZA: 1,
    MW: 1,
    CM: 64,
    CN: 1,
    FR: 8,
    QA: 1,
    MA: 1,
    VN: 1,
    NG: 1,
    GA: 1,
    GB: 1,
    KH: 1,
    RO: 1,
    TR: 1,
    CA: 9,
    US: 11
  },
  audience_gender_age: {
    'F.18-24': 1,
    'F.25-34': 26,
    'F.35-44': 4,
    'F.45-54': 1,
    'F.55-64': 1,
    'M.18-24': 4,
    'M.25-34': 34,
    'M.35-44': 34,
    'M.45-54': 2,
    'U.18-24': 2,
    'U.25-34': 2,
    'U.35-44': 4
  },
  audience_locale: {
    it_IT: 1,
    fr_CA: 4,
    en_GB: 8,
    fr_FR: 59,
    en_US: 46,
    de_DE: 3
  },
  online_followers: 0
};
