import { LoaderFunctionArgs } from 'react-router-dom';
import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import {
  getInstagramBusinessDailyInsights,
  getInstagramBusinessInsightsSummary,
  getInstagramBusinessLifetimeInsights,
  getInstagramBusinessMediaInsights
} from 'src/User/services/analytics/instagramAnalyticsService';
import { DATE_SEARCH_PARAMS, USER_CONFIG } from 'src/configs';

const instagramBusinessAnalyticsLoader = async ({
  params,
  request
}: LoaderFunctionArgs) => {
  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);

  const { id: accountId } = params;
  const requestUrl = new URL(request.url);
  const searchParams = new URLSearchParams(requestUrl.search);
  const startDate = searchParams.get(DATE_SEARCH_PARAMS.start);
  const endDate = searchParams.get(DATE_SEARCH_PARAMS.stop);

  const { data: dailyInsightsResponse } =
    await getInstagramBusinessDailyInsights({
      brandId,
      igBusinessAccountId: accountId as string,
      date: {
        start: startDate,
        stop: endDate
      }
    });

  const { data: lifetimeInsightsResponse } =
    await getInstagramBusinessLifetimeInsights({
      brandId,
      igBusinessAccountId: accountId as string
    });

  const { data: mediaInsightsResponse } =
    await getInstagramBusinessMediaInsights({
      brandId,
      igBusinessAccountId: accountId as string,
      date: {
        start: startDate,
        stop: endDate
      }
    });

  const { data: insightsSummaryResponse } =
    await getInstagramBusinessInsightsSummary({
      brandId,
      igBusinessAccountId: accountId as string,
      date: {
        start: startDate,
        stop: endDate
      }
    });

  return {
    dailyInsights: dailyInsightsResponse.data?.filter(
      ({ followers, following }) => !!followers && !!following
    ),
    lifetimeInsights: lifetimeInsightsResponse.data,
    mediaInsights: mediaInsightsResponse.data,
    insightsSummary: insightsSummaryResponse.data
  };
};

export default instagramBusinessAnalyticsLoader;
