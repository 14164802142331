import { TSocialAccountKind } from 'src/lib/models/socialAccounts';

const templatePagesMap = {
  META_ADS: {
    CLICKS: true,
    FREQUENCY: true,
    IMPRESSIONS: true,
    PERFORMANCE: true,
    CPC: true,
    CPM: true,
    CTR: true,
    CAMPAIGNS_RANKING: true,
    CREATIVES_RANKING: true
  },
  IG_BUSINESS: {
    SUMMARY: true,
    DEMOGRAPHICS: true,
    MEDIA_RANKING: true,
    FOLLOWERS_GROWTH: true,
    IMPRESSIONS: true,
    REACH: true,
    STORIES_RANKING: true
  },
  FACEBOOK_PAGE: {
    FOLLOWERS_GROWTH: true,
    IMPRESSIONS: true
  },
  LINKEDIN_PAGE: {
    FOLLOWERS_GROWTH: true,
    IMPRESSIONS: true
  },
  LINKEDIN_ADS: {
    CPC: true,
    SPEND: true
  }
};

const getTemplatePagesByAccounType = (accountType: TSocialAccountKind) =>
  templatePagesMap[accountType];

export default getTemplatePagesByAccounType;
