import { getMetaAdCampaignInsightsByCampaigns } from 'src/User/services/meta-ads';
import useChartSeries, {
  IActiveChartSeries
} from '../../../hooks/useChartSeries';
import useGetData from '../../../hooks/useGetData';
import {
  CampaignInsightByCampaign,
  TActiveChartSeriesInitializer
} from '../../../types';
import createChartSeriesData from '../../../utils/createChartSeriesData';

const useGetCampaignInsights = () => {
  const { loading, response } = useGetData({
    fetcher: getMetaAdCampaignInsightsByCampaigns
  });
  const { activeChartSeries, initializeActiveChartSeries, toggleChartSeries } =
    useChartSeries();

  const { data } = response ?? {};
  const aggregatedCampaignTableData = getAggregatedCampaignsTableData(data);
  const campaingCharts = getAllChartData({
    data,
    initializeActiveChartSeries,
    activeSeriesByChart: activeChartSeries
  });

  return {
    aggregatedCampaignTableData,
    campaingCharts,
    loading,
    toggleChartSeries
  };
};

interface IGetAllChartData {
  data?: CampaignInsightByCampaign[];
  activeSeriesByChart?: IActiveChartSeries;
  initializeActiveChartSeries: TActiveChartSeriesInitializer;
}

const getAllChartData = (props: IGetAllChartData) => {
  const { data, activeSeriesByChart, initializeActiveChartSeries } = props;

  return (
    data?.map(({ insights, name, id, adsets_count }) => ({
      campaignName: name,
      campaignId: id,
      adsetCount: adsets_count,
      chartData: createChartSeriesData({
        chartId: id,
        initializeActiveChartSeries,
        insights,
        activeSeriesByChart
      })
    })) ?? []
  );
};

const getAggregatedCampaignsTableData = (
  data?: CampaignInsightByCampaign[]
) => {
  return (
    data?.map(({ insights, name, id, objective }) => ({
      ...insights.summary,
      objective,
      campaignName: name,
      campaignId: id,
      currency: ''
    })) ?? []
  );
};

export default useGetCampaignInsights;
