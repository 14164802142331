import { startCase } from 'lodash';
import { IInstagramBusinessInsightSummary } from '../../../types';
import { ICONS_MAP } from 'src/User/features/CompetitionAnalysis/views/Instagram/views/Overview/constants';
import i18n, { TNamespace } from 'src/i18n';

const getSummaryData = (data: IInstagramBusinessInsightSummary) => {
  const summary = data.overview;

  return [
    {
      icon: ICONS_MAP['followers'],
      title: summary.followers_growth_rate,
      description: i18n.t('followers_growth_rate', {
        ns: 'analytics' satisfies TNamespace
      })
    },
    {
      icon: ICONS_MAP['engagementRate'],
      title: summary.engagement_rate,
      description: startCase(i18n.t('engagement_rate') ?? '')
    },
    {
      icon: ICONS_MAP['averageLikes'],
      title: summary.average_likes,
      description: i18n.t('Average Likes', {
        ns: 'analytics' satisfies TNamespace
      })
    },
    {
      icon: ICONS_MAP['averageComments'],
      title: summary.average_comments,
      description: i18n.t('Average Comments', {
        ns: 'analytics' satisfies TNamespace
      })
    },
    {
      icon: ICONS_MAP['weeklyPosts'],
      title: summary.new_posts,
      description: i18n.t('New Posts', {
        ns: 'analytics' satisfies TNamespace
      })
    },
    {
      icon: ICONS_MAP['followers'],
      title: summary.new_followers,
      description: i18n.t('New Followers', {
        ns: 'analytics' satisfies TNamespace
      })
    }
  ];
};

export default getSummaryData;
