import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInDays from 'date-fns/differenceInDays';
import addDays from 'date-fns/addDays';
import addHours from 'date-fns/addHours';

type DateDiffUnits = 'w' | 'd' | 'h' | 'm';

const calculateDateDiff = (earlierDate: Date, laterDate: Date) => {
  // Helper function to recursively calculate difference
  const calculateDifference = (
    earlierDate: Date,
    laterDate: Date,
    unit: DateDiffUnits
  ): string => {
    switch (unit) {
      case 'w':
        const weeks = Math.floor(differenceInDays(laterDate, earlierDate) / 7);

        return weeks > 0
          ? `${weeks}w ` +
              calculateDifference(
                addDays(earlierDate, weeks * 7),
                laterDate,
                'd'
              )
          : calculateDifference(earlierDate, laterDate, 'd');
      case 'd':
        const days = Math.floor(differenceInHours(laterDate, earlierDate) / 24);

        return days > 0
          ? `${days}d ` +
              calculateDifference(
                addHours(earlierDate, days * 24),
                laterDate,
                'h'
              )
          : calculateDifference(earlierDate, laterDate, 'h');
      case 'h':
        const hours = Math.floor(
          differenceInMinutes(laterDate, earlierDate) / 60
        );

        return hours > 0
          ? `${hours}h ` +
              calculateDifference(addHours(earlierDate, hours), laterDate, 'm')
          : calculateDifference(earlierDate, laterDate, 'm');
      case 'm':
        const minutes = differenceInMinutes(laterDate, earlierDate) % 60;

        return minutes > 0 ? `${minutes}m` : '';
      default:
        return '';
    }
  };

  // Start the recursion with weeks as the initial unit
  return calculateDifference(earlierDate, laterDate, 'w').trim();
};

export default calculateDateDiff;
