import { Fragment, ReactNode } from 'react';
import { Circle } from 'lucide-react';
import classNames from 'classnames';

interface IStepperProps {
  steps: IStep[];
  orientation?: 'vertical';
}

export interface IStep {
  label: string;
  isActive: boolean;
  content?: ReactNode;
}

const Stepper = (props: IStepperProps) => {
  const { steps, orientation = 'vertical' } = props;

  if (steps?.filter((step) => step.isActive).length > 1) {
    throw new Error('Only one step can be active at a time');
  }

  // TODO: Implement horizontal stepper
  if (orientation !== 'vertical') {
    return null;
  }

  return (
    <div className='tw-flex tw-flex-col tw-py-2'>
      {steps.map(({ isActive, label, content }, index) => {
        return (
          <Fragment key={index}>
            <div>
              <div
                className={classNames(
                  'tw-flex tw-gap-3 tw-items-center tw-leading-none tw-font-medium tw-overflow-visible',
                  {
                    'tw-text-primary-black': isActive,
                    'tw-text-muted-foreground': !isActive
                  }
                )}>
                <Circle
                  className={classNames('tw-w-6 tw-h-6', {
                    'tw-fill-primary-black tw-text-transparent tw-animate-pulse':
                      isActive
                  })}
                />
                <p className='tw-flex-grow tw-mb-0'>{label}</p>
              </div>
              {content ? (
                <div className='tw-flex tw-gap-3 tw-relative'>
                  <div className='tw-absolute tw-top-0 tw-left-0 tw-h-full'>
                    <Connector isActive={isActive} />
                  </div>
                  <div className='tw-ml-8'>{content}</div>
                </div>
              ) : null}
            </div>
            {index !== steps.length - 1 ? (
              <Connector isActive={isActive} />
            ) : null}
          </Fragment>
        );
      })}
    </div>
  );
};

interface IConnectorProps {
  isActive: boolean;
}

const Connector = (props: IConnectorProps) => {
  const { isActive } = props;

  return (
    <div
      className={classNames('tw-h-full tw-w-[1px] tw-ml-[10px]', {
        'tw-bg-slate-600': isActive,
        'tw-bg-muted-foreground': !isActive
      })}>
      <span className='tw-invisible'>Connector</span>
    </div>
  );
};

export default Stepper;
