import {
  Column,
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  SortDirection,
  useReactTable
} from '@tanstack/react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableHead
} from 'src/components/ui/table';
import { ITableData } from '../../../type';
import { TableLoading } from 'src/User/features/Viz/components/Table';
import { CSSProperties, useState } from 'react';
import { ArrowUp, ArrowDown } from 'lucide-react';
import classNames from 'classnames';

interface IDataGrid {
  data: ITableData[];
  columns: ColumnDef<ITableData, string | number>[];
  isLoading?: boolean;
}

const PINNED_COLUMNS = ['thumbnail_url'];

const DataGrid = ({ data, columns, isLoading }: IDataGrid) => {
  const [expanded, setExpanded] = useState<ExpandedState>({});

  //Create table instance
  const table = useReactTable({
    data,
    columns,
    state: {
      expanded
    },
    initialState: {
      columnPinning: {
        left: PINNED_COLUMNS
      }
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: ({ sub_categorie }) => sub_categorie,
    getSortedRowModel: getSortedRowModel()
  });

  if (isLoading) return <TableLoading />;

  return (
    <div className='tw-border tw-rounded-lg tw-flex tw-flex-col tw-h-[500px] tw-overflow-hidden'>
      <Table className=''>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              key={headerGroup.id}
              className='tw-text-base'>
              {headerGroup.headers.map((header) => (
                <TableHead
                  key={header.id}
                  className={classNames(
                    'tw-text-primary-black tw-py-4 !tw-sticky !tw-z-20 tw-bg-[#FBFBFB] tw-top-0 after:tw-w-[2px] after:tw-h-5 after:tw-bg-[#DEDEDE] after:tw-absolute after:tw-top-1/3 after:tw-right-0',
                    { '!tw-z-40 ': header.column.getIsPinned() }
                  )}
                  style={{ ...getPinningStyles(header.column) }}>
                  <div
                    className='tw-flex tw-justify-between tw-items-center hover:tw-cursor-pointer'
                    style={{ minWidth: header.getSize() }}
                    onClick={header.column.getToggleSortingHandler()}>
                    <div>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </div>
                    {header.column.getIsSorted()
                      ? getSortIcon(header.column.getIsSorted())
                      : null}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody className='tw-h-full'>
          {table.getRowModel().rows.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                className='tw-max-h-[56px] !tw-h-[56px] tw-align-top'>
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    className='!tw-py-0 !tw-px-[1px] tw-h-[56px]'
                    style={{ ...getPinningStyles(cell.column) }}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className='tw-h-24 tw-text-center'>
                No data
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

const getPinningStyles = (column: Column<ITableData>): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isPinned === 'left' && column.getIsLastColumn('left');

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-3px 0 4px -4px gray inset'
      : undefined,
    left: isPinned == 'left' ? column.getStart('left') : undefined,
    position: isPinned ? 'sticky' : undefined,
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
    backgroundColor: 'white'
  };
};

const getSortIcon = (sortOrder: SortDirection | false) => {
  if (sortOrder) {
    const Icon = sortOrder == 'asc' ? ArrowUp : ArrowDown;

    return <Icon className='tw-w-[16px] tw-h-[16px] tw-font-bold' />;
  }

  return null;
};

export default DataGrid;
