import { LoaderFunctionArgs } from 'react-router-dom';
import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { getAllMetaAdCreativesByDate } from 'src/User/services/meta-ads';
import { USER_CONFIG } from 'src/configs';
import i18n, { TNamespace } from 'src/i18n';

const facebookAdCreativeLoader = async ({
  params,
  request
}: LoaderFunctionArgs) => {
  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);
  const requestUrl = new URL(request.url);
  const searchParams = new URLSearchParams(requestUrl.search);
  const startDate = searchParams.get('start');
  const endDate = searchParams.get('stop');
  const { adAccountId } = params;

  if (!adAccountId) {
    throw new Response(
      i18n.t('meta.loaders.error.404', 'meta.loaders.error.unspecified', {
        ns: 'adAnalytics' satisfies TNamespace
      }),
      { status: 404 }
    );
  }

  const { data: response } = await getAllMetaAdCreativesByDate({
    adAccountId,
    brandId,
    date: {
      start: startDate ?? '',
      stop: endDate ?? ''
    }
  });
  const { data: adCreatives } = response;

  return adCreatives;
};

export default facebookAdCreativeLoader;
