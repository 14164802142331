import styles from '../../components/index.module.css';
import classNames from 'classnames';

export const METRICS_DATA = [
  {
    icon: (
      <div
        className={classNames(styles['icon-container'], styles['orange-icon'])}>
        <i className='bi bi-lightning-charge-fill'></i>
      </div>
    ),
    title: '1.01%',
    description: 'Engagement Rate'
  },
  {
    icon: (
      <div className={classNames(styles['icon-container'], styles['red-icon'])}>
        <i className='bi bi-heart-fill'></i>
      </div>
    ),
    title: '220',
    description: 'Average Likes'
  },
  {
    icon: (
      <div
        className={classNames(styles['icon-container'], styles['green-icon'])}>
        <i className='bi bi-lightning-charge-fill'></i>
      </div>
    ),
    title: '0.00%',
    description: 'Weekly Engagement Rate'
  },
  {
    icon: (
      <div
        className={classNames(styles['icon-container'], styles['blue-icon'])}>
        <i className='bi bi-image-fill'></i>
      </div>
    ),
    title: 0,
    description: 'Weekly Posts'
  },
  {
    icon: (
      <div
        className={classNames(styles['icon-container'], styles['purple-icon'])}>
        <i className='bi bi-chat-left-fill'></i>
      </div>
    ),
    title: 24,
    description: 'Average Comments'
  },
  {
    icon: (
      <div
        className={classNames(styles['icon-container'], styles['purple-icon'])}>
        <i className='bi bi-chat-left-fill'></i>
      </div>
    ),
    title: 11.04,
    description: 'Comments Ratio'
  }
];
