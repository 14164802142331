import { defer } from 'react-router-dom';
import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { getAllConnectSocialAccounts } from 'src/User/services/connected-social-accts';
import { USER_CONFIG } from 'src/configs';

const getAdAnalyticsAccountLoader = async () => {
  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);

  const response = getAllConnectSocialAccounts(brandId);

  // * For now we are hard coding to `meta-ads`, but as soon as we have other ad providers
  // * remove the hard coding and make sure it's dynamic
  return defer({ response });
};

export default getAdAnalyticsAccountLoader;
