// React Router Imports
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

// Utils Imports
import classNames from 'classnames';

// Localisation Imports
import { useTranslation } from 'react-i18next';
import i18next, { TNamespace } from 'src/i18n';

// Shadcn Imports
import { Button } from 'src/components/ui/button';

// Constants
import { ROUTER_PATHS } from 'src/lib/constants';

// Icon Imports
import {
  ChartNoAxesCombined,
  Presentation,
  CalendarClock,
  CalendarCheck2
} from 'lucide-react';

// Custom Components Imports
import UserSubscriptionCard from './components/UserSubscriptionCard';

interface IStepLinkButton {
  href: string;
  title: string;
  icon: ReactNode;
}

const Dashboard = () => {
  return (
    <article className='tw-min-h-screen-90 tw-h-full tw-bg-white tw-rounded-xl tw-shadow-lg tw-px-5 tw-py-4'>
      <header className='tw-mt-5 '>
        <h4 className='tw-text-lg tw-font-normal tw-font-sans'>
          Welcome to SoMate, your social media mate
        </h4>
        <h2 className='tw-mb-10 tw-tracking-tight tw-font-semibold'>
          How to get the most of SoMate
        </h2>
      </header>
      <div className='tw-grid tw-grid-cols-[1fr,_300px] tw-gap-10'>
        <div className='tw-flex tw-flex-col tw-gap-12'>
          <Section
            title='Connect your social media accounts'
            subtitle='SoMate supports multiple social media platforms. Link your accounts to see all your data our hub'
            isComingSoon={false}
            isCompleted={true}>
            <StepLinks
              steps={[
                {
                  title: 'Facebook Page',
                  icon: (
                    <div>
                      <i className='bi bi-flag-fill tw-text-4xl'></i>
                    </div>
                  ),
                  href: '/socials/facebook-page/integrations/select-media-type/facebook-page'
                },
                {
                  title: 'Instagram Business',
                  icon: (
                    <div>
                      <i className='bi bi-instagram tw-text-4xl'></i>
                    </div>
                  ),
                  href: '/socials/ig-business/integrations/select-media-type/ig-business'
                },
                {
                  title: `Meta ${i18next.t('Ads')}`,
                  href: '/socials/meta-ads/integrations/select-media-type/meta-ads',
                  icon: (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='1em'
                      height='1em'
                      viewBox='0 0 24 24'
                      className='tw-w-9 tw-h-9'>
                      <path
                        fill='currentColor'
                        d='M6.915 4.03c-1.968 0-3.683 1.28-4.871 3.113C.704 9.208 0 11.883 0 14.449c0 .706.07 1.369.21 1.973a6.624 6.624 0 0 0 .265.86a5.297 5.297 0 0 0 .371.761c.696 1.159 1.818 1.927 3.593 1.927c1.497 0 2.633-.671 3.965-2.444c.76-1.012 1.144-1.626 2.663-4.32l.756-1.339l.186-.325c.061.1.121.196.183.3l2.152 3.595c.724 1.21 1.665 2.556 2.47 3.314c1.046.987 1.992 1.22 3.06 1.22c1.075 0 1.876-.355 2.455-.843a3.743 3.743 0 0 0 .81-.973c.542-.939.861-2.127.861-3.745c0-2.72-.681-5.357-2.084-7.45c-1.282-1.912-2.957-2.93-4.716-2.93c-1.047 0-2.088.467-3.053 1.308c-.652.57-1.257 1.29-1.82 2.05c-.69-.875-1.335-1.547-1.958-2.056c-1.182-.966-2.315-1.303-3.454-1.303zm10.16 2.053c1.147 0 2.188.758 2.992 1.999c1.132 1.748 1.647 4.195 1.647 6.4c0 1.548-.368 2.9-1.839 2.9c-.58 0-1.027-.23-1.664-1.004c-.496-.601-1.343-1.878-2.832-4.358l-.617-1.028a44.908 44.908 0 0 0-1.255-1.98c.07-.109.141-.224.211-.327c1.12-1.667 2.118-2.602 3.358-2.602zm-10.201.553c1.265 0 2.058.791 2.675 1.446c.307.327.737.871 1.234 1.579l-1.02 1.566c-.757 1.163-1.882 3.017-2.837 4.338c-1.191 1.649-1.81 1.817-2.486 1.817c-.524 0-1.038-.237-1.383-.794c-.263-.426-.464-1.13-.464-2.046c0-2.221.63-4.535 1.66-6.088c.454-.687.964-1.226 1.533-1.533a2.264 2.264 0 0 1 1.088-.285'
                      />
                    </svg>
                  )
                }
              ]}
            />
          </Section>
          <Section
            title='Explore Real-time analytics on your accounts'
            subtitle='Use our advance analytics tracker to see how your accounts are performing'>
            <div className='tw-flex tw-flex-wrap tw-gap-5'>
              <StepLinkWithSubtitle
                title={i18next.t('analytics')}
                icon={<ChartNoAxesCombined className=' tw-stroke-2' />}
                href={ROUTER_PATHS.ANALYTICS_ROOT_LAYOUT}
                subtitle='View organic analytics of your accounts'
              />
              <StepLinkWithSubtitle
                title={i18next.t('ads-analytics')}
                icon={<Presentation className=' tw-stroke-2' />}
                href={ROUTER_PATHS.ADS_ANALYTICS_LAYOUT}
                subtitle='View paid analytics of your accounts'
              />
            </div>
          </Section>
          <Section
            title='Report on your account performance'
            subtitle='Create and generate reports to share with your team or clients'
            isCompleted={false}>
            <ul className='tw-list-disc tw-pl-4'>
              <li>
                <Button
                  asChild
                  variant={'link'}
                  className='!tw-text-base !tw-px-0 hover:tw-text-primary !tw-text-inherit'>
                  <Link to='/reports/template/new'>
                    Create a report template
                  </Link>
                </Button>
              </li>
              <li>
                <Button
                  asChild
                  variant={'link'}
                  className='!tw-text-base !tw-pl-0 !tw-pr-2 hover:tw-text-primary !tw-text-inherit'>
                  <Link to='/reports'>Generate a report</Link>
                </Button>
                <span className='tw-font-normal'>based on a template</span>
              </li>
            </ul>
          </Section>
        </div>
        <UserSubscriptionCard />
      </div>
    </article>
  );
};

interface ISectionProps {
  title: string;
  subtitle?: string;
  children: ReactNode;
  isCompleted?: boolean;
  isComingSoon?: boolean;
}

const Section = (props: ISectionProps) => {
  const { children, title, subtitle, isComingSoon, isCompleted } = props;

  return (
    <div className='tw-flex tw-flex-col tw-gap-2'>
      <div className='tw-flex tw-items-center tw-gap-3'>
        <div className='tw-p-2 tw-rounded-lg tw-h-fit tw-w-fit tw-border tw-bg-primary-beige-light tw-border-primary tw-text-primary'>
          {isCompleted ? (
            <CalendarCheck2 size={22} />
          ) : (
            <CalendarClock size={22} />
          )}
        </div>
        <h4 className='tw-mb-0 tw-font-medium tw-text-2xl tw-h-12 tw-flex tw-items-center tw-capitalize tw-relative before:tw-absolute before:tw-w-36 before:tw-h-2 before:tw-bottom-3 before:tw-bg-primary before:tw-opacity-25'>
          {title}
          {isComingSoon ? (
            <span className='tw-text-base tw-text-slate-500'>
              (Coming Soon)
            </span>
          ) : (
            ''
          )}
        </h4>
      </div>
      <div className='tw-ml-14'>
        {subtitle ? (
          <p className='tw-mb-4 tw-text-base tw-font-normal'>{subtitle}</p>
        ) : null}
        <div className=''>{children}</div>
      </div>
    </div>
  );
};

const StepLinks = ({ steps }: { steps: IStepLinkButton[] }) => {
  return (
    <div className='tw-flex tw-flex-wrap tw-gap-5'>
      {steps.map((step) => (
        <StepLink
          key={step.href}
          {...step}
        />
      ))}
    </div>
  );
};

interface IStepLinkContainerProps extends IStepLinkButton {
  children: ReactNode;
}

const StepLinkContainer = (props: IStepLinkContainerProps) => {
  const { href, icon, title, children } = props;

  return (
    <Button
      asChild
      variant={'ghost'}>
      <Link
        to={href}
        className={classNames(
          'tw-no-underline tw-border tw-pl-8 tw-pr-4 tw-py-6 tw-rounded-xl tw-h-auto tw-min-w-52 lg:tw-min-w-80 tw-flex tw-flex-col !tw-justify-start lg:tw-flex-row tw-gap-4 tw-text-primary-black tw-shadow tw-transition hover:tw-text-primary-black hover:tw-border-primary hover:tw-bg-white'
        )}>
        <div className='tw-w-10 tw-h-10 tw-flex tw-items-baseline tw-justify-center'>
          {icon}
        </div>
        <div>
          <p className='tw-mb-1 tw-font-semibold tw-text-lg'>{title}</p>
          <div>{children}</div>
        </div>
      </Link>
    </Button>
  );
};

const StepLink = (props: IStepLinkButton) => {
  const { t } = useTranslation<TNamespace>();

  return (
    <StepLinkContainer {...props}>
      <p className='tw-mb-0 tw-font-medium tw-text-sm tw-text-slate-600 tw-w-fit tw-border-b tw-border-primary-black tw-capitalize'>
        {t('connect-now')}
      </p>
    </StepLinkContainer>
  );
};

interface IStepLinkWithSubtitle extends IStepLinkButton {
  subtitle: string;
  showSubtitle?: boolean;
}

const StepLinkWithSubtitle = (props: IStepLinkWithSubtitle) => {
  const { showSubtitle, subtitle } = props;

  return (
    <StepLinkContainer {...props}>
      <p
        className={classNames(
          'tw-mb-0 tw-font-medium tw-text-sm tw-text-slate-600 tw-w-fit tw-capitalize tw-max-w-52 tw-text-wrap',
          { 'tw-hidden': showSubtitle == false }
        )}>
        {subtitle}
      </p>
    </StepLinkContainer>
  );
};

export default Dashboard;
