import { useEffect, useState } from 'react';
import SideBar from './Sidebar';
import NavBar from './Navbar';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigation } from 'react-router-dom';
import classNames from 'classnames';
import { NAV_LINKS } from './Sidebar/constants';

// ** Loader Import
import NProgress from 'nprogress';

function Layout() {
  // ** Hooks
  const [collpaseSidebar, setCollapseSidebar] = useState(false);
  const { t: languageTranslation, i18n } = useTranslation();
  const navigation = useNavigation();

  // ** Utils
  const toggleSidebar = () => {
    setCollapseSidebar(!collpaseSidebar);
  };

  const changeLanguageHandler = (languageValue: string) => {
    i18n.changeLanguage(languageValue);
  };

  useEffect(() => {
    if (navigation.state == 'loading') {
      NProgress.start();
    }

    if (navigation.state == 'idle') {
      NProgress.done();
    }
  }, [navigation.state]);

  return (
    <>
      <div className='app-body tw-p-3'>
        <main
          className={classNames('app-container tw-flex tw-gap-3', {
            // eslint-disable-next-line no-useless-computed-key
            ['ToggleMenuClass']: collpaseSidebar
          })}>
          <SideBar
            translator={languageTranslation}
            collapse={collpaseSidebar}
            navLinks={NAV_LINKS}
          />
          <div className='app-content-container'>
            <NavBar
              isSidebarOpen={collpaseSidebar}
              translator={languageTranslation}
              changeLanguageHandler={changeLanguageHandler}
              toggleSidebar={toggleSidebar}
            />
            <div className={classNames('app-content tw-flex-grow')}>
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Layout;
