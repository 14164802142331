import ErrorRenderer from '../../../components/Error/AnalyticsErrorRenderer';

const ErrorBoundary = () => {
  return (
    <ErrorRenderer
      title='We encountered while fetching analytics data'
      fallbackErrorMsg='please reload the page'
    />
  );
};

export default ErrorBoundary;
