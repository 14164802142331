import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Swal from 'sweetalert2';
import './CreatePost.css';
import LaptopIc from '../../Assets/images/laptop.svg';
import MobileIc from '../../Assets/images/mobile.svg';
import DirectionIc from '../../Assets/images/direction.svg';
import LocationIc from '../../Assets/images/location.svg';
import EditIc from '../../Assets/images/edit.svg';
import ImageIc from '../../Assets/images/image.svg';
import ChatIc from '../../Assets/images/chat.svg';
import ShareIc from '../../Assets/images/share.svg';
import DeleteIc from '../../Assets/images/delete.svg';
import TimingIc from '../../Assets/images/time-laps.svg';
import ProfileImg from '../../Assets/images/profile-img.png';
import ActiveImg from '../../Assets/images/active-check.svg';
import InstaIc from '../../Assets/images/insta-circle.svg';
import CloseIc from '../../Assets/images/close-ic.svg';
import { format } from 'date-fns';
import InstaCircle from '../../Assets/images/insta-circle.svg';
import { Modal, ModalBody } from 'reactstrap';

// import { useRecoilState } from "recoil";
// import { UserInstaAccounts } from '../recoil/Atoms/UserInforAtom';

const CreatePost = () => {
  const [selecteStatus, setSelectStatus] = useState('Instagram');
  const [selecteStatus2, setSelectStatus2] = useState('8');
  const [descriptionValue, setDescriptionValue] = useState(
    'This is a template description for the bear post.'
  );
  const [modal, setModal] = useState(false);
  const [chatInput, setChatInput] = useState('');
  const toggle2 = () => setModal(!modal);

  // const [accounts, SetAccounts] = useRecoilState(UserInstaAccounts);
  const [devSelected, setDevSelected] = useState('mobile');

  const [hashArray, setHashArray] = useState(['Bear']);
  const [hashInput, setHashInput] = useState('');
  const deleteHash = (index) => {
    const arr = hashArray;
    arr.splice(index, 1);
    setHashArray([...arr]);
  };

  const [file, setFile] = useState();
  function handleFileChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const [publishToList, setPublishToList] = useState([
    {
      email: 'Vanky.s@wanes1',
      checked: true,
      mediaType: 'post'
    },
    {
      email: 'Shaddy.s@wanes2',
      checked: false,
      mediaType: 'post'
    },
    {
      email: 'Noah.s@wanes3',
      checked: true,
      mediaType: 'post'
    },
    {
      email: 'Jenelef.s@wanes4',
      checked: false,
      mediaType: 'post'
    },
    {
      email: 'Zara.s@wanes5',
      checked: true,
      mediaType: 'post'
    }
  ]);

  const publishToListDelete = (index) => {
    const arr = publishToList;
    arr[index].checked = false;
    setPublishToList([...arr]);
  };

  const checkPublishItem = (index) => {
    const arr = publishToList;
    arr[index].checked = !arr[index].checked;
    setPublishToList([...arr]);
  };

  const checkPublishItemRadio = (index, d) => {
    const arr = publishToList;
    arr[index].mediaType = d;
    setPublishToList([...arr]);
  };
  return (
    <>
      <div className='create-post'>
        <div className='post-area card-style'>
          <div className='create-post-inner'>
            {/* <div className="post-type-wrapper">
                        <div className="post-type">
                            <span onClick={() => { }} className='badges'>Post</span>
                            <span onClick={() => { }} className='badges'>Reel</span>
                        </div>
                        <div className="select-post-type">
                            <select onChange={(e) => { setSelectedOption(e.target.value) }} value={selectedOption}>
                                <option value="Instagram">Instagram</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Linkedin">Linkedin</option>
                                <option value="Twitter">Twitter</option>
                                <option value="Pinterest">Pinterest</option>
                            </select>
                        </div>
                    </div> */}
            <div className='enter-post-details'>
              <div className='publish-to'>
                <p>Publish to</p>
                <div className='publish-to-wrapper'>
                  <div className='publish-to-list'>
                    {publishToList.map((v, i) => {
                      if (v.checked) {
                        return (
                          <span
                            key={i}
                            className='publish-to-list-itm'>
                            <div className='publish-to-img'>
                              <img
                                src={InstaIc}
                                alt='Instagram'
                              />
                            </div>
                            <div className='publish-to-details'>
                              <p className='publish-to-username'>{v.email}</p>
                              <small>{v.mediaType}</small>
                            </div>
                            <div className='close-ic'>
                              <img
                                onClick={() => {
                                  publishToListDelete(i);
                                }}
                                src={CloseIc}
                                alt='close'
                              />
                            </div>
                          </span>
                        );
                      }

                      return null;
                    })}
                  </div>
                  <div className='social-acc-col-wrapper'>
                    <div
                      className='social-acc-col add-new-account'
                      onClick={() => {
                        setModal(!modal);
                      }}>
                      <i className='bi bi-plus'></i>
                    </div>
                  </div>
                </div>
              </div>
              <textarea
                value={descriptionValue}
                onChange={(e) => {
                  setDescriptionValue(e.target.value);
                }}
                type='text'
                className='form-control'
                alt=''
                placeholder='Description'
                rows='4'
              />
              <div className='hashtags-row'>
                <div className='hashtags-row-inner'>
                  <input
                    type='text'
                    value={hashInput}
                    onChange={(e) => {
                      if (e.target.value != ' ') {
                        setHashInput(e.target.value);
                      }
                    }}
                    className='form-control'
                    alt=''
                    placeholder='Hashtags'
                  />
                  <span
                    className='btn btn-outline-primary waves-effect waves-light'
                    onClick={() => {
                      if (hashInput !== '') {
                        if (hashArray.length < 30) {
                          setHashInput('');
                          setHashArray([...hashArray, hashInput]);
                        } else {
                          alert('hash limit reached');
                        }
                      } else {
                        Swal.fire({
                          position: 'top-center',
                          icon: 'warning',
                          title: 'Hash limit reached',
                          showConfirmButton: false,
                          timer: 1000
                        });
                      }
                    }}>
                    add
                  </span>
                </div>
                <div className='hashtags-span'>
                  {hashArray.map((v, i) => {
                    return (
                      <span key={i}>
                        #{v}
                        <h6
                          onClick={() => {
                            deleteHash(i);
                          }}
                          className='span-cross'>
                          x
                        </h6>
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className='editor-row'>
                <div className='action-left'>
                  <button
                    onClick={() => {}}
                    type='button'
                    className='btn btn-outline-primary waves-effect waves-light'>
                    Edit Image
                  </button>
                </div>
                <div className='action-right'>
                  <select
                    onChange={(e) => {
                      setSelectStatus2(e.target.value);
                    }}
                    value={selecteStatus2}
                    className='font-siz'>
                    <option value='6'>6</option>
                    <option value='8'>8</option>
                    <option value='10'>10</option>
                    <option value='12'>12</option>
                    <option value='14'>14</option>
                    <option value='16'>16</option>
                  </select>
                  <span className='font-bold'>B</span>
                  <span className='font-italic'>/</span>
                  <span className='emoji'>🙂</span>
                </div>
              </div>
              <label
                htmlFor='draginput'
                className='btn btn-primary waves-effect waves-light w-100 '>
                Click or Drag & Drop media
              </label>
              <input
                onChange={handleFileChange}
                id='draginput'
                type='file'
                hidden
              />
            </div>
            <div className='feed-actions'>
              <div className='feed-action-list'>
                <ul>
                  <li onClick={() => {}}>
                    <img
                      src={DirectionIc}
                      alt='Direction'
                    />
                  </li>
                  <li onClick={() => {}}>
                    <img
                      src={LocationIc}
                      alt='Location'
                    />
                  </li>
                </ul>
                <ul>
                  <li onClick={() => {}}>
                    <img
                      src={EditIc}
                      alt='Edit'
                    />
                  </li>
                  <li onClick={() => {}}>
                    <img
                      src={ImageIc}
                      alt='post preview'
                    />
                  </li>
                </ul>
                <ul>
                  <li onClick={() => {}}>
                    <img
                      src={ChatIc}
                      alt='Chat'
                    />
                  </li>
                  <li onClick={() => {}}>
                    <img
                      src={ShareIc}
                      alt='Share'
                    />
                  </li>
                  <li onClick={() => {}}>
                    <img
                      src={DeleteIc}
                      alt='Delete'
                    />
                  </li>
                </ul>
                <ul>
                  <li onClick={() => {}}>
                    <img
                      src={TimingIc}
                      alt='Timing'
                    />
                  </li>
                </ul>
              </div>
              <button
                onClick={() => {}}
                type='button'
                className='btn btn-outline-primary waves-effect waves-light'>
                Feed
              </button>
            </div>
          </div>
        </div>
        <div className='preview-area card-style'>
          <nav>
            <div
              className='nav nav-tabs preview-area-tab-list'
              id='nav-tab'
              role='tablist'>
              <button
                className='nav-link tab active'
                id='nav-preview-tab'
                data-bs-toggle='tab'
                data-bs-target='#nav-preview'
                type='button'
                role='tab'
                aria-controls='nav-home'
                aria-selected='true'>
                Preview
              </button>
              <button
                className='nav-link tab'
                id='nav-schedule-tab'
                data-bs-toggle='tab'
                data-bs-target='#nav-schedule'
                type='button'
                role='tab'
                aria-controls='nav-schedule'
                aria-selected='false'>
                Schedule
              </button>
              <button
                className='nav-link tab'
                id='nav-ai-tab'
                data-bs-toggle='tab'
                data-bs-target='#nav-ai'
                type='button'
                role='tab'
                aria-controls='nav-ai'
                aria-selected='false'>
                AI Tools
              </button>
              <button
                className='nav-link tab'
                id='nav-accounts-tab'
                data-bs-toggle='tab'
                data-bs-target='#nav-accounts'
                type='button'
                role='tab'
                aria-controls='nav-accounts'
                aria-selected='false'>
                Accounts
              </button>
            </div>
          </nav>
          <div
            className='tab-content preview-tab-content'
            id='nav-tabContent'>
            <div
              className='tab-pane fade show active'
              id='nav-preview'
              role='tabpanel'
              aria-labelledby='nav-preview-tab'
              tabIndex='0'>
              <div className='preview-inner'>
                <div className='plateform-selection'>
                  <select
                    onChange={(e) => {
                      setSelectStatus(e.target.value);
                    }}
                    value={selecteStatus}>
                    <option value='Instagram'>Instagram</option>
                    <option value='Facebook'>Facebook</option>
                    <option value='Linkedin'>Linkedin</option>
                    <option value='Twitter'>Twitter</option>
                    <option value='Pinterest'>Pinterest</option>
                    <option value='Youtube'>Youtube</option>
                  </select>
                </div>
                <div className='select-device'>
                  <div
                    onClick={() => {
                      setDevSelected('laptop');
                    }}
                    className={
                      devSelected == 'laptop' ? 'laptop selected' : 'laptop'
                    }>
                    <img
                      src={LaptopIc}
                      alt='Laptop'
                    />
                  </div>
                  <div
                    onClick={() => {
                      setDevSelected('mobile');
                    }}
                    className={
                      devSelected == 'mobile' ? 'mobile selected' : 'mobile'
                    }>
                    <img
                      src={MobileIc}
                      alt='Mobile'
                    />
                  </div>
                </div>
              </div>
              <div className='post-item card-style'>
                <div className='post-title-time'>
                  <h2>Post Title</h2>
                  <div className='date-time text-muted'>
                    <span>{format(new Date(), 'hh:mm a')}</span>,
                    <span> {format(new Date(), 'dd MMM yyyy')}</span>
                  </div>
                </div>
                <div className='post-thumbnail'>
                  {file ? (
                    <img
                      src={file}
                      alt='Thumbnail'
                    />
                  ) : (
                    <img
                      src='https://static.vecteezy.com/packs/media/photos/term-bg-3-f6a12264.jpg'
                      alt='Thumbnail'
                    />
                  )}
                </div>
                <div className='hashtags hashtag-createpost'>
                  <span style={{ color: '#555' }}>{descriptionValue}</span>
                  {hashArray.map((v, i) => {
                    return <span key={i}>#{v}</span>;
                  })}
                </div>
                <div className='plateforms-list-wrapper'>
                  <div className='plateforms-list'>
                    <span>
                      <img
                        src={InstaCircle}
                        alt='Instagram'
                      />
                    </span>
                  </div>
                  <div className='repost'>
                    <span>
                      <i className='bi bi-arrow-90deg-right'></i>
                    </span>
                    Post
                  </div>
                </div>
              </div>
            </div>
            <div
              className='tab-pane fade'
              id='nav-schedule'
              role='tabpanel'
              aria-labelledby='nav-schedule-tab'
              tabIndex='0'>
              <Calendar />
            </div>
            <div
              className='tab-pane fade'
              id='nav-ai'
              role='tabpanel'
              aria-labelledby='nav-ai-tab'
              tabIndex='0'>
              <div className='chatting-cell-AI'>
                <div className='chatting-area'>
                  {/* <div className="chat-date"><span>07 May, 2023</span></div> */}
                  <div className='chat-right-AI'>
                    <span>hi</span>
                  </div>
                  <div className='chat-left-AI'>
                    <span>Hello, how can I help you today?</span>
                  </div>
                  <div className='chat-right-AI'>
                    <span>How can I have the best post for my followers?</span>
                  </div>
                  <div className='chat-left-AI'>
                    <span>
                      I will help you out. Just follow my instructions.
                    </span>
                  </div>
                  <div className='chat-right-AI'>
                    <span>Yes, sure.</span>
                  </div>
                  <div className='chat-right-AI'>
                    <span>And also I want to improve my ranking.</span>
                  </div>
                  <div className='chat-right-AI'>
                    <span>...</span>
                  </div>
                </div>
                <div className='chat-input-AI'>
                  <input
                    value={chatInput}
                    onChange={(e) => {
                      setChatInput(e.target.value);
                    }}
                    type='text'
                    placeholder='Your message...'
                  />
                  <div className='send-ic'>
                    <i
                      onClick={() => {
                        alert(chatInput);
                        setChatInput('');
                      }}
                      className='bi bi-send-fill'></i>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='tab-pane fade'
              id='nav-accounts'
              role='tabpanel'
              aria-labelledby='nav-accounts-tab'
              tabIndex='0'>
              <div className='accounts-wrapper'>
                {publishToList.map((v, i) => {
                  return (
                    <div
                      key={i}
                      className='account-col'>
                      <div className='profile-wrapper'>
                        <div className='profile-user'>
                          <img
                            src={ProfileImg}
                            alt='profile_img'
                          />
                        </div>
                        <div className='active-status'>
                          <img
                            src={ActiveImg}
                            alt='Active status'
                          />
                        </div>
                        <div className='plateform-ic'>
                          <img
                            src={InstaIc}
                            alt='Plateform'
                          />
                        </div>
                      </div>
                      <h4>{v.email}</h4>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Account Modal */}

      <Modal
        size='md'
        isOpen={modal}
        toggle={toggle2}>
        <ModalBody>
          <div className='modal-head'>
            <h4 id='addAccModalLabel'>Select account</h4>
            <button
              type='button'
              className='btn-close'
              onClick={() => {
                setModal(!modal);
              }}></button>
          </div>
          <div className='add-acc-list'>
            {publishToList.map((v, i) => {
              return (
                <div
                  key={i}
                  className='add-acc-list-itm'>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={v.checked}
                      onChange={() => {
                        checkPublishItem(i);
                      }}
                      value=''
                      id='flexCheckDefault'
                    />
                  </div>
                  <div className='account-img'>
                    <img
                      src={InstaIc}
                      alt='Instagram'
                    />
                  </div>
                  <p
                    className='acc-username'
                    style={{ margin: 0 }}>
                    {v.email}
                  </p>
                  <div className='post-type-wrapper'>
                    <div className='post-type'>
                      <span
                        onClick={() => {
                          checkPublishItemRadio(i, 'post');
                        }}
                        className={
                          v.mediaType == 'post'
                            ? 'badges badge-active'
                            : 'badges'
                        }>
                        Post
                      </span>
                      <span
                        onClick={() => {
                          checkPublishItemRadio(i, 'reel');
                        }}
                        className={
                          v.mediaType == 'reel'
                            ? 'badges badge-active'
                            : 'badges'
                        }>
                        Reel
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <button
            type='button'
            className='btn btn-primary btn-sm'
            onClick={() => {
              setModal(!modal);
            }}>
            Continue
          </button>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreatePost;
