import AnalyticsErrorRenderer from 'src/User/components/Error/AnalyticsErrorRenderer';

const RootRouteErrorBoundary = () => {
  return (
    <AnalyticsErrorRenderer
      title='Unable to load reports'
      fallbackErrorMsg='Error occurred while loading reports data'
      hideConnectAccount={true}
    />
  );
};

export default RootRouteErrorBoundary;
