import { reduce } from 'lodash';
import {
  getChartFilterOptions,
  getChartOptions,
  getSeriesData
} from '../../Posts/utils/chart-utils';
import { IFacebookPageAudienceInsight } from '../types';

const useAudienceData = (data: IFacebookPageAudienceInsight) => {
  const { daily, page_fans } = data;

  const dates = daily?.map(({ date }) => date);
  const dailyAgeImpressions = daily.map(({ date, impressions_by_age }) => ({
    date,
    ...impressions_by_age
  }));
  const dailyGenderImpressions = daily.map(
    ({ date, impressions_by_gender }) => ({
      date,
      male: impressions_by_gender['M'],
      female: impressions_by_gender['F'],
      unknown: impressions_by_gender['U']
    })
  );

  const impressionsByGenderChartSeriesData = getSeriesData(
    dailyGenderImpressions
  );
  const totalImpressionsByGender = getChartFilterOptions(
    impressionsByGenderChartSeriesData
  );
  const impressionsByGenderChartConfigOptions = getChartOptions(dates);

  const impressionsByAgeChartSeriesData = getSeriesData(
    dailyAgeImpressions,
    true
  );

  const totalImpressionsByAge = getChartFilterOptions(
    impressionsByAgeChartSeriesData
  );
  const impressionsByAgeChartConfigOptions = getChartOptions(dates);

  const regionNamesInEnglish = new Intl.DisplayNames(['en'], {
    type: 'region'
  });
  const pageFans = reduce(
    page_fans,
    function convertCountryAbbrToFullNames(acc, count, countryAbbreviation) {
      const fullCtryName =
        regionNamesInEnglish.of(countryAbbreviation) ?? countryAbbreviation;

      return {
        ...acc,
        [fullCtryName]: count
      };
    },
    {} as Record<string, number>
  );

  return {
    impressionsByGenderChartSeriesData,
    totalImpressionsByGender,
    impressionsByGenderChartConfigOptions,
    impressionsByAgeChartSeriesData,
    totalImpressionsByAge,
    impressionsByAgeChartConfigOptions,
    pageFans
  };
};

export default useAudienceData;
