import { useState } from 'react';
import styles from './settings.module.css';
import {
  AccountSettings,
  BrandSettings,
  NotificationSettings,
  SubscriptionSettings,
  TimeSettings,
  Sidebar
} from './components';
import classNames from 'classnames';

const links = [
  {
    key: 'account',
    label: 'Account'
  },
  {
    key: 'subscription',
    label: 'Subscription'
  },
  {
    key: 'time',
    label: 'Time & Language Setting'
  },
  {
    key: 'notification',
    label: 'Notification Settings'
  },
  {
    key: 'brand',
    label: 'Brands'
  }
];

export default function Settings() {
  const [selected, setSelected] = useState('account');

  return (
    <>
      <div>
        <div className='comp-header'>
          <h2>Settings</h2>
          <p>Accounts settings</p>
        </div>
        <div className={classNames(styles['settings-inner'], 'card-style')}>
          <Sidebar
            links={links}
            onLinkChange={setSelected}
            activeLink={selected}
          />
          <div>
            <div className={styles['settings-head']}>
              <h4>My Account</h4>
            </div>
            <div>
              {selected == 'account' && <AccountSettings />}
              {selected == 'subscription' && <SubscriptionSettings />}
              {selected == 'time' && <TimeSettings />}
              {selected == 'notification' && <NotificationSettings />}
              {selected == 'brand' && <BrandSettings />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
