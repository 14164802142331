import { omit } from 'lodash';
import { IActiveChartSeries } from '../hooks/useChartSeries';
import {
  IInsight,
  TActiveChartSeriesInitializer,
  TChartMetrics
} from '../types';

interface ICreateChartData {
  insights: IInsight;
  initializeActiveChartSeries: TActiveChartSeriesInitializer;
  activeSeriesByChart?: IActiveChartSeries;
  chartId: string;
}

const INSIGHT_METRICS: TChartMetrics[] = [
  'spend',
  'clicks',
  'reach',
  'impressions',
  'cpc',
  'ctr',
  'cpm'
];

const INITIAL_ACTIVE_SERIES: TChartMetrics[] = ['cpm', 'ctr'];

const createChartSeriesData = (props: ICreateChartData) => {
  const {
    chartId,
    insights,
    activeSeriesByChart,
    initializeActiveChartSeries
  } = props;

  const InsightAsArray = Object.entries(omit(insights, 'summary'));
  const sortedInsights = Object.fromEntries(
    InsightAsArray.sort(
      ([date1], [date2]) =>
        new Date(date1).getTime() - new Date(date2).getTime()
    )
  );

  return {
    dates: Object.keys(sortedInsights),
    seriesData: INSIGHT_METRICS.map((metric) => {
      const dailyMetricValues = Object.values(sortedInsights).map(
        (item) => item[metric]
      );
      const activeChartSeries = activeSeriesByChart?.[chartId];

      if (!activeChartSeries) {
        initializeActiveChartSeries(chartId, INITIAL_ACTIVE_SERIES);
      }

      return {
        name: metric,
        data: dailyMetricValues,
        total: insights.summary[metric] ?? 0,
        showSeries: activeSeriesByChart?.[chartId]?.includes(metric)
      };
    })
  };
};

export default createChartSeriesData;
