import React from 'react';
import { Chart } from 'src/User/features/Viz/components/Chart';
import { Table } from 'src/User/features/Viz/components/Table';
import Card from 'src/User/features/AdAnalytics/views/MetaAdsAnalytics/components/Card';
import * as STATIC from './constants';
import useGetObjectiveInsights from './hooks/useGetObjectiveInsights';
import { IChartHeaderFilterOption } from 'src/User/features/Viz/components/types';
import { TChartMetrics } from '../../types';
import getChartOptions from '../../utils/getChartOptions';

const ObjectiveAnalytics = () => {
  const {
    aggregatedObjectivesTableData,
    objectiveCharts,
    loading,
    toggleChartSeries
  } = useGetObjectiveInsights();

  return (
    <div className='tw-flex tw-flex-col tw-gap-6 tw-flex-grow tw-mt-4 tw-h-full'>
      <Card
        title='Campaign Objectives'
        className='tw-h-full tw-min-h-[450px] tw-flex tw-flex-col tw-flex-grow'
        cardContentProps={{
          className: 'tw-flex tw-flex-col tw-flex-grow !tw-pl-6'
        }}>
        <Table
          isLoading={loading}
          height={'100%'}
          columnsDefs={STATIC.OBJECTIVES_TABLE_COLS}
          data={aggregatedObjectivesTableData}
        />
      </Card>

      {objectiveCharts.map(({ campaignCount, chartData, objective }) => (
        <Card
          key={objective}
          title={
            <>
              {objective}{' '}
              <span className='tw-text-base tw-text-slate-600'>
                ({campaignCount} Campaigns)
              </span>
            </>
          }>
          <Chart
            data={{
              options: getChartOptions(objective, chartData.dates),
              series: chartData.seriesData?.filter(
                ({ showSeries }) => showSeries
              )
            }}
            header={{
              title: '',
              filtersOptions: chartData.seriesData.reduce(
                (acc, { name, total, showSeries }, seriesIndex) => {
                  acc[name] = {
                    value: total,
                    isActive: showSeries
                  };

                  return acc;
                },
                {} as IChartHeaderFilterOption
              ),
              onFilterOptionClick: (seriesName) => {
                toggleChartSeries(objective, seriesName as TChartMetrics);
              }
            }}
            type='area'
          />
        </Card>
      ))}
    </div>
  );
};

export default ObjectiveAnalytics;
