const sortDataByDate = <T extends { date: string }>(
  data: T[],
  order?: 'asc' | 'desc'
) => {
  return data.sort((a, b) =>
    order == 'desc'
      ? new Date(b.date).getTime() - new Date(a.date).getTime()
      : new Date(a.date).getTime() - new Date(b.date).getTime()
  );
};

export default sortDataByDate;
