import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { getConnectedSocialAccountsByType } from 'src/User/services/connected-social-accts';
import { USER_CONFIG } from 'src/configs';
import Swal from 'sweetalert2';
import { connectAccount } from 'src/User/services/oauth-connect';
import { lowerCase, startCase } from 'lodash';
import { TMediaType } from 'src/User/services/oauth-connect/models';
import { SocialAccountKindMap } from 'src/lib/models/socialAccounts';
import {
  OAUTH_FLOW_STATUS,
  OAUTH_FLOW_STATUS_QUERY_KEY
} from '../../SelectOauthSocialMediaType/views/Integrations/constants';
import displayAxiosError from 'src/lib/utils/displayAxiosError';

const igBussinessAccountLoader = async ({
  request,
  params
}: LoaderFunctionArgs) => {
  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);

  const { mediaType } = params as { mediaType: TMediaType };
  const requestURL = new URL(request.url);
  const searchParams = new URLSearchParams(requestURL.search);
  const oauthStateParam = searchParams.get('state');
  const oauthState = JSON.parse(oauthStateParam ?? '{}');

  // Do noting if oauth flow is pending
  if (oauthState?.[OAUTH_FLOW_STATUS_QUERY_KEY] === OAUTH_FLOW_STATUS.PENDING)
    return null;

  // Print error message if oauth flow failed
  if (searchParams.has('error')) {
    Swal.fire({
      title: `Unable to connect ${lowerCase(mediaType)} account`,
      text: searchParams.get('error') ?? 'Please try again, or contact support',
      icon: 'error',
      showConfirmButton: false,
      toast: true,
      position: 'top-right',
      timer: 7000,
      timerProgressBar: true
    });
  }

  // Send oauth code to the server if oauth flow is successful, then
  // redirect to the connect account profile page
  if (
    Object.keys(SocialAccountKindMap).includes(oauthState?.oauth_flow) &&
    searchParams.has('code')
  ) {
    try {
      const { data } = await connectAccount({
        brandId,
        code: searchParams.get('code') as string,
        mediaType
      });

      Swal.fire({
        title: `${lowerCase(mediaType)} connected successfully`,
        icon: 'success',
        showConfirmButton: false,
        toast: true,
        position: 'top-right',
        timer: 5000,
        timerProgressBar: true
      });

      const { data: allIgBusinessAccountProfiles } = data;
      const metaId = allIgBusinessAccountProfiles[0]?.meta_id ?? '';

      return redirect(`/socials/${mediaType}/${metaId}/connect-account`);
    } catch (error) {
      displayAxiosError(error, {
        title: `Connection Failed`,
        fallbackMsg: `Unable to validate connection with ${startCase(
          mediaType
        )}`,
        duration: 10000,
        toast: true
      });
    }
  }

  const { data: reponse } = await getConnectedSocialAccountsByType({
    brandId,
    accountType: SocialAccountKindMap[mediaType]
  });
  const { data: allIgBusinessAccount } = reponse;

  return allIgBusinessAccount[SocialAccountKindMap[mediaType]] ?? [];
};

export default igBussinessAccountLoader;
