import React from 'react';
import { LINKEDIN_PAGE_OAUTH_CONFIG } from '../../constants';

export default function LinkedinPageOauthModal() {
  return (
    <div>
      <p>
        Monitor your linkedin page&apos;s performance and manage your
        page&apos;s content.
      </p>
    </div>
  );
}

LinkedinPageOauthModal.headerTitle = 'Connect Linkedin';

LinkedinPageOauthModal.headerIcon = (
  <div>
    <i className='bi bi-linkedin'></i>
  </div>
);

LinkedinPageOauthModal.oauthReqUrl =
  `https://www.linkedin.com/oauth/v2/authorization?` +
  `client_id=${LINKEDIN_PAGE_OAUTH_CONFIG.clientId}&` +
  `redirect_uri=${LINKEDIN_PAGE_OAUTH_CONFIG.redirectUri}&` +
  `response_type=${LINKEDIN_PAGE_OAUTH_CONFIG.responseType}&` +
  `scope=${LINKEDIN_PAGE_OAUTH_CONFIG.scope}&` +
  `state=${LINKEDIN_PAGE_OAUTH_CONFIG.state}`;
