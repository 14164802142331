import { FormGroup, Input, Label } from 'reactstrap';
import styles from './table-header.module.css';

interface IProps {
  displayName: string;
  activeMetrics: string[];
  metricKey: string;
  toggleMetric: (metricKey: string) => void;
  toggleSort?: () => void;
}

const TableHeaderComponent = (props: IProps) => {
  const { activeMetrics, metricKey, displayName, toggleMetric, toggleSort } =
    props;

  const activateCheckbox = activeMetrics.includes(metricKey);

  return (
    <div>
      <FormGroup
        check
        inline
        className={styles['form-input-group']}
        onClick={toggleSort}>
        <Input
          type='checkbox'
          className={styles['checkbox']}
          onChange={() => toggleMetric(metricKey)}
          checked={activateCheckbox}
        />
        <Label check>{displayName}</Label>
      </FormGroup>
    </div>
  );
};

export default TableHeaderComponent;
