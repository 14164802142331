import facebookPageStoryAnalyticsLoader from './views/Stories/loader/facebookPageStoryAnalyticsLoader';
import facebookPageAudienceAnalyticsLoader from './views/Audience/loaders/facebookPageAudienceAnalyticsLoader';
import facebookPageOverviewAnalyticsLoader from './loaders/facebookPageOverviewAnalyticsLoader';
import facebookPageClicksAnalyticsLoader from './loaders/facebookPageClicksAnalyticsLoader';
import facebookPageMediaAnalyticsLoader from './loaders/facebookPageMediaAnalyticsLoader';

export { default as FacebookPageAnalyticsLayout } from './layout';
export { default as FacebookPageAnalyticsOverview } from './views/Overview';
export { default as FacebookPageAnalyticsClicksOnPage } from './views/clicks-on-page';
export { default as FacebookPageAnalyticsPosts } from './views/Posts';
export { default as FacebookPageAnalyticsStories } from './views/Stories';
export { default as FacebookPageAnalyticsAudience } from './views/Audience';

export const facebookPageAnalyticsloaders = {
  facebookPageStoryAnalyticsLoader,
  facebookPageAudienceAnalyticsLoader,
  facebookPageOverviewAnalyticsLoader,
  facebookPageClicksAnalyticsLoader,
  facebookPageMediaAnalyticsLoader
};
