import {
  CampaignInsightByObjective,
  IObjectivesTableRow,
  TActiveChartSeriesInitializer
} from '../../../types';
import useChartSeries, {
  IActiveChartSeries
} from '../../../hooks/useChartSeries';
import createChartSeriesData from '../../../utils/createChartSeriesData';
import useGetData from '../../../hooks/useGetData';
import { getMetaAdCampaignInsightsByObjectives } from 'src/User/services/meta-ads';

const useGetObjectiveInsights = () => {
  const { response, loading } = useGetData({
    fetcher: getMetaAdCampaignInsightsByObjectives
  });
  const { activeChartSeries, initializeActiveChartSeries, toggleChartSeries } =
    useChartSeries();

  const { data } = response ?? {};
  const aggregatedObjectivesTableData = getAggregatedObjectivesTableData(data);
  const objectiveCharts = getAllChartData({
    data,
    initializeActiveChartSeries,
    activeSeriesByChart: activeChartSeries
  });

  return {
    aggregatedObjectivesTableData,
    objectiveCharts,
    toggleChartSeries,
    loading
  };
};

interface IGetAllChartData {
  data?: Record<string, CampaignInsightByObjective>;
  initializeActiveChartSeries: TActiveChartSeriesInitializer;
  activeSeriesByChart?: IActiveChartSeries;
}

const getAllChartData = (props: IGetAllChartData) => {
  const { data, activeSeriesByChart, initializeActiveChartSeries } = props;

  return (
    Object.entries(data ?? {})?.map(
      ([objectiveName, { insights, campaigns_count }]) => ({
        objective: objectiveName,
        campaignCount: campaigns_count,
        chartData: createChartSeriesData({
          chartId: objectiveName,
          initializeActiveChartSeries,
          insights,
          activeSeriesByChart
        })
      })
    ) ?? []
  );
};

const getAggregatedObjectivesTableData = (
  data?: Record<string, CampaignInsightByObjective>
): IObjectivesTableRow[] => {
  return (
    Object.entries(data ?? {})?.map(([objectiveName, { insights }]) => ({
      ...insights.summary,
      objective: objectiveName,
      currency: ''
    })) ?? []
  );
};

export default useGetObjectiveInsights;
