import { ColorPicker as Picker, useColor, IColor } from 'react-color-palette';
import 'react-color-palette/css';
import styles from './colorPicker.module.css';
import { useState } from 'react';
import { Fade } from 'reactstrap';
import classNames from 'classnames';
import { useClickAway } from '@uidotdev/usehooks';

interface IColorPickerProps {
  btnSize?: 'small' | 'big';
  initialColor: string;
  onColorChange: (color: string) => void;
}

export default function ColorPicker({
  btnSize = 'small',
  initialColor,
  onColorChange
}: IColorPickerProps) {
  const [pickerVisibility, setPickerVisibility] = useState(false);
  const [color, setColor] = useColor(initialColor);

  const colorChangeInitiator = (color: IColor) => {
    setColor(color);
    onColorChange(color.hex);
  };

  const onPickerVisibilityChange = () => {
    setPickerVisibility((prev) => !prev);
  };

  const ref = useClickAway<HTMLDivElement>(() => {
    setPickerVisibility(false);
  });

  return (
    <div ref={ref}>
      <button
        type='button'
        className={classNames({
          [styles['color-picker-btn-lg']]: btnSize == 'big',
          [styles['color-picker-btn-xs']]: btnSize == 'small'
        })}
        style={{
          backgroundColor: initialColor
        }}
        onClick={onPickerVisibilityChange}>
        <span>{initialColor}</span>
      </button>
      <Fade
        className={classNames('position-absolute z-3', styles['color-picker'], {
          'd-none': !pickerVisibility
        })}>
        <div>
          <Picker
            color={color}
            onChange={colorChangeInitiator}
            hideInput={['hsv']}
          />
        </div>
      </Fade>
    </div>
  );
}
