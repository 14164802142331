import { ICellRendererParams } from 'ag-grid-community';
import { IMetaAdAccount } from 'src/lib/models/socialAccounts';
import { DateCellRenderer } from '../../InstagramAccounts/InstagramAccountsView';
import daysToWeeks from 'date-fns/daysToWeeks';

const AgeCellRenderer = (
  props: ICellRendererParams<IMetaAdAccount, string>
) => {
  const { value } = props;
  const numbersOfWeeks = daysToWeeks(Number(value));
  const formattedValue = numbersOfWeeks ? `${numbersOfWeeks} weeks old` : 'N/A';

  return (
    <DateCellRenderer
      {...props}
      value={formattedValue}
    />
  );
};

export default AgeCellRenderer;
