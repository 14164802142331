import { startCase } from 'lodash';
import i18next, { type TNamespace } from 'src/i18n';

export const facebookPageDailyInsightTitleKeyMapper = {
  followers: startCase(
    i18next.t('followers', {
      ns: 'analytics' satisfies TNamespace
    }) ?? ''
  ),
  page_actions_post_reactions_like_total: i18next.t(
    'Page Actions: Total Post Like Reactions',
    { ns: 'analytics' satisfies TNamespace }
  ),
  page_impressions: i18next.t('Page Impressions', {
    ns: 'analytics' satisfies TNamespace
  }),
  page_views_total: i18next.t('Total Page Views', {
    ns: 'analytics' satisfies TNamespace
  }),
  page_daily_follows: i18next.t('Page Daily Follows', {
    ns: 'analytics' satisfies TNamespace
  }),
  post_impressions: i18next.t('Post Impressions', {
    ns: 'analytics' satisfies TNamespace
  }),
  fans_acquired: i18next.t('Fans Acquired', {
    ns: 'analytics' satisfies TNamespace
  }),
  fans_lost: i18next.t('Fans Lost', { ns: 'analytics' satisfies TNamespace }),
  fans: startCase(
    i18next.t('fans', { ns: 'analytics' satisfies TNamespace }) ?? ''
  ),
  call_to_action_clicks: i18next.t('Call to Action Clicks', {
    ns: 'analytics' satisfies TNamespace
  }),
  website_button_clicks: i18next.t('Website Button Clicks', {
    ns: 'analytics' satisfies TNamespace
  }),
  page_visits_total: i18next.t('Page Visits', {
    ns: 'analytics' satisfies TNamespace
  }),
  average_reach_per_post: i18next.t('Average Reach per Post', {
    ns: 'analytics' satisfies TNamespace
  }),
  interactions_total: i18next.t('Interactions', {
    ns: 'analytics' satisfies TNamespace
  }),
  engagement_rate: i18next.t('engagement'),
  impressions: i18next.t('Page Impressions', {
    ns: 'analytics' satisfies TNamespace
  }),
  views: i18next.t('Total Page Views', {
    ns: 'analytics' satisfies TNamespace
  }),
  page_actions_total_post_reactions: i18next.t(
    'Page Actions: Total Post Reactions',
    { ns: 'analytics' satisfies TNamespace }
  ),
  cta_clicks: i18next.t('Call to Action Clicks', {
    ns: 'analytics' satisfies TNamespace
  }),
  page_visits: i18next.t('Page Visits', {
    ns: 'analytics' satisfies TNamespace
  }),
  website_clicks: i18next.t('Website Clicks', {
    ns: 'analytics' satisfies TNamespace
  })
};
