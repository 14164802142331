import { isString } from 'lodash';
import { ActionFunctionArgs } from 'react-router-dom';
import { IConnectAccountProfileFormEntry } from './models';
import { connectAccountProfile } from 'src/User/services/oauth-connect';
import { TMediaType } from 'src/User/services/oauth-connect/models';
import displayAxiosError from 'src/lib/utils/displayAxiosError';

const connectIgBusinessAccountProfileAction = async (
  props: ActionFunctionArgs
) => {
  const { request, params } = props;
  const { mediaType } = params as { mediaType: TMediaType };
  const formData = await request.formData();
  const formDataEntries = Object.fromEntries(formData);

  if (!isValidFormData(formDataEntries)) {
    throw new Error('Invalid form data');
  }

  try {
    const response = await connectAccountProfile({
      brandId: formDataEntries.brand_id,
      accountId: formDataEntries.account_id,
      connected: formDataEntries.connected === 'true',
      mediaType
    });

    return response;
  } catch (error: any) {
    displayAxiosError(error, {
      title: `Unable to ${
        formDataEntries?.connected == 'true' ? 'connect' : 'disconnect'
      } account`,
      fallbackMsg: '',
      toast: true,
      duration: 7000
    });

    return null;
  }
};

export const isValidFormData = (
  formData: any
): formData is IConnectAccountProfileFormEntry => {
  return (
    isString(formData.brand_id) &&
    isString(formData.account_id) &&
    isString(formData.connected) &&
    (formData?.reason == 'connect' ||
      formData?.reason == 'disconnect' ||
      typeof formData?.reason == 'undefined')
  );
};

export default connectIgBusinessAccountProfileAction;
