import axios from 'axios';
import { ROUTER_PATHS } from './lib/constants';

const BASE_URL = process.env.REACT_APP_API_URL;
const Api = axios.create({
  baseURL: BASE_URL
});

Api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');

  if (token !== null) {
    config.headers['Authorization'] = `Token ${token}`;
  }

  if (!config.headers['Content-Type'])
    config.headers['Content-Type'] = 'application/json';

  return config;
});

Api.interceptors.response.use(
  function interceptSuccessResponse(response) {
    return response;
  },
  function interceptErrorResponse(error) {
    const statusCode = error?.response?.status;

    if (statusCode === 401) {
      localStorage.clear();
      window.location.href = ROUTER_PATHS.LOGIN;
    }

    return Promise.reject(error);
  }
);

export default Api;
