import { omit } from 'lodash';

type TBaseData = IInstagramBaseData | IFacebookBaseData;

interface IInstagramBaseData {
  thumbnail_url: string;
  permalink: string;
  caption: string;
}

interface IFacebookBaseData {
  permalink_url: string;
  full_picture: string;
  message: string | null;
}

export const createTableData = <T extends TBaseData>(rawData: T[]) => {
  return rawData.map((data) => {
    return {
      media_preview_details: getMediaPreviewDetails(data),
      ...omit(data, [
        'thumbnail_url',
        'permalink',
        'caption',
        'permalink_url',
        'full_picture',
        'message'
      ])
    };
  });
};

const getMediaPreviewDetails = (data: TBaseData) => {
  if (isInstagramBaseData(data)) {
    return {
      thumbnail_url: data.thumbnail_url,
      permalink: data.permalink,
      caption: data.caption
    };
  }

  return {
    thumbnail_url: data.full_picture,
    permalink: data.permalink_url,
    caption: data.message ?? ''
  };
};

const isInstagramBaseData = (data: any): data is IInstagramBaseData => {
  return 'thumbnail_url' in data && 'permalink' in data && 'caption' in data;
};

export default createTableData;
