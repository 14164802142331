import { isString } from 'lodash';
import { ActionFunctionArgs } from 'react-router-dom';
import { connectMetaAdAccount } from 'src/User/services/meta-ads';
import { IConnectAdAccountFormEntry } from './models';
import displayAxiosError from 'src/lib/utils/displayAxiosError';

const connectMetaAdAccountAction = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const formDataEntries = Object.fromEntries(formData);

  if (!isValidFormData(formDataEntries)) {
    throw new Error('Invalid form data');
  }

  try {
    const response = await connectMetaAdAccount({
      brandId: formDataEntries.brand_id,
      actAccountId: formDataEntries.act_account_id,
      connected: formDataEntries.connected === 'true',
      metaId: formDataEntries.meta_id
    });

    return response;
  } catch (error) {
    console.log('error occured');
    displayAxiosError(error, {
      title: `Unable to ${
        formDataEntries?.connected == 'true' ? 'connect' : 'disconnect'
      } account`,
      fallbackMsg: '',
      toast: true,
      duration: 7000
    });

    return null;
  }
};

const isValidFormData = (
  formData: any
): formData is IConnectAdAccountFormEntry => {
  return (
    isString(formData.brand_id) &&
    isString(formData.act_account_id) &&
    isString(formData.connected) &&
    isString(formData.meta_id)
  );
};

export default connectMetaAdAccountAction;
