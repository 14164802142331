import { forEach, fromPairs, toPairs } from 'lodash';
import i18n, { TNamespace } from 'src/i18n';
import {
  INITIAL_POST_TABLE_SUMMARY_DATA,
  POST_TABLE_SUMMARY_DATA_KEYS_MAP
} from '../constants';
import { Maybe } from 'src/lib/models';

interface ITableSummaryProps<T> {
  data?: Partial<T>[];
}

interface IData {
  reach: Maybe<number>;
  impressions: Maybe<number>;
  total_interactions: Maybe<number>;
}

const createMediaTableSummaryData = <T extends IData>({
  data
}: ITableSummaryProps<T>) => {
  let cumulativeData: Record<string, number> =
    data?.reduce(
      (acc, media) => {
        forEach(acc, (value, _key) => {
          const key = _key as keyof typeof acc;
          const mediaKey = POST_TABLE_SUMMARY_DATA_KEYS_MAP?.[key];

          if (!mediaKey) return;

          acc[key] += media[mediaKey] ?? 0;
        });

        return acc;
      },
      { ...INITIAL_POST_TABLE_SUMMARY_DATA }
    ) ?? {};

  cumulativeData = fromPairs(
    toPairs(cumulativeData).map(
      // prettier-ignore
      function translateCumulativeDataKeys([key,value]) {
        const translatedMetricKey = i18n.t(key, {
          ns: 'analytics' satisfies TNamespace
        });
  
        return [translatedMetricKey, value];
      }
    )
  );

  return cumulativeData;
};

export default createMediaTableSummaryData;
