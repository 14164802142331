import { useState } from 'react';
import styles from '../settings.module.css';

// TODO: refactor state management here
export default function TimeSettings() {
  const [option, setOption] = useState('Bolivia Time');
  const [option2, setOption2] = useState('24 hrs');
  const [option3, setOption3] = useState('Hindi');

  return (
    <div
      className={styles['setting-card']}
      id='time-setting'>
      <div className={styles['setting-card-head']}>
        <h5>Time & Language Setting</h5>
        <button
          onClick={() => {
            alert('clicked');
          }}
          className='btn btn-outline-primary'>
          Edit
        </button>
      </div>
      <div>
        <div className='row'>
          <div className='col-md-6'>
            <div className='mb-3'>
              <label
                htmlFor='time-zone'
                className='form-label'>
                Time Zone
              </label>
              <select
                value={option}
                onChange={(e) => {
                  setOption(e.target.value);
                }}
                className='form-select'
                aria-label='Time Zone'
                id='time-zone'>
                <option value='UTC +2 Warsaw, Poland'>
                  UTC +2 Warsaw, Poland
                </option>
                <option value='Alaska Standard Time'>
                  {' '}
                  Alaska Standard Time
                </option>
                <option value='Bolivia Time'> Bolivia Time</option>
                <option value='Acre Time'> Acre Time</option>
              </select>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='mb-3'>
              <label
                htmlFor='time-format'
                className='form-label'>
                Time Format
              </label>
              <select
                value={option2}
                onChange={(e) => {
                  setOption2(e.target.value);
                }}
                className='form-select'
                aria-label='Time Format'
                id='time-format'>
                <option value='24 hrs'>24 hrs</option>
                <option value='12 hrs'> 12 hrs</option>
              </select>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='mb-3'>
              <label
                htmlFor='language'
                className='form-label'>
                Language
              </label>
              <select
                value={option3}
                onChange={(e) => {
                  setOption3(e.target.value);
                }}
                className='form-select'
                aria-label='Language'
                id='language'>
                <option value='English'>English</option>
                <option value='Japanees'> Japanees</option>
                <option value='Hindi'> Hindi</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
