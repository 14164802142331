import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Outlet, NavLink, useSearchParams } from 'react-router-dom';
import { TNamespace } from 'src/i18n';
import { cn } from 'src/lib/utils/shadcn-utils';
import { Input } from 'src/components/ui/input';
import { Search } from 'lucide-react';
import { Folder, Goal, Component, AppWindowMac } from 'lucide-react';
import { DATE_SEARCH_PARAMS } from 'src/configs';
import addParamsToPathname from 'src/lib/utils/addParamsToPathname';
import { ROUTER_PATHS } from 'src/lib/constants';

const navLinks = [
  {
    label: 'Objectives',
    href: ROUTER_PATHS.ADS_ANALYTICS_META_ADS_CAMPAIGN_INSIGHT
      .OBJECTIVE_OVERVIEW,
    i18nKey: 'meta.campaigns.layout.nav.objectives',
    icon: Goal
  },
  {
    label: 'Campaigns',
    href: ROUTER_PATHS.ADS_ANALYTICS_META_ADS_CAMPAIGN_INSIGHT
      .CAMPAIGNS_OVERVIEW,
    i18nKey: 'meta.campaigns.layout.nav.campaigns',
    icon: Folder
  },
  {
    label: 'Ad sets',
    href: ROUTER_PATHS.ADS_ANALYTICS_META_ADS_CAMPAIGN_INSIGHT.ADSET_OVERVIEW,
    i18nKey: 'meta.campaigns.layout.nav.adSets',
    icon: Component
  },
  {
    label: 'Ads',
    href: ROUTER_PATHS.ADS_ANALYTICS_META_ADS_CAMPAIGN_INSIGHT.ADS_OVERVIEW,
    i18nKey: 'meta.campaigns.layout.nav.ads',
    icon: AppWindowMac
  }
];

const MetaAdsAnalyticsCampaignLayout = () => {
  const { t } = useTranslation<TNamespace>('adAnalytics');
  const [searchParams] = useSearchParams();

  const duration = {
    start: searchParams.get(DATE_SEARCH_PARAMS.start) ?? undefined,
    stop: searchParams.get(DATE_SEARCH_PARAMS.stop) ?? undefined
  };

  return (
    <section className='tw-h-full tw-flex tw-flex-col tw-flex-grow'>
      <header className='tw-flex tw-items-center tw-justify-between tw-gap-10'>
        <ul className='tw-pl-0 tw-inline-grid tw-col-span-4 tw-row-span-1 tw-grid-flow-col tw-h-full tw-w-full tw-mb-2'>
          {navLinks.map(({ label, href, i18nKey, icon: Icon }) => (
            <li key={label}>
              <NavLink
                to={addParamsToPathname(href, duration)}
                className={({ isActive, isPending }) =>
                  cn(
                    'tw-no-underline hover:tw-text-primary tw-flex tw-items-center tw-gap-3 tw-whitespace-nowrap tw-rounded-t-lg tw-px-6 tw-py-3 tw-text-base tw-font-semibold tw-ring-offset-background tw-transition-[border-top,_border-left,_border-right,_color]',
                    {
                      'tw-text-primary tw-border-t tw-border-x': isActive,
                      'tw-pointer-events-none tw-text-primary': isPending,
                      'tw-text-muted-foreground tw-border-b hover:tw-bg-blue-20':
                        !isActive
                    }
                  )
                }>
                <Icon />
                {capitalize(t(i18nKey) ?? label)}
              </NavLink>
            </li>
          ))}
        </ul>
        <div className='tw-relative'>
          <Search className='tw-absolute tw-left-2.5 tw-top-3 tw-h-4 tw-w-4 tw-text-muted-foreground' />
          <Input
            type='search'
            placeholder='Search...'
            className='!tw-text-base tw-w-full tw-rounded-lg tw-bg-blue-20 tw-pl-8 md:tw-w-[200px] lg:tw-w-[320px]'
          />
        </div>
      </header>
      <Outlet />
    </section>
  );
};

export default MetaAdsAnalyticsCampaignLayout;
