import { indexRouteLoader } from './loaders/indexRouteLoader';

export { default as Ads } from './views/Ads/CampaignAdsOverview';
export { default as Objectives } from './views/Objectives/ObjectiveAnalytics';
export { default as Campaigns } from './views/Campaign/CampaignsAnalytics';
export { default as Adsets } from './views/Adsets';
export { default as Layout } from './layout';

export const loaders = {
  indexRouteLoader
};
