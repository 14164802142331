import { defer, LoaderFunctionArgs } from 'react-router-dom';
import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { getInstagramBusinessMediaInsights } from 'src/User/services/analytics/instagramAnalyticsService';
import { DATE_SEARCH_PARAMS, USER_CONFIG } from 'src/configs';

const instagramStoriesInsightsLoader = async ({
  params,
  request
}: LoaderFunctionArgs) => {
  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);

  const { id: accountId } = params;
  const requestUrl = new URL(request.url);
  const searchParams = new URLSearchParams(requestUrl.search);
  const startDate = searchParams.get(DATE_SEARCH_PARAMS.start);
  const endDate = searchParams.get(DATE_SEARCH_PARAMS.stop);

  const { data: mediaInsightsResponse } =
    await getInstagramBusinessMediaInsights({
      brandId,
      igBusinessAccountId: accountId as string,
      type: 'STORY',
      date: {
        start: startDate,
        stop: endDate
      }
    });

  return defer({
    igBusinessStoriesInsight: mediaInsightsResponse.data
  });
};

export default instagramStoriesInsightsLoader;
