import { ROUTER_PATHS_ID } from 'src/lib/constants';
import { InstagramAnalyticsLoaderData } from '../../models';
import { useRouteLoaderData } from 'react-router-dom';
import getPostCountByWeek from '../utils/getPostCountByWeek';
import getMetricSummaryData from '../utils/getMetricSummaryData';
import getChartOptions from 'src/User/features/CompetitionAnalysis/views/Instagram/components/MetricsView/utils/getChartOptions';
import { useTranslation } from 'react-i18next';
import { TNamespace } from 'src/i18n';
import { startCase } from 'lodash';

const useGetIGBussinessEngagement = () => {
  const { dailyInsights } = useRouteLoaderData(
    ROUTER_PATHS_ID.IG_BUSINESS_ANALYTICS_LAYOUT
  ) as InstagramAnalyticsLoaderData;
  const { t } = useTranslation<TNamespace>();

  const sortedData = dailyInsights?.sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });

  const summaryData = getMetricSummaryData();

  const knownChartData = (['impressions', 'reach', 'media'] as const).map(
    (key) => {
      return {
        options: getChartOptions(sortedData?.map(({ date }) => date)),
        series: {
          name: t(key, {
            ns: 'analytics'
          }),
          data: sortedData?.map((insight) => insight[key]) || []
        }
      };
    }
  );

  const engagementRate = {
    options: getChartOptions(sortedData?.map(({ date }) => date)),
    series: {
      name: startCase(t('engagement_rate') ?? '') + '(WIP)',
      data:
        sortedData?.map(({ impressions, reach }) => impressions + reach) || []
    }
  };

  const weeklyEngagementRate = {
    options: getChartOptions(sortedData?.map(({ date }) => date)),
    series: {
      name:
        t('Weekly Engagement Rate', {
          ns: 'analytics'
        }) + '(WIP)',
      data: sortedData?.map(() => 0) || []
    }
  };

  const averageComments = {
    options: getChartOptions(sortedData?.map(({ date }) => date)),
    series: {
      name:
        t('Average Comments', {
          ns: 'analytics'
        }) + '(WIP)',
      data: sortedData?.map(() => 0) || []
    }
  };

  const postCountByWeek = getPostCountByWeek(sortedData);
  const weeklyPosts = {
    options: {
      ...getChartOptions(postCountByWeek?.map(({ date }) => date)),
      chartType: 'bar' as const
    },
    series: {
      name: t('Weekly New Posts', {
        ns: 'analytics'
      }),
      data: postCountByWeek.map(({ media }) => media) || []
    }
  };

  const averageLikes = {
    options: getChartOptions(sortedData?.map(({ date }) => date)),
    series: {
      name:
        t('Average Likes', {
          ns: 'analytics'
        }) + '(WIP)',
      data: sortedData?.map(() => 0) || []
    }
  };

  return {
    summaryData,
    knownChartData,
    engagementRate,
    weeklyEngagementRate,
    averageComments,
    weeklyPosts,
    averageLikes
  };
};

export default useGetIGBussinessEngagement;
