import { defer } from 'react-router-dom';
import { USER_CONFIG } from 'src/configs';
import displayAxiosError from 'src/lib/utils/displayAxiosError';
import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { getConversations } from 'src/User/services/somate-ai';

export const conversationHistoryLoader = () => {
  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);

  const conversationHistoryPromise = getConversations(brandId);

  return defer({
    conversationHistory: conversationHistoryPromise
      .then(({ data }) => data)
      .catch((error) => {
        displayAxiosError(error, {
          title: 'Failed to fetch conversation history',
          fallbackMsg: '',
          duration: 7000,
          toast: true
        });

        return { data: [] };
      })
  });
};
