import { ColDef } from 'ag-grid-community';
import TableLink from '../../../components/TableLink';
import { IAdsetsTableRow } from '../../../types';
import { CAMPAIGN_TABLE_COLS } from '../../Campaign/constants';

export const ADSETS_TABLE_COLS: ColDef<IAdsetsTableRow>[] = [
  {
    field: 'adsetId',
    headerName: 'Adset',
    sortable: true,
    filter: true,
    cellRenderer: TableLink,
    pinned: 'left',
    minWidth: 300
  },
  {
    field: 'campaignName',
    headerName: 'campaign',
    sortable: true,
    filter: true
  },
  ...(CAMPAIGN_TABLE_COLS.slice(1) as ColDef[])
];
