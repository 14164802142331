import { ALL_SOCIALS_TABLE_COLS_DEFS } from '.';
import { Table } from 'src/User/features/Viz/components/Table';
import { Outlet, useLoaderData } from 'react-router-dom';
import { AllSocialAccountDict } from 'src/lib/models/socialAccounts';
import getTableData from './utils/getTableData';

export default function AllSocialAccountsView() {
  const allSocialAccounts = useLoaderData() as AllSocialAccountDict;
  const tableData = getTableData(allSocialAccounts);

  return (
    <div className='w-100'>
      <Table
        data={tableData}
        columnsDefs={ALL_SOCIALS_TABLE_COLS_DEFS}
        isLoading={false}
        height={'100%'}
      />
      <Outlet />
    </div>
  );
}
