import { Link, useLocation } from 'react-router-dom';
import React from 'react';
import AppLogo from 'src/Assets/images/somate-logo.svg';
import AppLogoSmall from 'src/Assets/images/app-icon.svg';
import { BrandsSelectList } from './components/BrandSelect';
import classNames from 'classnames';
import styles from './SideBar.module.css';
import { INavLinks, TNavGroup } from './types';

interface ISidebarProps {
  translator: any;
  collapse: boolean;
  navLinks: INavLinks;
}

interface ISiderbarItemProps {
  href: string;
  icon: JSX.Element;
  title: string;
  translator: any;
  hidden?: boolean;
  hideText?: boolean;
  i18nKey: string;
}

const SideBar = ({ collapse, translator, navLinks }: ISidebarProps) => {
  return (
    <>
      <aside
        className={classNames(styles.appContainerSidebar, 'card-style', {
          [styles.appContainerSidebarShrink]: collapse
        })}>
        <div className={styles.appLogo}>
          <Link to={'/'}>
            <img
              src={collapse ? AppLogoSmall : AppLogo}
              alt='SOMate logo, click to go back to home page'
              className={classNames({
                [styles.appLogoBig]: !collapse,
                [styles.appLogoSmall]: collapse
              })}
            />
          </Link>
        </div>
        <div className={styles.sidebarMenuContainer}>
          <BrandsSelectList collapse={collapse} />
          <div
            className={classNames(styles.sidebarMenu, {
              [styles.borderNone]: collapse
            })}>
            {Object.entries(navLinks).map(([key, values]) => (
              <ul
                className={styles.sidebarMenuList}
                key={key}>
                {(values as TNavGroup).map(
                  ({ href, icon, title, hidden = false, i18nKey }, index) => {
                    // INFO: Incase you want to add sub menu

                    // const Icon = () => icon;
                    // if (Array.isArray(children) && children.length > 0) {
                    //   return (
                    //     <li
                    //       key={title}
                    //       className={classNames({
                    //         [styles.selectedMenuLink]: pathname == href,
                    //         [styles.hiddenMenuLink]: hidden
                    //       })}>
                    //       <UncontrolledAccordion
                    //         stayOpen
                    //         flush>
                    //         <AccordionItem>
                    //           <AccordionHeader
                    //             targetId='1'
                    //             tag={'div'}
                    //             className={classNames(styles.accordionHeader, {
                    //               [styles.selectedMenuLink]: pathname == href,
                    //               [styles.hideAccordionBtnIcon]:
                    //                 collapse
                    //             })}>
                    //             <Link
                    //               to={href}
                    //               className={classNames(
                    //                 styles.accordionHeaderLink,
                    //                 styles.sidebarMenuLink,
                    //                 {
                    //                   [styles.shrinkMenuLink]: collapse
                    //                 }
                    //               )}>
                    //               {icon ? (
                    //                 <div className={styles.menuLinkIcon}>
                    //                   <Icon />
                    //                 </div>
                    //               ) : null}
                    //               <span
                    //                 className={classNames(
                    //                   styles.menuLinkTitle,
                    //                   {
                    //                     [styles.activeMenuLink]:
                    //                       pathname == href,
                    //                     [styles.hiddenMenuLink]:
                    //                       collapse
                    //                   }
                    //                 )}>
                    //                 {translator(title)}
                    //               </span>
                    //             </Link>
                    //           </AccordionHeader>
                    //           <AccordionBody
                    //             accordionId='1'
                    //             className={styles.accordionBody}>
                    //             <ul
                    //               className={classNames(styles.sidebarSubMenu, {
                    //                 [styles.hiddenMenuLink]: collapse
                    //               })}>
                    //               {children.map(
                    //                 ({ href, title, hidden = false }) => {
                    //                   return (
                    //                     <SideBarItem
                    //                       {...{
                    //                         href,
                    //                         title,
                    //                         translator: translator,
                    //                         hidden: hidden,
                    //                         hideText: collapse,
                    //                         key: title
                    //                       }}
                    //                     />
                    //                   );
                    //                 }
                    //               )}
                    //             </ul>
                    //           </AccordionBody>
                    //         </AccordionItem>
                    //       </UncontrolledAccordion>
                    //     </li>
                    //   );
                    // }

                    return (
                      <SideBarItem
                        key={title}
                        {...{
                          href,
                          icon,
                          title,
                          translator: translator,
                          hidden: hidden,
                          hideText: collapse,
                          i18nKey
                        }}
                      />
                    );
                  }
                )}
              </ul>
            ))}
          </div>
        </div>
      </aside>
    </>
  );
};

const SideBarItem = ({
  href,
  icon,
  title,
  translator,
  hidden,
  hideText,
  i18nKey
}: ISiderbarItemProps) => {
  const Icon = () => icon;
  const { pathname } = useLocation();

  return (
    <li
      key={title}
      className={classNames({
        [styles.selectedMenuLink]: pathname == href,
        [styles.hiddenMenuLink]: hidden
      })}>
      <Link
        to={href}
        className={classNames(styles.sidebarMenuLink, {
          [styles.shrinkMenuLink]: hideText
        })}>
        {icon ? (
          <span className={styles.menuLinkIcon}>
            <Icon />
          </span>
        ) : null}
        <span
          className={classNames(styles.menuLinkTitle, {
            [styles.activeMenuLinkTitle]: pathname == href,
            [styles.hiddenMenuLink]: hideText
          })}>
          {translator(i18nKey)}
        </span>
      </Link>
    </li>
  );
};

export default SideBar;
