import { ICONS_MAP } from 'src/User/features/CompetitionAnalysis/views/Instagram/views/Overview/constants';
import { facebookPageDailyInsightTitleKeyMapper } from '../../../constants';
import { IFacebookPageClicksSummaryInsight } from '../types';

type TData = IFacebookPageClicksSummaryInsight;

export const getSummaryData = (data: TData) => {
  return [
    {
      title: data.cta_clicks,
      description: facebookPageDailyInsightTitleKeyMapper.cta_clicks,
      icon: ICONS_MAP['clicks']
    },
    {
      title: data.page_visits,
      description: facebookPageDailyInsightTitleKeyMapper.page_visits,
      icon: ICONS_MAP['pageVisits']
    },
    {
      title: data.website_clicks,
      description: facebookPageDailyInsightTitleKeyMapper.website_clicks,
      icon: ICONS_MAP['clicks']
    }
  ];
};
