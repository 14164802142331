import { TFunction } from 'i18next';
import { TNamespace } from 'src/i18n';

const getAdProviderPlatforms = (
  translate: TFunction<TNamespace, undefined, TNamespace>
) => [
  {
    name: `Meta ${translate('Ads')}`,
    href: '/socials/meta-ads/integrations/select-media-type/meta-ads',
    icon: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='1em'
        height='1em'
        viewBox='0 0 24 24'
        className='tw-w-9 tw-h-9'>
        <path
          fill='currentColor'
          d='M6.915 4.03c-1.968 0-3.683 1.28-4.871 3.113C.704 9.208 0 11.883 0 14.449c0 .706.07 1.369.21 1.973a6.624 6.624 0 0 0 .265.86a5.297 5.297 0 0 0 .371.761c.696 1.159 1.818 1.927 3.593 1.927c1.497 0 2.633-.671 3.965-2.444c.76-1.012 1.144-1.626 2.663-4.32l.756-1.339l.186-.325c.061.1.121.196.183.3l2.152 3.595c.724 1.21 1.665 2.556 2.47 3.314c1.046.987 1.992 1.22 3.06 1.22c1.075 0 1.876-.355 2.455-.843a3.743 3.743 0 0 0 .81-.973c.542-.939.861-2.127.861-3.745c0-2.72-.681-5.357-2.084-7.45c-1.282-1.912-2.957-2.93-4.716-2.93c-1.047 0-2.088.467-3.053 1.308c-.652.57-1.257 1.29-1.82 2.05c-.69-.875-1.335-1.547-1.958-2.056c-1.182-.966-2.315-1.303-3.454-1.303zm10.16 2.053c1.147 0 2.188.758 2.992 1.999c1.132 1.748 1.647 4.195 1.647 6.4c0 1.548-.368 2.9-1.839 2.9c-.58 0-1.027-.23-1.664-1.004c-.496-.601-1.343-1.878-2.832-4.358l-.617-1.028a44.908 44.908 0 0 0-1.255-1.98c.07-.109.141-.224.211-.327c1.12-1.667 2.118-2.602 3.358-2.602zm-10.201.553c1.265 0 2.058.791 2.675 1.446c.307.327.737.871 1.234 1.579l-1.02 1.566c-.757 1.163-1.882 3.017-2.837 4.338c-1.191 1.649-1.81 1.817-2.486 1.817c-.524 0-1.038-.237-1.383-.794c-.263-.426-.464-1.13-.464-2.046c0-2.221.63-4.535 1.66-6.088c.454-.687.964-1.226 1.533-1.533a2.264 2.264 0 0 1 1.088-.285'
        />
      </svg>
    ),
    disabled: false
  },
  {
    name: `Google ${translate('Ads')}`,
    href: '#',
    icon: <i className='bi bi-google modal-icon'></i>,
    disabled: true
  },
  {
    name: `Twitter ${translate('Ads')}`,
    href: '#',
    icon: <i className='bi bi-twitter modal-icon'></i>,
    disabled: true
  },
  {
    name: `LinkedIn ${translate('Ads')}`,
    href: '#',
    icon: <i className='bi bi-linkedin modal-icon'></i>,
    disabled: true
  },
  {
    name: `Youtube ${translate('Ads')}`,
    href: '#',
    icon: <i className='bi bi-youtube modal-icon'></i>,
    disabled: true
  },
  {
    name: `Tiktok ${translate('Ads')}`,
    href: '#',
    icon: <i className='bi bi-tiktok modal-icon'></i>,
    disabled: true
  }
];

export default getAdProviderPlatforms;
