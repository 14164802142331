import classNames from 'classnames';
import { CAMPAIGN_PERFORMANCE_DATA } from '../constants';
import { startCase } from 'lodash';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle
} from 'src/components/ui/card';
import { ReactNode } from 'react';

const CampaignPerformanceOverview = () => {
  return (
    <Card className='tw-w-full tw-h-full tw-shadow-xl tw-rounded-xl tw-border-0 daily-reports'>
      <CardHeader>
        <CardTitle className='tw-text-xl !tw-font-bold'>
          Campaign Performance Overview
        </CardTitle>
      </CardHeader>
      <CardContent className='tw-grid tw-gap-12 tw-grid-cols-1'>
        {Object.entries(CAMPAIGN_PERFORMANCE_DATA).map(([key, value]) => {
          return (
            <div
              key={key}
              className={classNames({
                'tw-flex-grow': key == 'cost_per_conversion'
              })}>
              <CampaignPerformanceCard
                key={key}
                {...value}
              />
            </div>
          );
        })}
      </CardContent>
    </Card>
  );
};

interface CampaignPerformanceCardProps {
  data: {
    spent: Metric;
    impressions: Metric;
    conversions: Metric;
    clicks: Metric;
    cost_per_conversion: Metric;
  };
  logo: ({ className }: { className?: string }) => ReactNode;
  label: string;
}

interface Metric {
  value: number | string;
  change_rate: number;
}

const CampaignPerformanceCard = (props: CampaignPerformanceCardProps) => {
  const { data, logo: Logo, label } = props;

  return (
    <div className='tw-flex tw-gap-4 tw-flex-col 2xl:tw-flex-row'>
      <div className='tw-flex-grow tw-min-w-56 tw-flex tw-gap-4 tw-items-center'>
        <div className='tw-flex tw-justify-center tw-items-center tw-w-fit'>
          <Logo className='tw-w-10 tw-h-10' />
        </div>
        <p className='tw-text-lg tw-mb-0'>{label}</p>
      </div>
      <div className='tw-w-full tw-flex tw-gap-4 tw-justify-between'>
        {Object.entries(data).map(([metric, { value, change_rate }]) => (
          <MetricSummaryElement
            key={metric}
            changeRate={change_rate}
            label={startCase(metric)}
            value={value}
          />
        ))}
      </div>
    </div>
  );
};

interface MetricSummaryElementProps {
  label: string;
  value: number | string;
  changeRate?: number;
  changeRateFormatter?: (changeRate: number) => string;
  theme?: 'primary' | 'secondary';
}

const MetricSummaryElement = (props: MetricSummaryElementProps) => {
  const {
    label,
    value,
    changeRate,
    theme = 'secondary',
    changeRateFormatter
  } = props;

  const isChangeRateValid = typeof changeRate == 'number';
  const changeRateClass =
    isChangeRateValid && changeRate > 0
      ? 'tw-text-green-500'
      : 'tw-text-red-500';

  return (
    <div className='[&>p]:tw-my-0'>
      <p
        className={classNames(
          'tw-font-medium tw-text-sm xl:tw-text-base !tw-mb-2',
          {
            'tw-text-brown-600': theme === 'primary',
            'tw-text-primary-black': theme === 'secondary'
          }
        )}>
        {label}
      </p>
      <p className='tw-text-primary-black tw-font-bold tw-text-2xl tw-tracking-wide'>
        {value}
      </p>
      {isChangeRateValid ? (
        <p
          className={classNames(
            changeRateClass,
            'tw-text-sm tw-flex tw-items-end'
          )}>
          {Math.sign(changeRate) == 1 ? (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='tw-w-6 tw-h-6'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941'
              />
            </svg>
          ) : Math.sign(changeRate) == -1 ? (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='tw-w-6 tw-h-6'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M2.25 6 9 12.75l4.286-4.286a11.948 11.948 0 0 1 4.306 6.43l.776 2.898m0 0 3.182-5.511m-3.182 5.51-5.511-3.181'
              />
            </svg>
          ) : null}
          <span className='tw-pl-1'>
            {changeRateFormatter
              ? changeRateFormatter(changeRate)
              : `${changeRate}%`}
          </span>
        </p>
      ) : null}
    </div>
  );
};

export default CampaignPerformanceOverview;
export { MetricSummaryElement };
