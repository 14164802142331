import React, { useState } from 'react'
import './Upgrade.css'
import { useNavigate } from 'react-router';
import { Spinner } from 'reactstrap';
import { changePlan } from '../services/planServices';
import PlanThumb1 from '../../Assets/images/plan-1.png';
import PlanThumb2 from '../../Assets/images/plan-2.png';
import PlanThumb3 from '../../Assets/images/plan-3.png';
import PlanThumb4 from '../../Assets/images/plan-4.png';
import { SUBSCRIPTION_PLANS } from './CompleteFlow/constants';

const UpgradePlan = () => {
    const [planData, setPlanData] = useState(SUBSCRIPTION_PLANS);
    const navigate = useNavigate();
    const [isSubmitting , setIsSubmitting] = useState(false)
    
    // const [planDuration, setPlanDuration] = useState(JSON.parse(localStorage.getItem("user_current_plan"))?.freq =="RecurringInterval.MONTH_1"?"Month":"Year");
    const [planDuration, setPlanDuration] = useState("Year");
    const token = localStorage.getItem("token");
    const selectPlanFun = (type,pID) => {
        setIsSubmitting(true);
        localStorage.setItem("userSubscripData", type);
        changePlan(pID)
            .then(
                (res) => {
                    // window.location.href = `${res.data}`
                    console.warn(res.data)
        setIsSubmitting(false);

                }
            )
            .catch(
                (error) => {
                    console.warn(error)
                }
            )
    }
    return (
        <div>
            <div className='upgrade-plan-wrapper card-style'>
                <div className="comp-header">
                    <h4>Plans & Pricing</h4>
                    <p>Simple Pricing and New features for Business</p>
                </div>
                <div style={{border:"1px solid brown",width:"fit-content"}} className="plan-switch">
                <input
                    checked={planDuration == "Month" ? true : false}
                    onChange={() => {
                        setPlanDuration("Month");
                    }}
                    type="radio"
                    className="btn-check"
                    name="plan-switch"
                    id="monthly"
                />
                <label className="btn btn-outline-primary" htmlFor="monthly">
                    Monthly
                </label>

                <input
                    checked={planDuration == "Year" ? true : false}
                    onChange={() => {
                        setPlanDuration("Year");
                    }}
                    type="radio"
                    className="btn-check"
                    name="plan-switch"
                    id="yearly"
                />
                <label className="btn btn-outline-primary" htmlFor="yearly">
                    Yearly
                </label>
            </div>
                <div className="plan-inner">
                    {/* <div className="plan-itm">
                        <div className="plan-thumb">
                            <img src={PlanThumb1} alt='' />
                        </div>
                        <div className="plan-title">
                            <h4>Creator</h4>
                        </div>
                        <div className="plan-cost">
                            <h2>$15 <small>/month</small></h2>
                        </div>
                        <div className="plan-action">
                            <button type="button" className="btn btn-outline-primary current-plan">Current Plan</button>
                        </div>
                        <h6 className='tagline'>Best for Small brands</h6>
                        <div className="plan-features">
                            <ul>
                                <li><span className='ic-check'><i className="bi bi-check-circle-fill"></i></span> 4 socials</li>
                                <li><span className='ic-check'><i className="bi bi-check-circle-fill"></i></span> Posting Plan</li>
                                <li><span className='ic-check'><i className="bi bi-check-circle-fill"></i></span> Analytics</li>
                                <li><span className='ic-check'><i className="bi bi-check-circle-fill"></i></span> Social inbox</li>
                            </ul>
                        </div>
                    </div> */}
                   
                     {planData.map((v,i)=>{
                    if(planDuration=='Month'){
                        if(v.duration=="Month"){
                    return (
                        <div key={i} className="plan-itm">
                             <div className="plan-thumb">
                            <img src={PlanThumb1} alt='' />
                        </div>
                            <div className="plan-title">
                                <h4>{v.title}</h4>
                            </div>
                            <div className="plan-cost">
                                <h2>{v.price}<small>/ {planDuration}</small></h2>
                            </div>
                            <div className="plan-action">
                                <button type="button" disabled={isSubmitting} className="btn btn-primary" onClick={() => { selectPlanFun(v.title,v.priceId) }}>{
                                    isSubmitting? <Spinner color="light">
                                        Loading...
                                      </Spinner>:
                                    "Select Plan"}</button>
                                 {/* {JSON.parse(localStorage.getItem("user_current_plan"))?.price_id!==v.priceId?<button type="button" disabled={isSubmitting} className="btn btn-primary" onClick={() => { selectPlanFun(v.title,v.priceId) }}>{
                                    isSubmitting? <Spinner color="light">
                                        Loading...
                                      </Spinner>:
                                    "Select Plan"}</button>:<button type="button" disabled className="btn current-plan">Current Plan</button>
                                } */}
                                </div>
                                    <div className="devider"></div>
                            <h6 className='tagline'>{v.description}</h6>
                            <div className="plan-features">
                                <ul>
                                {v.featureList.map((feature,featureIndex)=>{
                                    return (
                                        <li key={featureIndex}><span className='ic-check'><i className="bi bi-check-circle-fill"></i></span>{feature}</li>
                                    )
                                })}
                                </ul>
                            </div>
                           
                        </div>
                    )}}
                    else {
                        if(v.duration=="Year")
                        return (
                            <div key={i} className="plan-itm">
                                 <div className="plan-thumb">
                            <img src={PlanThumb1} alt='' />
                        </div>
                                <div className="plan-title">
                                    <h4>{v.title}</h4>
                                </div>
                                <div className="plan-cost">
                                    <h2>{v.price}<small>/ {planDuration}</small></h2>
                                </div>
                                <div className="plan-action">
                               <button type="button" disabled={isSubmitting} className="btn btn-primary" onClick={() => { selectPlanFun(v.title,v.priceId) }}>{
                                        isSubmitting? <Spinner color="light">
                                        Loading...
                                      </Spinner>:
                                    "Select Plan"}</button>
                                </div>
                                <div className="devider"></div>
                                <h6 className='tagline'>{v.description}</h6>
                                <div className="plan-features">
                                    <ul>
                                    {v.featureList.map((feature,featureIndex)=>{
                                        return (
                                            <li key={featureIndex}><span className='ic-check'><i className="bi bi-check-circle-fill"></i></span>{feature}</li>
                                        )
                                    })}
                                    </ul>
                                </div>
                               
                            </div>
                        )
                    }
                })}
                </div>
            </div>
        </div>
    )
}

export default UpgradePlan
