import Card from '../../../../components/Card';
import { Table } from 'src/User/features/Viz/components/Table';
import { ADSETS_TABLE_COLS } from './constants';
import { Chart } from 'src/User/features/Viz/components/Chart';
import useGetAdsetsInsightsData from './hooks/useGetAdsetsInsightsData';
import { IChartHeaderFilterOption } from 'src/User/features/Viz/components/types';
import { TChartMetrics } from '../../types';
import getChartOptions from '../../utils/getChartOptions';

const Adsets = () => {
  const { aggregatedAdsetsTableData, adsetCharts, loading, toggleChartSeries } =
    useGetAdsetsInsightsData();

  return (
    <div className='tw-flex tw-flex-col tw-gap-6 tw-mt-4 tw-h-full tw-flex-grow'>
      <Card
        title='Campaign Ad sets'
        className='tw-h-full tw-min-h-[450px] tw-flex tw-flex-col tw-flex-grow'
        cardContentProps={{
          className: 'tw-flex tw-flex-col tw-flex-grow !tw-pl-6'
        }}>
        <Table
          height={'100%'}
          isLoading={loading}
          columnsDefs={ADSETS_TABLE_COLS}
          data={aggregatedAdsetsTableData}
        />
      </Card>
      {adsetCharts.map(({ chartData, adsCount, adsetId, adsetName }) => (
        <Card
          key={adsetId}
          title={
            <>
              {adsetName}{' '}
              <span className='tw-text-base tw-text-slate-600'>
                ({adsCount} Ads)
              </span>
            </>
          }>
          <Chart
            data={{
              options: getChartOptions(adsetId, chartData.dates),
              series: chartData.seriesData?.filter(
                ({ showSeries }) => showSeries
              )
            }}
            header={{
              title: '',
              filtersOptions: chartData.seriesData.reduce(
                (acc, { name, total, showSeries }) => {
                  acc[name] = {
                    value: total,
                    isActive: showSeries
                  };

                  return acc;
                },
                {} as IChartHeaderFilterOption
              ),
              onFilterOptionClick: (seriesName) => {
                console.log('seriesName', seriesName);
                toggleChartSeries(adsetId, seriesName as TChartMetrics);
              }
            }}
            type='area'
          />
        </Card>
      ))}
    </div>
  );
};

export default Adsets;
