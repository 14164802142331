import { Link, useParams, useSearchParams } from 'react-router-dom';
import { ICellRendererParams } from 'ag-grid-community';
import {
  IObjectivesTableRow,
  ICampaignsTableRow,
  IAdsetsTableRow
} from '../types';
import { DATE_SEARCH_PARAMS } from 'src/configs';
import addParamsToPathname from 'src/lib/utils/addParamsToPathname';
import { CAMPAIGN_INSIGHTS_SEARCH_PARAMS } from '../constant';
import { ROUTER_PATHS } from 'src/lib/constants';

export type TTableLinkData =
  | IAdsetsTableRow
  | IObjectivesTableRow
  | ICampaignsTableRow;

export type TTableLinkProps = ICellRendererParams<TTableLinkData, any, any>;

export default function TableLink({ data }: TTableLinkProps) {
  const { adAccountId } = useParams();
  const [searchParams] = useSearchParams();

  const duration = {
    start: searchParams.get(DATE_SEARCH_PARAMS.start) ?? undefined,
    stop: searchParams.get(DATE_SEARCH_PARAMS.stop) ?? undefined
  };

  const { label, queryStr, href: hrefProp } = getLink(data) ?? {};

  const href =
    `/ad-analytics/meta-ads/fb/${adAccountId}/${ROUTER_PATHS.ADS_ANALYTICS_META_ADS_CAMPAIGN_INSIGHT.LAYOUT}` +
    `/${hrefProp}?${queryStr}`;

  return (
    <Link
      to={addParamsToPathname(href, duration)}
      className='tw-text-primary tw-no-underline tw-cursor-pointer hover:tw-text-primary hover:tw-underline'>
      {label}
    </Link>
  );
}

const getLink = (data?: TTableLinkData) => {
  if (typeof (data as IAdsetsTableRow)?.adsetId !== 'undefined')
    return {
      label: (data as IAdsetsTableRow).adsetName,
      href: ROUTER_PATHS.ADS_ANALYTICS_META_ADS_CAMPAIGN_INSIGHT.ADS_OVERVIEW,
      queryStr: `${CAMPAIGN_INSIGHTS_SEARCH_PARAMS.adsets}=${
        (data as IAdsetsTableRow).adsetId
      }`
    };

  if (typeof (data as ICampaignsTableRow)?.campaignId !== 'undefined')
    return {
      label: (data as ICampaignsTableRow).campaignName,
      href: ROUTER_PATHS.ADS_ANALYTICS_META_ADS_CAMPAIGN_INSIGHT.ADSET_OVERVIEW,
      queryStr: `${CAMPAIGN_INSIGHTS_SEARCH_PARAMS.campaigns}=${
        (data as ICampaignsTableRow).campaignId
      }`
    };

  return {
    label: data?.objective,
    href: ROUTER_PATHS.ADS_ANALYTICS_META_ADS_CAMPAIGN_INSIGHT
      .CAMPAIGNS_OVERVIEW,
    queryStr: `${CAMPAIGN_INSIGHTS_SEARCH_PARAMS.objectives}=${data?.objective}`
  };
};
