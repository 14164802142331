import Api from 'src/Api';
import {
  IStartSomateAIConversationProps,
  IStartSomateAIConversationResponse,
  IGetConversationProps,
  IGetSomateAIConversationsResponse,
  IPromptSomateAIConversationProps
} from './models';

export const getConversations = async (brandId: string) => {
  return Api.get<IGetSomateAIConversationsResponse>(
    `/ai/conversation/?brand_id=${brandId}`
  );
};

export const startConversation = async (
  props: IStartSomateAIConversationProps
) => {
  const { brandId, prompt } = props;

  return Api.post<IStartSomateAIConversationResponse>(
    `/ai/start-conversation/`,
    {
      brand_id: brandId,
      message: prompt
    }
  );
};

export const getConversation = async (props: IGetConversationProps) => {
  const { brandId, conversationId } = props;

  return Api.get<IGetSomateAIConversationsResponse>(
    `/ai/conversation/?brand_id=${brandId}&conversation_id=${conversationId}`
  );
};

export const promptConversation = async (
  props: IPromptSomateAIConversationProps
) => {
  const { brandId, conversationId, prompt } = props;

  return Api.post<IStartSomateAIConversationResponse>(`/ai/conversation/`, {
    brand_id: brandId,
    conversation_id: conversationId,
    message: prompt
  });
};

export const deleteConversation = async (props: IGetConversationProps) => {
  const { brandId, conversationId } = props;

  return Api.delete(`/ai/conversation/`, {
    data: { brand_id: brandId, conversation_id: conversationId }
  });
};
