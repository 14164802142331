import { ColDef } from 'ag-grid-community';
import TableLink from '../../../components/TableLink';
import { IObjectivesTableRow } from '../../../types';
import formatCellValueToAmount from 'src/User/features/Viz/components/Table/utils/formatCellValue';
import { round } from 'lodash';

export const OBJECTIVES_TABLE_COLS: ColDef<IObjectivesTableRow>[] = [
  {
    field: 'objective',
    headerName: 'Objective',
    sortable: true,
    filter: true,
    cellRenderer: TableLink,
    pinned: 'left',
    minWidth: 300
  },
  {
    field: 'spend',
    headerName: 'Spend',
    sortable: true,
    filter: true,
    valueFormatter: ({ value, data }) => {
      const currency =
        !!data?.currency && data?.currency !== 'N/A'
          ? data?.currency
          : undefined;

      return formatCellValueToAmount(value ?? 'N/A', currency);
    }
  },
  {
    field: 'clicks',
    headerName: 'Clicks',
    sortable: true,
    filter: true,
    valueFormatter: ({ value }) => `${round(value, 2)}`
  },
  {
    field: 'cpc',
    headerName: 'CPC',
    sortable: true,
    filter: true,
    valueFormatter: ({ value }) => `${round(value, 2)}`
  },
  {
    field: 'cpm',
    headerName: 'CPM',
    sortable: true,
    filter: true,
    valueFormatter: ({ value }) => `${round(value, 2)}`
  },
  {
    field: 'ctr',
    headerName: 'CTR',
    sortable: true,
    filter: true,
    valueFormatter: ({ value }) => `${round(value, 2)}`
  },
  {
    field: 'impressions',
    headerName: 'Impressions',
    sortable: true,
    filter: true,
    valueFormatter: ({ value }) => `${round(value, 2)}`
  },
  {
    field: 'reach',
    headerName: 'Reach',
    sortable: true,
    filter: true,
    valueFormatter: ({ value }) => `${round(value, 2)}`
  }
];

export const CAMPAIGN_OBJECTIVES = [
  'REACH',
  'OUTCOME_TRAFFIC',
  'OUTCOME_SALES',
  'POST_ENGAGEMENT',
  'OUTCOME_ENGAGEMENT',
  'OUTCOME_LEADS',
  'LINK_CLICKS'
];
