import {
  Terminal,
  Image,
  BookText,
  Mail,
  Earth,
  Store,
  ImagePlus
} from 'lucide-react';
import { ISuggestion, TAgents } from '../types';
import MetaIcon from 'src/components/icons/meta';

export const AGENTS = [
  {
    name: 'Meta Ads Mate',
    description:
      'Ask how good campaign performs, ask for tips how to improve campaigns',
    id: 'meta-ads-expert-agent',
    icon: <MetaIcon className='tw-w-8 tw-h-8' />
  },
  {
    name: 'Marketing Mate',
    description: 'Ask for tips on how to improve your marketing efforts',
    id: 'marketing-expert-agent',
    icon: <Store className='tw-w-8 tw-h-8' />
  },
  {
    name: 'Adcreative copy / hooks Agent',
    description:
      'Create hooks, headlines, and descriptions for your ad creatives',
    id: 'adcreative-copy-hooks-agent',
    icon: <ImagePlus className='tw-w-8 tw-h-8' />,
    hide: true
  },
  {
    name: 'Competition Analysis Agent',
    description:
      'Ask about competition accounts tracked by brand, get feedback on how to improve your offering',
    id: 'competition-analysis-agent',
    icon: <Store className='tw-w-8 tw-h-8' />,
    hide: true
  },
  {
    name: 'Cold Outreach Agent',
    description:
      'Start from a prompt and reach out to potential clients on LinkedIn or email without a lot of hassle',
    id: 'cold-outreach-agent',
    icon: <Earth className='tw-w-8 tw-h-8' />,
    hide: true
  },
  {
    name: 'Email Template Generator Agent',
    description: 'Generate insightful email template that move the needle',
    id: 'email-template-generator-agent',
    icon: <Mail className='tw-w-8 tw-h-8' />,
    hide: true
  },
  {
    name: 'Content Creator Agent',
    description: 'Generate great content from a simple prompt',
    id: 'content-creator-agent',
    icon: <BookText className='tw-w-8 tw-h-8' />,
    hide: true
  },
  {
    name: 'Image Generator Agent',
    description:
      'Create stunning images for your social accounts and ads campaigns from a simple prompt',
    id: 'image-generator-agent',
    icon: <Image className='tw-w-8 tw-h-8' />,
    hide: true
  }
];

export const SUGGESTION_BY_AGENT: { [k in TAgents]: ISuggestion[] } = {
  'general-purpose-agent': [
    {
      suggestion: 'Ideas for a new meta ads campaign',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'How to better identify ads targets',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'How to boost ads on instagram?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'How to reduce meta ads spending?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    }
  ],
  'meta-ads-expert-agent': [
    {
      suggestion: 'How to improve my meta ads campaign?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'What is the best time to post on instagram?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'How to boost ads on instagram?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'What is my lifetime spend on meta ads?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    }
  ],
  'marketing-expert-agent': [
    {
      suggestion: 'How to improve my meta ads campaign?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'What is the best time to post on instagram?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'How to boost ads on instagram?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'What is my lifetime spend on meta ads?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    }
  ],
  'adcreative-copy-hooks-agent': [
    {
      suggestion: 'How to improve my ad creative?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'What is the best time to post on instagram?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'How to boost ads on instagram?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'What is my lifetime spend on meta ads?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    }
  ],
  'competition-analysis-agent': [
    {
      suggestion: 'How to improve my meta ads campaign?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'What is the best time to post on instagram?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'How to boost ads on instagram?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'What is my lifetime spend on meta ads?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    }
  ],
  'cold-outreach-agent': [
    {
      suggestion: 'How to improve my meta ads campaign?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'What is the best time to post on instagram?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'How to boost ads on instagram?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'What is my lifetime spend on meta ads?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    }
  ],
  'email-template-generator-agent': [
    {
      suggestion: 'How to improve my meta ads campaign?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'What is the best time to post on instagram?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'How to boost ads on instagram?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'What is my lifetime spend on meta ads?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    }
  ],
  'content-creator-agent': [
    {
      suggestion: 'How to improve my meta ads campaign?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'What is the best time to post on instagram?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'How to boost ads on instagram?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'What is my lifetime spend on meta ads?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    }
  ],
  'image-generator-agent': [
    {
      suggestion: 'How to improve my meta ads campaign?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'What is the best time to post on instagram?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'How to boost ads on instagram?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    },
    {
      suggestion: 'What is my lifetime spend on meta ads?',
      icon: <Terminal className='tw-w-5 tw-h-5' />
    }
  ]
};
