import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './tailwind-global.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './i18n';
import Providers from './Providers';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLDivElement
);

root.render(<Providers />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
