export const ERROR_MESSAGE_MAP = {
  'ig-business':
    'Unable to get Instagram business accounts profiles for the specified meta account',
  'facebook-page': 'Unable to get Facebook pages for the specified meta account'
};

export const CONNECT_MEDIA_ACCOUNT_DIALOG_TITLES = {
  'ig-business': 'Connect Instagram Account Profiles',
  'facebook-page': 'Connect Facebook Pages on meta account',
  'linkedin-page': 'Connect LinkedIn Pages Profiles',
  'linkedin-ads': 'Connect LinkedIn Ad Accounts'
};

export const CONNECT_MEDIA_ACCOUNT_DIALOG_EMPTY_DESCRIPTION = {
  'ig-business':
    "You don't have any Instagram business accounts linked to the selected meta account",
  'facebook-page':
    "You don't have any Facebook pages linked to the selected meta account",
  'linkedin-page': 'Could not find any LinkedIn pages'
};
