import classNames from 'classnames';
import { Progress } from 'reactstrap';
import styles from './index.module.css';
import { CSSProperties } from 'react';

type TLabel = {
  title: string;
  value?: string | number;
  icon?: JSX.Element;
};

type TProgressBar = {
  value: number | string;
  maxValue?: number | string;
  minValue?: number | string;
  progressStyle?: CSSProperties;
  barColor?: string;
  startLabel?: TLabel;
  middleLabel?: TLabel;
  endLabel?: TLabel;
  labelPostion?: 'top' | 'bottom';
};

export default function ProgressBar(props: TProgressBar) {
  const {
    value,
    maxValue,
    minValue,
    progressStyle,
    barColor,
    startLabel,
    middleLabel,
    endLabel,
    labelPostion = 'top'
  } = props;

  const labels = [startLabel, middleLabel, endLabel];

  return (
    <div className='d-flex flex-column gap-2'>
      <div
        className={classNames('d-flex justify-content-between m-0', {
          'order-1': labelPostion == 'top',
          'order-2': labelPostion == 'bottom'
        })}>
        {labels.map((label, index) =>
          label ? (
            <Label
              key={index}
              title={label?.title}
              icon={label?.icon}
              value={label?.value}
            />
          ) : null
        )}
      </div>
      <Progress
        className={classNames({
          'order-2': labelPostion == 'top',
          'order-1': labelPostion == 'bottom'
        })}
        style={{
          height: '3px',
          ...progressStyle
        }}
        barStyle={{
          backgroundColor: barColor ?? '#9a5333'
        }}
        value={value}
        max={maxValue}
        min={minValue}
      />
    </div>
  );
}

const Label = ({ title, icon, value }: TLabel) => {
  const Icon = () => icon;

  return (
    <div className='m-0 d-flex gap-2 justify-content-baseline'>
      {icon ? <Icon /> : null}
      <p className='d-flex gap-1 flex-column'>
        <span className={styles['progress-label']}>{title || ''}</span>
        <span className={styles['progress-label']}>{value ?? ''}</span>
      </p>
    </div>
  );
};
