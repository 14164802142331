import { Suspense } from 'react';
import { useLoaderData, Await } from 'react-router-dom';
import AnalyticsSocialAccountsRenderer from 'src/User/components/AnalyticsSocialAccountsRenderer';
import getSocialMediaTypes from '../../utils/getSocialMediaTypes';
import { IMetaAdAccount } from 'src/lib/models/socialAccounts';
import { IGetAllSocialAccountsResponse } from 'src/User/services/connected-social-accts/models';
import { AxiosResponse } from 'axios';
import ContentLoader from 'react-content-loader';
import { DATE_SEARCH_PARAMS, DEFAULT_DATE_DURATION } from 'src/configs';
import { useTranslation } from 'react-i18next';
import { TNamespace } from 'src/i18n';
import getAdProviderPlatforms from './utils/getAdProviderPlatforms';

const AdAnalyticsIndexRoute = () => {
  const { response } = useLoaderData() as any;
  const { t } = useTranslation<TNamespace>();

  return (
    <AnalyticsSocialAccountsRenderer.Layout title={t('ads-analytics')}>
      <Suspense fallback={<ConnectedAccountsLoadingView />}>
        <Await
          resolve={response}
          errorElement={<ConnectedAccountsError />}>
          {({
            data: resolvedResponse
          }: AxiosResponse<IGetAllSocialAccountsResponse>) => (
            <ConnectedAccountsView
              socialAccounts={resolvedResponse?.data?.META_ADS ?? []}
            />
          )}
        </Await>
      </Suspense>
      <AnalyticsSocialAccountsRenderer.AccountProviderView
        type={'ads-analytics'}
        accountProviders={getAdProviderPlatforms(t)}
      />
    </AnalyticsSocialAccountsRenderer.Layout>
  );
};

const ConnectedAccountsView = ({
  socialAccounts
}: {
  socialAccounts: IMetaAdAccount[];
}) => {
  const { t } = useTranslation<TNamespace>();

  const socialMediaTypes = getSocialMediaTypes(t);
  const metaAdsAccountMetadata = socialMediaTypes?.find(
    ({ value }) => value == 'meta-ads'
  );

  return (
    <AnalyticsSocialAccountsRenderer.ConnectedAccountsView
      connectedAccounts={socialAccounts.map(
        ({ name, act_account_id: accountId }) => ({
          name,
          href:
            `/ad-analytics/${metaAdsAccountMetadata?.value}/` +
            `${metaAdsAccountMetadata?.slug}/${accountId}/summary` +
            `?${DATE_SEARCH_PARAMS.start}=${DEFAULT_DATE_DURATION.start}` +
            `&${DATE_SEARCH_PARAMS.stop}=${DEFAULT_DATE_DURATION.stop}`,
          avatar: metaAdsAccountMetadata?.logo
        })
      )}
    />
  );
};

const ConnectedAccountsError = () => {
  const { t } = useTranslation<TNamespace>();

  return (
    <AnalyticsSocialAccountsRenderer.LayoutItem title={t('connected-accounts')}>
      <div className='tw-text-red-500'>
        <i className='bi bi-exclamation-circle-fill'></i>
        {t('root.error.404', { ns: 'adAnalytics' })}
      </div>
    </AnalyticsSocialAccountsRenderer.LayoutItem>
  );
};

const ConnectedAccountsLoadingView = () => {
  const { t } = useTranslation<TNamespace>();

  return (
    <AnalyticsSocialAccountsRenderer.LayoutItem title={t('connected-accounts')}>
      <div className='tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-gap-7'>
        {Array.from({ length: 4 })
          .fill(1)
          .map((_, index) => (
            <LoadingAccount key={index} />
          ))}
      </div>
    </AnalyticsSocialAccountsRenderer.LayoutItem>
  );
};

const LoadingAccount = (props: any) => {
  return (
    <ContentLoader
      speed={2}
      width={280}
      height={140}
      viewBox='0 0 280 140'
      backgroundColor='#f3f2f2'
      foregroundColor='#e5e0e0'
      {...props}>
      <rect
        x='3'
        y='140'
        rx='0'
        ry='0'
        width='280'
        height='201'
      />
      <rect
        x='5'
        y='1'
        rx='11'
        ry='11'
        width='272'
        height='137'
      />
    </ContentLoader>
  );
};

export default AdAnalyticsIndexRoute;
