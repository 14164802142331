import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { USER_CONFIG } from 'src/configs';
import { LoaderFunctionArgs } from 'react-router-dom';
import { getAllAccountProfilesInBrandByMediaTypeId } from 'src/User/services/oauth-connect';
import isValidMediaType from '../utils/isValidMediaType';
import { TMediaType } from 'src/User/services/oauth-connect/models';

const connectIgBusinessAccountProfileLoader = async ({
  params
}: LoaderFunctionArgs) => {
  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);
  const { metaId, mediaType } = params;

  if (!metaId || !isValidMediaType(mediaType)) return [];

  const socialAccountsResponse =
    await getAllAccountProfilesInBrandByMediaTypeId({
      brandId,
      metaId,
      mediaType: mediaType as TMediaType
    });
  const { data: organicMetaAccounts } = socialAccountsResponse;

  return organicMetaAccounts.data;
};

export default connectIgBusinessAccountProfileLoader;
