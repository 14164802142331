import { useRef } from 'react';
import { AgGridReact as AgGrid, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './table.css';

import { ITableProps } from '../types';

import TableLoading from './TableLoading';

export default function Table({
  data,
  height = 400,
  columnsDefs,
  isLoading,
  pagination,
  paginationPageSize
}: ITableProps) {
  const gridRef = useRef<AgGridReact | null>(null);

  return isLoading ? (
    <TableLoading />
  ) : (
    <div
      className='ag-theme-quartz'
      style={{
        width: '100%',
        height: height
      }}>
      <AgGrid
        ref={gridRef}
        rowData={data}
        columnDefs={columnsDefs}
        suppressHorizontalScroll={false}
        pagination={pagination}
        paginationPageSize={paginationPageSize}
      />
    </div>
  );
}
