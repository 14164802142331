import { faker } from '@faker-js/faker';
import type * as FacebookPageModels from '../types';
import { format, subMonths } from 'date-fns';
import { sum } from 'lodash';
import { facebookPageDailyInsightTitleKeyMapper } from '../../../constants';
import { ICONS_MAP } from 'src/User/features/CompetitionAnalysis/views/Instagram/views/Overview/constants';
import { uniq } from 'lodash';
import { IFacebookPageAccount } from 'src/lib/models/socialAccounts';

export const generateFakeFacebookPageAccount = (): IFacebookPageAccount => {
  return {
    facebook_page_id: faker.string.uuid(),
    meta_id: faker.string.uuid(),
    name: faker.company.name(),
    connected: true,
    about: faker.lorem.sentences({ min: 4, max: 5 }, '\n'),
    fan_count: faker.number.int({ min: 0, max: 1000 }),
    cover: faker.image.imageUrl(),
    profile_pic: faker.image.imageUrl(),
    website: faker.internet.url(),
    link: faker.internet.url(),
    category: faker.random.word(),
    emails: faker.internet.email(),
    created_at: format(faker.date.past(), 'yyyy-MM-dd'),
    updated_at: format(faker.date.recent(), 'yyyy-MM-dd')
  };
};

export const generateMockFacebookPageDailyInsight = (props: {
  from: string | undefined;
  to: string | undefined;
}): FacebookPageModels.IFacebookPageMockDailyInsight => {
  const startDate =
    props.from || format(subMonths(new Date(), 1), 'yyyy-MM-dd');
  const endDate = props.to || format(new Date(), 'yyyy-MM-dd');

  return {
    followers: generateMockFacebookPageInsightRecord(startDate, endDate),
    page_actions_post_reactions_like_total:
      generateMockFacebookPageInsightRecord(startDate, endDate),
    page_impressions: generateMockFacebookPageInsightRecord(startDate, endDate),
    page_views_total: generateMockFacebookPageInsightRecord(startDate, endDate),
    page_daily_follows: generateMockFacebookPageInsightRecord(
      startDate,
      endDate
    ),
    fans_aquired: generateMockFacebookPageInsightRecord(startDate, endDate),
    fans_lost: generateMockFacebookPageInsightRecord(startDate, endDate),
    call_to_action_clicks: generateMockFacebookPageInsightRecord(
      startDate,
      endDate
    ),
    website_button_clicks: generateMockFacebookPageInsightRecord(
      startDate,
      endDate
    ),
    page_visits_total: generateMockFacebookPageInsightRecord(
      startDate,
      endDate
    ),
    average_reach_per_post: generateMockFacebookPageInsightRecord(
      startDate,
      endDate,
      {
        min: 0,
        max: 100
      }
    ),
    interactions: generateMockFacebookPageInteractionInsightRecord(
      startDate,
      endDate,
      {
        min: 0,
        max: 50
      }
    ),
    interactions_total: generateMockFacebookPageInsightRecord(
      startDate,
      endDate,
      {
        min: 0,
        max: 75
      }
    ),
    engagement_rate: generateMockFacebookPageInsightRecord(startDate, endDate, {
      min: 0,
      max: 150
    }),
    story_count: generateMockFacebookPageInsightRecord(startDate, endDate, {
      min: 0,
      max: 10
    })
  };
};

const generateMockFacebookPageInsightRecord = (
  startDate: string,
  endDate: string,
  range?: {
    min: number;
    max: number;
  }
): FacebookPageModels.IFacebookPageMockInsightRecord[] => {
  const data: FacebookPageModels.IFacebookPageMockInsightRecord[] = [];
  let startDateInstance = new Date(startDate);

  while (startDateInstance <= new Date(endDate)) {
    data.push({
      date: format(startDateInstance, 'yyyy-MM-dd'),
      value: faker.number.float(range ?? { min: 0, max: 1000 }).toLocaleString()
    });
    startDateInstance.setDate(startDateInstance.getDate() + 1);
  }

  return data;
};

const generateMockFacebookPageInteractionInsightRecord = (
  startDate: string,
  endDate: string,
  range?: {
    min: number;
    max: number;
  }
): FacebookPageModels.IFacebookPageMockInteractionInsightRecord[] => {
  const data: FacebookPageModels.IFacebookPageMockInteractionInsightRecord[] =
    [];
  let startDateInstance = new Date(startDate);

  while (startDateInstance <= new Date(endDate)) {
    data.push({
      date: format(startDateInstance, 'yyyy-MM-dd'),
      reactions: faker.number.int(range ?? { min: 0, max: 1000 }),
      comments: faker.number.int(range ?? { min: 0, max: 1000 }),
      shared: faker.number.int(range ?? { min: 0, max: 1000 }),
      clicks: faker.number.int(range ?? { min: 0, max: 1000 })
    });
    startDateInstance.setDate(startDateInstance.getDate() + 1);
  }

  return data;
};

export const generateMockFacebookPageDailyInsightSummary = (
  data: FacebookPageModels.IFacebookPageMockDailyInsight
) => {
  return {
    followers: {
      title: getSum(data.followers),
      description: facebookPageDailyInsightTitleKeyMapper.followers,
      icon: ICONS_MAP['followers']
    },
    page_actions_post_reactions_like_total: {
      title: getSum(data.page_actions_post_reactions_like_total),
      description:
        facebookPageDailyInsightTitleKeyMapper.page_actions_post_reactions_like_total,
      icon: ICONS_MAP['averageLikes']
    },
    page_impressions: {
      title: getSum(data.page_impressions),
      description: facebookPageDailyInsightTitleKeyMapper.page_impressions,
      icon: ICONS_MAP['engagementRate']
    },
    page_views_total: {
      title: getSum(data.page_views_total),
      description: facebookPageDailyInsightTitleKeyMapper.page_views_total,
      icon: ICONS_MAP['pageVisits']
    },
    page_daily_follows: {
      title: getSum(data.page_daily_follows),
      description: facebookPageDailyInsightTitleKeyMapper.page_daily_follows,
      icon: ICONS_MAP['followers']
    },
    fans_aquired: {
      title: getSum(data.fans_aquired),
      description: facebookPageDailyInsightTitleKeyMapper.fans_acquired,
      icon: ICONS_MAP['followersRatio']
    },
    fans_lost: {
      title: getSum(data.fans_lost),
      description: facebookPageDailyInsightTitleKeyMapper.fans_lost,
      icon: ICONS_MAP['weeklyFollowers']
    },
    call_to_action_clicks: {
      title: getSum(data.call_to_action_clicks),
      description: facebookPageDailyInsightTitleKeyMapper.call_to_action_clicks,
      icon: ICONS_MAP['clicks']
    },
    page_visits_total: {
      title: getSum(data.page_visits_total),
      description: facebookPageDailyInsightTitleKeyMapper.page_visits_total,
      icon: ICONS_MAP['pageVisits']
    },
    website_button_clicks: {
      title: getSum(data.website_button_clicks),
      description: facebookPageDailyInsightTitleKeyMapper.website_button_clicks,
      icon: ICONS_MAP['clicks']
    }
  };
};

export const getSum = (
  data: FacebookPageModels.IFacebookPageMockInsightRecord[]
) => {
  return Number(
    sum(data.map((record) => parseFloat(record.value))).toFixed(2)
  ).toLocaleString();
};

export const mergeDialyPostInsights = (
  props: Pick<
    FacebookPageModels.IFacebookPageMockDailyInsight,
    'average_reach_per_post' | 'interactions_total' | 'engagement_rate'
  >
) => {
  const { average_reach_per_post, interactions_total, engagement_rate } = props;
  const avergeReachDates = [...average_reach_per_post.map(({ date }) => date)];
  const interactionsDates = [...interactions_total.map(({ date }) => date)];
  const engagementDates = [...engagement_rate.map(({ date }) => date)];

  const dates = uniq([
    ...avergeReachDates,
    ...interactionsDates,
    ...engagementDates
  ]);

  return dates.map((date) => {
    const avgReach = average_reach_per_post.find((item) => item.date === date);
    const interactions = interactions_total.find((item) => item.date === date);
    const engagement = engagement_rate.find((item) => item.date === date);

    return {
      date,
      average_reach_per_post: avgReach?.value || null,
      interactions: interactions?.value || null,
      engagement_rate: engagement?.value || null
    };
  });
};

export const generateMockFacebookPageLifeTimeMediaInsight = (
  mediaCount?: number
): FacebookPageModels.IFacebookPageMockLifeTimeMediaInsight[] => {
  return Array.from({ length: mediaCount ?? 7 }).map(() => ({
    impressions: faker.number.int({ min: 0, max: 1000 }),
    reach: faker.number.int({ min: 0, max: 200 }),
    engagement_rate: faker.number.float({ precision: 2, min: 0, max: 100 }),
    spent: faker.finance.amount({ min: 0, max: 500, symbol: 'PLN ' }),
    video_views: faker.number.int({ min: 0, max: 200 }),
    video_time_watched: faker.number.int({ min: 0, max: 200 }),
    link_clicks: faker.number.int({ min: 0, max: 200 }),
    clicks: faker.number.int({ min: 0, max: 200 }),
    shares: faker.number.int({ min: 0, max: 200 }),
    comments: faker.number.int({ min: 0, max: 200 }),
    reactions: faker.number.int({ min: 0, max: 200 }),
    date: format(faker.date.recent(), 'yyyy-MM-dd'),
    type: faker.lorem.word({ length: 5 }),
    thumbnail_url: faker.image.url(),
    permalink: faker.internet.url(),
    caption: faker.lorem.sentence({ min: 10, max: 20 })
  }));
};

export const generateMockFacebookPageDailyStoryInsight = (
  storyCount?: number
): FacebookPageModels.IFacebookPageMockDailyStoryInsight[] => {
  return Array.from({ length: storyCount ?? 7 }).map(() => ({
    date: format(faker.date.recent(), 'yyyy-MM-dd'),
    thumbnail_url: faker.image.url(),
    permalink: faker.internet.url(),
    caption: faker.lorem.sentence({ min: 10, max: 20 }),
    type: faker.helpers.arrayElement([
      'checkin',
      'coupon',
      'event',
      'fan',
      'mention',
      'page post',
      'question',
      'user post',
      'other'
    ])
  }));
};

export function generateMockFacebookPageReachByAgeData(
  startDate: string,
  endDate: string,
  range?: {
    min: number;
    max: number;
  }
): FacebookPageModels.IFacebookPageMockReachByAge[] {
  const data: FacebookPageModels.IFacebookPageMockReachByAge[] = [];
  let startDateInstance = new Date(
    startDate || format(subMonths(new Date(), 1), 'yyyy-MM-dd')
  );
  const endDateInstance = new Date(endDate || format(new Date(), 'yyyy-MM-dd'));

  while (startDateInstance <= endDateInstance) {
    data.push({
      date: format(startDateInstance, 'yyyy-MM-dd'),
      '13-17': faker.number.int(range ?? { min: 0, max: 100 }),
      '18-24': faker.number.int(range ?? { min: 0, max: 100 }),
      '25-34': faker.number.int(range ?? { min: 0, max: 100 }),
      '35-44': faker.number.int(range ?? { min: 0, max: 100 }),
      '45-54': faker.number.int(range ?? { min: 0, max: 100 }),
      '55-64': faker.number.int(range ?? { min: 0, max: 100 }),
      '65+': faker.number.int(range ?? { min: 0, max: 100 })
    });
    startDateInstance.setDate(startDateInstance.getDate() + 1);
  }

  return data;
}

export function generateMockFacebookPageReachByGenderData(
  startDate: string,
  endDate: string,
  range?: {
    min: number;
    max: number;
  }
): FacebookPageModels.IFacebookPageMockReachByGender[] {
  const data: FacebookPageModels.IFacebookPageMockReachByGender[] = [];
  let startDateInstance = new Date(
    startDate || format(subMonths(new Date(), 1), 'yyyy-MM-dd')
  );
  const endDateInstance = new Date(endDate || format(new Date(), 'yyyy-MM-dd'));

  while (startDateInstance <= endDateInstance) {
    data.push({
      date: format(startDateInstance, 'yyyy-MM-dd'),
      male: faker.number.int(range ?? { min: 0, max: 1500 }),
      female: faker.number.int(range ?? { min: 0, max: 1500 }),
      unknown: faker.number.int(range ?? { min: 0, max: 500 })
    });
    startDateInstance.setDate(startDateInstance.getDate() + 1);
  }

  return data;
}

export function generateMockFacebookPageFollowersByCountry(): FacebookPageModels.IFacebookPageMockFollowersByCountry {
  return {
    Poland: faker.number.int(),
    Germany: faker.number.int(),
    Spain: faker.number.int(),
    Italy: faker.number.int(),
    Thailand: faker.number.int(),
    Vietnam: faker.number.int()
  };
}
