import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'src/components/ui/button';

interface IFullScreenFeedbackProps {
  icon: ReactNode;
  title: string;
  message: string;
  children?: ReactNode;
}

export default function FullScreenFeedback(props: IFullScreenFeedbackProps) {
  const { icon, title, message, children } = props;

  return (
    <div className='login'>
      <div className='tw-mx-auto tw-max-w-md md:tw-max-w-lg lg:tw-max-w-3xl tw-text-center'>
        <div className='tw-mx-auto tw-w-fit'>{icon}</div>
        <h1 className='tw-mt-4 tw-text-3xl tw-font-bold tw-tracking-tighter tw-text-foreground md:tw-text-5xl lg:tw-text-7xl'>
          {title}
        </h1>
        <p className='tw-mt-4 tw-text-muted-foreground tw-max-w-lg tw-text-center tw-mx-auto'>
          {message}
        </p>
        {children}
      </div>
    </div>
  );
}

interface IFullScreenFeedbackButtonProps {
  href: string;
  label: string;
}
FullScreenFeedback.Button = (props: IFullScreenFeedbackButtonProps) => {
  const { href, label } = props;

  return (
    <Button asChild>
      <Link
        to={href}
        className='tw-no-underline tw-text-primary-foreground tw-transition-colors !hover:tw-bg-primary/80 hover:tw-text-primary-foreground'>
        {label}
      </Link>
    </Button>
  );
};
