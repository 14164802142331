import styles from '../../components/index.module.css';
import classNames from 'classnames';
import { startCase } from 'lodash';
import i18next, { TNamespace } from 'src/i18n';

export const ICONS_MAP = {
  followers: (
    <div
      className={classNames(styles['icon-container'], styles['purple-icon'])}>
      <i className='bi bi-people-fill'></i>
    </div>
  ),
  weeklyFollowers: (
    <div className={classNames(styles['icon-container'], styles['red-icon'])}>
      <i className='bi bi-people-fill'></i>
    </div>
  ),
  engagementRate: (
    <div
      className={classNames(styles['icon-container'], styles['orange-icon'])}>
      <i className='bi bi-lightning-charge-fill'></i>
    </div>
  ),
  averageLikes: (
    <div className={classNames(styles['icon-container'], styles['red-icon'])}>
      <i className='bi bi-heart-fill'></i>
    </div>
  ),
  averageComments: (
    <div
      className={classNames(styles['icon-container'], styles['purple-icon'])}>
      <i className='bi bi-chat-left-fill'></i>
    </div>
  ),
  weeklyPosts: (
    <div className={classNames(styles['icon-container'], styles['green-icon'])}>
      <i className='bi bi-image-fill'></i>
    </div>
  ),
  followersRatio: (
    <div className={classNames(styles['icon-container'], styles['green-icon'])}>
      <i className='bi bi-people-fill'></i>
    </div>
  ),
  commentsRatio: (
    <div
      className={classNames(styles['icon-container'], styles['purple-icon'])}>
      <i className='bi bi-chat-left-fill'></i>
    </div>
  ),
  clicks: (
    <div
      className={classNames(styles['icon-container'], styles['purple-icon'])}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='1em'
        height='1em'
        viewBox='0 0 24 24'
        className='tw-text-2xl tw-rounded-xl'>
        <path
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M3 12h3m6-9v3M7.8 7.8L5.6 5.6m10.6 2.2l2.2-2.2M7.8 16.2l-2.2 2.2M12 12l9 3l-4 2l-2 4z'></path>
      </svg>
    </div>
  ),
  pageVisits: (
    <div
      className={classNames(styles['icon-container'], styles['orange-icon'])}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='1em'
        height='1em'
        viewBox='0 0 24 24'
        className='tw-text-2xl tw-rounded-xl'>
        <path
          fill='currentColor'
          d='M6 8c0-2.21 1.79-4 4-4s4 1.79 4 4s-1.79 4-4 4s-4-1.79-4-4m3.14 11.75L8.85 19l.29-.75c.7-1.75 1.94-3.11 3.47-4.03c-.82-.14-1.69-.22-2.61-.22c-4.42 0-8 1.79-8 4v2h7.27c-.04-.09-.09-.17-.13-.25M17 18c-.56 0-1 .44-1 1s.44 1 1 1s1-.44 1-1s-.44-1-1-1m6 1c-.94 2.34-3.27 4-6 4s-5.06-1.66-6-4c.94-2.34 3.27-4 6-4s5.06 1.66 6 4m-3.5 0a2.5 2.5 0 0 0-5 0a2.5 2.5 0 0 0 5 0'></path>
      </svg>
    </div>
  )
};

export const METRICS_DATA = [
  {
    icon: ICONS_MAP.followers,
    title: '7.51%',
    description: i18next.t('Followers Growth Rate (90 Days)', {
      ns: 'competitionAnalysis' satisfies TNamespace
    })
  },
  {
    icon: ICONS_MAP.weeklyFollowers,
    title: '186',
    description: i18next.t('Weekly Followers', {
      ns: 'competitionAnalysis' satisfies TNamespace
    })
  },
  {
    icon: ICONS_MAP.engagementRate,
    title: '1.01%',
    description: startCase(i18next.t('engagement_rate') ?? 'Engagement rate')
  },
  {
    icon: ICONS_MAP.averageLikes,
    title: 220,
    description: i18next.t('Average Likes', {
      ns: 'competitionAnalysis' satisfies TNamespace
    })
  },
  {
    icon: ICONS_MAP.averageComments,
    title: 24,
    description: i18next.t('Average Comments', {
      ns: 'competitionAnalysis' satisfies TNamespace
    })
  },
  {
    icon: ICONS_MAP.weeklyPosts,
    title: 0,
    description: i18next.t('Weekly Posts', {
      ns: 'competitionAnalysis' satisfies TNamespace
    })
  },
  {
    icon: ICONS_MAP.followersRatio,
    title: 64.3,
    description: i18next.t('Followers Ratio', {
      ns: 'competitionAnalysis' satisfies TNamespace
    })
  },
  {
    icon: ICONS_MAP.commentsRatio,
    title: 11.02,
    description: i18next.t('Comments Ratio', {
      ns: 'competitionAnalysis' satisfies TNamespace
    })
  }
];
