import { conversationHistoryLoader } from './loaders/conversationHistoryLoader';
import { conversationLoader } from './views/OldConversation/loaders/conversationLoader';
import { startConversationAction } from './views/StartGenericConversation/actions';
import { promptConversationAction } from './views/OldConversation/actions/promptConversationAction';
import { deleteConversationAction } from './actions/deleteConversationAction';

export { default as StartGenericConversation } from './views/StartGenericConversation';
export { default as Conversation } from './views/OldConversation';
export { default as StartAgentConversation } from './views/StartAgentConversation';
export { default as Layout } from './layout';
export const loaders = {
  conversationHistoryLoader,
  conversationLoader
};
export const actions = {
  startConversationAction,
  promptConversationAction,
  deleteConversationAction
};
