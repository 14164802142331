// dependencies
import React, { useState } from 'react';
import { Formik } from 'formik';
import { Form } from 'reactstrap';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

// auth services
import { reset } from '../services/auth';

// images
import PatternRight from '../../Assets/images/pattern-right.png';
import PatternStars from '../../Assets/images/pattern-stars.png';
import SOMateLogo from '../../Assets/images/somate-logo.svg';
import { PASSWORD_REGEX } from 'src/configs';

const ResetPassword = () => {
  let { Uid, Token } = useParams();

  const [isProgress, setIsProgress] = useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const [showPassword2, setShowPassword2] = React.useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);

  return (
    <>
      <React.Fragment>
        <div className='authentication-form-container'>
          <div className='logo_wrapper'>
            <img
              className='somate_logo'
              src={SOMateLogo}
              alt='SOMateLogo'
            />
          </div>
          <div className='login_form'>
            <h4>Create New Password.</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste,
              repellendus?
            </p>
            <Formik
              initialValues={{
                password: '',
                confirm_password: ''
              }}
              onSubmit={async (values) => {
                setIsProgress(true);
                console.warn(values, Uid, Token);
                const response = await reset(values, Uid, Token);
                if (response.status === 200) {
                  Swal.fire({
                    title: 'Success',
                    text: 'Password Changed Successfully',
                    icon: 'success',
                    confirmButtonText: 'OK'
                  }).then(function () {
                    window.location.href = '/login';
                  });
                  setIsProgress(false);
                } else {
                  Swal.fire({
                    title: 'Error',
                    text: response?.data?.detail,
                    icon: 'error',
                    confirmButtonText: 'OK'
                  });
                  setIsProgress(false);
                }
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .required('Required')
                  .min(8, 'Password must be at least 8 characters.')
                  .matches(
                    PASSWORD_REGEX,
                    'Password must contain at least 8 charactes'
                  ),
                confirm_password: Yup.string()
                  .required('Required')
                  .oneOf([Yup.ref('password'), null], 'Passwords must match.')
              })}>
              {({
                values,
                handleChange,
                handleSubmit,
                handleBlur,
                errors,
                touched
              }) => (
                <Form className='mt-5'>
                  <div className='mb-3 forgot-pass-wrapper'>
                    <label htmlFor='password'>New Password</label>
                    <input
                      className='form-control'
                      value={values.password}
                      onChange={handleChange}
                      required
                      style={{
                        border:
                          errors.password && touched.password && '1px solid red'
                      }}
                      onBlur={handleBlur}
                      type={showPassword ? 'text' : 'password'}
                      id='password'
                      name='password'
                    />
                    <span
                      onClick={handleClickShowPassword}
                      className='show_password'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='bi bi-eye-fill'
                        viewBox='0 0 16 16'>
                        <path d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z' />
                        <path d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z' />
                      </svg>
                    </span>
                    {errors.password && touched.password ? (
                      <div
                        style={{
                          color: 'red',
                          maxWidth: '363px',
                          fontSize: '12px'
                        }}>
                        {errors.password}
                      </div>
                    ) : null}
                  </div>

                  <div className='mb-3 forgot-pass-wrapper'>
                    <label htmlFor='confirm_password'>
                      Confirm New Password
                    </label>
                    <input
                      className='form-control'
                      value={values.confirm_password}
                      onChange={handleChange}
                      required
                      style={{
                        border:
                          errors.confirm_password &&
                          touched.confirm_password &&
                          '1px solid red'
                      }}
                      onBlur={handleBlur}
                      type={showPassword2 ? 'text' : 'password'}
                      id='confirm_password'
                      name='confirm_password'
                    />
                    <span
                      onClick={handleClickShowPassword2}
                      className='show_password'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='bi bi-eye-fill'
                        viewBox='0 0 16 16'>
                        <path d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z' />
                        <path d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z' />
                      </svg>
                    </span>
                    {errors.confirm_password && touched.confirm_password ? (
                      <div
                        style={{
                          color: 'red',
                          maxWidth: '363px',
                          fontSize: '12px'
                        }}>
                        {errors.confirm_password}
                      </div>
                    ) : null}
                  </div>

                  <button
                    className='my-3 btn btn-primary waves-effect waves-light'
                    color='primary'
                    type='submit'
                    disabled={isProgress}
                    onClick={handleSubmit}>
                    {!isProgress && 'Save'}
                    {isProgress && (
                      <div
                        className='spinner-border text-light'
                        role='status'>
                        <span className='sr-only'></span>
                      </div>
                    )}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <img
            className='pattern_right'
            src={PatternRight}
            alt='pattern_right'
          />
        </div>
        <img
          className='pattern_stars'
          src={PatternStars}
          alt='pattern_stars'
        />
      </React.Fragment>
    </>
  );
};

export default ResetPassword;
