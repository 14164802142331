import { useState } from 'react';
import ProfileImg from 'src/Assets/images/member01.png';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styles from '../settings.module.css';
import { useRecoilState } from 'recoil';
import userInfoState from 'src/User/recoil/Atoms/UserInforAtom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { updateUserInfo } from 'src/User/services/account';

// TODO: Improve modal styling
export default function AccountSettings() {
  const { formik, userInfo, modal } = useUpdateAccount();

  return (
    <>
      <div
        className={styles['setting-card profile']}
        id='profile-setting'>
        <div className={styles['setting-card-head']}>
          <h5>Profile</h5>
          <button
            onClick={modal.toggle}
            className='btn btn-outline-primary'>
            Edit
          </button>
        </div>
        <div className={styles['profile-inner']}>
          <div className={styles['profile-img']}>
            <img
              src={ProfileImg}
              alt=''
              className='rounded-circle'
            />
          </div>
          <div className={styles['profile-details']}>
            <h5>
              {userInfo?.first_name} {userInfo?.last_name}
            </h5>
            <p> {userInfo?.email}</p>
          </div>
        </div>
      </div>
      <Modal
        className='add-account-modal-class'
        centered
        isOpen={modal.isOpen}
        toggle={modal.toggle}>
        <ModalHeader toggle={modal.toggle}>
          <span>Modify profile </span>
        </ModalHeader>
        <hr />
        <ModalBody>
          <form
            onSubmit={formik.handleSubmit}
            className='d-flex flex-column gap-4'>
            <div className='d-flex gap-3'>
              <fieldset className='w-100 d-flex flex-column gap-2'>
                <label htmlFor='first_name'>First Name</label>
                <input
                  type='text'
                  className='form-control w-100'
                  id='first_name'
                  name='first_name'
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder='Enter your first name'
                />
              </fieldset>
              <fieldset className='w-100 d-flex flex-column gap-2'>
                <label htmlFor='last_name'>Last Name</label>
                <input
                  type='text'
                  className='form-control w-100'
                  id='last_name'
                  name='last_name'
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder='Enter your last name'
                />
              </fieldset>
            </div>
            <fieldset className='w-100 d-flex flex-column gap-2'>
              <label htmlFor='company_name'>Company Name</label>
              <input
                type='text'
                className='form-control w-100'
                id='company_name'
                name='company_name'
                value={formik.values.company_name ?? ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder='Enter your company name'
              />
            </fieldset>

            {/* <br />
              <br />
              <label htmlFor='profileImage'>Profile Image</label>
              <br />
              <input
                type='file'
                className='form-control'
                name=''
                id='profileImage'
              />
              <br /> 
            */}
          </form>
        </ModalBody>
        <ModalFooter className='d-flex justify-content-center'>
          <Button
            className='btn btn-outline-primary waves-effect waves-light'
            onClick={() => {
              formik.handleSubmit();
            }}>
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

function useUpdateAccount() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);

  const toggleModal = () => setModalIsOpen((prev) => !prev);

  const onSubmit = async (values: typeof userInfo) => {
    try {
      const { data } = await updateUserInfo(values);
      setUserInfo(data);

      Swal.fire({
        icon: 'success',
        title: 'Profile updated!',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        toast: true,
        position: 'top-right'
      });
      toggleModal();
    } catch (error: any) {
      const { response } = error;
      const { data } = response;

      Swal.fire({
        title: 'Error',
        text:
          data?.email?.[0] ??
          data?.non_field_errors?.[0] ??
          data?.company_name?.[0]
            ? `Company name: $${data?.company_name?.[0]}`
            : data.message ??
              data.details ??
              'Error while updating account info',
        icon: 'error',
        showConfirmButton: false,
        toast: true,
        timer: 6000,
        timerProgressBar: true,
        position: 'top-right'
      });
    }
  };

  const formik = useFormik({
    initialValues: userInfo,
    validationSchema: Yup.object({
      first_name: Yup.string()
        .trim()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .required('Required'),
      last_name: Yup.string()
        .trim()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .required('Required'),
      email: Yup.string().email('Invalid email').required('Required')
    }),
    onSubmit
  });

  return {
    formik,
    userInfo,
    modal: {
      toggle: toggleModal,
      isOpen: modalIsOpen
    }
  };
}
