import { ReactNode } from 'react';

const ComingSoonWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div className='tw-relative'>
      {children}
      <div className='tw-absolute tw-top-0 tw-w-full tw-h-full tw-bg-black/35 tw-rounded-xl tw-flex tw-items-center tw-justify-center tw-z-20'>
        <p className='tw-text-slate-100 tw-font-semibold tw-text-3xl'>
          Coming Soon
        </p>
      </div>
    </div>
  );
};

export default ComingSoonWrapper;
