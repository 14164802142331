import { TColumDef } from 'src/User/features/Viz/components/types';
import { DateCellRenderer } from '../../InstagramAccounts/InstagramAccountsView';
import AgeCellRenderer from '../components/AgeCellRenderer';
import { IMetaAdAccount } from 'src/lib/models/socialAccounts';
import formatCellValueToAmount from 'src/User/features/Viz/components/Table/utils/formatCellValue';

export const META_ADS_TABLE_COLS_DEFS: TColumDef<IMetaAdAccount> = [
  {
    field: 'name',
    headerName: 'Ad Account Name',
    cellRenderer: DateCellRenderer,
    autoHeight: true,
    sortable: true,
    flex: 1,
    minWidth: 250
  },
  {
    field: 'meta_name',
    headerName: 'Meta Account Name',
    cellRenderer: DateCellRenderer,
    autoHeight: true,
    sortable: true,
    flex: 0.7,
    minWidth: 230
  },
  {
    field: 'amount_spent',
    headerName: 'Amount Spent',
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    },
    valueFormatter: ({ value, data }) => {
      return formatCellValueToAmount(value, data?.currency) ?? '';
    },
    autoHeight: true,
    sortable: true,
    flex: 0.5,
    minWidth: 170
  },
  {
    field: 'age',
    headerName: 'Account Age',
    cellRenderer: AgeCellRenderer,
    autoHeight: true,
    sortable: true,
    flex: 0.35,
    minWidth: 150
  },
  {
    field: 'timezone_name',
    headerName: 'Time Zone',
    cellRenderer: DateCellRenderer,
    autoHeight: true,
    sortable: true,
    flex: 0.5,
    minWidth: 180
  }
];
