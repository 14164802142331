import { redirect, LoaderFunctionArgs } from 'react-router-dom';
import { DATE_SEARCH_PARAMS } from 'src/configs';
import { ROUTER_PATHS } from 'src/lib/constants';
import addParamsToPathname from 'src/lib/utils/addParamsToPathname';

export const indexRouteLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const searchParams = url.searchParams;
  const duration = {
    [DATE_SEARCH_PARAMS.start]:
      searchParams.get(DATE_SEARCH_PARAMS.start) ?? undefined,
    [DATE_SEARCH_PARAMS.stop]:
      searchParams.get(DATE_SEARCH_PARAMS.stop) ?? undefined
  };

  return redirect(
    addParamsToPathname(
      ROUTER_PATHS.ADS_ANALYTICS_META_ADS_CAMPAIGN_INSIGHT.OBJECTIVE_OVERVIEW,
      duration
    )
  );
};
