import { eachWeekOfInterval, format, isSameWeek } from 'date-fns';
import { IInstagramBusinessDailyInsight } from '../../../types';
import sortDataByDate from '../../../utils/sortDataByDate';
import getInsightDataWithMetricCountDifference from '../../../utils/getInsightDataWithMetricCountDifference';

const getPostCountByWeek = (data: IInstagramBusinessDailyInsight[]) => {
  const dataSortedByDate = sortDataByDate(data);
  const dataWithDailyMediaCountDifference =
    getInsightDataWithMetricCountDifference(dataSortedByDate, 'media');

  const dates = dataWithDailyMediaCountDifference.map(({ date }) => date);
  const earliestDateInData = getEarliestDate(dates);
  const latestDateInData = getLatestDate(dates);
  const weeksInData = eachWeekOfInterval({
    start: new Date(earliestDateInData),
    end: new Date(latestDateInData)
  });

  const weeklyPostCountAsDict = weeksInData?.reduce(
    function getPostCountByWeek(acc, week) {
      return {
        ...acc,
        [format(week, 'yyyy-MM-dd')]: getNumberOfPostsInWeek(
          dataWithDailyMediaCountDifference,
          week
        )
      };
    },
    {} as Record<string, number>
  );

  const weeklyPostCountAsList = Object.entries(weeklyPostCountAsDict).map(
    function convertWeeklyPostCountDictToList([date, media]) {
      return {
        date,
        media
      };
    }
  );

  return weeklyPostCountAsList;
};

const getEarliestDate = (dates: string[]) => {
  return dates.reduce(
    (acc, date) => {
      return new Date(acc) < new Date(date) ? acc : date;
    },
    format(Date.now(), 'yyyy-MM-dd')
  );
};

const getLatestDate = (dates: string[]) => {
  return dates.reduce((acc, date) => {
    return new Date(acc) > new Date(date) ? acc : date;
  }, '1970-01-01');
};

/**
 *
 * @param data List of daily insights sorted by date
 * @param week Date object representing the week
 * @returns Number of posts in the given week
 */
const getNumberOfPostsInWeek = (
  data: IInstagramBusinessDailyInsight[],
  week: Date
) => {
  return data.reduce(function getNumberOfPostForWeek(sum, { date, media }) {
    return isSameWeek(new Date(date), week) ? sum + media : sum;
  }, 0);
};

export default getPostCountByWeek;
