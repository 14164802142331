import { ICellRendererParams } from 'ag-grid-community';
import { ITableRowData } from '../models';
import { SOCIAL_ACCOUNT_ICONS } from 'src/User/features/Reporting/views/template/layout/sidebar/constant';
import { capitalize, startCase } from 'lodash';

const AccountTypeCellRenderer = (
  props: ICellRendererParams<ITableRowData, string>
) => {
  const { data } = props;
  const SocialMediaLogo =
    SOCIAL_ACCOUNT_ICONS[
      data?.accountType as keyof typeof SOCIAL_ACCOUNT_ICONS
    ] ?? (() => null);

  return (
    <div className='tw-flex tw-gap-3 tw-items-center tw-h-full py-2'>
      <SocialMediaLogo className='tw-w-6 tw-h-6' />
      <p className='tw-my-0'>{startCase(capitalize(data?.accountType))}</p>
    </div>
  );
};

export default AccountTypeCellRenderer;
