import { atom } from 'recoil';

export interface IMetaAdsAnalyticsAtom {
  adCampaign: {
    [cacheKey: string]: any;
  };
}

const metaAdsAnalyticsAtom = atom<IMetaAdsAnalyticsAtom>({
  key: 'metaAdsAnalytics',
  default: {
    adCampaign: {}
  }
});

export { metaAdsAnalyticsAtom };
