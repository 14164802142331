import { LoaderFunctionArgs } from 'react-router-dom';

const integrationLoader = ({ params }: LoaderFunctionArgs) => {
  const { integrationMediaType } = params;

  if (!integrationMediaType) {
    throw new Error('Integration media type not provided');
  }

  return null;
};

export default integrationLoader;
