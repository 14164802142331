import { isEmpty } from 'lodash';
import { IInstagramBusinessMediaInsight } from '../../../types';
import createTableData from 'src/User/features/Analytics/utils/createTableData';
import { INITIAL_POST_TABLE_SUMMARY_DATA } from '../../../constants';
import createTableSummaryData from '../../../utils/createMediaTableSummaryData';

export default function getTableData(data?: IInstagramBusinessMediaInsight[]) {
  if (isEmpty(data)) {
    return {
      data: [],
      cumulativeData: { ...INITIAL_POST_TABLE_SUMMARY_DATA }
    };
  }

  const consolidatedData = createTableData(data ?? []);
  const cumulativeData = createTableSummaryData({ data: consolidatedData });

  return { data: consolidatedData, cumulativeData };
}
