import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { getAllConnectSocialAccounts } from 'src/User/services/connected-social-accts';
import { USER_CONFIG } from 'src/configs';
import { defer } from 'react-router-dom';

const mediaTypeAdAccountLoader = async () => {
  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);

  const socialAccountsPromise = getAllConnectSocialAccounts(brandId);

  return defer({
    metaAdAccounts: socialAccountsPromise.then(
      ({ data: allSocialAccounts }) => allSocialAccounts?.data?.META_ADS ?? []
    )
  });
};

export default mediaTypeAdAccountLoader;
