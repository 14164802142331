import { TFunction } from 'i18next';
import MetaIcon from 'src/components/icons/meta';
import { TNamespace } from 'src/i18n';

const getSocialMediaTypes = (
  translate: TFunction<TNamespace, undefined, TNamespace>
) =>
  [
    {
      value: 'meta-ads',
      label: `Meta ${translate('Ads')}`,
      disabled: false,
      slug: 'fb',
      logo: MetaIcon
    },
    {
      value: 'twitter-ads',
      label: `Twitter ${translate('Ads')}`,
      disabled: true,
      slug: 'tw',
      logo: () => null
    },
    {
      value: 'google-ads',
      label: `Google ${translate('Ads')}`,
      disabled: true,
      slug: 'gg',
      logo: () => null
    },
    {
      value: 'tiktok-ads',
      label: `Tiktok ${translate('Ads')}`,
      disabled: true,
      slug: 'tk',
      logo: () => null
    }
  ] as const;

export default getSocialMediaTypes;
