import { AuditRating } from 'src/User/components/MetricsCard';
import { IInstagramBusinessInsightSummary } from '../../../types';
import { AUDIT_UI_MAP } from 'src/User/features/CompetitionAnalysis/views/Instagram/views/Audit/constants';
import i18next, { type TNamespace } from 'src/i18n';

interface ICreateAuditDataProps {
  data: IInstagramBusinessInsightSummary;
}

const createAuditData = ({ data }: ICreateAuditDataProps) => {
  const { overview } = data;

  const totals = {
    followers_growth_rate: overview.followers_growth_rate,
    daily_followers: overview.new_followers,
    engagement_rate: overview.engagement_rate,
    average_likes: overview.average_likes,
    average_comments: overview.average_comments
  };

  return Object.keys(totals).map((key) => {
    const typedKey = key as keyof typeof totals;
    const value = totals[typedKey];
    //! Hardcoded values to zero because API does not provide these values
    //! anylonger. It has been temporarily removed from the API response.
    const maxValue = '0';
    const minValue = '0';
    const avgValue = '0';
    const auditKey = AUDIT_UI_KEYS_MAP[typedKey];

    return {
      icon: AUDIT_UI_MAP[auditKey].icon,
      maxValue,
      minValue,
      avgValue,
      metricTitle: value,
      metricDescription: i18next.t(auditKey, {
        ns: 'analytics' satisfies TNamespace
      }),
      rating: calculateRating({
        value: parseFloat(value),
        minValue: parseFloat(minValue),
        avgValue: parseFloat(avgValue),
        maxValue: parseFloat(maxValue)
      }),
      value
    };
  });
};

const AUDIT_UI_KEYS_MAP = {
  followers_growth_rate: 'follower_growth',
  daily_followers: 'daily_follower',
  engagement_rate: 'engagement_rate',
  average_likes: 'avg_like',
  average_comments: 'avg_comments'
} as const;

interface ICalculateRatingProps {
  value: number;
  minValue: number;
  avgValue: number;
  maxValue: number;
}

const calculateRating = (props: ICalculateRatingProps): AuditRating => {
  const { value, minValue, avgValue, maxValue } = props;
  const lowerRange = avgValue - minValue;
  const upperRange = maxValue - avgValue;
  const lowerRangeNormalizedValue = (value - minValue) / lowerRange;
  const upperRangeNormalizedValue = (value - avgValue) / upperRange;

  if (lowerRangeNormalizedValue > 0.8 && upperRangeNormalizedValue <= 0.25)
    return 'C';

  if (value <= avgValue) {
    return getRating({
      matrix: LOWER_RANGE_RATING_MATRIX,
      normalizedValue: lowerRangeNormalizedValue,
      defaultRating: 'C'
    });
  }

  return getRating({
    matrix: UPPER_RANGE_RATING_MATRIX,
    normalizedValue: upperRangeNormalizedValue,
    defaultRating: 'A+'
  });
};

interface IGetRatingProps {
  matrix: Record<number, AuditRating>;
  normalizedValue: number;
  defaultRating: AuditRating;
}

function getRating(props: IGetRatingProps) {
  const { matrix, normalizedValue, defaultRating } = props;

  const sortedMatrixRangeBoundaries = Object.keys(matrix).sort(
    (a, b) => parseFloat(a) - parseFloat(b)
  );
  const matrixRangeBoundaryMatch = sortedMatrixRangeBoundaries.find(
    (rangeUpperBound) => normalizedValue <= parseFloat(rangeUpperBound)
  );

  if (matrixRangeBoundaryMatch)
    return matrix[parseFloat(matrixRangeBoundaryMatch)];

  return defaultRating;
}

const LOWER_RANGE_RATING_MATRIX = {
  0.4: 'E',
  0.8: 'D',
  1: 'C'
} as const;

const UPPER_RANGE_RATING_MATRIX = {
  0.25: 'C',
  0.5: 'B',
  0.75: 'B+',
  0.9: 'A',
  1: 'A+'
} as const;

export default createAuditData;
