import { isEmpty } from 'lodash';
import {
  IFacebookPageAccount,
  IInstagramAccount
} from 'src/lib/models/socialAccounts';
import format from 'date-fns/format';

export function getInstagramTableData(data: IInstagramAccount[] | null) {
  if (isEmpty(data)) return [];

  return data!.map((account) => ({
    accountInfo: {
      avatar: account.profile_picture_url,
      name: account.username,
      username: ''
    },
    createdAt: format(new Date(account.created_at), 'dd MMM yyyy'),
    updatedAt: account?.updated_at
      ? format(new Date(account?.updated_at), 'dd MMM yyyy')
      : 'N/A',
    followerCount: account.followers,
    followingCount: account.following,
    mediaCount: account.media_count,
    igBusinessAccountId: account.ig_business_account_id
  }));
}

export function getFacebookPageTableData(data: IFacebookPageAccount[] | null) {
  if (isEmpty(data)) return [];

  return data!.map((account) => ({
    accountInfo: {
      avatar: account.profile_pic,
      name: account.name,
      username: ''
    },
    createdAt: format(new Date(account.created_at), 'dd MMM yyyy'),
    updatedAt: account.updated_at
      ? format(new Date(account.updated_at), 'dd MMM yyyy')
      : 'N/A',
    fanCount: account.fan_count,
    facebookPageId: account.facebook_page_id,
    category: account.category
  }));
}
