import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SOMateLogo from 'src/Assets/images/somate-logo-light.svg';
import classNames from 'classnames';
import { IOauthSelectItem, TModalItem } from './types';
import { Outlet, useNavigate } from 'react-router-dom';

const items: IOauthSelectItem[] = [
  {
    title: 'Facebook Page',
    icon: (
      <div>
        <i className='bi bi-flag-fill modal-icon'></i>
      </div>
    ),
    href: 'facebook-page'
  },
  {
    title: 'Instagram Business',
    icon: (
      <div>
        <i className='bi bi-instagram modal-icon'></i>
      </div>
    ),
    href: 'ig-business'
  },
  {
    title: 'Meta Ads',
    icon: (
      <div>
        <img
          className='tw-w-9 tw-h-9'
          src='/icon/meta-icon.svg'
          alt='meta icon'
        />
      </div>
    ),
    href: 'meta-ads'
  },
  {
    title: 'LinkedIn Page',
    icon: (
      <div>
        <i className='bi bi-linkedin modal-icon'></i>
      </div>
    ),
    disabled: true
  },
  {
    title: 'LinkedIn Ads',
    icon: (
      <div>
        <i className='bi bi-linkedin modal-icon'></i>
      </div>
    ),
    disabled: true
  },
  {
    title: 'Google Analytics',
    icon: (
      <div>
        <i className='bi bi-google modal-icon'></i>
      </div>
    )
  },
  {
    title: 'Comming Soon',
    icon: (
      <div>
        <i className='bi bi-twitter modal-icon'></i>
      </div>
    ),
    disabled: true
  },
  {
    title: 'Comming Soon',
    icon: (
      <div>
        <i className='bi bi-youtube modal-icon'></i>
      </div>
    ),
    disabled: true
  },
  {
    title: 'Comming Soon',
    icon: (
      <div>
        <i className='bi bi-tiktok modal-icon'></i>
      </div>
    ),
    disabled: true
  }
];

export default function AddSocialModal() {
  const navigate = useNavigate();

  return (
    <Modal
      className='add-social-acct-modal'
      centered
      isOpen={true}
      toggle={() => navigate(-1)}>
      <ModalHeader
        toggle={() => navigate(-1)}
        close={<div></div>}>
        <div className='add-social-acct-header-content'>
          <div className='new_user-logo_wrapper'>
            <img
              className='somate_logo'
              src={SOMateLogo}
              alt='SOMateLogo'
            />
          </div>
          <div className='add-social-acct-modal-header-text-container'>
            <div>
              <h2 className='modal-body-title'>Add your social account</h2>
              <h3 className='modal-body-subtitle'>
                Connect your instagram, facebook, google analytics accounts
              </h3>
            </div>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className='socials-modal-body'>
          {items.map((item, index) => {
            return (
              <ModalItem
                key={index}
                title={item.title}
                icon={item.icon}
                action={() => navigate(item?.href ?? '')}
                disabled={item?.disabled ?? !item?.href}
              />
            );
          })}
          <Outlet />
        </div>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
}

export const ModalItem = (props: TModalItem) => {
  const { title, icon, action, disabled } = props;
  const Icon = () => icon;

  return (
    <div
      className={classNames('add-social-acct-modal-item', {
        'add-social-acct-modal-item-disabled': disabled
      })}
      onClick={action}>
      <div className='icon'>
        <Icon />
      </div>
      <p className='add-social-acct-modal-item-icon-name'>{title}</p>
    </div>
  );
};
