import React from 'react';
import './CampaignsAnalytics.css';
import { Table } from 'src/User/features/Viz/components/Table';
import { Chart } from 'src/User/features/Viz/components/Chart';
import Card from '../../../../components/Card';
import { CAMPAIGN_TABLE_COLS } from './constants';
import useGetCampaignInsights from './hooks/useGetCampaignInsights';
import getChartOptions from '../../utils/getChartOptions';
import { IChartHeaderFilterOption } from 'src/User/features/Viz/components/types';
import { TChartMetrics } from '../../types';

const CampaignsOverview = () => {
  const {
    aggregatedCampaignTableData,
    campaingCharts,
    loading,
    toggleChartSeries
  } = useGetCampaignInsights();

  return (
    <div className='ad-analytics tw-flex tw-flex-col tw-flex-grow tw-gap-6 tw-mt-4'>
      <Card
        title='Campaigns'
        className='tw-h-full tw-min-h-[450px] tw-flex tw-flex-col tw-flex-grow'
        cardContentProps={{
          className: 'tw-flex tw-flex-col tw-flex-grow !tw-pl-6'
        }}>
        <Table
          isLoading={loading}
          height={'100%'}
          columnsDefs={CAMPAIGN_TABLE_COLS}
          data={aggregatedCampaignTableData}
        />
      </Card>
      {campaingCharts.map(
        ({ adsetCount, campaignId, campaignName, chartData }) => (
          <Card
            key={campaignId}
            title={
              <>
                {campaignName}{' '}
                <span className='tw-text-base tw-text-slate-600'>
                  ({adsetCount} Adsets)
                </span>
              </>
            }>
            <Chart
              data={{
                options: getChartOptions(campaignId, chartData.dates),
                series: chartData.seriesData?.filter(
                  ({ showSeries }) => showSeries
                )
              }}
              header={{
                title: '',
                filtersOptions: chartData.seriesData.reduce(
                  (acc, { name, total, showSeries }) => {
                    acc[name] = {
                      value: total,
                      isActive: showSeries
                    };

                    return acc;
                  },
                  {} as IChartHeaderFilterOption
                ),
                onFilterOptionClick: (seriesName) => {
                  toggleChartSeries(campaignId, seriesName as TChartMetrics);
                }
              }}
              type='area'
            />
          </Card>
        )
      )}
    </div>
  );
};

export default CampaignsOverview;
