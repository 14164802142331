import '../styles/SocialAccounts.css';
import { Outlet, NavLink, useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import optionsWizardStyles from 'src/User/components/OptionWizard/index.module.css';
import { Button } from 'src/components/ui/button';
import { CircleFadingPlus } from 'lucide-react';

const SIDEBAR_LINKS = [
  {
    label: 'All Accounts',
    href: '/socials/main'
  },
  {
    label: 'Instagram Business',
    href: '/socials/ig-business'
  },
  {
    label: 'Facebook Page',
    href: '/socials/facebook-page'
  },
  {
    label: 'Meta Ads',
    href: '/socials/meta-ads'
  }
  // {
  //   label: 'LinkedIn Page',
  //   href: '/socials/linkedin-page'
  // },
  // {
  //   label: 'LinkedIn Ads',
  //   href: '/socials/linkedin-ads'
  // }
];

const SocialAccountsLayout = () => {
  return (
    <div className='card-style tw-flex tw-flex-col tw-h-full'>
      <Header />
      <div
        className={classNames(
          optionsWizardStyles['settings-inner'],
          'tw-flex-grow'
        )}>
        <SideBar />
        <Outlet />
      </div>
    </div>
  );
};

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className='tw-flex tw-justify-between tw-items-center tw-mb-8'>
      <div className='tw-flex tw-flex-col tw-gap-1'>
        <h2 className='tw-mb-0'>Social Accounts</h2>
        <p className='tw-mb-0 tw-text-slate-600'>
          Overview of all the socials media accounts managed by SOMate
        </p>
      </div>
      <Button
        size={'lg'}
        onClick={() => navigate(`${pathname}/integrations/select-media-type`)}>
        Add Social Account
        <CircleFadingPlus className='tw-w-5 tw-h-5 tw-ml-2' />
      </Button>
    </div>
  );
};

const SideBar = () => (
  <div className={optionsWizardStyles['settings-menu']}>
    {SIDEBAR_LINKS.map(({ href, label }) => (
      <NavLink
        to={href}
        key={href}
        className={({ isActive }) =>
          classNames(
            optionsWizardStyles['settings-menu-button'],
            'tw-no-underline',
            {
              [optionsWizardStyles['active']]: isActive
            }
          )
        }>
        {label}
      </NavLink>
    ))}
  </div>
);

export default SocialAccountsLayout;
