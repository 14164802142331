import getChartOptions from 'src/User/features/CompetitionAnalysis/views/Instagram/components/MetricsView/utils/getChartOptions';
import { Chart } from 'src/User/features/Viz/components/Chart';
import {
  generateMockFacebookPageDailyStoryInsight,
  getSum
} from './utils/mock-data-generators';
import { PostAnalyticsTableView } from '../../../InstagramAnalytics/views/Posts/components/PostAnalyticsTable';
import createTableData from 'src/User/features/Analytics/utils/createTableData';
import { pick, startCase } from 'lodash';
import PostPreviewCellRenderer from '../../../InstagramAnalytics/views/Posts/components/PostPreviewCellRenderer';
import { useLoaderData } from 'react-router-dom';
import facebookPageStroyAnalyticsLoader from './loader/facebookPageStoryAnalyticsLoader';
import { PromiseType } from 'src/lib/models';
import { useTranslation } from 'react-i18next';
import { type TNamespace } from 'src/i18n';

const Stories = () => {
  const dialyInsight = useLoaderData() as PromiseType<
    ReturnType<typeof facebookPageStroyAnalyticsLoader>
  >;
  const { t } = useTranslation<TNamespace>();

  const { story_count: storiesInsights } = pick(dialyInsight, ['story_count']);
  const storiesLifeTimeInsights = generateMockFacebookPageDailyStoryInsight(15);
  const tableFields = ['media_preview_details', 'date', 'type'] as const;

  return (
    <div className='tw-flex tw-flex-col tw-gap-6'>
      <div className='tw-rounded-xl tw-shadow-lg p-3 bg-white'>
        <Chart
          header={{
            title: t('stories') ?? '',
            filtersOptions: {
              total_stories: Number(Number(getSum(storiesInsights)).toFixed(0))
            }
          }}
          data={{
            options: getChartOptions(storiesInsights?.map(({ date }) => date)),
            series: [
              {
                name: t('stories') ?? '',
                data: storiesInsights?.map(({ value }) => Number(value))
              }
            ]
          }}
          type='area'
        />
      </div>
      <PostAnalyticsTableView
        cumulativeData={{}}
        tableTitle={t('list-of-stories', { ns: 'analytics' }) ?? ''}
        tableData={createTableData(storiesLifeTimeInsights)}
        tableColumnsDefs={tableFields.map((key) => ({
          field: key,
          headerName: startCase(
            t(key, {
              ns: 'analytics'
            }) ?? ''
          ),
          minWidth:
            key === 'media_preview_details' ? 400 : key.length > 10 ? 200 : 150,
          sortable: true,
          autoHeight: true,
          flex: 1,
          cellRenderer:
            key === 'media_preview_details'
              ? PostPreviewCellRenderer
              : undefined
        }))}
      />
    </div>
  );
};

export default Stories;
