// Hooks Imports
import { useRecoilValue } from 'recoil';
import userSubscriptionPlanAtom from 'src/User/recoil/Atoms/UserSubscriptionAtom';

// Utils Imports
import { getFeaturesList } from 'src/User/auth/CompleteFlow/utils/getSubscriptionData';
import { format } from 'date-fns';

// Shadcn Imports
import {
  Card,
  CardHeader,
  CardContent,
  CardDescription,
  CardTitle
} from 'src/components/ui/card';

// Styles Imports
import styles from './index.module.css';

// Icon Imports
import { CircleCheck } from 'lucide-react';

const UserSubscriptionCard = () => {
  const userSubscriptionPlan = useRecoilValue(userSubscriptionPlanAtom);
  const userSubscriptionFeaturesList = getFeaturesList(userSubscriptionPlan);

  return (
    <Card className={`tw-w-full tw-h-fit ${styles['card-bg-img']}`}>
      <CardHeader>
        <CardDescription className='tw-mb-1 !tw-text-base'>
          Current Plan
        </CardDescription>
        <CardTitle className='tw-mt-0 tw-text-4xl'>
          {userSubscriptionPlan?.plan_name ?? 'N/A'}
        </CardTitle>
      </CardHeader>
      <CardContent className=''>
        <div className='tw-mb-5'>
          <p className='tw-mb-1 '>Next Billing Date</p>
          <p className='tw-font-light'>
            {userSubscriptionPlan?.expire_date
              ? format(
                  new Date(userSubscriptionPlan?.expire_date),
                  'MMM dd, yyyy'
                )
              : 'N/A'}
          </p>
        </div>

        <div>
          <p className='tw-mb-1 '>What&apos;s Included</p>
          <ul className='tw-p-0 tw-flex tw-flex-col tw-gap-2 tw-font-light'>
            {userSubscriptionFeaturesList?.map((feature, index) => (
              <li
                key={index}
                className='tw-m-0 tw-flex tw-gap-2 '>
                <CircleCheck
                  size={18}
                  className='tw-fill-primary-beige-light tw-text-primary'
                />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
        </div>
      </CardContent>
    </Card>
  );
};

export default UserSubscriptionCard;
