import { FormGroup, Label } from 'reactstrap';
import indexStyles from './index.module.css';
import classNames from 'classnames';
import {
  Link,
  useLoaderData,
  useNavigate,
  useNavigation,
  useSubmit
} from 'react-router-dom';
import SOmateLogoBg from 'src/Assets/images/somate-logo.svg';
import { Table } from '../../../Viz/components/Table';
import {
  REPORT_HISTORY_TABLE_COLS,
  REPORT_LANGUAGE_OPTIONS
} from './constants';
import { ReactNode, useState } from 'react';
import { IReportsTableRowSchema } from 'src/User/services/reporting/models';
import formatDate from './utils/formatDate';
import { IReportTemplate } from '../../types';
import { isEmpty } from 'lodash';
import { useBrand } from 'src/User/layout/Sidebar/components/BrandSelect';
import { DateRange } from 'react-day-picker';
import { format, subMonths } from 'date-fns';
import TooltipButton from 'src/User/components/TooltipButton';
import CustomSelect from 'src/User/components/CustomSelect';
import {
  PencilLine,
  BookOpen,
  Images,
  OctagonX,
  LoaderCircle
} from 'lucide-react';
import { Button, buttonVariants } from 'src/components/ui/button';
import { DatePickerWithRange } from 'src/components/ui/datePickerWithRange';
import { TReportType } from '../../actions/model';

interface IGetTooltipTextProps {
  dateRange?: DateRange;
  activeTemplateId?: string;
}

const getTooltipText = (props: IGetTooltipTextProps) => {
  const { dateRange, activeTemplateId } = props;

  if (!activeTemplateId)
    return 'You must select a template in order to generate a report';

  if (!dateRange?.from || !dateRange?.to)
    return 'You must select a date range in order to generate a report';
};

export default function RootReportingPage() {
  const [activeTemplateId, setActiveTemplateId] = useState<string>();
  const [date, setDate] = useState<DateRange | undefined>({
    from: subMonths(new Date(), 1),
    to: new Date()
  });
  const [language, setLanguage] = useState<'en' | 'pl'>('pl');
  const [reportType, setReportType] = useState<TReportType>();

  const { reportsHistory, reportTemplates } = useLoaderData() as {
    reportsHistory: IReportsTableRowSchema[];
    reportTemplates: IReportTemplate[];
  };
  const { getCurrentBrand } = useBrand();
  const { id: brandId } = getCurrentBrand();
  const navigation = useNavigation();
  const navigate = useNavigate();

  const reportsTableData = reportsHistory?.map((item) => {
    const type = item?.pdf_name ? 'PDF' : 'PPT';
    const duration = `${formatDate(item.start_date)} - ${formatDate(
      item.end_date
    )}`;

    return { ...item, duration, type };
  });
  const disableActionButton =
    !date?.from ||
    !date?.to ||
    !activeTemplateId ||
    navigation.state == 'submitting';

  return (
    <div>
      <div className='d-flex flex-column gap-5'>
        <div
          className={classNames(
            'd-flex gap-5',
            indexStyles['template-container-bg']
          )}>
          <form
            className='d-flex flex-column gap-2'
            onSubmit={(e) => e.preventDefault()}>
            <h4 className={'tw-text-xl tw-mb-0'}>Duration</h4>
            <fieldset className='position-relative'>
              <DatePickerWithRange
                date={date}
                onDateChange={(dateRange) => setDate(dateRange)}
              />
            </fieldset>
          </form>
          <div className='tw-h-full'>
            <FormGroup className='d-flex flex-column'>
              <h4 className={'tw-text-xl tw-mb-0'}>
                <Label
                  for='language_select'
                  className='tw-my-0'>
                  Language
                </Label>
              </h4>
              <CustomSelect
                defaultValue={REPORT_LANGUAGE_OPTIONS[0].value}
                options={REPORT_LANGUAGE_OPTIONS}
                trigger={{
                  className:
                    'tw-min-w-[250px] tw-h-full !tw-rounded-lg !tw-text-sm'
                }}
                value={language}
                onChange={(value) => {
                  if (value == 'en' || value == 'pl') setLanguage(value);
                }}
              />
            </FormGroup>
          </div>
        </div>
        <div>
          <div className={indexStyles['template-container-bg']}>
            <div
              className={classNames(
                'mb-4',
                'tw-flex tw-justify-between tw-items-center'
              )}>
              <h4 className={'tw-text-2xl tw-mb-0'}>
                Generate report from an existing template
              </h4>
              <Button
                variant={'outline'}
                className='tw-flex tw-items-center tw-gap-2 tw-border-primary tw-text-primary tw-capitalize'
                onClick={() => navigate('template/new')}>
                <span>Create template</span>
                <PencilLine className='tw-w-4 tw-h-4' />
              </Button>
            </div>
            <div className={indexStyles['template-container']}>
              {isEmpty(reportTemplates) ? (
                <p className='tw-text-primary-black h-100 tw-min-h-10'>
                  {' '}
                  <i className='bi bi-exclamation-circle tw-pr-1 tw-text-base tw-text-gray-500'></i>{' '}
                  No templates found
                </p>
              ) : (
                reportTemplates.map(
                  ({ name: templateName, id: templateId, logo }, index) => (
                    <TemplateBox
                      key={templateId}
                      templateId={templateId}
                      templateLogo={logo ?? ''}
                      templateTitle={templateName}
                      isActive={templateId == activeTemplateId}
                      onClick={() => setActiveTemplateId(templateId)}
                    />
                  )
                )
              )}
            </div>
            <div className='d-flex  justify-content-between mt-5 w-100'>
              <div className='d-flex gap-5'>
                <GenerateReportButton
                  brandId={brandId}
                  activeTemplateId={activeTemplateId ?? ''}
                  date={date!} // disableActionButton already ensures date is defined
                  language={language}
                  reportType='pdf'
                  disableActionButton={disableActionButton}
                  label='Generate PDF'
                  icon={<BookOpen className='tw-w-4 tw-h-4 tw-text-inherit' />}
                  isLoading={
                    navigation.state == 'submitting' && reportType == 'pdf'
                  }
                  onClick={() => setReportType('pdf')}
                />
                <GenerateReportButton
                  brandId={brandId}
                  activeTemplateId={activeTemplateId ?? ''}
                  date={date!} // disableActionButton already ensures date is defined
                  language={language}
                  reportType='pptx'
                  disableActionButton={disableActionButton}
                  label='Generate PPT'
                  icon={<Images className='tw-w-4 tw-h-4 tw-text-inherit' />}
                  isLoading={
                    navigation.state == 'submitting' && reportType == 'pptx'
                  }
                  onClick={() => setReportType('pptx')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={indexStyles['template-container-bg']}>
          <h4 className={'tw-text-2xl tw-mb-5'}>Report History</h4>
          <Table
            isLoading={false}
            data={reportsTableData}
            columnsDefs={REPORT_HISTORY_TABLE_COLS}
            height={600}
          />
        </div>
      </div>
    </div>
  );
}

interface IGenerateReportButton {
  date: DateRange;
  activeTemplateId: string;
  disableActionButton: boolean;
  brandId: string;
  reportType: TReportType;
  language: string;
  icon: ReactNode;
  label: string;
  isLoading?: boolean;
  onClick?: () => void;
}

const GenerateReportButton = (props: IGenerateReportButton) => {
  const {
    activeTemplateId,
    brandId,
    date,
    disableActionButton,
    reportType,
    language,
    icon,
    label,
    isLoading,
    onClick
  } = props;

  const submit = useSubmit();

  return (
    <TooltipButton
      tooltipContent={getTooltipText({
        dateRange: date,
        activeTemplateId
      })}
      className={classNames(
        buttonVariants({
          variant: 'default',
          size: 'default',
          className: {
            'tw-flex tw-gap-2 tw-items-center': true,
            'tw-opacity-50 !tw-cursor-not-allowed': disableActionButton
          }
        })
      )}
      onClick={() => {
        onClick?.();

        if (!disableActionButton)
          submit(
            {
              brandId,
              templateId: activeTemplateId,
              startDate: format(date.from as Date, 'yyyy-MM-dd'),
              endDate: format(date.to as Date, 'yyyy-MM-dd'),
              language,
              reportType
            },
            { method: 'POST' }
          );
      }}>
      {label}
      {isLoading ? (
        <LoaderCircle className='tw-w-4 tw-h-4 tw-text-inherit tw-animate-spin' />
      ) : (
        icon
      )}
    </TooltipButton>
  );
};

function TemplateBox({
  templateLogo,
  templateTitle,
  templateId,
  isActive,
  onClick
}: {
  templateLogo: string;
  templateTitle: string;
  templateId: string;
  isActive?: boolean;
  onClick: () => void;
}) {
  const submit = useSubmit();

  return (
    <div
      className={classNames('shadow', indexStyles['template-box'], {
        '!tw-border !tw-border-primary': isActive
      })}
      onClick={onClick}>
      <div
        className={classNames(
          'bg-white',
          indexStyles['template-img-container']
        )}>
        <img
          src={templateLogo}
          alt='template logo'
          onError={(e) => {
            e.currentTarget.src = SOmateLogoBg;
          }}
        />
      </div>
      <div
        className={classNames(
          'p-3 d-flex flex-column gap-3 bg-white',
          indexStyles['text']
        )}>
        <p className={classNames('m-0', indexStyles['template-name'])}>
          {templateTitle}
        </p>
        <div className='d-flex gap-3 w-100'>
          <Link
            to={`template/${templateId}`}
            className={buttonVariants({
              variant: 'secondary',
              size: 'default',
              className:
                'tw-flex tw-gap-2 tw-items-center tw-no-underline tw-border hover:tw-border-primary hover:tw-text-primary '
            })}>
            <Button asChild>
              <>
                <span> Edit</span>
                <PencilLine className='tw-w-4 tw-h-4' />
              </>
            </Button>
          </Link>
          <Button
            variant={'outline'}
            className='tw-flex tw-gap-2 tw-items-center tw-no-underline hover:tw-bg-transparent hover:tw-text-primary hover:tw-border-primary'
            onClick={() => {
              submit(
                {
                  templateId
                },
                { method: 'DELETE' }
              );
            }}>
            Delete
            <OctagonX className='tw-w-4 tw-h-4' />
          </Button>
        </div>
      </div>
    </div>
  );
}
