import { atom } from 'recoil';

export type TPlanNames = string;

export interface IUserSubscriptionPlan {
  id: string;
  plan_name: TPlanNames;
  max_brands: number;
  max_users: number;
  max_competitors: number;
  max_socials: number;
  max_ai: number;
  membership_since: string;
  state: TSubscriptionState;
  expire_date: string | null;
  trial_start_date: string;
  trial_end_date: string;
  has_used_trial: true;
}

type TSubscriptionState = 'TRIAL' | 'RESTRICTED' | 'CANCELLED' | 'PAID';

const userSubscriptionPlan = atom<IUserSubscriptionPlan | null>({
  key: 'userSubscriptionPlan',
  default: null
});

export default userSubscriptionPlan;
