import { Suspense } from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { Chart } from 'src/User/features/Viz/components/Chart';
import { AudienceProgressBarAnalyticsView } from '../../../InstagramAnalytics/views/Audience';
import { IFacebookPageAudienceInsight } from './types';
import useAudienceData from './hooks/useAudienceData';
import ErrorRenderer from 'src/User/components/Error/AnalyticsErrorRenderer';
import MetricView from 'src/User/features/CompetitionAnalysis/views/Instagram/components/MetricsView';
import { CHART_COLORS } from 'src/User/features/Viz/components/Chart/constants/colors';
import { useTranslation } from 'react-i18next';
import { startCase } from 'lodash';
import { type TNamespace } from 'src/i18n';

const AudienceContainer = () => {
  const { facebookPageAudienceInsight } = useLoaderData() as any;
  const { t } = useTranslation<TNamespace>();

  return (
    <Suspense fallback={<MetricView.LoadingChart chartGridFlow='column' />}>
      <Await
        resolve={facebookPageAudienceInsight}
        errorElement={
          <ErrorRenderer
            title={t('error.title', {
              ns: 'analytics',
              accountType: 'facebook page',
              feature: 'audience'
            })}
            fallbackErrorMsg={t('error.description', {
              ns: 'analytics'
            })}
            hideConnectAccount
          />
        }>
        {(response) => <Audience {...response} />}
      </Await>
    </Suspense>
  );
};

const Audience = (props: IFacebookPageAudienceInsight) => {
  const {
    impressionsByAgeChartConfigOptions,
    impressionsByAgeChartSeriesData,
    totalImpressionsByAge,
    impressionsByGenderChartConfigOptions,
    impressionsByGenderChartSeriesData,
    totalImpressionsByGender,
    pageFans
  } = useAudienceData(props);
  const { t } = useTranslation<TNamespace>();

  return (
    <div className='tw-flex tw-flex-col tw-gap-6'>
      <div className='tw-rounded-xl tw-shadow-lg p-3 bg-white'>
        <Chart
          header={{
            title: t('gender-impressions', { ns: 'analytics' }) ?? '',
            filtersOptions: totalImpressionsByGender
          }}
          data={{
            options: impressionsByGenderChartConfigOptions,
            series: impressionsByGenderChartSeriesData
          }}
          type='bar'
        />
      </div>
      <div className='tw-rounded-xl tw-shadow-lg p-3 bg-white'>
        <Chart
          header={{
            title: t('age-impressions', { ns: 'analytics' }) ?? '',
            filtersOptions: totalImpressionsByAge,
            preventDefaultFilterOptionsFormatter: true
          }}
          data={{
            options: impressionsByAgeChartConfigOptions,
            series: impressionsByAgeChartSeriesData
          }}
          type='bar'
        />
      </div>
      <div className='tw-grid tw-grid-cols-2 tw-gap-6'>
        <div className='tw-rounded-xl tw-shadow-lg p-3 bg-white'>
          <AudienceProgressBarAnalyticsView
            title={startCase(t('fans-country', { ns: 'analytics' }) ?? '')}
            data={pageFans}
            appendRecordValueToLabel
            getBarColor={(key, value, dataIndex) =>
              CHART_COLORS[dataIndex % CHART_COLORS.length]
            }
          />
        </div>
        <div className='tw-rounded-xl tw-shadow-lg p-3 bg-white'>
          <Chart
            header={{
              title: t('fans-country', { ns: 'analytics' }) ?? '',
              filtersOptions: {}
            }}
            data={{
              options: {
                labels: Object.keys(pageFans),
                legend: {
                  show: true
                }
              },
              series: Object.values(pageFans)
            }}
            type='pie'
          />
        </div>
      </div>
    </div>
  );
};

export default AudienceContainer;
