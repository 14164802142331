import { LoaderFunctionArgs, defer } from 'react-router-dom';
import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { getFacebookPageLifetimeMediaAnalytics } from 'src/User/services/analytics/facebookPageAnalyticsService';
import { USER_CONFIG } from 'src/configs';

const facebookPageMediaAnalyticsLoader = (props: LoaderFunctionArgs) => {
  const { params } = props;

  const activeBrandJSON = localStorage.getItem(USER_CONFIG.storageUserBrand);
  const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);

  const facebookPageLifetimeMediaInsightResponse =
    getFacebookPageLifetimeMediaAnalytics({
      brandId,
      facebookPageId: params.id ?? ''
    });

  return defer({
    facebookPageLifetimeMediaInsight:
      facebookPageLifetimeMediaInsightResponse.then(
        ({ data: response }) => response.data
      )
  });
};

export default facebookPageMediaAnalyticsLoader;
