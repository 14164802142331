export const ERROR_IMG =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2.5em' height='2.5em' viewBox='0 0 32 32'%3E%3Cpath fill='%23000' d='M30 3.414L28.586 2L2 28.586L3.414 30l2-2H26a2.003 2.003 0 0 0 2-2V5.414zM26 26H7.414l7.793-7.793l2.379 2.379a2 2 0 0 0 2.828 0L22 19l4 3.997zm0-5.832l-2.586-2.586a2 2 0 0 0-2.828 0L19 19.168l-2.377-2.377L26 7.414zM6 22v-3l5-4.997l1.373 1.374l1.416-1.416l-1.375-1.375a2 2 0 0 0-2.828 0L6 16.172V6h16V4H6a2.002 2.002 0 0 0-2 2v16z'/%3E%3C/svg%3E";

export const REDIRECT_URL_SEARCH_PARAMS_Key = 'returnUrl';

export const ROUTER_PATHS_ID = {
  USER_SUBSCRIPTION_VALIDATION_LAYOUT: 'user-subscription-validation-layout',
  SUBSCRIPTION_PLANS_PAGE: 'subscription-plan-page',
  ANALYTICS_LAYOUT: 'analytics-layout',
  ADS_ANALYTICS_MEDIA_TYPE_LAYOUT: 'ads-analytics-media-type-layout',
  IG_BUSINESS_SOCIAL_ACCOUNTS: 'ig-business-social-accounts',
  IG_BUSINESS_ANALYTICS_LAYOUT: 'ig-business-analytics',
  FB_PAGE_ANALYTICS_LAYOUT: 'fb-page-social-accounts',
  REPORTING_TEMPLATE_LAYOUT: 'reporting-template-layout'
};

export const ROUTER_PATHS = {
  ROOT: '/',
  LOGIN: '/login',
  SUBSCRIPTION_PLANS: '/flow-plan',
  DASHBOARD: '/dashboard',
  SOMATE_AI_LAYOUT: '/somate-ai',
  SOMATE_AI_NEW_CONVERSATION: 'new',
  SOMATE_AI_AGENT_LAYOUT: 'agents',
  SOMATE_AI_NEW_AGENT_CONVERSATION: ':somateAiAgentId',
  MAINTEANCE: '/maintenance',
  RESTRICED_ACCESS: '/restricted-access',
  ANALYTICS_ROOT_LAYOUT: '/analytics',
  ANALYTICS_INSTAGRAM_PAGE_LAYOUT: 'instagram-analytics',
  ANALYTICS_INSTAGRAM_PAGE: ':id',
  ANALYTICS_FACEBOOK_PAGE_LAYOUT: 'facebook-analytics',
  ANALYTICS_FACEBOOK_PAGE: ':id',
  ADS_ANALYTICS_LAYOUT: '/ads-analytics',
  ADS_ANALYTICS_MEDIA_TYPE_LAYOUT: ':mediaType',
  ADS_ANALYTICS_META_ADS_LAYOUT: ':adAccountId',
  ADS_ANALYTICS_MEDTA_ADS_SUMMARY: 'summary',
  ADS_ANALYTICS_META_ADS_CAMPAIGN_INSIGHT: {
    LAYOUT: 'campaign-insights',
    OBJECTIVE_OVERVIEW: 'objective-overview',
    CAMPAIGNS_OVERVIEW: 'campaigns-overview',
    ADSET_OVERVIEW: 'adsets-overview',
    ADS_OVERVIEW: 'ads-overview'
  },
  ADS_ANALYTICS_META_ADS_CREATIVE: 'ad-creative-insights'
} as const;

export const SUBSCRIPTION_SUCCESS_REDIRECT_TIMEOUT = 10000;
