import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import {
  OAUTH_FLOW_STATUS,
  OAUTH_FLOW_STATUS_QUERY_KEY
} from '../pages/SelectOauthSocialMediaType/views/Integrations/constants';
import { REDIRECT_URL_SEARCH_PARAMS_Key } from 'src/lib/constants';
import { omit } from 'lodash';

const oauthConnectRedirectLoader = (props: LoaderFunctionArgs) => {
  const { request } = props;
  const currentURL = new URL(request.url);
  const currentURLPathname = currentURL.pathname;
  const currentURLSearchParams = currentURL.searchParams;
  const hasMetaOauthRedirectParams = currentURLSearchParams.has('state');

  if (hasMetaOauthRedirectParams) {
    const oauthStateQuery = JSON.parse(
      currentURLSearchParams.get('state') ?? '{}'
    );

    if (
      oauthStateQuery?.[OAUTH_FLOW_STATUS_QUERY_KEY] ===
      OAUTH_FLOW_STATUS.PENDING
    ) {
      currentURLSearchParams.set(
        'state',
        JSON.stringify(omit(oauthStateQuery, OAUTH_FLOW_STATUS_QUERY_KEY))
      );
      const newSearchParams = currentURLSearchParams.toString();

      return redirect(
        `/socials/connecting/?${REDIRECT_URL_SEARCH_PARAMS_Key}=${currentURLPathname}${
          !!newSearchParams ? '&' + newSearchParams : ''
        }`
      );
    }
  }

  return null;
};

export default oauthConnectRedirectLoader;
