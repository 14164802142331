import classNames from 'classnames';
import Stepper, { IStep } from 'src/User/components/Stepper';

interface ILoadingConversationProps {
  className?: string;
  title: string;
  steps: IStep[];
}

const LoadingConversation = (props: ILoadingConversationProps) => {
  const { title, className, steps } = props;

  return (
    <div
      className={classNames(
        'tw-rounded-xl tw-px-5 tw-py-3 tw-border tw-w-full tw-mb-2',
        className
      )}>
      <h5 className='tw-border-b tw-pb-2 tw-font-semibold tw-text-xl'>
        <span>{title}</span>
      </h5>
      <Stepper steps={steps} />
    </div>
  );
};

export default LoadingConversation;
