import { ApexOptions } from 'apexcharts';
import { format } from 'date-fns';

const getChartOptions = (chartId: string, xAxisDates: string[]) => {
  return {
    chart: {
      id: chartId
    },
    xaxis: {
      type: 'datetime',
      categories: xAxisDates,
      labels: {
        formatter: (value: string) => format(new Date(value), 'dd/MM/yyyy')
      }
    },
    legend: {
      show: true,
      position: 'bottom'
    }
  } satisfies ApexOptions;
};

export default getChartOptions;
