// ** React Router Imports
import { Form, useFetcher } from 'react-router-dom';

// ** Icons Imports
import { ArrowRightIcon, LoaderCircle, Settings } from 'lucide-react';

// ** Shadcn Components
import { Button } from 'src/components/ui/button';
import { Textarea } from 'src/components/ui/textarea';

interface IPromptBoxProps {
  action: string;
  methond: 'POST';
}

const PROMPT_FORM_FETCHER_KEY = 'somate-ai-prompt-form-fetcher';

const PromptBox = (props: IPromptBoxProps) => {
  const { action, methond } = props;

  const fetcher = usePromptBoxFetcher();
  const { state } = fetcher ?? {};

  return (
    <div className='tw-sticky tw-bottom-0 tw-bg-white tw-w-full tw-pt-4 tw-pb-6'>
      <div className='tw-max-w-2xl tw-w-full tw-border tw-rounded-2xl tw-mx-auto tw-py-1 tw-flex tw-flex-col tw-gap-1.5 tw-px-1 tw-bg-slate-50'>
        <Form
          navigate={false}
          fetcherKey={PROMPT_FORM_FETCHER_KEY}
          action={action}
          method={methond}
          className='tw-flex tw-items-center tw-gap-2 tw-p-2 tw-bg-white tw-rounded-xl tw-border tw-overflow-hidden'>
          <div>
            <Button
              type='button'
              size={'icon'}
              variant={'ghost'}
              className='!tw-rounded-full'>
              <Settings className='tw-w-4 tw-h-4' />
              <span className='tw-sr-only'>Settings</span>
            </Button>
          </div>
          <Textarea
            placeholder='Message SoMate AI...'
            name='prompt'
            id='prompt-box'
            rows={1}
            onInput={(e) => {
              const target = e.currentTarget;
              target.style.height = 'auto';
              target.style.height = `${target.scrollHeight}px`;
            }}
            className='!tw-min-h-full tw-max-h-[80px] tw-resize-none tw-border-none focus-visible:tw-ring-0 focus-visible:tw-ring-offset-0'
          />
          <div>
            {state == 'submitting' ? (
              <LoaderCircle className='tw-w-6 tw-h-6 tw-animate-spin tw-text-primary' />
            ) : (
              <Button
                type='submit'
                size='icon'
                className='tw-w-8 tw-h-8 !tw-rounded-full'>
                <ArrowRightIcon className='tw-w-4 tw-h-4' />
                <span className='tw-sr-only'>Send</span>
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export const usePromptBoxFetcher = () => {
  const currentFetcher = useFetcher({ key: PROMPT_FORM_FETCHER_KEY });

  return currentFetcher;
};

export default PromptBox;
