import { Fragment } from 'react';
import MetricsCard, { AuditRating } from 'src/User/components/MetricsCard';
import ProgressBar from 'src/User/components/ProgressBar';
import ComingSoonWrapper from 'src/User/components/ComingSoonWrapper';

type TAuditCardProps = {
  value: string | number;
  maxValue: string | number;
  minValue: string | number;
  avgValue: string | number;
  rating: AuditRating;
  metricTitle: string;
  metricDescription: string;
  icon: JSX.Element;
  showComingSoonOverlay?: boolean;
};

export default function AuditCard(props: TAuditCardProps) {
  const {
    value,
    maxValue,
    minValue,
    avgValue,
    rating,
    metricDescription,
    metricTitle,
    icon,
    showComingSoonOverlay
  } = props;

  const Container = !!showComingSoonOverlay ? ComingSoonWrapper : Fragment;

  return (
    <Container>
      <div className='bg-white p-4 tw-rounded-xl shadow'>
        <MetricsCard
          icon={icon}
          rating={rating}
          title={metricTitle}
          description={metricDescription}
          withShadow={false}
        />
        <div className='pb-4 px-4'>
          <ProgressBar
            value={value}
            startLabel={{
              title: 'min',
              value: minValue ?? '0'
            }}
            endLabel={{
              title: 'max',
              value: maxValue
            }}
            middleLabel={{
              title: 'avg',
              value: avgValue
            }}
            labelPostion='bottom'
            maxValue={maxValue}
            minValue={minValue}
          />
        </div>
      </div>
    </Container>
  );
}
