import { AUDIT_UI_MAP, AUDIT_DATA } from './constants';
import AuditCard from '../../components/AuditCard';

export default function Audit() {
  return (
    <div className='mt-4 d-flex flex-column gap-4'>
      {Object.entries(AUDIT_DATA).map(([key, value]) => {
        const metricKey = key as keyof typeof AUDIT_DATA;

        return (
          <AuditCard
            key={metricKey}
            icon={AUDIT_UI_MAP[metricKey].icon}
            maxValue={value.maxValue}
            minValue={value.minValue}
            avgValue={value.avgValue}
            metricTitle={String(value.value)}
            metricDescription={AUDIT_UI_MAP[metricKey].description}
            rating={value.rating}
            value={value.value}
            showComingSoonOverlay
          />
        );
      })}
    </div>
  );
}
