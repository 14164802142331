import { SVGProps } from 'react';
import { ROUTER_PATHS } from 'src/lib/constants';
import FullScreenFeedback from 'src/User/components/FullScreenFeedback';

export default function MaintenancePage() {
  return (
    <FullScreenFeedback
      icon={
        <WrenchIcon className='tw-mx-auto tw-h-12 tw-w-12 tw-text-primary' />
      }
      title={"We're currently under maintenance"}
      message={`
         Our website is undergoing some updates and improvements. We&apos;ll be
        back up and running shortly. Thank you for your patience!`}>
      <FullScreenFeedback.Button
        href={ROUTER_PATHS.DASHBOARD}
        label='Refresh'
      />
    </FullScreenFeedback>
  );
}

function WrenchIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <path d='M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z' />
    </svg>
  );
}
