import { Fragment, Suspense, useEffect, useRef } from 'react';
import ChatMessage from '../../components/ChatMessage';
import PromptBox, { usePromptBoxFetcher } from '../../components/PromptBox';
import { Await, useLoaderData, useParams } from 'react-router-dom';
import { IGetSomateAIConversationResponse } from 'src/User/services/somate-ai/models';
import { ISomateAIConversation } from '../../types';
import Markdown from 'markdown-to-jsx';
import { ROUTER_PATHS } from 'src/lib/constants';
import { useTranslation } from 'react-i18next';
import { TNamespace } from 'src/i18n';
import LoadingConversation from '../../components/LoadingConversation';
import { Sparkles } from 'lucide-react';
import classNames from 'classnames';
import styles from './old-conversation.module.css';

const SomateAIConversationContainer = () => {
  const { conversation } = useLoaderData() as any;
  const { t } = useTranslation<TNamespace>();

  return (
    <Suspense
      fallback={
        <div className='tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-5 tw-h-full tw-w-full md:tw-max-w-xl lg:tw-max-w-2xl xl:tw-max-w-4xl tw-mx-auto'>
          <div className='tw-flex tw-items-center tw-gap-2'>
            <span className='!tw-font-bold tw-text-2xl'>{t('somate-ai')}</span>
            <Sparkles className='tw-w-10 tw-h-10' />
          </div>
          <LoadingConversation
            title='Loading Conversation'
            steps={[{ label: 'Retrieving previous messages', isActive: true }]}
          />
        </div>
      }>
      <Await
        resolve={conversation}
        errorElement={<div>Unable to load conversation</div>}>
        {(response: IGetSomateAIConversationResponse) => (
          <div className='tw-h-full'>
            {response.data.map((conversation) => (
              <SomateAIConversation
                key={conversation.id}
                conversation={conversation}
              />
            ))}
          </div>
        )}
      </Await>
    </Suspense>
  );
};

interface ISomateAIConversationProps {
  conversation: ISomateAIConversation;
}

const SomateAIConversation = (props: ISomateAIConversationProps) => {
  const { conversation } = props;

  const conversationContainerRef = useRef<HTMLDivElement>(null);
  const params = useParams();
  const { t } = useTranslation<TNamespace>();
  const fetcher = usePromptBoxFetcher();

  useEffect(() => {
    conversationContainerRef.current?.scrollTo({
      top: conversationContainerRef.current?.scrollHeight,
      behavior: 'smooth'
    });
  }, [conversation, fetcher.state]);

  return (
    <div className='tw-flex tw-flex-col tw-h-full'>
      <div
        ref={conversationContainerRef}
        className={classNames(
          'tw-relative tw-flex tw-flex-col tw-items-center tw-justify-start tw-flex-1 tw-gap-8 tw-pr-4 tw-pt-4 tw-mx-auto tw-w-full tw-h-full tw-overflow-auto',
          styles['mask-container']
        )}>
        {conversation.messages.map((message, index) => (
          <Fragment key={index}>
            <ChatMessage name={'You'}>
              <Markdown>{message.human_message}</Markdown>
            </ChatMessage>
            <ChatMessage name={t('somate-ai')}>
              <Markdown>{message.ai_message}</Markdown>
            </ChatMessage>
          </Fragment>
        ))}
        {fetcher.state == 'loading' || fetcher.state == 'submitting' ? (
          <LoadingConversation
            title='Searching for the best response'
            steps={[{ label: 'Generating response...', isActive: true }]}
          />
        ) : null}
      </div>
      <PromptBox
        key={JSON.stringify(conversation)}
        action={`${ROUTER_PATHS.SOMATE_AI_LAYOUT}/${params.conversationId}`}
        methond='POST'
      />
    </div>
  );
};

export default SomateAIConversationContainer;
