export const COMPETITION_DATA = [
  {
    account_info: {
      avatar: '/images/place-holder-avatar.png',
      username: 'ipsum2',
      name: 'ipsum ipsum',
      borderRadius: 'rounded-full'
    },
    follower_count: {
      value: 96000,
      change_count: +348,
      change_rate: +0.36
    },
    following_count: {
      value: 219,
      change_count: +348,
      change_rate: +0.36
    },
    media_count: {
      value: 800,
      change_count: +348,
      change_rate: +0.36
    },
    engagement_rate: {
      value: 0.76,
      change_count: +0.11,
      change_rate: -12.36
    }
  },
  {
    account_info: {
      avatar: '/images/place-holder-avatar.png',
      username: 'ipsum2',
      name: 'ipsum ipsum',
      borderRadius: 'rounded-full'
    },
    follower_count: {
      value: 96000,
      change_count: +348,
      change_rate: +0.36
    },
    following_count: {
      value: 219,
      change_count: +348,
      change_rate: +0.36
    },
    media_count: {
      value: 800,
      change_count: +348,
      change_rate: +0.36
    },
    engagement_rate: {
      value: 0.76,
      change_count: +0.11,
      change_rate: -12.36
    }
  },
  {
    account_info: {
      avatar: '/images/place-holder-avatar.png',
      username: 'ipsum2',
      name: 'ipsum ipsum',
      borderRadius: 'rounded-full'
    },
    follower_count: {
      value: 96000,
      change_count: +348,
      change_rate: +0.36
    },
    following_count: {
      value: 219,
      change_count: +348,
      change_rate: +0.36
    },
    media_count: {
      value: 800,
      change_count: +348,
      change_rate: +0.36
    },
    engagement_rate: {
      value: 0.76,
      change_count: +0.11,
      change_rate: -12.36
    }
  }
];
