import {
  useRouteLoaderData,
  useParams,
  Outlet,
  useSearchParams
} from 'react-router-dom';
import { ROUTER_PATHS_ID } from 'src/lib/constants';
import ProfileCard from 'src/User/components/ProfileCard';
import AnalyticsNavbar from 'src/User/components/AnalyticsNavbar';
import { PromiseType } from 'src/lib/models';
import analyticsLayoutLoader from '../../../layout/loaders/analyticsLayoutLoader';
import addParamsToPathname from 'src/lib/utils/addParamsToPathname';
import { DATE_SEARCH_PARAMS } from 'src/configs';
import { useTranslation } from 'react-i18next';
import { capitalize, startCase } from 'lodash';
import { TNamespace } from 'src/i18n';

const navLinks = [
  {
    href: 'overview',
    i18nKey: 'overview'
  },
  // {
  //   href: 'audit',
  //   i18nKey: 'audit',
  // },
  {
    href: 'audience',
    i18nKey: 'audience'
  },
  {
    href: 'reels',
    i18nKey: 'reels'
  },
  {
    href: 'stories',
    i18nKey: 'stories'
  },
  {
    href: 'posts',
    i18nKey: 'posts'
  }
];

const IGBusinessAnalyticsLayout = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { socialAccounts } = useRouteLoaderData(
    ROUTER_PATHS_ID.ANALYTICS_LAYOUT
  ) as PromiseType<ReturnType<typeof analyticsLayoutLoader>>;
  const { t } = useTranslation<TNamespace>();

  const igBusinessAccountInfo = socialAccounts?.IG_BUSINESS;
  const activeSocialAccount = igBusinessAccountInfo?.find(
    ({ ig_business_account_id }) => ig_business_account_id == id
  );
  const duration = {
    start: searchParams.get(DATE_SEARCH_PARAMS.start) ?? undefined,
    stop: searchParams.get(DATE_SEARCH_PARAMS.stop) ?? undefined
  };

  return (
    <section>
      <header>
        <div className='shadow tw-rounded-b-xl tw-mb-5 tw-overflow-hidden'>
          <ProfileCard
            username={activeSocialAccount?.username ?? ''}
            bio={activeSocialAccount?.bio ?? ''}
            created_at={activeSocialAccount?.created_at ?? null}
            updated_at={activeSocialAccount?.updated_at ?? null}
            profilePhotoUrl={activeSocialAccount?.profile_picture_url ?? ''}
            profileMetrics={[
              {
                label: capitalize(t('followers', { ns: 'analytics' }) ?? ''),
                value: activeSocialAccount?.followers.toLocaleString() ?? ''
              },
              {
                label: capitalize(
                  t('following', {
                    ns: 'analytics'
                  }) ?? ''
                ),
                value: activeSocialAccount?.following.toLocaleString() ?? ''
              },
              {
                label: capitalize(t('posts') ?? ''),
                value: activeSocialAccount?.media_count.toLocaleString() ?? ''
              }
            ]}
          />
        </div>
        <AnalyticsNavbar
          navLinks={navLinks.map(({ href, i18nKey }) => ({
            title: startCase(t(i18nKey) ?? ''),
            href: addParamsToPathname(href, duration)
          }))}
        />
      </header>
      <section className='d-flex flex-column tw-mt-6 tw-gap-3 py-0 w-100'>
        <Outlet />
      </section>
    </section>
  );
};

export default IGBusinessAnalyticsLayout;
