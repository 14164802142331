import { useMemo } from 'react';
import createTableData from 'src/User/features/Analytics/utils/createTableData';
import createMediaTableSummaryData from '../../../utils/createMediaTableSummaryData';
import PostPreviewCellRenderer from '../../Posts/components/PostPreviewCellRenderer';
import { useTranslation } from 'react-i18next';
import { TNamespace } from 'src/i18n';
import { startCase } from 'lodash';
import { IInstagramBusinessMediaInsight } from '../../../types';
import { TColumDef } from 'src/User/features/Viz/components/types';

const tableFields = [
  'media_preview_details',
  'impressions',
  'reach',
  'navigation',
  'profile_visits',
  'profile_activity',
  'follows',
  'saved',
  'total_interactions',
  'shares'
] as const;

const useGetStoriesTableData = (data: IInstagramBusinessMediaInsight[]) => {
  const tableData = createTableData(data);
  const summaryData = createMediaTableSummaryData({ data: tableData });
  const { t } = useTranslation<TNamespace>();

  const tableCols = useMemo(
    (): TColumDef =>
      tableFields.map((key) => ({
        field: key,
        headerName: startCase(
          t(key, {
            ns: 'analytics'
          }) ?? ''
        ),
        minWidth:
          key === 'media_preview_details' ? 400 : key.length > 10 ? 200 : 150,
        sortable: true,
        autoHeight: true,
        flex: 1,
        cellRenderer:
          key === 'media_preview_details' ? PostPreviewCellRenderer : undefined,
        cellStyle: {
          display: 'flex',
          alignItems: 'center'
        },
        valueFormatter: ({ value }) => value ?? 'N/A'
      })),
    [t]
  );

  return {
    tableData,
    summaryData,
    tableCols
  };
};

export default useGetStoriesTableData;
