import React from 'react';
import './GroupsModal.css';
import membersImg from '../../Assets/images/workspace.jpg';

const GroupsModal = (props) => {

    return (
        <>
            <div className="modal-head">
                <div className="groups-cell-head">
                    <h4>Groups</h4>
                    <small>8 Groups</small>
                </div>
                <button type="button" className="btn-close" onClick={() => { props.setModal2(!props.modal2) }}></button>
            </div>
            <div className="groups-cell">
                <div className="group-list">
                    <div className="group-list-item">
                        <h5>Group title</h5>
                        <div className="groups-members-list">
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                        </div>
                    </div>
                    <div className="group-list-item">
                        <h5>Group title</h5>
                        <div className="groups-members-list">
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                        </div>
                    </div>
                    <div className="group-list-item">
                        <h5>Group title</h5>
                        <div className="groups-members-list">
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                        </div>
                    </div>
                    <div className="group-list-item">
                        <h5>Group title</h5>
                        <div className="groups-members-list">
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                        </div>
                    </div>
                    <div className="group-list-item">
                        <h5>Group title</h5>
                        <div className="groups-members-list">
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                            <span>
                                <img src={membersImg} alt="Group Member" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GroupsModal
