import { ReactNode } from 'react';
import { Button } from 'src/components/ui/button';

interface ISuggestionTileProps {
  icon: ReactNode;
  suggestion: string;
  onClick: () => void;
}

const SuggestionTile = (props: ISuggestionTileProps) => {
  const { suggestion, icon, onClick } = props;

  return (
    <Button
      variant='ghost'
      className='tw-shadow-sm tw-py-4 tw-gap-4 tw-flex-col tw-rounded-xl !tw-items-start !tw-justify-start tw-text-left !tw-text-base tw-border tw-w-full tw-h-auto tw-min-h-24'
      onClick={onClick}>
      {icon}
      <span className='tw-text-wrap tw-text-slate-500'>{suggestion}</span>
    </Button>
  );
};

export default SuggestionTile;
