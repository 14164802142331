// Wrapper for APIs
import Api from 'src/Api';
import {
  IChangePasswordProps,
  IForgotPasswordProps,
  IRegisterProps
} from './types';
import { IUserInfo } from 'src/lib/models/userInfo';

// Login
export const login = async (data: any) => {
  return await Api.post('auth/login/', data);
};

// Logged Out
export const logout = async () => {
  return await Api.post('auth/logout/');
};

// Get user profile
export const profile = async () => {
  return await Api.get<IUserInfo>('auth/user/');
};

// Registration
export const register = async (data: IRegisterProps) => {
  const newData = {
    email: data.email,
    password1: data.password,
    password2: data.password_confirm,
    first_name: data.first_name,
    last_name: data.last_name,
    timezone: 'Africa/Abidjan',
    language: 'EN'
  };

  return await Api.post('auth/registration/', newData);
};

// Invite User
export const inviteUser = async (data: IRegisterProps) => {
  const newData = {
    email: data.email,
    password1: data.password,
    password2: data.password_confirm,
    first_name: data.first_name,
    last_name: data.last_name,
    timezone: 'Africa/Abidjan',
    language: 'EN'
  };

  return await Api.post('workspace/accept-invitation/', newData);
};

// Forgot Password
export const forgot = async ({ email }: IForgotPasswordProps) => {
  return await Api.post('auth/password/reset/', { email });
};

// Reset Password
export const reset = async (data: any, Uid: any, Token: any) => {
  const newData = {
    new_password1: `${data.confirm_password}`,
    new_password2: `${data.confirm_password}`,
    uid: `${Uid}`,
    token: `${Token}`
  };

  return await Api.post('auth/password/reset/confirm/', newData);
};

// Change Password
export const change = async (data: IChangePasswordProps) => {
  const newPasswords = {
    new_password1: data.password,
    new_password2: data.confirm_password
  };

  return await Api.post('auth/password/change/', newPasswords);
};

// Verify User Email
export const verify = async (key: string) => {
  return await Api.post('auth/registration/verify-email/', { key });
};

// Resend verification code
export const resendVerificationCode = async (email: string) => {
  return await Api.post('auth/registration/resend-email/', { email });
};
