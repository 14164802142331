import { useEffect, useState } from 'react';
import React from 'react';
import './EventCalendarWeek.css';
import { useNavigate } from 'react-router-dom';
import { addDays, startOfWeek, format, subWeeks } from 'date-fns';

const EventCalendarWeek = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [calender, setCalender] = useState([]);
  const navigate = useNavigate();
  const nextWeek = () => {
    const selectedDate2 = addDays(selectedDate, 7);
    setSelectedDate(selectedDate2);
    getWeek(selectedDate2);
  };

  const getWeek = (date) => {
    let weekDays = startOfWeek(date);

    const week = [];
    for (let i = 0; i < 7; i++) {
      week.push(weekDays);
      weekDays = addDays(weekDays, 1);
    }
    setCalender([...week]);
  };

  const perTimeFun = (dd) => {
    alert('called ' + dd.target.innerHTML);
  };

  const prevWeek = () => {
    const selectedDate2 = subWeeks(selectedDate, 1);
    setSelectedDate(selectedDate2);
    getWeek(selectedDate2);
  };

  const showWeekMonth = () => {
    const weekDays = startOfWeek(selectedDate);
    const lastDateOfMonth = addDays(weekDays, 6);

    if (weekDays.getMonth() == lastDateOfMonth.getMonth()) {
      return format(weekDays, 'MMMM') + '  ' + format(selectedDate, 'Y');
    } else {
      return (
        format(weekDays, 'MMM') +
        ' - ' +
        format(lastDateOfMonth, 'MMM') +
        '  ' +
        format(selectedDate, 'Y')
      );
    }
  };

  const [selectedOption, setSelectedOption] = useState('Week');

  useEffect(() => {
    getWeek(selectedDate);
  }, []);

  return (
    <div className='calendar-week'>
      <div className='calendar-header'>
        <div className='cal-schedule'>
          <label>Schedule :</label>
          <select
            onChange={(e) => {
              setSelectedOption(e.target.value);
              navigate('/calendar');
            }}
            value={selectedOption}>
            <option value='Month'>Month</option>
            <option value='Week'>Week</option>
          </select>
        </div>
        <div className='month-year-navigator'>
          <div className='month-year-selector'>
            <span className='month-prev'>
              <i
                onClick={() => {
                  prevWeek();
                }}
                className='bi bi-chevron-left'></i>
            </span>
            <div className='current_month_year'>
              {/* March, 2023 */}
              {showWeekMonth()}
            </div>
            <span className='month-next'>
              <i
                onClick={() => {
                  nextWeek();
                }}
                className='bi bi-chevron-right'></i>
            </span>
          </div>
          <div
            onClick={() => {}}
            className='month-year-selector-direct'>
            <i className='bi bi-calendar2-event'></i>
          </div>
        </div>
        <div className='status-filter'>
          {/* <select>
                        <option>Active</option>
                        <option>Deactive</option>
                    </select> */}
        </div>
      </div>
      <div className='calendar-cells-wrappper'>
        <div className='calendar-week-inner'>
          <div className='table-responsive'>
            <table className='table table-bordered '>
              <thead>
                {/* <tr>
                                    <th scope="col">Time</th>
                                    <th scope="col">01 Mon</th>
                                    <th scope="col">02 Tue</th>
                                    <th scope="col">03 Wed</th>
                                    <th scope="col">04 Thu</th>
                                    <th scope="col">05 Fri</th>
                                    <th scope="col">06 Sat</th>
                                    <th scope="col">07 Sun</th>
                                </tr> */}
                <tr>
                  <th scope='col'>Time</th>
                  {calender.map((date, i) => {
                    return (
                      <th
                        key={i}
                        scope='col'>
                        {format(date, 'd')} {format(date, 'EEE')}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {Array.from(Array(25).keys()).map((as, ind) => {
                  return (
                    <tr key={ind}>
                      <th scope='row'>
                        {as}:00 {as >= 12 ? 'pm' : 'am'}
                      </th>
                      {calender.map((date, i) => {
                        if (i == 0 && as == 2) {
                          return (
                            <td
                              key={i}
                              onClick={(e) => {
                                perTimeFun(e);
                              }}
                              className='bg-color'>
                              Vacation Start
                            </td>
                          );
                        } else {
                          return (
                            <td
                              key={i}
                              onClick={(e) => {
                                perTimeFun(e);
                              }}></td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCalendarWeek;
