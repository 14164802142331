import * as D from 'src/components/ui/dialog';
import { Button } from 'src/components/ui/button';
import { ReactNode } from 'react';

interface IUnControlledDialogProps {
  title: ReactNode;
  children: ReactNode;
  open: boolean;
  onOpenChange: () => void;
  onCancel: () => void;
  onConfirm?: () => void;
  submitButtonLabel?: ReactNode;
  disableSubmit?: boolean;
}

const UnControlledDialog = (props: IUnControlledDialogProps) => {
  const {
    title,
    children,
    open,
    submitButtonLabel = 'Submit',
    disableSubmit,
    onOpenChange,
    onCancel,
    onConfirm
  } = props;

  return (
    <D.Dialog
      open={open}
      onOpenChange={onOpenChange}>
      <D.DialogContent>
        <D.DialogHeader>
          <D.DialogTitle>{title}</D.DialogTitle>
        </D.DialogHeader>
        {children}
        <D.DialogFooter>
          <div className='tw-w-full tw-flex tw-justify-between'>
            <Button
              variant={'secondary'}
              onClick={onCancel}>
              Cancel
            </Button>
            {submitButtonLabel && onConfirm ? (
              <Button
                variant={'default'}
                onClick={onConfirm}
                disabled={disableSubmit}>
                {submitButtonLabel}
              </Button>
            ) : null}
          </div>
        </D.DialogFooter>
      </D.DialogContent>
    </D.Dialog>
  );
};

export default UnControlledDialog;
