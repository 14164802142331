import { AxiosResponse } from 'axios';
import Api from 'src/Api';
import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { IAddBrandMember, IBrandMember } from './types';

export const getBrandById = async ({ brandId }: { brandId: string }) => {
  return await Api.get<IBrand>(`/brand/${brandId}/`);
};

export const getBrands = async (): Promise<AxiosResponse<IBrand[]>> => {
  return await Api.get('/brand/');
};

export const createBrand = async ({
  brandName
}: {
  brandName: string;
}): Promise<AxiosResponse<IBrand>> => {
  const bodyParameters = {
    name: brandName
  };

  return await Api.post('/brand/', bodyParameters);
};

export const updateBrand = async ({
  brand
}: {
  brand: IBrand;
}): Promise<AxiosResponse<IBrand>> => {
  const { id: brandId, name, is_default } = brand;

  return await Api.patch(`/brand/${brandId}/`, { name, is_default });
};

export const deleteBrand = async ({ brandId }: { brandId: string }) => {
  return await Api.delete(`/brand/${brandId}/`);
};

export const addBrandUser = async ({
  email,
  role,
  brandId
}: IAddBrandMember): Promise<AxiosResponse<IBrandMember>> => {
  return await Api.post(`/brand/${brandId}/add_user/`, { email, role });
};

export const getBrandUsers = async ({
  brandId
}: {
  brandId: string;
}): Promise<AxiosResponse<IBrandMember[]>> => {
  return await Api.get(`/brand/${brandId}/brand_users/`);
};

export const changeBrandUserRole = async ({
  email,
  role,
  brandId
}: IAddBrandMember): Promise<AxiosResponse<IBrandMember>> => {
  return await Api.patch(`/brand/${brandId}/add_user/`, { email, role });
};

export const removeBrandUser = async ({ email, brandId }: IAddBrandMember) => {
  return await Api.post(`/brand/${brandId}/add_user/`, { email });
};
