import Api from 'src/Api';
import {
  IGetAllSocaialAccountsResponseByType,
  IGetAllSocialAccountsProps,
  IGetAllSocialAccountsResponse
} from './models';
import { TSocialAccountKind } from 'src/lib/models/socialAccounts';

export const getAllConnectSocialAccounts = async (brandId: string) => {
  return await Api.get<IGetAllSocialAccountsResponse>(
    `/social-accounts/?brand_id=${brandId}`
  );
};

export const getConnectedSocialAccountsByType = async <
  T extends TSocialAccountKind
>(
  props: IGetAllSocialAccountsProps<T>
) => {
  const { brandId, accountType } = props;

  return await Api.get<IGetAllSocaialAccountsResponseByType<T>>(
    `/social-accounts/?brand_id=${brandId}&account_type=${accountType}`
  );
};
