import { startCase, map } from 'lodash';
import { Summary } from '../types';
import { MetricSummaryElement } from 'src/User/features/Dashboard/components/CampaignPerformanceOverview';
import { Card, CardContent } from 'src/components/ui/card';
import { ReactNode } from 'react';
import { Skeleton } from 'src/components/ui/skeleton';
import { useTranslation } from 'react-i18next';
import { TNamespace } from 'src/i18n';

interface CampaignSummaryProps {
  data: Omit<Summary, 'cpm'>;
  currency: string;
}

const getChangeRate = (current: number, previous: number, metric: string) => {
  const percentageChange =
    (((current || 0) - (previous || 0)) / (previous || 1)) * 100;
  const percentageChangeToFixed = Number(percentageChange.toFixed(2));

  return percentageChangeToFixed || undefined;
};

const CampaignSummary = (props: CampaignSummaryProps) => {
  const { data, currency } = props;

  const { i18n } = useTranslation();
  const { language: currentLanguage } = i18n;
  const locale = currentLanguage === 'en' ? 'en-US' : 'pl-PL';

  const currencyIntlInstance = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency
  });
  const genericIntlInstance = new Intl.NumberFormat(locale);

  const { t } = useTranslation<TNamespace>();

  return (
    <CampaignSummary.Container>
      {map(data, (summaryValue, metric) => {
        if (
          typeof summaryValue.current == 'number' &&
          typeof summaryValue.previous == 'number'
        ) {
          const typedSummaryValue = summaryValue as {
            current: number;
            previous: number;
          };
          const { current, previous } = typedSummaryValue;
          const intlRef =
            metric == 'spend' ? currencyIntlInstance : genericIntlInstance;
          const i18nMetricLabel = t(
            `meta.adcreative.common.${metric}`,
            metric,
            {
              ns: 'adAnalytics'
            }
          );
          const changeRateFormatter = (changeRate: number) =>
            genericIntlInstance.format(changeRate) + '%';

          return (
            <MetricSummaryElement
              key={metric}
              changeRate={getChangeRate(current, previous, metric)}
              label={startCase(i18nMetricLabel ?? metric)}
              value={intlRef.format(typedSummaryValue.current)}
              changeRateFormatter={changeRateFormatter}
            />
          );
        }

        return null;
      })}
    </CampaignSummary.Container>
  );
};

CampaignSummary.Container = ({ children }: { children: ReactNode }) => (
  <Card className='tw-w-full'>
    <CardContent className='tw-flex tw-gap-4 tw-justify-between !tw-p-6'>
      {children}
    </CardContent>
  </Card>
);

CampaignSummary.Loading = () => (
  <Skeleton className='tw-w-full tw-h-[119px] tw-rounded-xl' />
);

const ErrorElement = () => {
  const { t } = useTranslation<TNamespace>();

  return (
    <p className='!tw-my-0'>
      {t('meta.summary.campaignSummary.error.404', {
        ns: 'adAnalytics'
      })}
    </p>
  );
};

CampaignSummary.Error = ErrorElement;

export default CampaignSummary;
