import { AxiosError } from 'axios';
import { ActionFunctionArgs, redirect } from 'react-router-dom';
import { USER_CONFIG } from 'src/configs';
import { ROUTER_PATHS } from 'src/lib/constants';
import displayAxiosError from 'src/lib/utils/displayAxiosError';
import { IBrand } from 'src/User/recoil/Atoms/UserBrandAtom';
import { deleteConversation } from 'src/User/services/somate-ai';

export const deleteConversationAction = async (args: ActionFunctionArgs) => {
  const { request } = args;

  const formData = await request.formData();
  const conversationId = formData.get('conversationId');
  const isCurrentPageLink = formData.get('isCurrentPageLink');
  const redirectionPath = `${ROUTER_PATHS.SOMATE_AI_LAYOUT}/${ROUTER_PATHS.SOMATE_AI_NEW_CONVERSATION}`;

  try {
    if (typeof conversationId == 'string') {
      const activeBrandJSON = localStorage.getItem(
        USER_CONFIG.storageUserBrand
      );
      const { id: brandId }: IBrand = JSON.parse(`${activeBrandJSON}`);

      const response = await deleteConversation({
        conversationId: conversationId,
        brandId
      });

      return isCurrentPageLink == 'yes' ? redirect(redirectionPath) : response;
    }

    throw new Response('Conversation id is missing', { status: 400 });
  } catch (error) {
    // * We are using AxiosError type guard because Safari produces weird
    // * "Failed to load resource: The network connection was lost." when
    // * we send DELETE requests that responds with 204 status code
    if (!(error as AxiosError)?.response && !!(error as AxiosError)?.request) {
      return isCurrentPageLink == 'yes' ? redirect(redirectionPath) : null;
    }

    displayAxiosError(error, {
      title: 'Unable to delete chat',
      fallbackMsg: '',
      toast: true,
      duration: 7000
    });

    return null;
  }
};
