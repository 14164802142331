import { ITemplateState } from '../../../types';
import { TSidebarAction } from './models';

export default function reportReducer(
  state: ITemplateState,
  action: TSidebarAction
): ITemplateState {
  const { type, payload } = action;

  switch (type) {
    case 'UPDATE_TEMPLATE_NAME':
      return {
        ...state,
        templateName: payload
      };

    case 'UPDATE_SELECTED_ACCOUNT':
      return {
        ...state,
        socialAccounts: state.socialAccounts.map(({ accountId, ...rest }) => ({
          ...rest,
          status:
            accountId === payload.accountId ? payload.status : rest.status,
          accountId
        }))
      };

    case 'UPDATE_COVER_TITLE':
      return {
        ...state,
        coverTitle: payload
      };

    case 'UPDATE_COLOR':
      return {
        ...state,
        colors: {
          ...state.colors,
          ...payload
        }
      };

    case 'UPDATE_ACTIVE_PAGES':
      return {
        ...state,
        socialAccounts: state.socialAccounts.map((account) => {
          if (account.accountId == payload.accountId) {
            return {
              ...account,
              pages: {
                ...account.pages,
                [payload.page.name]: payload.page.isActive
              }
            };
          }

          return account;
        })
      };

    case 'UPDATE_LOGO_IMG':
      return {
        ...state,
        logo: payload
      };

    case 'UPDATE_BACKGROUND_IMG':
      return {
        ...state,
        backgroundImage: payload
      };

    case 'UPDATE_COVER_IMG':
      return {
        ...state,
        coverBackgroundImage: payload
      };

    default:
      return {
        ...state
      };
  }
}
