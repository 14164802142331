import { startCase } from 'lodash';
import { useParams } from 'react-router-dom';
import ErrorRenderer from 'src/User/components/Error/AnalyticsErrorRenderer';
import { TMediaType } from 'src/User/services/oauth-connect/models';

export const ERROR_MESSAGE_MAP = {
  'ig-business': 'Unable to get Instagram business accounts',
  'facebook-page': 'Unable to get Facebook pages'
};

type TErrorTitle = keyof typeof ERROR_MESSAGE_MAP;

const InstagramBussinessAccountsErrorBoundary = () => {
  const { mediaType } = useParams() as { mediaType: TMediaType | undefined };
  const errorTitle =
    ERROR_MESSAGE_MAP?.[mediaType as TErrorTitle] ??
    `Unable to get ${startCase(mediaType)} accounts`;

  return (
    <ErrorRenderer
      title={errorTitle}
      fallbackErrorMsg='please reload the page and try again'
      hideConnectAccount={true}
    />
  );
};

export default InstagramBussinessAccountsErrorBoundary;
