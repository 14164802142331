import ReactApexChart from 'react-apexcharts';
import { IChartProps } from '../types';
import ChartHeaderFilters from './components/ChartHeaderFilters';
import styles from './chart.module.css';
import { CHART_COLORS } from './constants/colors';
import { truncate, omit } from 'lodash';

export default function Chart({ data, header, type, height }: IChartProps) {
  const { options, series } = data;

  return (
    <div className='w-100'>
      {header ? (
        <hgroup className='w-100 d-flex align-items-end justify-content-between mb-3'>
          <h4 className={styles['chart-title']}>{header.title}</h4>
          <ChartHeaderFilters
            data={header.filtersOptions}
            onFilterOptionClick={header?.onFilterOptionClick}
            preventDefaultFormatter={
              header.preventDefaultFilterOptionsFormatter
            }
          />
        </hgroup>
      ) : null}
      <ReactApexChart
        options={{
          colors: CHART_COLORS,
          dataLabels: {
            enabled: false,
            ...(options?.dataLabels ?? {})
          },
          markers: {
            size: 3,
            ...(options?.markers ?? {})
          },
          stroke: {
            curve: 'smooth',
            width: 2,
            ...(options?.stroke ?? {})
          },
          tooltip: {
            shared: true,
            intersect: false,
            ...(options?.tooltip ?? {})
          },
          fill: {
            colors: CHART_COLORS,
            ...(options?.fill ?? {})
          },
          legend: {
            show: false,
            ...(options?.legend ?? {})
          },
          xaxis: {
            labels: {
              formatter: (value) => truncate(value, { length: 30 }),
              ...(options?.xaxis?.labels ?? {})
            },
            ...(omit(options?.xaxis, ['labels']) ?? {})
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              borderRadiusApplication: 'end',
              ...(options?.plotOptions?.bar ?? {})
            },
            ...(options?.plotOptions ?? {})
          },
          chart: {
            width: '100%',
            ...(options?.chart ?? {})
          },
          ...omit(options, [
            'dataLabels',
            'markers',
            'stroke',
            'tooltip',
            'fill',
            'legend',
            'xaxis',
            'plotOptions',
            'chart'
          ])
        }}
        series={series}
        type={type}
        height={height ?? 350}
      />
    </div>
  );
}
