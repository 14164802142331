import { CHART_COLORS } from 'src/User/features/Viz/components/Chart/constants/colors';
import { ITemplateState } from '../../../types';

export const EMPTY_REPORT_TEMPLATE_STATE_WITHOUT_BRAND: Omit<
  ITemplateState,
  'brand'
> = {
  templateName: 'My Template',
  socialAccounts: [],
  coverTitle: 'My Template Title',
  colors: {
    1: CHART_COLORS[0],
    2: CHART_COLORS[1],
    3: CHART_COLORS[2],
    4: CHART_COLORS[3],
    5: CHART_COLORS[4]
  },
  backgroundImage: null,
  coverBackgroundImage: null,
  logo: null
};
